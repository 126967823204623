import {Injectable} from '@angular/core';
import {Router} from "@angular/router";

@Injectable()

export class TableSettingsService {
    private validTableSettingNames: string[] = [
        'assignmentListSettings',
        'customerListSettings',
        'chainListSettings',
        'storeListSettings',
        'employeeListSettings',
        'customerUserListSettings',
        'salesConsultantListSettings',
        'teamplayerUserListSettings',
        'exportListSettings',
        'userSendSettings'
    ];


    constructor(private router: Router) {
    }


    getTableSettingsName() {
        let name;
        // find the name of the tableSettings based on the router
        if (this.router.url.includes('/task/list')) {
            name = 'assignmentListSettings';
        } else if (this.router.url.includes('/customer/list')) {
            name = 'customerListSettings';
        } else if (this.router.url.includes('/chain/list')) {
            name = 'chainListSettings';
        } else if (this.router.url.includes('/store/list')) {
            name = 'storeListSettings';
        } else if (this.router.url.includes('/user/list/employee')) {
            name = 'employeeListSettings';
        } else if (this.router.url.includes('/user/list/customer')) {
            name = 'customerUserListSettings';
        } else if (this.router.url.includes('/user/list/consultant')) {
            name = 'salesConsultantListSettings';
        } else if (this.router.url.includes('/user/list/teamplayer')) {
            name = 'teamplayerUserListSettings';
        } else if (this.router.url.includes('/export/list')) {
            name = 'exportListSettings';
        } else if (this.router.url.includes('/user/send')) {
            name = 'userSendSettings';
        } else {
            throw new Error("No tableSettingsName found");
        }

        return name;
    }

    getTableSettings(key = undefined) {
        this.validateTableSettingsName();

        // retrieve from sessionstorage
        let tableSettings = JSON.parse(sessionStorage.getItem(this.getTableSettingsName()));
        if (!tableSettings) tableSettings = {};

        // if asking for a key, return the value
        if (key) {
            if (tableSettings.hasOwnProperty(key)) {
                return tableSettings[key];
            } else {
                return undefined;
            }
        }

        // return the tableSettings as an object
        return tableSettings;
    }

    setTableSettings(key, value) {
        this.validateTableSettingsName();

        // Set in SESSION
        const storedSessionStorage = this.getTableSettings();
        storedSessionStorage[key] = value;
        sessionStorage.setItem(this.getTableSettingsName(), JSON.stringify(storedSessionStorage));
    }

    validateTableSettingsName() {
        const name = this.getTableSettingsName();

        if (!this.validTableSettingNames.includes(name)) {
            throw new Error("Invalid TableSettingsName: '" + name + "'");
        }

        return true;
    }
}