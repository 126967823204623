import {Component, OnInit} from '@angular/core';
import {UtilService} from '../services/util.service';
import {DataService} from '../services/data.service';
import {ApiService} from '../services/api.service';
import {LangService} from '../services/lang.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '../models/store';
import {TableHeader} from '../models/tableheader';
import {TableSettings} from '../models/tablesettings';
import {NotificationService} from '../shared/notification.service';
import {CSVService} from '../services/csv.service';
import {UserService} from '../services/user.service';
import {Constants} from '../constants';

@Component({
    selector: 'app-store-list',
    templateUrl: './store-list.component.html',
    styleUrls: ['./store-list.component.scss']
})
export class StoreListComponent implements OnInit {

    Constants: any = Constants;

    isLoadingView: boolean = false;

    //Table
    isLoadingTable: boolean = true;
    tableData: Store[];
    tableHeaders: TableHeader[];
    tableSettings: TableSettings;


    constructor(public apiService: ApiService,
                public route: ActivatedRoute,
                private router: Router,
                public utilService: UtilService,
                public userService: UserService,
                public lang: LangService,
                public csvService: CSVService,
                public dataService: DataService,
                private notificationService: NotificationService
    ) {
    }

    ngOnInit() {
        this.tableHeaders = [
            new TableHeader("store_name", this.lang.la.name),
            new TableHeader("chains.chain_name", this.lang.la.chain),
            new TableHeader("store_phone", this.lang.la.phoneno),
            new TableHeader("store_address", this.lang.la.address),
            new TableHeader("store_postal_code", this.lang.la.postalcode),
            new TableHeader("store_city", this.lang.la.city)
        ];

        this.tableSettings = new TableSettings();
        this.tableSettings.sortBy = this.tableHeaders[0];
        this.tableSettings.sortBy.asc = false;
        this.tableSettings.tableHeaders = this.tableHeaders;

        this.tableSettings.searchColumn = this.tableHeaders[0];

        this.tableSettings.columns = "chain_id,chains.chain_name,store_id,store_name,store_phone,store_address,store_postal_code,store_city";
    }

    getData(tableSettings: TableSettings) {
        this.tableSettings = tableSettings;

        this.isLoadingTable = true;

        this.apiService.getStores(tableSettings).subscribe((data) => {

            this.isLoadingView = false;
            this.isLoadingTable = false;
            this.tableData = data.main;
            this.tableSettings.tableOffset = data.tableOffset;
            this.dataService.saveStores(this.tableData);

        }, (error) => {
            this.utilService.onRetryApi("getStores", () => {
                this.getData(this.tableSettings);
            }, () => {
                this.isLoadingView = false;
                this.isLoadingTable = false;
                this.notificationService.setNotification(this.lang.la.error_general_message + " " + error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
            });
        });

    }

    onRowPressed(event) {
        this.router.navigate(['app/store/show/', this.tableData[event.pos].store_id]);
    }

}
