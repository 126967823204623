<h1 class="push-m-50-b h2">{{lang.la.create_an_export}}</h1>


<div class="card card-top">

    <div class="card-body row">
        <div class="col-sm-6">

            <div class="form-group">
                <label>* {{lang.la.customer_slash_employee}}</label>
                <div class="dropdown dropdown-select">

                    <div class="input-cancel-button input-cancel-button--dropdown input-has-appended-items">
                        <input type="text" class="form-control dropdown-toggle" placeholder="{{lang.la.search}}"
                               [ngClass]="{'is-invalid': inputCustomerOrEmployeeSelectedError.error}"
                               (click)="isDropdownActive = !isDropdownActive" [(ngModel)]="inputDropdown"
                               (input)="onSearchChange($event.target)"
                               (keydown.tab)="onItemSelected(0)">
                        <img src="./assets/ic_cancel.svg" class="input-cancel-button-img" alt=""
                             *ngIf="inputDropdown.length != 0" (click)="onClearSearch()">
                        <div class="invalid-feedback d-inline" *ngIf="inputCustomerOrEmployeeSelectedError.error">
                            {{inputCustomerOrEmployeeSelectedError.error}}
                        </div>
                    </div>

                    <div class="dropdown-menu" [ngClass]="{'show': isDropdownActive}"
                         *ngIf="customerAndEmployee.length != 0">
                        <a class="dropdown-item" *ngFor="let item of customerAndEmployee; let pos = index"
                           (click)="onItemSelected(pos)">
                            <b>{{item.type}}:</b> {{item.name}}
                        </a>
                    </div>

                    <img src="./assets/dropdown.svg" alt="" class="dropdown-select-arrow"
                         (click)="isDropdownActive = !isDropdownActive">
                </div>
                <div class="dropdown-back-click" *ngIf="isDropdownActive" (click)="isDropdownActive = false"></div>
            </div>


        </div>
    </div>

    <div class="card-divider"></div>

    <div class="card-body row">
        <div class="col-sm-6">
            <div class="form-group">
                <label>* {{lang.la.period}}</label>
                <div [(bsValue)]="inputPeriod " #drp="bsDaterangepicker"
                     bsDaterangepicker [bsConfig]="dpConfig">
                    <input type="text" class="form-control" [placeholder]="lang.la.select_a_period"
                           [ngClass]="{'is-invalid': inputPeriodError.error}"
                           [value]="inputPeriod && inputPeriod.length ? (inputPeriod[0] | mdate: 'DD/MM/YYYY') + '-' + (inputPeriod[1] | mdate: 'DD/MM/YYYY') : ''"
                           disabled
                    />
                </div>
                <div class="invalid-feedback d-inline" *ngIf="inputPeriodError.error">
                    {{inputPeriodError.error}}
                </div>
            </div>
        </div>
        <div class="col-sm-6">
        </div>
    </div>

    <div class="card-divider"></div>

    <div class="card-body row">
        <div class="col-sm-6">

            <div class="form-group">
                <label>{{lang.la.status}}</label>
                <select class="custom-select" (ngModelChange)="inputStatus.error = ''" [(ngModel)]="inputStatus.value"
                        name="inputStatus.value" [ngClass]="{'is-invalid': inputStatus.error}">
                    <option value="all" selected>{{lang.la.all}}</option>
                    <option value="i-udbud-fra-kunde">{{lang.la.created_awaiting_approval}}</option>
                    <option value="adhoc">{{lang.la.created_awaiting_info}}</option>
                    <option value="afventer-medarbejder-accept">{{lang.la.suppplied_waiting_employee}}</option>
                    <option value="afventer-medarbejder-udfyldelse">{{lang.la.assigned_employee_awaiting_report}}</option>
                    <option value="afventer-godkendelse">{{lang.la.awaiting_approval}}</option>
                    <option value="godkendt">{{lang.la.approved}}</option>
                </select>
                <div class="invalid-feedback d-inline" *ngIf="inputStatus.error">
                    {{inputStatus.error}}
                </div>
            </div>

        </div>
        <div class="col-sm-6">

            <div class="form-group">
                <label>{{lang.la.type}}</label>
                <select class="custom-select" (ngModelChange)="inputType.error = ''" [(ngModel)]="inputType.value"
                        name="inputType.value" [ngClass]="{'is-invalid': inputType.error}">
                    <option value="samtlige" selected>{{lang.la.all}}</option>
                    <option value="demo">{{lang.la.demo}}</option>
                    <option value="mercer">{{lang.la.mercer}}</option>
                    <option value="projectmanagement">{{lang.la.projectmanagement}}</option>
                </select>
                <div class="invalid-feedback d-inline" *ngIf="inputType.error">
                    {{inputType.error}}
                </div>
            </div>
        </div>
    </div>

    <div class="card-divider"></div>

    <div class="card-body row">
        <div class="col-sm-12">
            <label>
                {{lang.la.indstillinger}}
            </label>
        </div>
        <div class="col-sm-6">
            <div class="form-group input-group">
                <label type="text" for="check1" class="form-control"
                       [ngClass]="{'is-invalid': inputMarkAsExported.error}"> {{lang.la.indstillinger_1}}</label>
                <div class="input-group-append">
                    <div class="input-group-text">
                        <input type="checkbox" aria-label="Opfyldning" id="check1"
                               [(ngModel)]="inputMarkAsExported.value"
                               name="inputSettingDriveYes.value" (ngModelChange)="inputMarkAsExported.error = ''">
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-6">
            <div class="form-group input-group">
                <label type="text" for="check2" class="form-control"
                       [ngClass]="{'is-invalid': inputIncludePreviouslyExported.error}"> {{lang.la.indstillinger_2}}</label>
                <div class="input-group-append">
                    <div class="input-group-text">
                        <input type="checkbox" aria-label="Opfyldning" id="check2"
                               [(ngModel)]="inputIncludePreviouslyExported.value"
                               name="inputSettingDriveNo.value"
                               (ngModelChange)="inputIncludePreviouslyExported.error = ''">
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-12 has-text-right">
            <button class="btn btn-secondary ml-2" [ngClass]="{'is-loading': isLoading}"
                    (click)="onGoBack()">{{lang.la.cancel}}</button>
            <button class="btn btn-primary ml-2" [ngClass]="{'is-loading': isLoading}"
                    (click)="onCreateExport()">{{lang.la.create}}</button>
        </div>
    </div>
</div>
