import {Component, OnInit} from '@angular/core';
import {UtilService} from '../services/util.service';
import {DataService} from '../services/data.service';
import {ApiService} from '../services/api.service';
import {LangService} from '../services/lang.service';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../models/user';
import {UserService} from '../services/user.service';
import {TableHeader} from '../models/tableheader';
import {TableSettings} from '../models/tablesettings';
import {TableSettingsEquals} from '../models/tablesettingsequals';
import {Constants} from '../constants';
import {NotificationService} from '../shared/notification.service';
import {CSVService} from '../services/csv.service';
import {UserSettings} from '../models/usersettings';

@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

    Constants: any = Constants;

    //Table
    isLoadingTable: boolean = true;
    tableData: any[] = [];
    tableHeaders: TableHeader[] = [];
    tableSettings: TableSettings = new TableSettings();
    tableSettingsForExport: TableSettings;

    isLoadingView: boolean = true;
    lastType: string;

    userType: string = "";

    header: string = "";

    //Only used for headline
    CONSTANT_USER_TYPE_TRANSLATE: any = {
        "employee": "Medarbejdere",
        "consultant": "Salgskonsulenter",
        "customer": "Kundebrugere",
        "teamplayer": "Teamplayere",
    };

    CONSTANT_USER_TYPE_SORT: any = {
        "employee": "2",
        "consultant": "3",
        "customer": "4",
        "teamplayer": "5",
    };

    CONSTANT_TABLE: any = {
        "employee": {
            "table_header": [
                "Navn", "Email", "Telefon", "Adresse", "Postnr.", "By", "Kunder", ""
            ],
            "data_name": [
                "user_firstname", "user_email", "user_phone", "user_address", "user_postal_code", "user_city", "customers", "login"
            ]
        },
        "consultant": {
            "table_header": [
                "Navn", "Kunde", "Stilling", "Distrikt", "Email", "Telefon", ""
            ],
            "data_name": [
                "user_firstname", "customers", "user_position", "user_district", "user_email", "user_phone", "login"
            ]
        },
        "customer": {
            "table_header": [
                "Navn", "Kunde", "Stilling", "Email", "Telefon", ""
            ],
            "data_name": [
                "user_firstname", "customers", "user_position", "user_email", "user_phone", "login"
            ]
        },
        "teamplayer": {
            "table_header": [
                "Navn", "Email", "Telefon", ""
            ],
            "data_name": [
                "user_firstname", "user_email", "user_phone", "login"
            ]
        }
    };

    constructor(public apiService: ApiService,
                public route: ActivatedRoute,
                private router: Router,
                public utilService: UtilService,
                public userService: UserService,
                public lang: LangService,
                public dataService: DataService,
                public notificationService: NotificationService,
                public csvService: CSVService
    ) {
    }


    ngOnInit() {

        /**
         * Added this since you can change parameter when switching view from "Employees" to "Customers",
         * so you have to listen to changes
         * Author: Tommy Jepsen - tommy@tonsstudio.com
         **/
        this.route.params.subscribe(params => {
            this.userType = params['type'];
            this.setTableHeadsersAndSettings();


            // if we have routed to another user list page, reinit the table component
            if (this.lastType && this.lastType !== params['type']) {
                this.reInitTable()
            }

            // set the lastType to allow for re-routing check
            this.lastType = params['type'];
        });
    }

    reInitTable() {
        this.isLoadingView = true;
        this.isLoadingTable = true;

        setTimeout(() => {
            this.isLoadingView = false;
        }, 200)
    }

    setTableHeadsersAndSettings() {
        this.tableHeaders = [];

        for (let i = 0; i < this.CONSTANT_TABLE[this.userType]['table_header'].length; i++) {
            const element = this.CONSTANT_TABLE[this.userType]['table_header'][i];
            this.tableHeaders.push(new TableHeader(this.CONSTANT_TABLE[this.userType]['data_name'][i], element));
        }

        this.tableSettings = new TableSettings();
        this.tableSettings.sortBy = this.tableHeaders[0];
        this.tableSettings.sortBy.asc = false;
        this.tableSettings.tableHeaders = this.tableHeaders;
        this.tableSettings.searchColumn = this.tableHeaders[0];
        this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals("user_active", "1"));
        this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals("user_role_id", this.CONSTANT_USER_TYPE_SORT[this.userType]));
        this.tableSettings.columns = "user_firstname,user_lastname,user_email,user_phone,user_postal_code,user_position,user_district,user_city,user_address,user_role_id,user_active,"
            + Constants.PARAMETER_IMAGES_ONLY_ACTIVE;
        this.tableSettings.isShowingDeactivated = true;

        // Export
        this.tableSettingsForExport = new TableSettings();
        this.tableSettingsForExport.sortBy = this.tableSettings.sortBy;
        this.tableSettingsForExport.tableHeaders = this.tableSettings.tableHeaders;
        this.tableSettingsForExport.searchColumn = this.tableSettings.searchColumn;
        this.tableSettingsForExport.tableSettingsEquals = this.tableSettings.tableSettingsEquals;
        this.tableSettings.columns = "user_firstname,user_lastname,user_email,user_phone,user_postal_code,user_position,user_district,user_city,user_address,user_role_id,user_active";

        this.isLoadingView = false;
    }

    getData(tableSettings: TableSettings) {
        this.isLoadingTable = true;

        this.tableSettings = tableSettings;

        this.apiService.getUsers(tableSettings).subscribe((data) => {

            this.tableSettings.tableOffset = data.tableOffset;
            this.tableData = data.main;
            this.dataService.saveStores(this.tableData);
            this.isLoadingView = false;
            this.isLoadingTable = false;
        }, (error) => {

            this.utilService.onRetryApi("getUsers", () => {
                this.getData(this.tableSettings);
            }, () => {
                this.isLoadingView = false;
                this.isLoadingTable = false;
                this.notificationService.setNotification(error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
            });
        });

    }

    onRowPressed(event) {
        if (event.tableValue != 'login') {
            this.router.navigate(['app/user/show/', this.tableData[event.pos]['user_id']]);
        }
    }

    onCustomPressed(pos) {
        this.tableData[pos]['isLoading'] = true;
        this.apiService.getToken(this.tableData[pos]['user_id']).subscribe((data) => {
            this.apiService.getUser(new TableSettings(), this.tableData[pos]['user_id']).subscribe((data2) => {

                var us: User = new User(data['meta']['user_details']);
                const userFakeSettings = new UserSettings(data['meta']['user_settings']);
                us = data2.main[0];
                us.user_token = data['data']['token'];
                this.userService.loginAsFakeUser(us, userFakeSettings);
                this.tableData[pos]['isLoading'] = false;

                //Make sure to reset user
                this.dataService.assignmentListPage = 1;
            }, (error2) => {
                this.tableData[pos]['isLoading'] = false;
                this.notificationService.setNotification(this.lang.la.error_general, this.notificationService.CONSTANT_TYPE_WARNING);
            })
        }, (error) => {
            this.tableData[pos]['isLoading'] = false;
            this.notificationService.setNotification(this.lang.la.error_general, this.notificationService.CONSTANT_TYPE_WARNING);
        })
    }

    onCreateUser() {
        this.router.navigate(['./app/user/create', {userType: this.CONSTANT_USER_TYPE_SORT[this.userType]}]);
    }
}
