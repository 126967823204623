import { OnInit } from '@angular/core';
import { UtilService } from '../services/util.service';
import { DataService } from '../services/data.service';
import { LangService } from '../services/lang.service';
import { ApiService } from '../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import { User } from '../models/user';
import { InputString } from '../models/inputstring';
import { Constants } from '../constants';
import { InputCheckbox } from '../models/inputcheckbox';
import { TableSettings } from '../models/tablesettings';
import { NotificationService } from '../shared/notification.service';
import { TableSettingsEquals } from '../models/tablesettingsequals';
import { Vacation } from '../models/vacation';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { TableHeader } from '../models/tableheader';
import { UserService } from '../services/user.service';
import { Location } from "@angular/common";
import { FileUploaderService } from "../services/fileUploaderService";
var UserCreateComponent = /** @class */ (function () {
    function UserCreateComponent(apiService, route, router, utilService, lang, dataService, notificationService, userService, location, fileUploaderService) {
        this.apiService = apiService;
        this.route = route;
        this.router = router;
        this.utilService = utilService;
        this.lang = lang;
        this.dataService = dataService;
        this.notificationService = notificationService;
        this.userService = userService;
        this.location = location;
        this.fileUploaderService = fileUploaderService;
        this.Constants = Constants;
        //View
        this.isLoadingView = false;
        this.isLoading = false;
        this.isReloading = false;
        //
        this.isDeleteModalActive = false;
        //
        this.isVacationActive = false;
        this.isLoadingVacation = false;
        this.vacations = [];
        this.inputVacationRange = [];
        //Inputs
        this.inputRole = new InputString();
        this.inputRoleMercer = new InputCheckbox();
        this.inputRoleDemo = new InputCheckbox();
        this.inputProjectCreate = new InputCheckbox();
        this.inputCustomersId = ""; //Customer used for single select
        this.inputCustomersOriginal = []; //Customers used for multiple slect
        this.inputCustomers = []; //Customers used for multiple slect
        this.inputCustomerError = new InputString(); // Only used for consistent error messages
        this.inputFirstName = new InputString();
        this.inputLastName = new InputString();
        this.inputPhoneNo = new InputString();
        this.inputPostalCode = new InputString();
        this.inputCPR = new InputString();
        this.inputAddress = new InputString();
        this.inputEmail = new InputString();
        this.inputCity = new InputString();
        this.inputReqNo = new InputString();
        this.inputAccNo = new InputString();
        this.inputMercer = new InputString();
        this.inputDanSalary = new InputString();
        this.inputCollabText = new InputString();
        this.inputPosition = new InputString();
        this.inputDistrict = new InputString();
        this.inputDemo = new InputString();
        this.inputPassword1 = new InputString();
        this.inputPassword2 = new InputString();
        this.inputBreastSize = new InputString();
        this.inputDelieveryShop = new InputCheckbox();
        this.inputDelieveryAddress = new InputCheckbox();
        this.inputNotes = new InputString();
        this.startingCustomers = [];
        //Different roles
        //Editing
        this.isEditingId = -1;
        this.isEditing = false;
        //Image upload
        this.isImageUploadActive = false;
        this.filesImages = new FileUploader({});
        //IDS TO SHOW
        this.ID_TO_SHOW_CUSTOMERS = 0;
        this.ID_TO_SHOW_TYPES = 1;
        this.ID_TO_SHOW_CPR = 2;
        this.ID_TO_SHOW_DISTRICT = 3;
        this.ID_TO_SHOW_POSITION = 4;
        this.ID_TO_SHOW_POSTALCODE = 5;
        this.ID_TO_SHOW_ADDRESS = 6;
        this.ID_TO_SHOW_CITY = 7;
        this.ID_TO_SHOW_MERCER = 8;
        this.ID_TO_SHOW_DEMO = 9;
        this.ID_TO_SHOW_DANLOEN = 10;
        this.ID_TO_SHOW_REGACC = 12;
        this.ID_TO_SHOW_CUSTOMERS_MULTIPLE_SELECT = 13;
        this.ID_TO_SHOW_CUSTOMERS_SINGLE_SELECT = 14;
        this.ID_TO_SHOW_TSHIRT = 15;
        //
        this.dpConfig = new BsDatepickerConfig();
    }
    UserCreateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dpConfig = this.utilService.getDatePickerConfig();
        this.inputRole.value = "" + 2;
        this.route.params.subscribe(function (params) {
            _this.onCheckIfEditing();
            _this.onCheckData();
            if (params.userType) {
                _this.inputRole.value = params.userType;
            }
        });
        this.idsToShow = {
            '2': [
                this.ID_TO_SHOW_CUSTOMERS,
                this.ID_TO_SHOW_TYPES,
                this.ID_TO_SHOW_CPR,
                this.ID_TO_SHOW_POSTALCODE,
                this.ID_TO_SHOW_ADDRESS,
                this.ID_TO_SHOW_CITY,
                this.ID_TO_SHOW_MERCER,
                this.ID_TO_SHOW_DEMO,
                this.ID_TO_SHOW_DANLOEN,
                this.ID_TO_SHOW_REGACC,
                this.ID_TO_SHOW_CUSTOMERS_MULTIPLE_SELECT,
                this.ID_TO_SHOW_TSHIRT
            ],
            '3': [
                this.ID_TO_SHOW_CUSTOMERS,
                this.ID_TO_SHOW_DISTRICT,
                this.ID_TO_SHOW_POSITION,
                this.ID_TO_SHOW_CUSTOMERS_MULTIPLE_SELECT,
            ],
            '4': [
                this.ID_TO_SHOW_CUSTOMERS,
                this.ID_TO_SHOW_POSITION,
                this.ID_TO_SHOW_CUSTOMERS_SINGLE_SELECT,
            ],
            '5': [
                this.ID_TO_SHOW_MERCER,
                this.ID_TO_SHOW_DEMO,
                this.ID_TO_SHOW_DANLOEN,
            ],
        };
    };
    UserCreateComponent.prototype.onCheckIfEditing = function () {
        var _this = this;
        this.isEditingId = this.route.snapshot.params['id'];
        if (this.isEditingId != undefined) {
            this.isEditing = true;
            this.isLoadingView = true;
            var adminColumns = this.userService.userIsUserRoleId(Constants.USER_ROLE_ADMIN_ID) ? 'user_demo_rate,user_mercer_rate,' : '';
            var ts = new TableSettings();
            ts.columns = "user_id,user_email,user_firstname,user_lastname,user_password,user_login_attempts,user_blocked,user_type_mercer,user_type_demo,user_role_id,user_phone,user_cpr,user_address,user_postal_code,user_city,user_bank_reg,user_bank_account,user_danloen_id,user_notes,user_cooperation_agreements,user_cooperation_agreements_date,user_active,user_contact_on_email,user_contact_on_sms,user_contact_on_app,image_id,user_creation,user_home_delivery,can_create_project_management," + adminColumns + Constants.PARAMETER_IMAGES_ONLY_ACTIVE;
            this.apiService.getUser(ts, this.isEditingId).subscribe(function (data) {
                if (data.main[0] != undefined) {
                    _this.isEditingUser = data.main[0];
                    _this.onSetInputs(data.main[0]);
                }
                //Get user customers
                var tscfu = new TableSettings();
                tscfu.tableSettingsEquals.push(new TableSettingsEquals("user_id", _this.isEditingId));
                //tscfu.tableSettingsEquals.push(new TableSettingsEquals("customers.customer_active", 1));
                var customers = "customers.customer_id,customers.customer_name,customers.customer_active";
                tscfu.columns = "customer_for_user_connection_id,customer_id,user_id," + customers;
                tscfu.results = 500;
                _this.apiService.getUserCustomers(tscfu).subscribe(function (data) {
                    _this.inputCustomersOriginal = data.main;
                    for (var i = 0; i < data.main.length; i++) {
                        var element = data.main[i];
                        _this.inputCustomers.push(data.main[i].customers);
                    }
                    if (_this.inputCustomers.length != 0) {
                        _this.inputCustomersId = "" + _this.inputCustomers[0].customer_id;
                    }
                    _this.isLoadingView = false;
                }, function (error) {
                    _this.isLoadingView = false;
                });
                _this.onGetVacation();
            }, function (error) {
                _this.isLoadingView = false;
            });
        }
    };
    UserCreateComponent.prototype.onCheckData = function () {
        var _this = this;
        var ts = new TableSettings();
        ts.sortBy = new TableHeader("customer_name", "customer_name");
        ts.tableSettingsEquals.push(new TableSettingsEquals("customer_active", 1));
        ts.sortBy.asc = !ts.sortBy.asc;
        ts.results = 500;
        this.apiService.getCustomers(ts).subscribe(function (data) {
            _this.dataService.saveCustomers(data.main);
        }, function (error) {
            _this.utilService.onRetryApi("getCustomers", function () {
                _this.onCheckData();
            }, function () {
                _this.notificationService.setNotification(_this.lang.la.error_general_message + " " + error.error.message, _this.notificationService.CONSTANT_TYPE_WARNING);
            });
        });
    };
    UserCreateComponent.prototype.onSetInputs = function (u) {
        this.inputFirstName.value = u.user_firstname;
        this.inputLastName.value = u.user_lastname;
        this.inputRoleMercer.value = u.user_type_mercer;
        this.inputRoleDemo.value = u.user_type_demo;
        this.inputRole.value = "" + u.user_role_id;
        this.inputPhoneNo.value = u.user_phone;
        this.inputPostalCode.value = u.user_postal_code;
        this.inputCPR.value = "" + u.user_cpr;
        this.inputAddress.value = u.user_address;
        this.inputEmail.value = u.user_email;
        this.inputCity.value = u.user_city;
        this.inputReqNo.value = u.user_bank_reg;
        this.inputAccNo.value = u.user_bank_account;
        this.inputMercer.value = u.user_mercer_rate;
        this.inputDanSalary.value = u.user_danloen_id;
        this.inputCollabText.value = u.user_cooperation_agreements;
        this.inputPosition.value = u.user_position;
        this.inputDistrict.value = u.user_district;
        this.inputDemo.value = u.user_demo_rate;
        this.inputDelieveryShop.value = u.user_home_delivery === '1' ? true : false;
        this.inputProjectCreate.value = u.can_create_project_management === 1;
        // this.inputBreastSize.value = u.breastsize;
        this.inputDelieveryAddress.value = false; //TODO
        this.inputNotes.value = u.user_notes;
    };
    UserCreateComponent.prototype.resetInputs = function () {
        var inputRole = this.inputRole.value;
        this.onSetInputs(new User());
        this.inputRole.value = inputRole;
        this.inputCustomers = [];
        this.inputCustomersId = "";
        this.startingCustomers = [];
        this.inputPassword1 = new InputString();
        this.inputPassword2 = new InputString();
        this.inputCPR = new InputString();
        this.filesImages = undefined;
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    };
    UserCreateComponent.prototype.onChangeRole = function () {
        var _this = this;
        this.isReloading = true;
        setTimeout(function () {
            _this.isReloading = false;
        }, 0);
    };
    UserCreateComponent.prototype.onShouldShow = function (id) {
        for (var i = 0; i < this.idsToShow[this.inputRole.value].length; i++) {
            if (this.idsToShow[this.inputRole.value][i] == id) {
                return true;
            }
        }
        return false;
    };
    UserCreateComponent.prototype.onShouldShowMultiple = function () {
        var id = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            id[_i] = arguments[_i];
        }
        var valid = 0;
        for (var b = 0; b < id.length; b++) {
            var element = id[b];
            for (var i = 0; i < this.idsToShow[this.inputRole.value].length; i++) {
                if (this.idsToShow[this.inputRole.value][i] == element) {
                    valid++;
                }
            }
        }
        if (valid != 0) {
            return true;
        }
        else {
            return false;
        }
    };
    UserCreateComponent.prototype.onCheckForms = function () {
        var validForm = true;
        if (this.inputRole.value == Constants.USER_ROLE_EMPLOYEE_ID.toString()) {
            this.inputRoleMercer.error = this.utilService.checkIfChecked(this.inputRoleMercer, this.inputRoleDemo);
            if (this.inputRoleMercer.error) {
                validForm = false;
            }
            if ((this.inputPostalCode.error = this.utilService.checkFilled(this.inputPostalCode.value)))
                validForm = false;
            if ((this.inputAddress.error = this.utilService.checkFilled(this.inputAddress.value)))
                validForm = false;
            if ((this.inputCity.error = this.utilService.checkFilled(this.inputCity.value)))
                validForm = false;
            if (this.userService.userIsUserRoleId(Constants.USER_ROLE_ADMIN_ID)) {
                if (this.inputRoleMercer.value && (this.inputMercer.error = this.utilService.checkNumber(this.inputMercer.value)) || this.inputRoleMercer.value && (this.inputMercer.error = parseInt(this.inputMercer.value) == 0 ? 'Skal være over 0' : ''))
                    validForm = false;
                if (this.inputRoleDemo.value && (this.inputDemo.error = this.utilService.checkNumber(this.inputDemo.value)) || this.inputRoleDemo.value && (this.inputDemo.error = parseInt(this.inputDemo.value) == 0 ? 'Skal være over 0' : ''))
                    validForm = false;
            }
        }
        if ((this.inputRole.value == Constants.USER_ROLE_CONSULTANT_ID.toString() || this.inputRole.value == Constants.USER_ROLE_CUSTOMER_ID.toString()) && (this.getCustomerIds().length == 0 || this.getCustomerIds()[0] == '')) {
            this.inputCustomerError.error = 'Vælg venligst kunde';
            validForm = false;
        }
        if (!this.isEditing || this.inputPassword1.value.length != 0) {
            if (this.inputPassword1.value != this.inputPassword2.value) {
                this.inputPassword1.error = "Adgangskoder er ikke ens";
                validForm = false;
            }
            else if (!this.utilService.checkPassword(this.inputPassword1)) {
                validForm = false;
            }
        }
        if (this.utilService.checkMultipleIfvalid(this.inputFirstName, this.inputLastName, this.inputEmail)) {
            validForm = false;
        }
        return validForm;
    };
    UserCreateComponent.prototype.onGetUserWithData = function () {
        var u = new User();
        if (this.isEditingId != -1) {
            u.user_id = this.isEditingId;
        }
        u.user_firstname = this.inputFirstName.value;
        u.user_lastname = this.inputLastName.value;
        if (this.inputPassword1.value != "") {
            u.user_password = this.inputPassword1.value;
        }
        u.user_role_id = +this.inputRole.value;
        if (this.inputCPR.value == "") {
            u.user_cpr = null;
        }
        else {
            u.user_cpr = this.inputCPR.value;
        }
        u.user_home_delivery = this.inputDelieveryShop.value === true ? '1' : '0';
        u.user_phone = this.inputPhoneNo.value;
        u.user_postal_code = this.inputPostalCode.value;
        u.user_address = this.inputAddress.value;
        u.user_email = this.inputEmail.value;
        u.user_city = this.inputCity.value;
        u.user_bank_reg = this.inputReqNo.value;
        u.user_bank_account = this.inputAccNo.value;
        if (this.userService.user.user_role_id === Constants.USER_ROLE_ADMIN_ID) {
            u.user_mercer_rate = this.inputMercer.value;
            u.user_demo_rate = this.inputDemo.value;
            u.user_danloen_id = !this.inputDanSalary.value ? "0" : this.inputDanSalary.value;
        }
        u.user_position = this.inputPosition.value;
        u.user_district = this.inputDistrict.value;
        u.user_type_mercer = this.inputRoleMercer.value;
        u.user_type_demo = this.inputRoleDemo.value;
        u.user_breastsize = this.inputBreastSize.value;
        u.user_cooperation_agreements = this.inputCollabText.value;
        u.user_notes = this.inputNotes.value;
        u.can_create_project_management = this.inputProjectCreate.value === true ? 1 : 0;
        return u;
    };
    UserCreateComponent.prototype.save = function (action) {
        var _this = this;
        if (this.abortSaveAction())
            return;
        this.isLoading = true;
        this.getSaveAction(action).subscribe(function (data) {
            _this.afterSaveAction(data['data'], action);
        }, function (error) {
            _this.notificationService.setNotification(error.error.message, _this.notificationService.CONSTANT_TYPE_WARNING);
            _this.isLoading = false;
        });
    };
    UserCreateComponent.prototype.getSaveAction = function (action) {
        if (action == 'create') {
            return this.apiService.postUser(this.onGetUserWithData());
        }
        else {
            return this.apiService.patchUser(this.onGetUserWithData());
        }
    };
    UserCreateComponent.prototype.abortSaveAction = function () {
        if (!this.onCheckForms()) {
            this.notificationService.setNotification(this.lang.la.error_form_invalid, this.notificationService.CONSTANT_TYPE_WARNING);
            return true;
        }
        return false;
    };
    UserCreateComponent.prototype.afterSaveAction = function (user, action) {
        var _this = this;
        // add customers, then add images
        this.addCustomers(user)
            .then(function () {
            console.log('aftersave action: addimages call');
            return _this.addImages(user);
        })
            .then(function () {
            console.log('aftersave stop', action);
            _this.isLoading = false;
            if (action == 'create') {
                _this.showCreateNotification(user);
            }
            else {
                _this.notificationService.setNotification(_this.lang.la.success_updated, _this.notificationService.CONSTANT_TYPE_NORMAL);
                _this.returnToListPage();
            }
        });
    };
    UserCreateComponent.prototype.addCustomers = function (user) {
        // if the user is not one of the customers, return a promise so that we can chain on it
        if (user.user_role_id === Constants.USER_ROLE_TEAMPLAYER_ID) {
            return new Promise(function (resolve, reject) {
                resolve(true);
            });
        }
        var customer_ids = this.getCustomerIds();
        var data = {
            'user_id': user.user_id,
            'customer_ids': customer_ids
        };
        // put new customers
        return this.apiService.patchUserCustomersMultiple(data).toPromise();
    };
    UserCreateComponent.prototype.addImages = function (c) {
        var _this = this;
        console.log('addImages');
        return this.fileUploaderService.uploadAllInQueue(this.filesImages, {
            type: 'image',
            module: 'users',
            id: c.user_id
        }).catch(function (error) {
            console.log('catch');
            _this.notificationService.setNotification('Brugeren er gemt, men en eller flere filer kunne ikke vedhæftes.<br /><br />' + _this.lang.la.image_restrictions_explanation, _this.notificationService.CONSTANT_TYPE_WARNING, 30000);
            _this.isLoading = false;
            throw error;
        });
    };
    UserCreateComponent.prototype.returnToListPage = function () {
        var type = "teamplayer";
        if (this.inputRole.value == "2") {
            type = "employee";
        }
        else if (this.inputRole.value == "3") {
            type = "consultant";
        }
        else if (this.inputRole.value == "4") {
            type = "customer";
        }
        this.router.navigate(['./app/user/list/', type]);
    };
    UserCreateComponent.prototype.getCustomerIds = function () {
        var ids = new Set();
        if (this.onShouldShow(this.ID_TO_SHOW_CUSTOMERS_SINGLE_SELECT)) {
            ids.add(this.inputCustomersId);
        }
        else if (this.onShouldShow(this.ID_TO_SHOW_CUSTOMERS_MULTIPLE_SELECT)) {
            for (var i = 0; i < this.inputCustomers.length; i++) {
                ids.add(this.inputCustomers[i].customer_id);
            }
        }
        return Array.from(ids);
    };
    UserCreateComponent.prototype.showCreateNotification = function (user) {
        this.notificationService.showCreateNotification('Bruger', user.user_id, '/app/user/show');
        this.resetInputs();
    };
    //Vacation
    UserCreateComponent.prototype.onGetVacation = function () {
        var _this = this;
        var ts = new TableSettings();
        ts.tableSettingsEquals.push(new TableSettingsEquals("vacation_active", "1"));
        ts.tableSettingsEquals.push(new TableSettingsEquals("user_id", this.isEditingId));
        ts.sortBy = new TableHeader("vacation_start", "vacation_start");
        this.apiService.getVacations(ts).subscribe(function (data) {
            _this.vacations = data.main;
        }, function (error) {
        });
    };
    UserCreateComponent.prototype.onAddVacation = function () {
        var _this = this;
        this.isLoadingVacation = true;
        var v = new Vacation();
        v.user_id = this.isEditingId;
        v.vacation_start = moment(this.inputVacationRange[0]).format("YYYY-MM-DD HH:mm:ss");
        v.vacation_end = moment(this.inputVacationRange[1]).format("YYYY-MM-DD HH:mm:ss");
        this.apiService.postVacation(v).subscribe(function (data) {
            _this.onGetVacation();
            _this.isLoadingVacation = false;
            _this.isVacationActive = false;
        }, function (error) {
            _this.isLoadingVacation = false;
            _this.notificationService.setNotification(error.error.message, _this.notificationService.CONSTANT_TYPE_WARNING);
        });
    };
    UserCreateComponent.prototype.onDeleteVacation = function (pos) {
        var _this = this;
        this.isLoadingVacation = true;
        this.vacations[pos].vacation_active = 0;
        this.apiService.patchVacation(this.vacations[pos]).subscribe(function (data) {
            _this.onGetVacation();
            _this.isLoadingVacation = false;
            _this.isVacationActive = false;
            _this.notificationService.setNotification("Ferie slettet.", _this.notificationService.CONSTANT_TYPE_NORMAL);
        }, function (error) {
            _this.isLoadingVacation = false;
            _this.notificationService.setNotification(_this.lang.la.error_general_message, _this.notificationService.CONSTANT_TYPE_WARNING);
        });
    };
    UserCreateComponent.prototype.onChangeActive = function (active) {
        var _this = this;
        var c = new User();
        c.user_id = this.isEditingId;
        c.user_active = active;
        this.apiService.patchUser(c).subscribe(function (data) {
            _this.notificationService.setNotification(_this.lang.la.success_updated, _this.notificationService.CONSTANT_TYPE_NORMAL);
            _this.onCheckIfEditing();
            _this.isDeleteModalActive = false;
            _this.isLoading = false;
        }, function (error) {
            _this.notificationService.setNotification(error.error.message, _this.notificationService.CONSTANT_TYPE_WARNING);
            _this.isLoading = false;
        });
    };
    UserCreateComponent.prototype.onGoBack = function () {
        this.location.back();
    };
    return UserCreateComponent;
}());
export { UserCreateComponent };
