import {Consultant} from "./consultant";
import {User} from "./user";
import {Store} from "./store";
import {Chain} from "./chain";
import {Image} from "./image";
import {AssignmentExecutions} from "./assignment-executions";

export class Assignment {

    public primary_key: string = "assignment_id";

    public assignment_id: number;

    public assignment_type: string;

    public assignment_status: string; //Udbyd, tildel eller gemt

    public assignment_duration: string;


    public assignment_contact_person: string;
    public assignment_pos_material: number;
    public assignment_product: string;
    public assignment_place_in_store: string;

    public assignment_timestype_free: number;
    public assignment_timestype_start: string;
    public assignment_timestype_free_end: string;
    // public assignment_timestype_free_start: string;
    // public assignment_timestype_free_end: string;
    // public assignment_timestype_fixed_start: string;

    public assignment_execution_id: number;

    public assignment_execution: AssignmentExecutions;

    public assignment_description: string;

    // //HAS IMAGE???
    // public assignment_execution_image: boolean = false;

    public assignment_time_estimate: string;
    public assignment_type_id: string;

    public assignment_todos: number[];

    public user_ids: number[];
    public user_ids_assigned: number; // Used in assignment list

    public customer_id: number;
    public store_id: number;
    public user_id: number;

    public assignment_active: number;

    public assignment_approved: number;
    public assignment_approved_time: string;

    public users: User[]; // Used in assignment list

    public assignment_creation: Date;

    //OLD CODE TODO

    public driving: string;
    public employees: any[];
    public expenses: string;
    public date: string;
    public salaryperiod: string;
    public accepted: boolean;


    public time_for_creating: Date;

    public chain: Chain;
    public consultant: Consultant;
    public customer: User;
    public store: Store;
    stores: Store;
    public price: string; //Variable or fixed
    public frequent: string; //Running og single
    public todos: string[];

    public images: Image[];
    public appendix: string [];

    public assignment_created_by: number;

    public appendix_ids: any;
    sales_consultant: any;
    execution: any;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }

    public getPostObject() {
        var object: any = this;
        Object.keys(object).forEach(element => {
            if (object[element] == undefined || object[element] == null || object[element].length == 0) {
                delete object[element];
            }
        });
        delete object.primary_key;
        return JSON.stringify(object);
    }

    public getPatchObject() {
        var object: any = this;
        Object.keys(object).forEach(element => {
            if (object[element] == undefined || object[element] == null || object[element].length == 0) {
                delete object[element];
            }
        });
        delete object.primary_key;
        return JSON.stringify(object);
    }
}


