<div class="container-fluid has-text-centered is-fullheightvh">
    <div class="row is-fullheightvh">
        <div class="col-sm-12 col-xs-12 bg-image col-md-6 is-fullheightvh has-centered-everything">
            <div class="max-width-400">
                <img src="./assets/logo_dark.png" alt="" class="push-m-50-b max-width-100"
                     [ngClass]="{'fadeOut  delay-04': isSuccessful}">
                <div class="is-fullwidth has-text-left" [ngClass]="{'fadeOut': isSuccessful}">

                    <form (ngSubmit)="onLogin()">

                        <div class="form-group">
                            <label for="inputEmail">{{lang.la.email}}</label>
                            <input type="email" placeholder="Email" class="form-control" required
                                   [(ngModel)]="inputEmail.value"
                                   name="emailInput" [ngClass]="{'is-invalid': inputEmail.error}"/>
                            <div class="invalid-feedback d-inline" *ngIf="inputEmail.error">
                                {{inputEmail.error}}
                            </div>
                        </div>

                        <div class="form-group">
                            <label>{{lang.la.password}}</label>
                            <input type="password" class="form-control" placeholder="Password"
                                   [(ngModel)]="inputPassword.value"
                                   name="passwordInput" [ngClass]="{'is-invalid': inputPassword.error}"/>
                            <div class="invalid-feedback d-inline" *ngIf="inputPassword.error">
                                {{inputPassword.error}}
                            </div>
                        </div>

                        <div class="invalid-feedback d-inline-block" *ngIf="error">
                            {{error}}
                        </div>
                        <button class="btn btn-primary push-m-10-t is-fullwidth"
                                [ngClass]="{'is-loading': isLoading}">{{lang.la.login}}</button>
                        <div class="form-group push-m-30-t text-center">
                            <a class="link is-grey" routerLink="../lostpassword">Har du glemt din kode?</a>
                        </div>
                    </form>

                </div>
            </div>
        </div>
        <div class="col-sm-6  is-fullheightvh hidden-sm hidden-xs" [ngClass]="{'fadeOut  delay-04': isSuccessful}">

        </div>
    </div>
</div>
	