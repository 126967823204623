/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./import-store-preview-row.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../dropdown-select/dropdown-select.component.ngfactory";
import * as i3 from "../../dropdown-select/dropdown-select.component";
import * as i4 from "../../../services/lang.service";
import * as i5 from "../../../services/util.service";
import * as i6 from "@angular/common";
import * as i7 from "./import-store-preview-row";
var styles_ImportStorePreviewRow = [i0.styles];
var RenderType_ImportStorePreviewRow = i1.ɵcrt({ encapsulation: 0, styles: styles_ImportStorePreviewRow, data: {} });
export { RenderType_ImportStorePreviewRow as RenderType_ImportStorePreviewRow };
function View_ImportStorePreviewRow_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "text-is-danger small"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.store["raw"][_v.parent.parent.context.$implicit.tablevalue]; _ck(_v, 1, 0, currVal_0); }); }
function View_ImportStorePreviewRow_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dropdown-select", [["arrayListId", "chain_id"], ["arrayListName", "chain_name"]], null, [[null, "selectedItem"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedItem" === en)) {
        var pd_0 = ((_co.store["chain_id"] = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DropdownSelectComponent_0, i2.RenderType_DropdownSelectComponent)), i1.ɵdid(1, 638976, null, 0, i3.DropdownSelectComponent, [i4.LangService, i5.UtilService], { arrayList: [0, "arrayList"], arrayListName: [1, "arrayListName"], arrayListId: [2, "arrayListId"], arrayListSelectedId: [3, "arrayListSelectedId"], arrayListSelectedObject: [4, "arrayListSelectedObject"], loadMore: [5, "loadMore"] }, { selectedItem: "selectedItem" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chains; var currVal_1 = "chain_name"; var currVal_2 = "chain_id"; var currVal_3 = _co.store["chain_id"]; var currVal_4 = _co.store["chain"]; var currVal_5 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_ImportStorePreviewRow_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImportStorePreviewRow_3)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImportStorePreviewRow_4)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.store["chain_id"]; _ck(_v, 2, 0, currVal_0); var currVal_2 = !_co.store[_v.parent.context.$implicit.tablevalue]; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (!!_co.store[_v.parent.context.$implicit.tablevalue] ? _co.store[_v.parent.context.$implicit.tablevalue].chain_name : ""); _ck(_v, 3, 0, currVal_1); }); }
function View_ImportStorePreviewRow_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dropdown-select", [["arrayListId", "value"], ["arrayListName", "title"]], null, [[null, "selectedItem"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedItem" === en)) {
        var pd_0 = (_co.selectHandleExistingStoreAction($event, _co.store) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DropdownSelectComponent_0, i2.RenderType_DropdownSelectComponent)), i1.ɵdid(1, 638976, null, 0, i3.DropdownSelectComponent, [i4.LangService, i5.UtilService], { arrayList: [0, "arrayList"], arrayListName: [1, "arrayListName"], arrayListId: [2, "arrayListId"], loadMore: [3, "loadMore"] }, { selectedItem: "selectedItem" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.handleExistingStoreOptions; var currVal_1 = "title"; var currVal_2 = "value"; var currVal_3 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_ImportStorePreviewRow_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "i", [["class", "material-icons icon-fixed"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.store["expandHandleExistingStore"] = !_co.store["expandHandleExistingStore"]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i6.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "push-p-30-l d-block z-0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImportStorePreviewRow_7)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "material-icons icon-fixed"; var currVal_1 = (_co.store["alreadyExistsAsStore"].action ? "text-is-success" : "text-is-danger"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _co.store["expandHandleExistingStore"]; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.store["expandHandleExistingStore"] ? "close" : "store"); _ck(_v, 3, 0, currVal_2); }); }
function View_ImportStorePreviewRow_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "position-relative storeHandling"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImportStorePreviewRow_6)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.store["alreadyExistsAsStore"] != undefined); _ck(_v, 2, 0, currVal_0); }, null); }
function View_ImportStorePreviewRow_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.store[_v.parent.context.$implicit.tablevalue]; _ck(_v, 1, 0, currVal_0); }); }
function View_ImportStorePreviewRow_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "td", [], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i6.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImportStorePreviewRow_2)), i1.ɵdid(3, 278528, null, 0, i6.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i6.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImportStorePreviewRow_5)), i1.ɵdid(5, 278528, null, 0, i6.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i6.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImportStorePreviewRow_8)), i1.ɵdid(7, 16384, null, 0, i6.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i6.NgSwitch], null, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.tablevalue; _ck(_v, 1, 0, currVal_0); var currVal_1 = "chain"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "alreadyExistsAsStore"; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_ImportStorePreviewRow_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "i", [["class", "material-icons"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i6.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImportStorePreviewRow_1)), i1.ɵdid(5, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "material-icons"; var currVal_1 = (_co.validateRow(_co.store) ? "text-is-success" : "text-is-warning"); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _co.headers; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.validateRow(_co.store) ? "check" : "warning"); _ck(_v, 3, 0, currVal_2); }); }
export function View_ImportStorePreviewRow_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["app-import-store-preview-row", ""]], null, null, null, View_ImportStorePreviewRow_0, RenderType_ImportStorePreviewRow)), i1.ɵdid(1, 49152, null, 0, i7.ImportStorePreviewRow, [i4.LangService, i5.UtilService], null, null)], null, null); }
var ImportStorePreviewRowNgFactory = i1.ɵccf("[app-import-store-preview-row]", i7.ImportStorePreviewRow, View_ImportStorePreviewRow_Host_0, { store: "store", headers: "headers", chains: "chains", validateRow: "validateRow" }, {}, []);
export { ImportStorePreviewRowNgFactory as ImportStorePreviewRowNgFactory };
