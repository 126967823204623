import {Component, OnInit} from '@angular/core';
import {UtilService} from '../services/util.service';
import {DataService} from '../services/data.service';
import {ApiService} from '../services/api.service';
import {LangService} from '../services/lang.service';
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment-timezone';
import {Export} from '../models/export';
import {TableHeader} from '../models/tableheader';
import {TableSettings} from '../models/tablesettings';
import {NotificationService} from '../shared/notification.service';
import {CSVService} from '../services/csv.service';

@Component({
    selector: 'app-export-list',
    templateUrl: './export-list.component.html',
    styleUrls: ['./export-list.component.scss']
})
export class ExportListComponent implements OnInit {


    isLoadingView: boolean = false;


    //Table
    isLoadingTable: boolean = false;
    tableData: Export[];
    tableHeaders: TableHeader[];
    tableSettings: TableSettings;

    constructor(
        public apiService: ApiService,
        public route: ActivatedRoute,
        private router: Router,
        public utilService: UtilService,
        public lang: LangService,
        public dataService: DataService,
        public notificationService: NotificationService,
        private csvService: CSVService
    ) {
    }

    ngOnInit() {
        this.tableHeaders = [
            //new TableHeader("export_id", this.lang.la.id),
            new TableHeader("export_creation", this.lang.la.drivingdate),
            new TableHeader("export_name", this.lang.la.name),
            new TableHeader("export_status_pretty", "Opgavestatus"),
            new TableHeader("export_assignment_type_pretty", "Opgavetype"),
        ];

        this.tableSettings = new TableSettings();
        this.tableSettings.sortBy = this.tableHeaders[0];
        this.tableSettings.tableHeaders = this.tableHeaders;
        this.tableSettings.searchColumn = this.tableHeaders[0];
        this.tableSettings.columns = "export_id,user_id,customer_id,export_creation,export_updated,export_assignment_status,export_assignment_type";
        this.tableSettings.isShowingDeactivated = false;

        this.isLoadingView = false;

        this.getData(this.tableSettings);
    }

    getData(tableSettingsForData: TableSettings) {
        this.tableSettings = tableSettingsForData;

        this.isLoadingTable = true;

        this.apiService.getExports(tableSettingsForData).subscribe((data) => {
            this.isLoadingView = false;
            this.isLoadingTable = false;


            data.main.forEach(function (export_instance) {
                export_instance.export_creation = moment.tz(export_instance.export_creation, 'UTC').tz('Europe/Copenhagen').format('DD. MMM YYYY HH:mm');
            });

            this.tableData = data.main;
            this.tableSettings.tableOffset = data.tableOffset;

        }, (error) => {
            this.isLoadingTable = false;
            this.isLoadingView = false;
            this.utilService.onRetryApi("exportlist", () => {
                this.getData(this.tableSettings);
            }, () => {
                this.notificationService.setNotification(this.lang.la.error_general_message + " " + error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
            });
        });
    }


    onRowPressed(event) {
        this.csvService.exportCSVdata = this.tableData[event.pos];
        this.router.navigate(['app/export/show/' + this.tableData[event.pos].export_id]);
    }
}
