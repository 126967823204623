import {User} from "./user";
import {Image} from "./image";

export class News {

    public news_id: number;
    public user: User;
    public news_title: string;
    public news_description: string;
    public news_active: number;
    public news_for_all: boolean;
    public news_for_customer_users: boolean;
    public news_for_sales_consultants: boolean;
    public news_for_employees: boolean;
    public created: Date;

    public image: Image;
    public images: Image[];

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }

    public getPostObject() {
        var object = this;
        delete object.image;
        delete object.created;
        delete object.user;
        delete object.images;
        return JSON.stringify(object);
    }

}