import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/user.service";
import * as i2 from "@angular/router";
var UserRoleGuard = /** @class */ (function () {
    function UserRoleGuard(userService, router) {
        this.userService = userService;
        this.router = router;
        this.allowedUsersRoles = [];
    }
    UserRoleGuard.prototype.canActivate = function (next, state) {
        this.allowedUsersRoles = next.data['roles'];
        if (this.allowedUsersRoles.includes(this.userService.user.user_role_id)) {
            return true;
        }
        this.router.navigate(['/app/news']);
        return false;
    };
    UserRoleGuard.ngInjectableDef = i0.defineInjectable({ factory: function UserRoleGuard_Factory() { return new UserRoleGuard(i0.inject(i1.UserService), i0.inject(i2.Router)); }, token: UserRoleGuard, providedIn: "root" });
    return UserRoleGuard;
}());
export { UserRoleGuard };
