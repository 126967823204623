import {User} from "./user";

export class UserLogs {

    public primary_key: string = "user_log_id";

    public user_log_id: number;
    public user_id: number;
    public user_log_table: string;
    public user_log_table_id: number;
    public user_log_action: string;
    public user_log_text: string;
    public user_log_creation: string;
    public user_log_updated: string;
    public users: User;
    public parsed_user_log_text: any;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}