import {EventEmitter, Injectable} from '@angular/core';

import {User} from '../models/user';
import {Constants} from '../constants';
import {UserSettings} from '../models/usersettings';

@Injectable()
export class UserService {

    public userOriginal: User;
    public user: User;
    public language = 'da';
    public isUserUpdated: EventEmitter<boolean> = new EventEmitter();
    public logoutEvent: EventEmitter<boolean> = new EventEmitter();

    // User settings
    public userSettings: UserSettings = new UserSettings();

    constructor() {
        if (localStorage.getItem('user') !== null) {
            this.user = new User(this.getUserLocalStorage());
        }

        this.getUserSettings();
    }

    public saveLogin(userParam: User) {
        const localStorageFakeUser = JSON.parse(localStorage.getItem('fake_user')) as User;
        const isFakeUser = JSON.parse(localStorage.getItem('is_fake_user'));
        this.user = new User(userParam);
        if (isFakeUser && localStorageFakeUser !== null) {
            localStorage.setItem('fake_user', JSON.stringify(userParam));
        } else {
            localStorage.setItem('user', JSON.stringify(userParam));
        }
    }

    public deleteLogin() {
        this.user = undefined;
        localStorage.removeItem('user');
        localStorage.removeItem('userSettings');
        sessionStorage.removeItem('fake_user');
        sessionStorage.removeItem('is_fake_user');
        sessionStorage.removeItem('fake_user_settings');
        sessionStorage.setItem('assignmentListFilter', JSON.stringify({}));
        this.logoutEvent.emit(true);
    }

    // Fake user (Log in as other user temporarily)
    public loginAsFakeUser(userFakeParam: User, userFakeSettings: UserSettings) {
        sessionStorage.setItem('assignmentListFilter', JSON.stringify({}));
        const isFakeUser = sessionStorage.getItem('is_fake_user');
        if (isFakeUser) {
            this.userOriginal = JSON.parse(localStorage.getItem('user')) as User;
        } else {
            this.userOriginal = this.user;
        }


        // Set fake user information in the local storage so that it will still be persistent on reloading
        sessionStorage.setItem('fake_user', JSON.stringify(userFakeParam));
        sessionStorage.setItem('is_fake_user', JSON.stringify(true));
        sessionStorage.setItem('fake_user_settings', JSON.stringify(userFakeSettings));

        this.user = userFakeParam;
        this.userSettings = userFakeSettings;

        this.isUserUpdated.emit(true);
    }

    public logoutAsFakeUser() {
        delete this.user.images;

        sessionStorage.setItem('assignmentListFilter', JSON.stringify({}));
        sessionStorage.removeItem('fake_user');
        sessionStorage.removeItem('is_fake_user');
        sessionStorage.removeItem('fake_user_settings');
        sessionStorage.removeItem('data_customers_for_users');
        this.user.setUserDetails(this.getUserLocalStorage());

        this.userOriginal = undefined;

        this.isUserUpdated.emit(true);
    }

    public getShowForUser(user_rights_type): boolean {
        if (this.user == undefined || this.user.user_role_id == undefined || Constants.USER_RIGHTS[this.user.user_role_id] == undefined) {
            return false;
        }
        for (let i = 0; i < Constants.USER_RIGHTS[this.user.user_role_id].length; i++) {
            if (Constants.USER_RIGHTS[this.user.user_role_id][i] == user_rights_type) {
                return true;
            }
        }
        return false;
    }

    public userIsOneOfUserRoleIds(user_role_ids) {
        let valid = false;
        for (let i = 0; user_role_ids.length > i; i++) {
            if (this.userIsUserRoleId(user_role_ids[i])) valid = true;
        }

        return valid;
    }

    public userIsUserRoleId(user_role_id) {
        if (this.user == undefined) return false;

        return this.user.user_role_id === user_role_id;
    }

    public getUserId(){
        // if the user is not set, logout and throw uniform error (to make this error possible to ignore in Bugsnag)
        if(!this.user || (this.user && !this.user.user_id)){
            this.deleteLogin();
            throw new Error("Authentication failed - no user set");
        }

        return this.user.user_id;
    }

    // Language
    public saveLanguage() {
        localStorage.setItem('language', JSON.stringify(this.language));
    }

    public getLanguage() {
        if (localStorage.getItem('language') != undefined) {
            const retrievedObject = localStorage.getItem('language');
            this.language = JSON.parse(retrievedObject) as string;
        }
    }

    //User settings
    public setUserSettings(data: UserSettings) {
        this.userSettings = data;
        for (const assignment of data.assignmentList) {
            if (assignment.value === 'Medarbejder') {
                assignment.tablevalue = 'user_name';
            }
        }

        localStorage.setItem('userSettings', JSON.stringify(this.userSettings));
    }


    public getUserSettings(): UserSettings {
        // localStorage.getItem returns null not undefined if it does not exist
        const isFakeUser = JSON.parse(sessionStorage.getItem('is_fake_user'));
        let settingsType;

        // if is fake user get the fake_user_settings else get the user settings
        //settingsType = isFakeUser ? 'fake_user_settings' : 'userSettings';

        if ((isFakeUser ? sessionStorage.getItem('fake_user_settings') : localStorage.getItem('userSettings')) !== null && (isFakeUser ? sessionStorage.getItem('fake_user_settings') : localStorage.getItem('userSettings')) != 'undefined') {
            const retrievedObject = isFakeUser ? sessionStorage.getItem('fake_user_settings') : localStorage.getItem('userSettings');
            this.userSettings = JSON.parse(retrievedObject) as UserSettings;

            return this.userSettings;
        }

        return this.userSettings;
    }

    public setCustomerIDForUser(id) {
        let userSettings = this.userSettings;
        userSettings.customerIDForUser = id;
        this.setUserSettings(userSettings);
    }

    public getCustomerIDForUser() {
        return this.user.customer_id;
    }

    // get user from the local storage
    public getUserLocalStorage(): User {
        const isFakeUser = JSON.parse(sessionStorage.getItem('is_fake_user'));
        const fakeUser = JSON.parse(sessionStorage.getItem('fake_user')) as User;
        const user = JSON.parse(localStorage.getItem('user')) as User;

        if (user && isFakeUser && !fakeUser) {
            this.logoutAsFakeUser();
        } else if (user && isFakeUser && fakeUser) {
            // if there is a fake user set the original user as the user from the local storage and return the fake user
            this.userOriginal = user;
            return fakeUser;
        } else if (!user) {
            this.deleteLogin();
        }
        return user;
    }

    // this was need for the case where the user attribute had no value and the application could not find and it could not make any
    // requests without the user id
    public getUser() {
        if (this.user !== undefined) {
            // if there is no user to return set the user with the active user form local storage
            this.user.setUserDetails(this.getUserLocalStorage());
        }
        return this.user;
    }

    //  set the user method and update the local storage values
    public setUser(user: User): void {
        if (user) {
            this.user.setUserDetails(user);
            const isFakeUser = JSON.parse(sessionStorage.getItem('is_fake_user'));
            if (isFakeUser) {
                sessionStorage.setItem('fake_user', JSON.stringify(this.user));
            } else {
                localStorage.setItem('user', JSON.stringify(this.user));
            }
        }
    }
}
