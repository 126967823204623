<td><i class="material-icons"
       [ngClass]="validateRow(assignment) ? 'text-is-success' : 'text-is-warning'">{{ validateRow(assignment) ? 'check' : 'warning' }}</i>
</td>
<td *ngFor="let header of headers; let key = index;" [ngSwitch]="header.tablevalue">
    <!--
    -
    - Dropdowns for selecting store, employee, salesConstulant or Customer if need be
    -
    -->
    <span *ngSwitchCase="'store'">
                <span *ngIf="!assignment['store_id']"
                      class="text-is-danger small">{{ assignment['raw'][header.tablevalue] + ', ' + assignment['raw']['address'] + ', ' + assignment['raw']['zip_code'] }}</span>
        {{ !!assignment[header.tablevalue] ? assignment[header.tablevalue].store_name : ''}}
        <app-dropdown-select
                *ngIf="!assignment[header.tablevalue]"
                [arrayList]="stores"
                [loadMore]="false"
                arrayListName="store_name"
                arrayListId="store_id"
                [arrayListSelectedObject]="assignment['store']"
                [arrayListSelectedId]="assignment['store_id']"
                (selectedItem)="assignment['store_id'] = $event"></app-dropdown-select>
              </span>
    <span *ngSwitchCase="'employee'">
                <span *ngIf="!assignment['employee_id']"
                      class="text-is-danger small">{{ assignment['raw'][header.tablevalue] }}</span>
        {{ !!assignment[header.tablevalue] ? assignment[header.tablevalue].user_name : ''}}
        <app-dropdown-select
                *ngIf="!assignment[header.tablevalue]"
                [arrayList]="employees"
                [loadMore]="false"
                arrayListName="user_name"
                arrayListId="user_id"
                [arrayListSelectedObject]="assignment['employee']"
                [arrayListSelectedId]="assignment['employee_id']"
                (selectedItem)="assignment['employee_id'] = $event"></app-dropdown-select>
              </span>
    <span *ngSwitchCase="'sales_consultant'">
                <span *ngIf="!assignment['user_id']" class="text-is-danger small">{{ assignment['raw']['user'] }}</span>
        {{ !!assignment[header.tablevalue] ? assignment[header.tablevalue].user_name : ''}}
        <app-dropdown-select
                *ngIf="!assignment[header.tablevalue]"
                [arrayList]="salesConsultants"
                [loadMore]="false"
                arrayListName="user_name"
                arrayListId="user_id"
                [arrayListSelectedObject]="assignment['sales_consultant']"
                [arrayListSelectedId]="assignment['user_id']"
                (selectedItem)="assignment['user_id'] = $event"></app-dropdown-select>
              </span>
    <span *ngSwitchCase="'customer'">
                <span *ngIf="!assignment['customer_id']"
                      class="text-is-danger small">{{ assignment['raw'][header.tablevalue] }}</span>
        {{ !!assignment[header.tablevalue] ? assignment[header.tablevalue].customer_name : ''}}
        <app-dropdown-select
                *ngIf="!assignment[header.tablevalue]"
                [arrayList]="customers"
                [loadMore]="false"
                arrayListName="customer_name"
                arrayListId="customer_id"
                [arrayListSelectedObject]="assignment['customer']"
                [arrayListSelectedId]="assignment['customer_id']"
                (selectedItem)="assignment['customer_id'] = $event"></app-dropdown-select>
              </span>
    <span *ngSwitchCase="'assignment_todos'">
                <span [ngClass]="{'text-is-danger small': assignment[header.tablevalue].length === 0 || assignment['expanded']}">{{ assignment[header.tablevalue].length > 0 && !assignment['expanded'] ? utilService.implodeTitle(assignment[header.tablevalue], 'assignment_todo_title') : assignment['raw']['assignment_todo_names'] }}</span>

                <button class="btn btn-outline-primary small btn-block"
                        *ngIf="assignment[header.tablevalue].length === 0 || assignment[header.tablevalue] === '' || assignment['expanded']"
                        (click)="assignment['expanded'] = !assignment['expanded']">{{ assignment['expanded'] ? 'Færdig' : 'Klik for at tilføje'}}</button>
        <!--
         -
         - Checkboxes for selecting todos
         -
         -->
                <span *ngIf="assignment['expanded']" class="expanded">
                  <span class="d-block push-m-5-t">
                    <label type="text">{{lang.la.as_c_card_todo_filling}}
                        <input type="checkbox" [(ngModel)]="assignment['selected_assignment_todos']['opfyldning']"
                               (change)="onTodoChange(assignment, 'opfyldning')">
                    </label>
                  </span>
                  <span class="d-block push-m-5-t">
                    <label type="text">{{lang.la.as_c_card_todo_cleaning}}
                        <input type="checkbox" [(ngModel)]="assignment['selected_assignment_todos']['rengoering']"
                               (change)="onTodoChange(assignment, 'rengoering')">
                    </label>
                  </span>
                  <span class="d-block push-m-5-t">
                    <label type="text">{{lang.la.as_c_card_todo_show}}
                        <input type="checkbox" [(ngModel)]="assignment['selected_assignment_todos']['udstilling']"
                               (change)="onTodoChange(assignment, 'udstilling')">
                    </label>
                  </span>
                  <span class="d-block push-m-5-t">
                    <label type="text">{{lang.la.as_c_card_todo_deliveries}}
                        <input type="checkbox" [(ngModel)]="assignment['selected_assignment_todos']['varebestilling']"
                               (change)="onTodoChange(assignment, 'varebestilling')">
                    </label>
                  </span>
                  <span class="d-block push-m-5-t">
                    <label type="text">{{lang.la.as_c_card_todo_out}}
                        <input type="checkbox"
                               [(ngModel)]="assignment['selected_assignment_todos']['opsaetning-af-stander']"
                               (change)="onTodoChange(assignment, 'opsaetning-af-stander')">
                    </label>
                  </span>
                  <span class="d-block push-m-5-t">
                    <label type="text">{{lang.la.as_c_card_todo_demo}}
                        <input type="checkbox" [(ngModel)]="assignment['selected_assignment_todos']['demonstration']"
                               (change)="onTodoChange(assignment, 'demonstration')">
                    </label>
                  </span>
                </span>
              </span>

    <span *ngSwitchCase="'assignment_timestype_start'">{{ assignment[header.tablevalue] | mdate: "DD/MM/YYYY HH:mm" }}</span>
    <span *ngSwitchCase="'assignment_timestype_free_end'">
                <span *ngIf="!!assignment[header.tablevalue]">{{ assignment[header.tablevalue] | mdate: "DD/MM/YYYY HH:mm"}}</span>
              </span>
    <span *ngSwitchCase="'assignment_time_estimate'">{{ utilService.onFormatToEstimate(assignment[header.tablevalue]) }}
        minutter</span>
    <span *ngSwitchCase="'assignment_pos_material'">{{ assignment[header.tablevalue] === 1 ? 'Ja' : 'Nej' }}</span>
    <span *ngSwitchDefault>{{ assignment[header.tablevalue] }}</span>
</td>