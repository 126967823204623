import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TableHeader} from "../../models/tableheader";
import {TableSettings} from "../../models/tablesettings";
import {LangService} from "../../services/lang.service";
import {TableSettingsService} from "../../services/table-settings.service";

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
})
export class TeamplayerPaginationComponent {
    @Input() tableSettings: TableSettings;
    @Output() pageChange = new EventEmitter();
    @Output() goToFirstPage = new EventEmitter();
    @Output() goToLastPage = new EventEmitter();

    constructor(public lang: LangService) {
    }

    onPageChange(event){
        this.pageChange.emit(event);
    }

    onPageChangeFirst(){
        this.goToFirstPage.emit();
    }

    onPageChangeLast(){
        this.goToLastPage.emit();
    }
}
