import {Component, OnInit} from '@angular/core';
import {UtilService} from '../services/util.service';
import {DataService} from '../services/data.service';
import {LangService} from '../services/lang.service';
import {ApiService} from '../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Customer} from '../models/customer';
import {InputString} from '../models/inputstring';
import {TableSettings} from '../models/tablesettings';
import {NotificationService} from '../shared/notification.service';
import {Location} from "@angular/common";

@Component({
    selector: 'app-customer-create',
    templateUrl: './customer-create.component.html',
    styleUrls: ['./customer-create.component.scss']
})
export class CustomerCreateComponent implements OnInit {

    //Loading
    isLoading: boolean = false;
    isLoadingView: boolean = false;

    isDeleteModalActive: boolean = false;

    //Editing
    isEditing: boolean = false;
    isEditingId: number = -1;
    isEditingCustomer: Customer;

    //Inputs
    inputName: InputString = new InputString();
    inputEmail: InputString = new InputString();
    inputPhoneNo: InputString = new InputString();
    inputAddress: InputString = new InputString();
    inputCity: InputString = new InputString();
    inputPostalCode: InputString = new InputString();
    inputContactPerson: InputString = new InputString();
    inputContactPersonPosition: InputString = new InputString();

    constructor(
        public apiService: ApiService,
        public route: ActivatedRoute,
        private router: Router,
        public utilService: UtilService,
        public lang: LangService,
        public notificationService: NotificationService,
        public dataService: DataService,
        private location: Location,
    ) {
    }

    ngOnInit() {
        this.onCheckIfEditing();
    }

    onCheckIfEditing() {
        this.isEditingId = this.route.snapshot.params['id'];
        if (this.isEditingId != undefined) {
            this.isEditing = true;
            this.isLoadingView = true;

            var ts: TableSettings = new TableSettings();
            ts.columns = "customer_id,customer_email,customer_name,customer_contact_name,customer_contact_position,customer_phone,customer_address,customer_city,customer_postal_code,customer_active";

            this.apiService.getCustomer(ts, this.isEditingId).subscribe((data) => {
                if (data.main[0] != undefined) {
                    this.isEditingCustomer = data.main[0];
                    this.onSetInputs(data.main[0]);
                }
                this.isLoadingView = false;
            }, (error) => {
                this.isLoadingView = false;
            });
        }
    }

    onSetInputs(c: Customer) {
        this.inputName.value = c.customer_name;
        this.inputPhoneNo.value = c.customer_phone;
        this.inputAddress.value = c.customer_address;
        this.inputCity.value = c.customer_city;
        this.inputPostalCode.value = c.customer_postal_code;
        this.inputEmail.value = c.customer_email;
        this.inputContactPerson.value = c.customer_contact_name;
        this.inputContactPersonPosition.value = c.customer_contact_position;
    }

    resetInputs() {
        this.onSetInputs(new Customer());
        window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
    }

    onCheckForms() {
        return this.utilService.checkMultipleIfvalid(this.inputName, this.inputPhoneNo, this.inputAddress, this.inputCity, this.inputPostalCode, this.inputEmail);
    }

    onCreateCustomer() {
        if (this.onCheckForms()) {
            return;
        }

        this.isLoading = true;

        var c: Customer = new Customer();
        c.customer_name = this.inputName.value;
        c.customer_phone = this.inputPhoneNo.value;
        c.customer_address = this.inputAddress.value;
        c.customer_city = this.inputCity.value;
        c.customer_postal_code = this.inputPostalCode.value;
        c.customer_email = this.inputEmail.value;
        c.customer_contact_name = this.inputContactPerson.value;
        c.customer_contact_position = this.inputContactPersonPosition.value;

        this.apiService.postCustomer(c).subscribe((response) => {
            this.notificationService.setNotification(this.lang.la.success_created, this.notificationService.CONSTANT_TYPE_NORMAL);
            // @ts-ignore
            this.notificationService.showCreateNotification('Kunde', response.data.customer_id, '/app/customer/show', 10000);
            this.resetInputs();
            this.isLoading = false;
        }, (error) => {
            this.notificationService.setNotification(error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
            this.isLoading = false;
        });
    }

    onEditCustomer() {
        if (this.onCheckForms()) {
            return;
        }

        this.isLoading = true;

        var c: Customer = new Customer();
        c.customer_id = this.isEditingId;
        c.customer_name = this.inputName.value;
        c.customer_phone = this.inputPhoneNo.value;
        c.customer_address = this.inputAddress.value;
        c.customer_city = this.inputCity.value;
        c.customer_postal_code = this.inputPostalCode.value;
        c.customer_email = this.inputEmail.value;
        c.customer_contact_name = this.inputContactPerson.value;
        c.customer_contact_position = this.inputContactPersonPosition.value;

        this.apiService.patchCustomer(c).subscribe((response) => {
            this.notificationService.setNotification(this.lang.la.success_updated, this.notificationService.CONSTANT_TYPE_NORMAL);
            this.router.navigate(['app/customer/list/']);
            this.isLoading = false;
        }, (error) => {
            this.notificationService.setNotification(error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
            this.isLoading = false;
        });
    }


    onChangeActive(active: number) {
        var c: Customer = new Customer();
        c.customer_id = this.isEditingId;
        c.customer_active = active;

        this.apiService.patchCustomer(c).subscribe((data) => {
            this.notificationService.setNotification(this.lang.la.success_updated, this.notificationService.CONSTANT_TYPE_NORMAL);
            this.onCheckIfEditing();
            this.isDeleteModalActive = false;

            this.isLoading = false;
        }, (error) => {
            this.notificationService.setNotification(error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
            this.isLoading = false;
        });
    }

    onGoBack() {
        this.location.back();
    }

}
