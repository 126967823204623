import {Image} from "./image";

export class ImagesForNews {
    public primary_key = "images_for_news_connection_id";

    public images_for_news_connection_id: number;
    public news_id: string;
    public image_id: string;
    public isLoading: boolean = false;


    public image: Image;
    public images: Image;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}