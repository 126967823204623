import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {LangService} from '../../services/lang.service';
import {ApiService} from '../../services/api.service';
import {TableSettings} from '../../models/tablesettings';
import {TableSettingsEquals} from '../../models/tablesettingsequals';
import {TableHeader} from '../../models/tableheader';
import {UtilService} from '../../services/util.service';
import {Subject} from 'rxjs';
import 'rxjs-compat/add/operator/takeUntil';

@Component({
    selector: 'app-dropdown-select-type-multiple',
    templateUrl: './dropdown-select-type-multiple.component.html'
})
export class DropdownSelectMulitpleTypeComponent implements OnInit, OnDestroy, OnChanges {

    public DROPDOWN_SELECT_TYPE_CUSTOMERS: number = 0;
    public DROPDOWN_SELECT_TYPE_EMPLOYEES: number = 1;
    public DROPDOWN_SELECT_TYPE_STORES: number = 2;
    public DROPDOWN_SELECT_TYPE_CHAINS: number = 3;
    public DROPDOWN_SELECT_TYPE_CONSULTANTS: number = 4;

    @Input() arrayList: any[] = [];
    @Input() arrayListName: string = "name";
    @Input() arrayListId: string = "id";
    @Input() arrayListSelected: any[];
    @Input() arrayType: number = -1;
    @Input() error: string = "";
    @Input() isDropdownActive: boolean = false;

    @Output() selectedItem: EventEmitter<any[]> = new EventEmitter<any[]>();

    isLoadingMore: boolean = false;
    isNoMoreToLoad: boolean = false;
    isAItemSelected: boolean = false;
    isUnselecting: boolean = false;

    inputDropdown: string = "";

    arrayListForView: any[] = [];

    timeout: any;

    tableSettings: TableSettings = new TableSettings();

    destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
        public lang: LangService,
        public utilService: UtilService,
        public apiService: ApiService,
        private el: ElementRef
    ) {
    }

    ngOnInit() {
        this.tableSettings.page = 1;

        //Get data when something is selected so we can show it
        if (this.arrayListSelected && this.arrayListSelected.length != 0 && this.arrayList.length == 0) {
            this.setDataForArrayType();
            this.getData();
        }
    }

    ngOnChanges() {
        if (this.arrayListSelected && this.arrayListSelected.length != 0 && this.arrayList.length == 0) {
            this.setDataForArrayType();
            this.getData();
        }
    }

    getData() {
        this.isNoMoreToLoad = false;
        this.tableSettings.tableSettingsEquals = [];
        if (this.arrayType == this.DROPDOWN_SELECT_TYPE_CUSTOMERS) {
            this.apiService.getCustomers(this.tableSettings).takeUntil(this.destroy$).subscribe((data) => this.onDataComplete(data), error => this.onDataError(error));
        } else if (this.arrayType == this.DROPDOWN_SELECT_TYPE_EMPLOYEES) {
            this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals("user_role_id", "2"));
            this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals("user_active", "1"));
            this.tableSettings.sortBy = new TableHeader("user_firstname", "user_firstname");
            this.tableSettings.sortBy.asc = false;
            this.apiService.getUsers(this.tableSettings).takeUntil(this.destroy$).subscribe((data) => this.onDataComplete(data), error => this.onDataError(error));
        } else if (this.arrayType == this.DROPDOWN_SELECT_TYPE_STORES) {
            this.apiService.getStores(this.tableSettings).takeUntil(this.destroy$).subscribe((data) => this.onDataComplete(data), error => this.onDataError(error));
        } else if (this.arrayType == this.DROPDOWN_SELECT_TYPE_CHAINS) {
            this.apiService.getChains(this.tableSettings).takeUntil(this.destroy$).subscribe((data) => this.onDataComplete(data), error => this.onDataError(error));
        } else if (this.arrayType == this.DROPDOWN_SELECT_TYPE_CONSULTANTS) {
            this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals("user_role_id", "3"));
            this.tableSettings.sortBy = new TableHeader("user_firstname", "user_firstname");
            this.tableSettings.sortBy.asc = false;
            this.apiService.getUsers(this.tableSettings).takeUntil(this.destroy$).subscribe((data) => this.onDataComplete(data), error => this.onDataError(error));
        }
    }

    onDataComplete(data) {

        var spliceArray = [];
        for (let i = 0; i < data.main.length; i++) {
            const elementi = data.main[i];
            for (let b = 0; b < this.arrayListSelected.length; b++) {
                const elementb = this.arrayListSelected[b];

                if (elementi[this.arrayListId] == elementb[this.arrayListId]) {
                    spliceArray.push(i);
                }
            }
        }

        for (let i = spliceArray.length - 1; i >= 0; i--) {
            data.main.splice(spliceArray[i], 1);
        }

        this.arrayList = this.arrayList.concat(data.main);

        this.tableSettings.tableOffset = data.tableOffset;

        this.arrayListForView = this.arrayList;

        if (data.main.length == 0 || this.tableSettings.tableOffset.highest_offset == this.tableSettings.page) {
            this.isNoMoreToLoad = true;
        }

        this.isLoadingMore = false;
    }

    onDataError(error) {
        this.isLoadingMore = false;
    }

    setDataForArrayType() {
        this.tableSettings.searchColumn = undefined;

        if (this.arrayType == this.DROPDOWN_SELECT_TYPE_CUSTOMERS) {
            this.tableSettings.searchColumn = new TableHeader("customer_name", this.inputDropdown);

            this.arrayListName = "customer_name";
            this.arrayListId = "customer_id";
        } else if (this.arrayType == this.DROPDOWN_SELECT_TYPE_EMPLOYEES) {
            this.tableSettings.searchColumn = new TableHeader("user_firstname", this.inputDropdown);
            this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals('user_active', 1));

            this.arrayListName = "user_firstname&user_lastname";
            this.arrayListId = "user_id";
        } else if (this.arrayType == this.DROPDOWN_SELECT_TYPE_STORES) {
            this.tableSettings.searchColumn = new TableHeader("store_name", this.inputDropdown);

            this.arrayListName = "store_name";
            this.arrayListId = "store_id";
        } else if (this.arrayType == this.DROPDOWN_SELECT_TYPE_CHAINS) {
            this.tableSettings.searchColumn = new TableHeader("chain_name", this.inputDropdown);

            this.arrayListName = "chain_name";
            this.arrayListId = "chain_id";
        } else if (this.arrayType == this.DROPDOWN_SELECT_TYPE_CONSULTANTS) {
            this.tableSettings.searchColumn = new TableHeader("user_firstname", this.inputDropdown);

            this.arrayListName = "user_firstname&user_lastname";
            this.arrayListId = "user_id";
        }
    }


    onLoadMorePressed() {
        this.isLoadingMore = true;

        if (this.tableSettings.tableOffset.highest_offset == this.tableSettings.page) {
            this.isNoMoreToLoad = true;
            return;
        }

        this.tableSettings.page = (+this.tableSettings.page) + 1;

        this.getData();
    }

    onClearSearch() {
        this.inputDropdown = "";

        this.selectedItem.emit(this.arrayListSelected);
    }

    /**
     * Searching through the results
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    onChangeInputDropdown() {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.isLoadingMore = true;

            this.error = "";
            this.arrayListForView = [];
            this.arrayList = [];

            this.tableSettings.search = this.inputDropdown;

            this.getData();
        }, 500);

    }

    onOpenDropdown() {
        if (!this.isUnselecting) {
            this.isDropdownActive = !this.isDropdownActive
        }
        if (this.arrayList.length == 0) {
            this.isLoadingMore = true;

            this.setDataForArrayType();
            this.getData();
        }
    }

    onItemSelected(pos) {
        if (this.arrayListForView.length < pos) {
            return;
        }
        this.inputDropdown = "";
        this.arrayListSelected.push(this.arrayListForView[pos]);

        this.selectedItem.emit(this.arrayListSelected);

        this.arrayListForView.splice(pos, 1);
    }

    onItemUnselected(pos) {
        this.arrayList.push(this.arrayListSelected[pos]);

        this.selectedItem.emit(this.arrayListSelected);

        this.arrayListSelected.splice(pos, 1);
        this.isUnselecting = true;
        setTimeout(() => {
            this.isUnselecting = false;
        }, 200);
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
