<app-imageuploader [multiple]="false" (imageUploaderChange)="filesImages = $event" [imageUploader]="filesImages"
                   [(isUploadActive)]="isImageUploadActive"></app-imageuploader>

<!-- Modal -->
<div class="modal fade modal-show" tabindex="-1" role="dialog" [ngClass]="{'modal-show': isDeleteModalActive}">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title">Du er i gang med at deaktivere</h6>
                <button type="button" class="close" (click)="isDeleteModalActive = !isDeleteModalActive"
                        aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Sikker på, du ønsker at deaktivere?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" [ngClass]="{'is-loading': isLoading}"
                        (click)="isDeleteModalActive = !isDeleteModalActive">{{lang.la.cancel}}</button>
                <button type="button" class="btn btn-danger" (click)="onChangeActive(0)"
                        [ngClass]="{'is-loading': isLoading}">Deaktiver
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal  modal-show" tabindex="-1" role="dialog" [ngClass]="{'modal-show': isVacationActive}">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Tilføj ny ferie dato</h5>
                <button type="button" class="close" (click)="isVacationActive = !isVacationActive" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label for="type">Vælg ferie periode</label>
                            <div class="input-group" [(bsValue)]="inputVacationRange " #drp="bsDaterangepicker"
                                 bsDaterangepicker [bsConfig]="dpConfig">
                                <div *ngIf="inputVacationRange.length != 0">
                                    <input class="form-control"
                                           [value]="(inputVacationRange[0] | mdate: 'DD/MM/YYYY') + '-' + (inputVacationRange[1] | mdate: 'DD/MM/YYYY')"
                                           name="inputVacationRange"
                                           disabled >
                                </div>
                                <div *ngIf="inputVacationRange.length == 0">
                                    <input class="form-control" placeholder="Fra - til" name="inputVacationRange" disabled >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-grey" (click)="isVacationActive = !isVacationActive">Fortryd
                </button>
                <button type="button" class="btn btn-primary" [ngClass]="{'is-loading': isLoadingVacation}"
                        (click)="onAddVacation()">Tilføj
                </button>
            </div>
        </div>
    </div>
</div>


<div class="row push-m-50-b">
    <div class="col-6">
        <h2 class="float-left h2" *ngIf="!isEditing">{{lang.la.create_user}} </h2>
        <h2 class="float-left h2" *ngIf="isEditing">{{lang.la.edit_user}} </h2>
    </div>
    <div class="col-6 has-text-right text-right" *ngIf="isEditing && isEditingUser != undefined">
        <button *ngIf="isEditingUser.user_active == 1" type="button" class="btn btn-danger"
                (click)="isDeleteModalActive = !isDeleteModalActive" [ngClass]="{'is-loading': isLoading}">Deaktiver
        </button>
        <button *ngIf="isEditingUser.user_active == 0" type="button" class="btn btn-primary" (click)="onChangeActive(1)"
                [ngClass]="{'is-loading': isLoading}">Aktiver
        </button>
    </div>
</div>

<!-- Loading -->
<app-loading *ngIf="isLoadingView"></app-loading>

<form *ngIf="!isLoadingView">
    <div class="card" *ngIf="!isReloading">
        <div class="card-body row">
            <div class="col-sm-6">

                <div class="form-group">
                    <label>* {{lang.la.role}}</label>
                    <div class="input-group">
                        <select class="custom-select"
                                (ngModelChange)="onChangeRole()"
                                [(ngModel)]="inputRole.value"
                                name="inputRole.value"
                                [ngClass]="{'is-invalid': inputRole.error}"
                        >
                            <option [value]="2">Medarbejder</option>
                            <option [value]="3">Salgskonsulent</option>
                            <option [value]="4">Kundebruger</option>
                            <option [value]="5">Teamplayer</option>
                        </select>
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputRole.error">
                        {{inputRole.error}}
                    </div>
                </div>

            </div>
            <div class="col-sm-6" *ngIf="onShouldShow(ID_TO_SHOW_CUSTOMERS)">

                <label *ngIf="onShouldShow(ID_TO_SHOW_CUSTOMERS_SINGLE_SELECT)">{{lang.la.customer}}</label>

                <div *ngIf="onShouldShow(ID_TO_SHOW_CUSTOMERS_SINGLE_SELECT)">
                    <app-dropdown-select
                            [arrayList]="dataService.customers"
                            [loadMore]="false"
                            arrayListName="customer_name"
                            arrayListId="customer_id"
                            [arrayListSelectedId]="inputCustomersId"
                            (selectedItem)="inputCustomersId = $event; inputCustomerError.error = '';"></app-dropdown-select>
                </div>

                <label *ngIf="onShouldShow(ID_TO_SHOW_CUSTOMERS_MULTIPLE_SELECT)">{{lang.la.customers_maybe}}</label>

                <div *ngIf="onShouldShow(ID_TO_SHOW_CUSTOMERS_MULTIPLE_SELECT)">
                    <app-dropdown-select-multiple
                            [arrayList]="dataService.customers"
                            arrayListName="customer_name"
                            arrayListId="customer_id"
                            [arrayListSelected]="inputCustomers"
                            (selectedItem)="inputCustomers = $event; inputCustomerError.error = ''"></app-dropdown-select-multiple>
                </div>

                <div class="invalid-feedback d-inline" *ngIf="inputCustomerError.error">
                    {{inputCustomerError.error}}
                </div>
            </div>

            <div class="col-sm-6 mt-2"
                 *ngIf="userService.user.user_role_id === Constants.USER_ROLE_ADMIN_ID && inputRole.value != 4">
                <div class="form-group input-group force-margin">
                    <label type="text" for="check5" class="form-control">{{lang.la.can_create_projects}}</label>
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <input type="checkbox" aria-label="Opfyldning" id="check5"
                                   [checked]="inputProjectCreate.value"
                                   (change)="inputProjectCreate.value = !inputProjectCreate.value; inputProjectCreate.error = ''"
                                   name="inputProjectCreate.value">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-divider"></div>
        <div class="card-body row">
            <div class="col-sm-6">

                <div class="form-group">
                    <label>{{lang.la.phoneno}}</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">+45</span>
                        </div>
                        <input type="number" class="form-control"
                               (ngModelChange)="inputPhoneNo.error = ''" [(ngModel)]="inputPhoneNo.value"
                               name="inputPhoneNo.value" [ngClass]="{'is-invalid': inputPhoneNo.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputPhoneNo.error">
                        {{inputPhoneNo.error}}
                    </div>
                </div>

            </div>
            <div class="col-sm-6">

                <div class="form-group">
                    <label>* {{lang.la.email}}</label>
                    <div class="input-group">
                        <input type="email" class="form-control"
                               (ngModelChange)="inputEmail.error = ''" [(ngModel)]="inputEmail.value"
                               name="inputEmail.value" [ngClass]="{'is-invalid': inputEmail.error}"/>

                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputEmail.error">
                        {{inputEmail.error}}
                    </div>
                </div>

            </div>
        </div>
        <div class="card-divider" *ngIf="onShouldShow(ID_TO_SHOW_TYPES)"></div>
        <div class="card-body row" *ngIf="onShouldShow(ID_TO_SHOW_TYPES)">
            <div class="col-sm-12">
                <label>{{lang.la.user_type}}</label>
                <div class="invalid-feedback d-inline" *ngIf="inputRoleMercer.error">
                    {{inputRoleMercer.error}}
                </div>
            </div>
            <div class="col-sm-6">

                <div class="form-group input-group">
                    <label type="text" for="check3" class="form-control">{{lang.la.mercer}}</label>
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <input type="checkbox" aria-label="Opfyldning" id="check3"
                                   [checked]="inputRoleMercer.value"
                                   (change)="this.inputRoleMercer.value = !this.inputRoleMercer.value;this.inputRoleMercer.error = ''"
                                   name="inputRoleMercer.value">
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-sm-6">

                <div class="form-group input-group">
                    <label type="text" for="check4" class="form-control">{{lang.la.demo}}</label>
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <input type="checkbox" aria-label="Opfyldning" id="check4"
                                   [checked]="inputRoleDemo.value"
                                   (change)="this.inputRoleDemo.value = !this.inputRoleDemo.value;this.inputRoleMercer.error= ''"
                                   name="inputRoleDemo.value">
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="card-divider"></div>
        <div class="card-body row">
            <div class="col-sm-6">

                <div class="form-group">
                    <label>* {{lang.la.firstname}}</label>
                    <div class="input-group">
                        <input type="text" class="form-control" required minlength="1" maxlength="999"
                               (ngModelChange)="inputFirstName.error = ''" [(ngModel)]="inputFirstName.value"
                               name="inputFirstName.value" [ngClass]="{'is-invalid': inputFirstName.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputFirstName.error">
                        {{inputFirstName.error}}
                    </div>
                </div>

                <!-- File upload -->
                <div class="form-group">
                    <label>{{lang.la.image}}</label>
                    <div class="input-group">
                        <button class="btn btn-outline-secondary is-fullwidth text-left"
                                (click)="isImageUploadActive = !isImageUploadActive">
                            Tilføj billede her
                            <span class="float-right"
                                  *ngIf="filesImages != undefined && filesImages.queue != undefined">
							({{filesImages.queue.length}} tilføjet)
						</span>
                        </button>
                    </div>
                </div>
                <!-- File upload ^ -->

            </div>
            <div class="col-sm-6">

                <div class="form-group">
                    <label>* {{lang.la.surname}}</label>
                    <div class="input-group">
                        <input type="text" class="form-control" required minlength="1" maxlength="999"
                               (ngModelChange)="inputLastName.error = ''" [(ngModel)]="inputLastName.value"
                               name="inputLastName.value" [ngClass]="{'is-invalid': inputLastName.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputLastName.error">
                        {{inputLastName.error}}
                    </div>
                </div>

            </div>
        </div>

        <div class="card-divider"
             *ngIf="onShouldShowMultiple(ID_TO_SHOW_CPR, ID_TO_SHOW_POSTALCODE, ID_TO_SHOW_ADDRESS, ID_TO_SHOW_CITY)"></div>
        <div class="card-body row"
             *ngIf="onShouldShowMultiple(ID_TO_SHOW_CPR, ID_TO_SHOW_POSTALCODE, ID_TO_SHOW_ADDRESS, ID_TO_SHOW_CITY)">
            <div class="col-sm-6">

                <div class="form-group" *ngIf="onShouldShow(ID_TO_SHOW_CPR)">
                    <label>{{lang.la.cprnumber}}</label>
                    <div class="input-group">
                        <input type="text" max="10" class="form-control"
                               (ngModelChange)="inputCPR.error = ''" [(ngModel)]="inputCPR.value"
                               name="inputCPR.value" [ngClass]="{'is-invalid': inputCPR.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputCPR.error">
                        {{inputCPR.error}}
                    </div>
                </div>

                <div class="form-group" *ngIf="onShouldShow(ID_TO_SHOW_POSTALCODE)">
                    <label>
                        <span *ngIf="inputRole.value == Constants.USER_ROLE_EMPLOYEE_ID.toString()">* </span>
                        {{lang.la.postalcode}}
                    </label>
                    <div class="input-group">
                        <input type="number" class="form-control"
                               (ngModelChange)="inputPostalCode.error = ''" [(ngModel)]="inputPostalCode.value"
                               name="inputPostalCode.value" [ngClass]="{'is-invalid': inputPostalCode.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputPostalCode.error">
                        {{inputPostalCode.error}}
                    </div>
                </div>

            </div>
            <div class="col-sm-6">

                <div class="form-group" *ngIf="onShouldShow(ID_TO_SHOW_ADDRESS)">
                    <label>
                        <span *ngIf="inputRole.value == Constants.USER_ROLE_EMPLOYEE_ID.toString()">* </span>
                        {{lang.la.address}}
                    </label>
                    <div class="input-group">
                        <input type="text" class="form-control"
                               (ngModelChange)="inputAddress.error = ''" [(ngModel)]="inputAddress.value"
                               name="inputAddress.value" [ngClass]="{'is-invalid': inputAddress.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputAddress.error">
                        {{inputAddress.error}}
                    </div>
                </div>

                <div class="form-group" *ngIf="onShouldShow(ID_TO_SHOW_CITY)">
                    <label>
                        <span *ngIf="inputRole.value == Constants.USER_ROLE_EMPLOYEE_ID.toString()">* </span>
                        {{lang.la.city}}
                    </label>
                    <div class="input-group">
                        <input type="text" class="form-control"
                               (ngModelChange)="inputCity.error = ''" [(ngModel)]="inputCity.value"
                               name="inputCity.value" [ngClass]="{'is-invalid': inputCity.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputCity.error">
                        {{inputCity.error}}
                    </div>
                </div>

            </div>
        </div>
        <div class="card-divider" *ngIf="onShouldShow(ID_TO_SHOW_REGACC)"></div>
        <div class="card-body row" *ngIf="onShouldShow(ID_TO_SHOW_REGACC)">
            <div class="col-sm-6">

                <div class="form-group">
                    <label>{{lang.la.reg_acc}}</label>
                    <div class="input-group">
                        <input type="number" class="form-control"
                               (ngModelChange)="inputReqNo.error = ''" [(ngModel)]="inputReqNo.value"
                               name="inputReqNo.value" [ngClass]="{'is-invalid': inputReqNo.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputReqNo.error">
                        {{inputReqNo.error}}
                    </div>
                </div>

            </div>
            <div class="col-sm-6">

                <div class="form-group">
                    <label>{{lang.la.acc_no}}</label>
                    <div class="input-group">
                        <input type="number" class="form-control"
                               (ngModelChange)="inputAccNo.error = ''" [(ngModel)]="inputAccNo.value"
                               name="inputAccNo.value" [ngClass]="{'is-invalid': inputAccNo.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputAccNo.error">
                        {{inputAccNo.error}}
                    </div>
                </div>

            </div>
        </div>
        <div class="card-divider" *ngIf="inputRole.value == '2'"></div>
        <div class="card-body row" *ngIf="inputRole.value == '2'">
            <div class="col-sm-12">
                <label for="type">Ferie</label>
            </div>
            <div class="col-sm-12">
			<span class="badge badge-success push-m-20-r mb-2" *ngFor="let vacation of vacations; let pos = index">
				<span class="h6 push-p-10">
					{{vacation.vacation_start | mdate: 'DD-MM-YYYY' : false}}
                    - {{vacation.vacation_end | mdate: 'DD-MM-YYYY' : false}}
				</span>


				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"
                     (click)="onDeleteVacation(pos)">
					<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
					<path d="M0 0h24v24H0z" fill="none"/>
				</svg>

			</span>
                <button class="btn btn-outline-secondary" (click)="isVacationActive = !isVacationActive"
                        [disabled]="!this.isEditingId">Tilføj ferie
                </button>
                <span *ngIf="!this.isEditingId" class="text-is-grey small push-m-5-l">Du kan tilføje ferie, efter at brugeren er oprettet</span>
            </div>
        </div>
        <div class="card-divider" *ngIf="onShouldShowMultiple(ID_TO_SHOW_POSITION, ID_TO_SHOW_DISTRICT)"></div>
        <div class="card-body row" *ngIf="onShouldShowMultiple(ID_TO_SHOW_POSITION, ID_TO_SHOW_DISTRICT)">
            <div class="col-sm-6" *ngIf="onShouldShow(ID_TO_SHOW_POSITION)">

                <div class="form-group">
                    <label>{{lang.la.jobposition}}</label>
                    <div class="input-group">
                        <input type="text" class="form-control"
                               (ngModelChange)="inputPosition.error = ''" [(ngModel)]="inputPosition.value"
                               name="inputPosition.value" [ngClass]="{'is-invalid': inputPosition.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputPosition.error">
                        {{inputPosition.error}}
                    </div>
                </div>

            </div>
            <div class="col-sm-6" *ngIf="onShouldShow(ID_TO_SHOW_DISTRICT)">

                <div class="form-group">
                    <label>{{lang.la.district}}</label>
                    <div class="input-group">
                        <input type="text" class="form-control"
                               (ngModelChange)="inputDistrict.error = ''" [(ngModel)]="inputDistrict.value"
                               name="inputDistrict.value" [ngClass]="{'is-invalid': inputDistrict.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputDistrict.error">
                        {{inputDistrict.error}}
                    </div>
                </div>

            </div>
        </div>
        <ng-container
                *ngIf="userService.userIsUserRoleId(Constants.USER_ROLE_ADMIN_ID) && inputRole.value == Constants.USER_ROLE_EMPLOYEE_ID">
            <div class="card-divider"></div>
            <div class="card-body row">
                <ng-container *ngIf="inputRole.value  == Constants.USER_ROLE_EMPLOYEE_ID">
                    <div class="col-sm-6" *ngIf="onShouldShow(ID_TO_SHOW_MERCER) && inputRoleMercer.value">

                        <div class="form-group">
                            <label>* {{lang.la.mercer_tax}}</label>
                            <div class="input-group">
                                <input type="number" class="form-control"
                                       (ngModelChange)="inputMercer.error = ''" [(ngModel)]="inputMercer.value"
                                       name="inputMercer.value" [ngClass]="{'is-invalid': inputMercer.error}"
                                       min="1">
                            </div>
                            <div class="invalid-feedback d-inline" *ngIf="inputMercer.error">
                                {{inputMercer.error}}
                            </div>
                        </div>

                    </div>
                    <div class="col-sm-6" *ngIf="onShouldShow(ID_TO_SHOW_DEMO) && inputRoleDemo.value">

                        <div class="form-group">
                            <label>* {{lang.la.demo_tax}}</label>
                            <div class="input-group">
                                <input type="text" class="form-control"
                                       (ngModelChange)="inputDemo.error = ''" [(ngModel)]="inputDemo.value"
                                       name="inputDemo.value" [ngClass]="{'is-invalid': inputDemo.error}"
                                       min="1">
                            </div>
                            <div class="invalid-feedback d-inline" *ngIf="inputDemo.error">
                                {{inputDemo.error}}
                            </div>
                        </div>
                    </div>
                    <!-- Buffer, quick solution for spacing -->
                    <div class="col-sm-6"
                         *ngIf="(!inputRoleDemo.value && inputRoleMercer.value) || (inputRoleDemo.value && !inputRoleMercer.value)"></div>
                </ng-container>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>{{lang.la.danloen}}</label>
                        <div class="input-group">
                            <input type="number" class="form-control"
                                   (ngModelChange)="inputDanSalary.error = ''" [(ngModel)]="inputDanSalary.value"
                                   name="inputDanSalary.value" [ngClass]="{'is-invalid': inputDanSalary.error}">
                        </div>
                        <div class="invalid-feedback d-inline" *ngIf="inputDanSalary.error">
                            {{inputDanSalary.error}}
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>


        <div class="card-divider"></div>
        <div class="card-body row">
            <div class="col-sm-6">

                <div class="form-group">
                    <label>* {{lang.la.password}}</label>
                    <div class="input-group"
                         tooltip="Adgangskoden skal være mindst 8 tegn, indeholde mindst et stort og et småt bogstav og mindst et tal"
                         placement="bottom">
                        <input type="password" class="form-control"
                               (ngModelChange)="inputPassword1.error = ''" [(ngModel)]="inputPassword1.value"
                               name="inputPassword1.value" [ngClass]="{'is-invalid': inputPassword1.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputPassword1.error">
                        {{inputPassword1.error}}
                    </div>
                </div>

            </div>
            <div class="col-sm-6">

                <div class="form-group">
                    <label>* {{lang.la.password_again}}</label>
                    <div class="input-group">
                        <input type="password" class="form-control"
                               (ngModelChange)="inputPassword2.error = ''" [(ngModel)]="inputPassword2.value"
                               name="inputPassword2.value" [ngClass]="{'is-invalid': inputPassword2.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputPassword2.error">
                        {{inputPassword2.error}}
                    </div>
                </div>

            </div>
        </div>
        <div class="card-divider" *ngIf="onShouldShow(ID_TO_SHOW_TSHIRT)"></div>
        <div class="card-body row" *ngIf="onShouldShow(ID_TO_SHOW_TSHIRT)">
            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label for="type">{{lang.la.profile_tshirt}}</label>
                    <div class="input-group">
                        <input type="email" class="form-control" (ngModelChange)="inputBreastSize.error = ''"
                               [(ngModel)]="inputBreastSize.value"
                               name="inputBreastSize.value" [ngClass]="{'is-invalid': inputBreastSize.error}">
                        <div class="input-group-append">
                            <span class="input-group-text">cm</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="form-group input-group">
                    <label for="type">{{lang.la.delievering}}</label><br/>
                    <div class="input-group">
                        <label type="text" for="inputDelieveryShop" class="form-control">{{lang.la.packshop}}</label>
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <input type="radio" aria-label="Løbende" id="inputDelieveryShop"
                                       name="inputDelieveryShop.value"
                                       (change)="inputDelieveryShop.value = !inputDelieveryShop.value"
                                       [checked]="inputDelieveryShop.value === true"
                                       name="inputDelieveryShop.value"
                                       [ngClass]="{'is-invalid': inputDelieveryShop.error}">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group input-group">
                    <label type="text" for="inputDelieveryAddress"
                           class="form-control">{{lang.la.delievering_on_info}}</label>
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <input type="radio" aria-label="Løbende" id="inputDelieveryAddress"
                                   name="inputDelieveryShop.value"
                                   (change)="inputDelieveryShop.value = !inputDelieveryShop.value"
                                   [checked]="inputDelieveryShop.value === false"
                                   name="inputDelieveryAddress.value"
                                   [ngClass]="{'is-invalid': inputDelieveryAddress.error}">
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="card-divider"></div>
        <ng-container
                *ngIf="userService.userIsUserRoleId(Constants.USER_ROLE_ADMIN_ID) && (isEditing && isEditingUser.user_role_id === Constants.USER_ROLE_EMPLOYEE_ID)">
            <div class="card-divider"></div>
            <div class="card-body row">
                <div class="col-sm-8">
                    <div class="form-group"
                         *ngIf="isEditing && isEditingUser.user_cooperation_agreements_date != '0000-00-00 00:00:00'">
                        <label for="type">{{lang.la.cooperation_accepted}}</label>
                        <div class="input-group">
                            <input class="text" class="is-fullwidth" id="samarbejdsaftalerdate" disabled
                                   [value]="isEditingUser.user_cooperation_agreements_date ? utilService.onUppercase(isEditingUser.user_cooperation_agreements_date | mdate: 'dddd	DD. MMM YYYY HH:mm') : ''"/>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label>
                            {{lang.la.cooperation_deal}}
                        </label>
                        <textarea class="form-control" id="samarbejdsaftaler" rows="5"
                                  name="inputCollabText.value" [(ngModel)]="inputCollabText.value"></textarea>
                    </div>
                </div>

            </div>
        </ng-container>
        <div class="card-divider"></div>
        <div class="card-body row">
            <div class="col-12">
                <div class="form-group">
                    <label for="exampleFormControlTextarea1">
                        {{lang.la.notes}}
                    </label>
                    <textarea class="form-control" rows="3" (ngModelChange)="inputNotes.error = ''"
                              [(ngModel)]="inputNotes.value"
                              name="inputNotes.value" [ngClass]="{'is-invalid': inputNotes.error}"></textarea>
                    <div class="invalid-feedback d-inline" *ngIf="inputNotes.error">
                        {{inputNotes.error}}
                    </div>
                </div>
            </div>

            <div class="col-sm-12 has-text-right">
                <button class="btn btn-secondary ml-2" [ngClass]="{'is-loading': isLoading}"
                        (click)="onGoBack()">{{lang.la.cancel}}</button>
                <button class="btn btn-primary ml-2" [ngClass]="{'is-loading': isLoading}" (click)="save('create')"
                        *ngIf="!isEditing">{{lang.la.create}}</button>
                <button class="btn btn-primary ml-2" [ngClass]="{'is-loading': isLoading}" (click)="save('edit')"
                        *ngIf="isEditing">{{lang.la.save}}</button>
            </div>
        </div>

    </div>
</form>

