<app-imageuploader [multiple]="false" [imageUploader]="filesImages"
                   [(isUploadActive)]="isImageUploadActive"></app-imageuploader>

<h1 class="push-m-50-b h2" *ngIf="!isEditing">{{lang.la.create_chain}}</h1>
<h1 class="push-m-50-b h2" *ngIf="isEditing">{{lang.la.edit_chain}}</h1>

<div class="card card-top  card-loading" *ngIf="isLoadingView">
    <div class="card-body">
        <div class="loader"></div>
    </div>
</div>
<!-- Table -->
<div class="card card-top" *ngIf="!isLoadingView">
    <div class="card-body row">

        <div class="col-sm-6">

            <div class="form-group">
                <label>* {{lang.la.name}}</label>
                <div class="input-group">
                    <input type="text" class="form-control"
                           (ngModelChange)="inputName.error = ''" [(ngModel)]="inputName.value"
                           name="inputName.value" [ngClass]="{'is-invalid': inputName.error}">
                </div>
                <div class="invalid-feedback d-inline" *ngIf="inputName.error">
                    {{inputName.error}}
                </div>
            </div>


        </div>

        <div class="col-12 col-sm-6">

            <!-- File upload -->
            <div class="form-group">
                <label>{{lang.la.image}}</label>
                <div class="input-group">
                    <button class="btn btn-outline-secondary is-fullwidth text-left"
                            (click)="isImageUploadActive = !isImageUploadActive">
                        {{lang.la.add_image_here}}
                        <span class="float-right" *ngIf="filesImages != undefined && filesImages.queue != undefined">
							({{filesImages.queue.length}} {{lang.la.added}})
						</span>
                    </button>
                </div>
            </div>
            <!-- File upload ^ -->

        </div>
        <div class="col-12 col-sm-6"></div>
        <div class="col-12 col-sm-6" *ngIf="inputImage != undefined">
            <div class="row">
                <div class="col-sm-12">
                    Allerede tilføjet billede
                </div>
                <div class="col-sm-10">
                    <img [src]="utilService.onImageToView(inputImage.image_link)" style="width: auto; height: 75px;">
                </div>
                <div class="col-sm-2 text-right">
                    <a class="text-grey float-right" (click)="onRemoveImage()">
                        <svg fill="#ff0000" height="16" viewBox="0 0 24 24" width="16"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                            <path d="M0 0h24v24H0z" fill="none"/>
                        </svg>
                    </a>
                </div>
            </div>
        </div>


        <div class="col-sm-12 has-text-right">
            <button class="btn btn-secondary ml-2" [ngClass]="{'is-loading': isLoading}"
                    (click)="onGoBack()">{{lang.la.cancel}}</button>
            <button class="btn btn-primary ml-2" [ngClass]="{'is-loading': isLoading}" (click)="onCreate()"
                    *ngIf="!isEditing">{{lang.la.create}}</button>
            <button class="btn btn-primary ml-2" [ngClass]="{'is-loading': isLoading}" (click)="onEdit()"
                    *ngIf="isEditing">{{lang.la.save}}</button>
        </div>

    </div>

</div>
