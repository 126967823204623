import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {TableSettings} from '../models/tablesettings';
import {UserService} from './user.service';
import {NotificationService} from '../shared/notification.service';
import {Export} from '../models/export';
import {moment} from 'ngx-bootstrap/chronos/test/chain';
import {UtilService} from "./util.service";

@Injectable()
export class CSVService {

    public exportCSVdata: Export;
    public isLoadingExport: boolean = false;

    constructor(
        private apiService: ApiService,
        private userService: UserService,
        private notificationService: NotificationService,
        private utilService: UtilService
    ) {
    }

    /**
     * Export CSV
     * I have to sent Authorization in header, so can't just window.open()
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    public exportCSV(tableSettings: TableSettings) {
        let xhr = new XMLHttpRequest();
        this.isLoadingExport = true;

        xhr.open('GET', this.apiService.onBuildEndpoint('export_assignments', tableSettings, true), true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.setRequestHeader('Authorization', this.userService.user.user_token);
        xhr.responseType = 'blob';

        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                this.isLoadingExport = false;

                if (xhr.status === 200) {
                    const fileName = "Teamplayer opgaveliste " + this.getPeriodString(tableSettings) + ".xls";
                    this.utilService.downloadFile(fileName, 'application/xls', xhr);
                } else {
                    this.notificationService.setNotification("Der skete en fejl. Prøv igen.", this.notificationService.CONSTANT_TYPE_WARNING);
                }
            }
        };
        xhr.send();
    }

    /**
     * Export  CSV
     * I have to sent Authorization in header, so can't just window.open()
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    public exportGeneralCSV(tableSettings: TableSettings, endpointString: string) {
        let xhr = new XMLHttpRequest();
        this.isLoadingExport = true;

        xhr.open('GET', this.apiService.onBuildEndpoint(endpointString, tableSettings), true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.setRequestHeader('Authorization', this.userService.user.user_token);
        xhr.responseType = 'blob';

        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                this.isLoadingExport = false;

                if (xhr.status === 200) {
                    var type = '';
                    switch (endpointString) {
                        case 'export_customers':
                            type = 'kundeoversigt';
                            break;
                        case 'export_chains':
                            type = 'kædeoversigt';
                            break;
                    }

                    const fileName = "Teamplayer " + type + ' ' + new Date().toLocaleString() + ".xls";
                    this.utilService.downloadFile(fileName, 'application/xls', xhr);
                } else {
                    this.notificationService.setNotification("Der skete en fejl. Prøv igen.", this.notificationService.CONSTANT_TYPE_WARNING);
                }
            }
        };
        xhr.send();
    }


    /**
     * Export USER CSV
     * I have to sent Authorization in header, so can't just window.open()
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    public exportUsersCSV(tableSettings: TableSettings, user_type = 'bruger') {
        let xhr = new XMLHttpRequest();
        this.isLoadingExport = true;

        xhr.open('GET', this.apiService.onBuildEndpoint('export_users', tableSettings), true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.setRequestHeader('Authorization', this.userService.user.user_token);
        xhr.responseType = 'blob';

        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                this.isLoadingExport = false;

                if (xhr.status === 200) {
                    const fileName = "Teamplayer " + user_type + " " + new Date().toLocaleString() + ".xls";
                    this.utilService.downloadFile(fileName, 'application/xls', xhr);
                } else {
                    this.notificationService.setNotification("Der skete en fejl. Prøv igen.", this.notificationService.CONSTANT_TYPE_WARNING);
                }
            }
        };
        xhr.send();
    }

    /**
     * Export CSV
     * I have to sent Authorization in header, so can't just window.open()
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    public exportStoreCSV(tableSettings: TableSettings) {
        let xhr = new XMLHttpRequest();
        this.isLoadingExport = true;

        xhr.open('GET', this.apiService.onBuildEndpoint('export_stores', tableSettings), true);
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.setRequestHeader('Authorization', this.userService.user.user_token);
        xhr.responseType = 'blob';

        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                this.isLoadingExport = false;

                if (xhr.status === 200) {
                    const fileName = "Teamplayer butikker " + new Date().toLocaleString() + ".xls";
                    this.utilService.downloadFile(fileName, 'application/xls', xhr);
                } else {
                    this.notificationService.setNotification("Der skete en fejl. Prøv igen.", this.notificationService.CONSTANT_TYPE_WARNING);
                }
            }
        };
        xhr.send();
    }

    /**
     * Export salary
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    public exportAssignments(tableSettings: TableSettings, customer_or_user_type, customer_or_user_id, export_id = null) {
        let xhr = new XMLHttpRequest();
        this.isLoadingExport = true;

        if (customer_or_user_type == "customer") {
            xhr.open('GET', this.apiService.onBuildEndpoint('export_invoice', tableSettings) + "&customer_id=" + customer_or_user_id + '&export_id=' + export_id, true);
        } else {
            xhr.open('GET', this.apiService.onBuildEndpoint('export_salary', tableSettings) + "&user_id=" + customer_or_user_id + '&export_id=' + export_id, true);
        }
        xhr.setRequestHeader('Content-type', 'application/json');
        xhr.setRequestHeader('Authorization', this.userService.user.user_token);
        xhr.responseType = 'blob';

        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                this.isLoadingExport = false;

                if (xhr.status === 200) {
                    var contentType = (customer_or_user_type == "customer" ? 'application/octet-stream' : 'application/csv');
                    const fileName = xhr.getResponseHeader('Content-Disposition').replace('attachment;filename=', '').replace('"', '');
                    this.utilService.downloadFile(fileName, contentType, xhr);
                } else {
                    const reader = new FileReader();
                    const blob = new Blob([xhr.response], {type: "text/plain"});
                    reader.addEventListener('loadend', (e) => {
                        // @ts-ignore
                        const error = JSON.parse(e.srcElement.result);
                        this.notificationService.setNotification(error.message, this.notificationService.CONSTANT_TYPE_WARNING);
                    });

                    reader.readAsText(blob);
                }
            }
        };
        xhr.send();
    }

    private parseDate(string: string, seconds: number) {
        if (typeof string !== 'string' || !string) return undefined;
        if (!seconds) seconds = 0;

        var a = string.split(/[^0-9]/);
        var d = new Date(parseInt(a[0]), parseInt(a[1]) - 1, parseInt(a[2]), parseInt(a[3]), parseInt(a[4]), seconds);
        return d;
    }

    private getPeriodString(tableSettings: TableSettings) {
        if (tableSettings.tableSettingsGreater["0"] != undefined) {
            var from_date = this.parseDate(tableSettings.tableSettingsGreater["0"].value, 0);
            var to_date = this.parseDate(tableSettings.tableSettingsLower["0"].value, 0);
            return from_date.toLocaleDateString().split('/').join('-') + ' - ' + to_date.toLocaleDateString().split('/').join('-');
        } else {
            return moment().format("DDMMYYYYHHMMSS");
        }
    }
}
