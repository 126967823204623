<div class="container-fluid has-text-centered is-fullheightvh">
    <div class="row is-fullheightvh">
        <div class="col-sm-12 col-xs-12 bg-image col-md-6 is-fullheightvh has-centered-everything">

            <div class="max-width-400" *ngIf="!isSuccessful">
                <img src="./assets/logo_dark.png" alt="" class="push-m-50-b max-width-100">
                <div class="is-fullwidth has-text-left">

                    <form (ngSubmit)="onForgotPassword()">

                        <div class="form-group">
                            <label for="inputEmail">{{lang.la.get_new_code_email}}</label>
                            <input type="email" placeholder="E-mail" class="form-control" required
                                   [(ngModel)]="inputEmail.value"
                                   name="emailInput" [ngClass]="{'is-invalid': inputEmail.error}"/>
                            <div class="invalid-feedback d-inline" *ngIf="inputEmail.error">
                                {{inputEmail.error}}
                            </div>
                        </div>

                        <button class="btn btn-primary push-m-10-t is-fullwidth"
                                [ngClass]="{'is-loading': isLoading}">{{lang.la.get_new_code}}</button>
                        <div class="form-group push-m-30-t text-center">
                            <a class="link is-grey" routerLink="../login">{{lang.la.go_to_login}}</a>
                        </div>
                    </form>

                </div>
            </div>

            <div class="max-width-400" *ngIf="isSuccessful">
                <img src="./assets/logo_dark.png" alt="" class="push-m-50-b max-width-100">
                <div class="is-fullwidth has-text-left">
                    <p>
                        {{lang.la.get_new_code_success}}
                    </p>

                    <button class="btn btn-primary push-m-20-t" routerLink="../login"
                            [ngClass]="{'is-loading': isLoading}">{{lang.la.go_to_login}}</button>
                </div>
            </div>

        </div>
        <div class="col-sm-6  is-fullheightvh hidden-sm hidden-xs">

        </div>
    </div>
</div>
