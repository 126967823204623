<div class="row" *ngIf="userService.user">
    <div class="col-sm-12">
        <div class="card card-top">
            <div class="card-body ">

                <div class="col-sm-12 push-p-30-b text-center push-p-30-t">
                    <h1 class="h4">Underskriv samarbejdsaftalen for at komme igang</h1>
                </div>
                <div class="col-sm-12" *ngIf="userService.user.user_cooperation_agreements">
					<textarea class="form-control" id="samarbejdsaftaler" rows="10"
                              disabled="true" [(ngModel)]="this.agreement"></textarea>
                </div>
                <div class="col-sm-12 text-center push-p-30-t push-p-30-b">
                    <button class="btn btn-primary" (click)="onAcceptAgreement()" [ngClass]="{'is-loading': isLoading}">
                        Accepter
                    </button>
                </div>

            </div>
        </div>
    </div>
</div>