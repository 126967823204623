import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../services/user.service';
import {ApiService} from '../services/api.service';
import {UtilService} from '../services/util.service';
import {User} from '../models/user';
import {InputString} from '../models/inputstring';
import {Constants} from '../constants';
import {LangService} from '../services/lang.service';
import {NotificationService} from '../shared/notification.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    inputEmail: InputString = new InputString();
    inputPassword: InputString = new InputString();
    error: string = "";

    isLoading: boolean = false;
    isSuccessful: boolean = false;

    emailInput: any;

    constructor(
        public utilService: UtilService,
        public route: ActivatedRoute,
        private userService: UserService,
        private router: Router,
        private apiService: ApiService,
        public lang: LangService,
        private notificationService: NotificationService
    ) {
    }

    ngOnInit() {
        // this.userService.getUser();
        if (this.userService.user != undefined) {
            this.isSuccessful = true;

            this.inputEmail.value = this.userService.user.user_email;
            setTimeout(() => {
                this.router.navigate(['app']);
                this.isLoading = false;
            }, 2000);
        }
    }

    onLogin() {
        this.error = "";

        var validForm = true;

        if (this.inputEmail.error = this.utilService.checkFilled(this.inputEmail.value)) {
            validForm = false;
        }

        if (this.inputEmail.error = this.utilService.checkMail(this.inputEmail.value)) {
            validForm = false;
        }

        if (this.inputPassword.error = this.utilService.checkFilled(this.inputPassword.value)) {
            validForm = false;
        }

        if (!validForm) {
            this.notificationService.setNotification(this.lang.la.error_form_invalid, this.notificationService.CONSTANT_TYPE_WARNING);
            return;
        }

        this.isLoading = true;

        this.apiService.postLogin(this.inputEmail.value, this.inputPassword.value).subscribe((data) => {

            var token = data.data.token;
            var user: User = data.meta.user_details;
            user.user_role = Constants.USER_ROLE_TEAMPLAYER; //TODO DELETE
            user.user_token = token;
            user.user_role_id = Constants.USER_ROLE_TEAMPLAYER_ID; // TODO REMOVE
            user.user_role = "teamplayer"; // TODO REMOVE

            this.userService.saveLogin(user);
            this.apiService.setHeaders();

            this.router.navigate(['app']);
            this.isSuccessful = true;
        }, (erroar) => {
            this.error = "Enten forkert email eller kode. Prøv venligst igen.";
            this.isLoading = false;
            this.notificationService.setNotification(this.lang.la.error_general, this.notificationService.CONSTANT_TYPE_WARNING);


        });
    }
}
