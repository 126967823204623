import {Injectable} from '@angular/core';
import {FileUploader} from "ng2-file-upload";
import {ApiService} from "./api.service";

@Injectable()
export class FileUploaderService {
    constructor(public apiService: ApiService) {
    }

    uploadAllInQueue(fileUploader: FileUploader, options: any) {
        let promises = [];

        for (let i = 0; i < fileUploader.queue.length; i++) {
            promises.push(this.upload(fileUploader.queue[i], options));
        }

        return Promise.all(promises);
    }

    upload(file, options) {
        const data = this.getFormData(file, options);

        return this.apiService.uploadFile(data, options.type, options.module, options.id).toPromise();
    }

    getFormData(file, options) {
        switch (options.type) {
            case 'appendix':
                return {
                    appendix: file._file,
                    appendix_amount: file.appendixNumber
                };
            case 'image':
                return {
                    image: file._file
                }
        }
    }
}
