<div class="row push-m-50-b">
    <div class="col-8">
        <h1 class="float-left h2">
            Eksport:
            <div class="h3" *ngIf="startDate">
                {{ export.export_name }}<br>
                Type: {{ export.export_assignment_type | titlecase }}<br>
                Status: {{ export.export_status_pretty | titlecase }}<br>
                {{startDate + ' - ' + endDate}}</div>
        </h1>
    </div>
    <ng-container *ngIf="tableData && tableData.length > 0">
        <div class="col-4 has-text-right">
            <button
                    *ngIf="export.export_type == 'customer'
		&& (this.userService.user.user_role_id == Constants.USER_ROLE_TEAMPLAYER_ID
		|| this.userService.user.user_role_id == Constants.USER_ROLE_ADMIN_ID)"
                    class="btn btn-primary"
                    [disabled]="csvService.isLoadingExport"
                    [ngClass]="{'is-loading': csvService.isLoadingExport}"
                    (click)="csvService.exportAssignments(tableSettings, 'customer', customerOrUserId, this.urlParams.id)">
                {{lang.la.export_to_excel}}
            </button>
            <button
                    *ngIf="(export.export_type == 'all_users' || export.export_type == 'user')
	&& (this.userService.user.user_role_id == Constants.USER_ROLE_TEAMPLAYER_ID
	|| this.userService.user.user_role_id == Constants.USER_ROLE_ADMIN_ID)"
                    class="btn btn-primary"
                    [disabled]="csvService.isLoadingExport"
                    [ngClass]="{'is-loading': csvService.isLoadingExport}"
                    (click)="csvService.exportAssignments(tableSettings, 'user', customerOrUserId, this.urlParams.id)">
                {{lang.la.export_to_danloen}}
            </button>
        </div>
    </ng-container>

</div>

<!-- Loading -->
<app-loading *ngIf="isLoadingView"></app-loading>

<!-- Table -->
<div *ngIf="!isLoadingView">

    <!-- Table -->
    <div class="card card-top">
        <div class="card-body card-table">
            <div class="table-responsive">
                <table class="table table-hover table-striped">
                    <thead>
                    <tr>
                        <th scope="col" *ngFor="let tableHeader of tableSettings.tableHeaders; let pos = index">
								<span class="push-m-5-r">
									{{tableHeader.value}}
								</span>

                            <!-- ASC OR DESC -->
                            <svg class="th-icon" fill="#6c757d" height="18" viewBox="0 0 24 24" width="18"
                                 xmlns="http://www.w3.org/2000/svg"
                                 *ngIf="tableSettings.sortBy.value == tableHeader.value && tableHeader.asc">
                                <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"/>
                                <path d="M0-.75h24v24H0z" fill="none"/>
                            </svg>
                            <svg class="th-icon" fill="#6c757d" height="18" viewBox="0 0 24 24" width="18"
                                 xmlns="http://www.w3.org/2000/svg"
                                 *ngIf="tableSettings.sortBy.value == tableHeader.value && !tableHeader.asc">
                                <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"/>
                                <path d="M0 0h24v24H0z" fill="none"/>
                            </svg>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of tableData; let pos = index">
                        <td *ngFor="let tableHeader of tableSettings.tableHeaders"
                            [routerLink]="['/app/task/show/', item['assignment_id']]"
                        >

								<span [ngSwitch]="tableHeader.tablevalue">
                  <span *ngSwitchCase="'has_image'"><i class="material-icons"
                                                       *ngIf="item[tableHeader.tablevalue] == 'true'">photo</i></span>
                  <span *ngSwitchCase="'short_description'" [innerHTML]="item[tableHeader.tablevalue]"></span>
                  <span *ngSwitchCase="'customer_and_sales_consultant'"
                        [innerHTML]="item[tableHeader.tablevalue]"></span>
									<span *ngSwitchDefault>
										{{onGetTableContent(item, tableHeader.tablevalue)}}
									</span>
								</span>

                        </td>
                    </tr>
                    <!-- Loading -->
                    <tr *ngIf="isLoadingTable">
                        <td [attr.colspan]="tableSettings.tableHeaders.length" class="table-empty">
                            <div class="loader"></div>
                        </td>
                    </tr>
                    <!-- Empty -->
                    <tr *ngIf="(tableData == undefined || tableData.length == 0) && !isLoadingTable">
                        <td [attr.colspan]="tableSettings.tableHeaders.length" class="table-empty">
                            <p>
                                {{lang.la.table_empty}}
                            </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
