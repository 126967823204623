import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment-timezone';
import {UtilService} from "../services/util.service";

@Pipe({
    name: 'mdate'
})

export class MdatePipe implements PipeTransform {
    constructor(private utilService: UtilService) {

    }

    transform(value: any, format: string = "", convertFromUTC = true): string {
        // Try and parse the passed value.
        if (convertFromUTC) {
            var momentDate = this.utilService.fromUTC(value);
        } else {
            var momentDate = moment.tz(value, 'UTC');
        }

        // If moment didn't understand the value, return it unformatted.
        if (!momentDate.isValid()) return value;

        // Otherwise, return the date formatted as requested.
        return momentDate.format(format);
    }
}