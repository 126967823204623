<div class="row mb-3 mb-sm-5">
    <div class="col-6">
        <h1 class="h2">{{lang.la.former_exports}}</h1>
    </div>
    <div class="col-6 text-right">
        <button class="btn btn-primary" routerLink="../../export/create/">{{lang.la.create_export}}</button>
    </div>
</div>

<!-- Loading -->
<app-loading *ngIf="isLoadingView"></app-loading>

<!-- Table -->
<div *ngIf="!isLoadingView">
    <app-table [tableSettings]="tableSettings" [canSearch]="false" [isLoadingTable]="isLoadingTable"
               [tableData]="tableData" (onRowPressed)="onRowPressed($event)" (getData)="getData($event)"></app-table>
</div>
