<div class="dropdown dropdown-select">

    <div class="btn btn-outline-secondary is-fullwidth" (click)="onOpenDropdown()">
        <div *ngIf="arrayListSelected.length == 0">
            {{lang.la.choose}}
        </div>
        <div *ngFor="let item of arrayListSelected; let pos = index" class="badge badge-secondary push-m-5-r"
             (click)="onItemUnselected(pos)"> {{item[arrayListName]}}</div>
    </div>

    <!-- Dropdown -->
    <div class="dropdown-menu push-p-0-t" [ngClass]="{'show': isDropdownActive}">
        <!-- Input -->
        <input type="text" class="form-control dropdown-toggle" placeholder="{{lang.la.search}}"
               *ngIf="isDropdownActive"
               [(ngModel)]="inputDropdown"
               (ngModelChange)="onChangeInputDropdown()"
               (keydown.tab)="onItemSelected(0)">

        <a class="dropdown-item" *ngFor="let item of arrayListForView; let pos = index"
           (click)="onItemSelected(pos)">{{item[arrayListName]}}</a>

    </div>

    <img src="./assets/dropdown.svg" alt="" class="dropdown-select-arrow" (click)="onOpenDropdown()">
</div>
<div class="dropdown-back-click" *ngIf="isDropdownActive" (click)="isDropdownActive = false"></div>