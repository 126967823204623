<div class="row push-m-50-b">
    <div class="col-4">
        <h1 class="float-left h2">{{lang.la.store}}</h1>
    </div>
    <div class="col-8 has-text-right" *ngIf="store != undefined">
        <button class="btn btn-outline-secondary"
                [routerLink]="['../../edit/', store.store_id]">{{lang.la.edit}}</button>
    </div>
</div>


<!-- Loading -->
<div class="card card-top  card-loading" *ngIf="isLoadingView">
    <div class="card-body">
        <div class="loader"></div>
    </div>
</div>
<div class="card card-top" *ngIf="!isLoadingView">
    <div class="card-body row">

        <div class="col-sm-6">

            <div class="form-group">
                <label class="text-is-grey">{{lang.la.name}}</label>
                <p class="h5">{{store.store_name}}</p>
            </div>

            <div class="form-group">
                <label class="text-is-grey">{{lang.la.storeno}}</label>
            </div>

        </div>

        <div class="col-sm-6">
            <div class="form-group">
                <label class="text-is-grey">{{lang.la.phoneno}}</label>
                <p class="h5">{{store.store_phone}}</p>
            </div>
        </div>
    </div>

    <div class="card-divider"></div>

    <div class="card-body row">
        <div class="col-sm-6">
            <div class="form-group">
                <label class="text-is-grey">{{lang.la.chain}}</label>
                <p class="h5">{{store.chains.chain_name}}</p>
            </div>
        </div>
        <div class="col-sm-6">
        </div>
    </div>

    <div class="card-divider"></div>

    <div class="card-body row">
        <div class="col-sm-6">
            <div class="form-group">
                <label class="text-is-grey">{{lang.la.street}}</label>
                <p class="h5">{{store.store_address}}</p>
            </div>
            <div class="form-group">
                <label class="text-is-grey">{{lang.la.city}}</label>
                <p class="h5">{{store.store_city}}</p>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="text-is-grey">{{lang.la.postalcode}}</label>
                <p class="h5">{{store.store_postal_code}}</p>
            </div>
        </div>
    </div>
</div>
