import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {LangService} from '../../services/lang.service';
import {FileUploader} from 'ng2-file-upload';
import {Constants} from '../../constants';
import {Image} from '../../models/image';
import {ApiService} from '../../services/api.service';
import {NotificationService} from '../notification.service';
import 'rxjs-compat/add/operator/takeUntil';
import {UtilService} from '../../services/util.service';

@Component({
    selector: 'app-inputappendix',
    templateUrl: './inputappendix.component.html',
    styleUrls: ['./inputappendix.component.scss']
})
export class InputAppendixComponent implements OnInit {
    maxFileSize = 20 * 1000 * 1000;

    @Output() totalAppendixValue: EventEmitter<number> = new EventEmitter<number>();
    @Output() imageUploaderChange: EventEmitter<FileUploader> = new EventEmitter<FileUploader>();
    @Output() validateQueueChange = new EventEmitter();

    @Input() isUploadActive: boolean = false;
    @Input() imageUploader: FileUploader = new FileUploader({url: Constants.URL});
    @Input() multiple: boolean = true;
    @Input() hasExpense;
    @Input() queueIsValid: boolean;

    totalAppendix: number = 0;

    lastIndexChecked = -1;

    allowedMimeType = ['jpeg', 'jpg', 'png', 'pdf', 'gif', 'png', 'csv', 'doc', 'docx',
        'dot', 'wbk', 'docm', 'dotx', 'dotm', 'docb', 'xls',
        'xlt', 'xlm', 'xlsx', 'xlsm', 'xltx', 'xltm', 'ppt',
        'pot', 'pps', 'pptx', 'pptm', 'potx', 'potm', 'ppam',
        'ppsx', 'ppsm', 'sldx', 'sldm', 'xps'];

    constructor(private sanitizer: DomSanitizer, private lang: LangService, private apiService: ApiService, public utilService: UtilService, public notificationService: NotificationService) {
    }

    ngOnInit() {
        this.imageUploader.onAfterAddingFile = (fileItem) => {
            fileItem['appendixNumber'] = '';
            fileItem.withCredentials = false;
            fileItem.file['imageimage'] = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(fileItem._file)));
            fileItem['uploaded'] = false;
            fileItem['error'] = false;
            fileItem['uploading'] = false;
            fileItem['errorText'] = '';
        };

    }

    fileOverBase(event) {
    }

    onRemoveAgain(pos, item) {
        this.imageUploader.queue.splice(pos, 1);
        this.totalAppendix -= item.appendixNumber;
        this.lastIndexChecked -= 1;
        this.imageUploaderChange.emit(this.imageUploader);

        this.checkIfQueueIsValid();
        this.recalculateExpenses();
        this.totalAppendixValue.emit(this.totalAppendix);
    }

    onInputChange(event) {
        if (event && this.hasExpense) {
            this.checkIfQueueIsValid();
            this.recalculateExpenses();
            this.totalAppendixValue.emit(this.totalAppendix);
        }
    }

    recalculateExpenses(){
        this.totalAppendix = 0;
        for (let image of this.imageUploader.queue) {
            this.totalAppendix += image['appendixNumber'];
        }
    }

    onCheckIfAppedixExists() {
        // check if the file type is correct
        for (let image of this.imageUploader.queue) {
            let imageType = image.file.name.substr(image.file.name.lastIndexOf('.', image.file.name.length) + 1, image.file.name.length);
            imageType = imageType.toLowerCase();
            if (!this.allowedMimeType.includes(imageType)) {
                image['error'] = true;
                image['errorText'] = 'Bad file format';
            }
            if (image.file.size >= this.maxFileSize) {
                image['error'] = true;
                image['errorText'] = 'File too big';
            }

            this.imageUploaderChange.emit(this.imageUploader);
        }


        // if the last index checked is different than the image uploader length
        if (this.hasExpense && this.lastIndexChecked !== this.imageUploader.queue.length - 1) {
            if (this.lastIndexChecked === -1 && this.imageUploader.queue.length === 1 ! && !this.imageUploader.queue[0]['error']) {
                const im: Image = new Image();
                im.image_file = this.imageUploader.queue[0]._file;
                im.appendix_number = this.imageUploader.queue[0]['appendixNumber'];
                this.onCheckIfAppendixExistsCall(im, 0);
                this.lastIndexChecked = 0;
                //if there is only on appendix and there is an error emit the error to the components and skip the checking of appendixes
            } else if (this.lastIndexChecked === -1 && this.imageUploader.queue.length === 1 ! && this.imageUploader.queue[0]['error']) {
                this.imageUploaderChange.emit(this.imageUploader);
            } else {
                // if the last indexChecked is smaller than the imageuploader length it means more than on image has been uploaded. multiple checks
                for (let index = this.lastIndexChecked + 1; index < this.imageUploader.queue.length; index++) {
                    // @ts-ignore
                    if (this.imageUploader.queue[index] && !this.imageUploader.queue[index].error) {
                        const im: Image = new Image();
                        im.image_file = this.imageUploader.queue[index]._file;
                        im.appendix_number = this.imageUploader.queue[index]['appendixNumber'];
                        // check if image exists
                        this.onCheckIfAppendixExistsCall(im, index);
                    }

                    this.lastIndexChecked = index;

                }
            }
        }
    }

    onCheckIfAppendixExistsCall(image, index) {
        this.apiService.checkAppendixExists(image).then(response => {
            // if it does not matches remove from array of pictures and set notification
            if (response.data.exists) {
                this.imageUploader.queue[index]['error'] = true;
                this.imageUploader.queue[index]['errorText'] = 'Bilag eksisterer ';
            } else {
                this.imageUploader.queue[index]['error'] = false;
                this.imageUploader.queue[index]['errorText'] = '';
            }
            // after we upload all the apendixes set the notifications
            if (index === this.imageUploader.queue.length - 1) {
                let imagesNotificationMessage = '';
                // get the name of the existing files
                this.imageUploader.queue.forEach(image => {
                    if (image['error']) {
                        imagesNotificationMessage += '\n' + image._file.name;
                    }

                });
                if (imagesNotificationMessage.length > 0) {
                    this.notificationService.setNotification(`Bilag eksisterer allerede ${imagesNotificationMessage}`, this.notificationService.CONSTANT_TYPE_WARNING);
                }
            }
            this.imageUploaderChange.emit(this.imageUploader);
            this.checkIfQueueIsValid();

        }).catch(err => {
        });
    }

    checkIfQueueIsValid(){
        let valid = !this.imageUploader.queue.find(item => item['error']);

        if(this.hasExpense && valid){
            valid = !this.imageUploader.queue.find(item => !this.checkIfValidExpense(item));
        }

        this.queueIsValid = valid;
        this.validateQueueChange.emit(this.queueIsValid);
    }

    checkIfValidExpense(item){
        if(!this.hasExpense){
            return true;
        }

        // must be truthy OR zero, and must be 0 or above
        return (item.appendixNumber || item.appendixNumber === 0) && item.appendixNumber >= 0;
    }

    showPlaceholder(imageToDisplay) {
        const image = imageToDisplay.file.rawFile.type;
        const imageType = image.substr((image.lastIndexOf('/') + 1), image.length).toLowerCase();

        const imageFormats = ['png', 'jpeg', 'jpg', 'gif'];
        const wordFormats = ['doc', 'dot', 'wbk', 'docx', 'dotx', 'dotm', 'docb'];
        const excelFormats = ['xls', 'xlt', 'xlm', 'xlsx', 'xlsm', 'xltx', 'xltm', 'xlsb', 'xla', 'xlam', 'xll', 'xlw'];
        const powerPointFormats = ['ppt', 'pot', 'pps', 'pptx', 'pptm', 'potx', 'potm', 'ppam', 'ppsx', 'ppsm', 'sldx', 'sldm'];
        const pdfFormat = ['pdf'];
        const csvFormat = ['csv'];

        if (imageFormats.includes(imageType)) {
            return imageToDisplay.file.imageimage;
        } else if (wordFormats.includes(imageType)) {
            return '../assets/placeholder_doc.svg';
        } else if (excelFormats.includes(imageType)) {
            return '../assets/placeholder_xls.svg';
        } else if (powerPointFormats.includes(imageType)) {
            return '../assets/placeholder_ppt.svg';
        } else if (pdfFormat.includes(imageType)) {
            return '../assets/placeholder_pdf.svg';
        } else if (csvFormat.includes(imageType)) {
            return '../assets/placeholder_csv.svg';
        } else {
            return '../assets/placeholder.svg';
        }
    }
}
