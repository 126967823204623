<div class="csvimport">
    <div class="csvimport-card" *ngFor="let csv_header of csv[0]; let pos = index"
         [ngClass]="{'is-done': isColumnSelected(csv_header, pos)}">
        <div class="csvimport-card-header">
            <p class="text-is-bold">
                {{csv_header}}
            </p>
        </div>
        <div class="csvimport-card-actions">
            <div class="form-group">
                <select class="select is-fullwidth" (change)="onColumnSelected(csv_header, $event.target.value, pos)">
                    <option [value]="-1" selected>Vælg tilhørende kolonne</option>
                    <option *ngFor="let item of validation_columns; let columnsPos = index;"
                            [selected]="item.csv_header == csv_header && item.picked_by == pos" [value]="item.type"
                            [disabled]="item.csv_header">
                        {{item.title}} {{ item.required ? '*' : '' }}
                    </option>
                </select>
            </div>
        </div>
        <div class="csvimport-card-body">
            <p class="text-is-bold">5 eksempler på data</p>
            <p class="border-top"
               *ngIf="csv[1] != undefined && csv[1][pos] != undefined">{{maxLengthString(csv[1][pos])}}</p>
            <p class="border-top"
               *ngIf="csv[2] != undefined && csv[2][pos] != undefined">{{maxLengthString(csv[2][pos])}}</p>
            <p class="border-top"
               *ngIf="csv[3] != undefined && csv[3][pos] != undefined">{{maxLengthString(csv[3][pos])}}</p>
            <p class="border-top"
               *ngIf="csv[4] != undefined && csv[4][pos] != undefined">{{maxLengthString(csv[4][pos])}}</p>
            <p class="border-top"
               *ngIf="csv[5] != undefined && csv[5][pos] != undefined">{{maxLengthString(csv[5][pos])}}</p>
        </div>
    </div>
</div>