<div class="dropdown dropdown-select">

    <!-- Input -->
    <div class="input-cancel-button input-cancel-button--dropdown input-has-appended-items">
        <input type="text" class="form-control dropdown-toggle" placeholder="{{lang.la.search}}"
               (click)="onOpenDropdown()" [(ngModel)]="inputDropdown"
               [ngClass]="{'is-invalid': error}"
               (ngModelChange)="onChangeInputDropdown()"
               (keydown.tab)="onItemSelected(0)">
        <img src="./assets/ic_cancel.svg" class="input-cancel-button-img" alt="" *ngIf="inputDropdown.length != 0"
             (click)="onClearSearch()">
    </div>

    <!-- Dropdown -->
    <div class="dropdown-menu push-p-0-t" [ngClass]="{'show': isDropdownActive}">
        <a class="dropdown-item" *ngFor="let item of arrayListForView; let pos = index" (click)="onItemSelected(pos)">
            {{utilService.onGetTableContent(item, arrayListName, true)}}
        </a>
        <span class="dropdown-item dropdown-item-btn" *ngIf="!isLoadingMore && arrayList.length > 18 && !isNoMoreToLoad"
              (click)="onLoadMorePressed()">
			Hent flere
		</span>
        <span class="dropdown-item" *ngIf="!isLoadingMore && arrayListForView.length == 0">
			Kan ikke finde nogen
		</span>
        <span class="dropdown-item" *ngIf="isLoadingMore">
			<div class="loader"></div>
		</span>
    </div>

    <img src="./assets/dropdown.svg" alt="" class="dropdown-select-arrow" (click)="onOpenDropdown()">

    <div class="invalid-feedback d-inline" *ngIf="error">
        {{error}}
    </div>
</div>
<div class="dropdown-back-click" *ngIf="isDropdownActive" (click)="onDropdownBackPressed()"></div>