<div *ngIf="this.dataService.customersForUsers.length === 0 && this.userService.user.user_role_id !== Constants.USER_ROLE_TEAMPLAYER_ID && this.userService.user.user_role_id !== Constants.USER_ROLE_ADMIN_ID; else allowed">
    <div id="notAllowedMessage">Du er ikke blevet tildelt nogen kunder. Kontakt venligt Administrator på<br> <a
            href="mailto:kn@teamplayer.dk">kn@teamplayer.dk</a></div>
</div>

<ng-template #allowed>
    <h1 class="push-m-50-b h2" *ngIf="!isState('editing')">{{lang.la.as_c_title}}</h1>
    <h1 class="push-m-50-b h2" *ngIf="isState('editing')">{{lang.la.as_c_title_editing}}</h1>

    <div class="col-sm-12" *ngIf="showAssignmentCreatedNotification">
        <div class="row has-centered-everything">
            <div class="alert alert-success alert-is-floating">
                <span class="push-m-50-r">
                    Opgave #{{assignment.assignment_id}} blev oprettet.
                </span>
                <a class="btn btn-white" [routerLink]="['/app/task/copy/',assignment.assignment_id]">Kopier opgaven</a>
                <ng-template [ngIf]="!isState('editing')" [ngIfElse]="notEditing">
                    <a class="btn btn-white" [routerLink]="['../../task/show/', assignment.assignment_id]">Se
                        opgaven</a>
                </ng-template>
                <ng-template #notEditing>
                    <a class="btn btn-white" #notEditing
                       [routerLink]="['../../../task/show/', assignment.assignment_id]">Se opgaven</a>
                </ng-template>
            </div>
        </div>
    </div>
    <form #heroForm="ngForm">
        <!-- Loading -->
        <app-loading *ngIf="isLoadingView"></app-loading>
        <!-- Table -->
        <div class="card card-top" *ngIf="!isLoadingView">

            <!-- Section 1 -->
            <div class="card-body row">
                <div class="col-sm-6">

                    <div class="form-group">
                        <label>* {{lang.la.type}}</label>

                        <div class=" input-group">
                            <select class="custom-select" [(ngModel)]="inputType.value" name="inputType.value">
                                <option [value]="'mercer'" selected>{{lang.la.mercer}}</option>
                                <option [value]="'demo'">{{lang.la.demo}}</option>
                                <option *ngIf="userService.user.can_create_project_management === 1"
                                        [value]="'projektstyring'">{{lang.la.projectmanagement}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputType.error">
                        {{inputType.error}}
                    </div>

                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>* {{lang.la.as_c_card_store}}</label>

                        <app-dropdown-select-type
                                [arrayType]="2"
                                [(arrayListSelectedId)]="inputStore.value"
                                [(arrayListSelectedObject)]="assignment.store"
                                [error]="inputStore.error"
                                (selectedItem)="inputStore.value = $event"></app-dropdown-select-type>

                    </div>
                </div>
            </div>

            <ng-container *ngIf="userService.user.user_role_id !== Constants.USER_ROLE_CONSULTANT_ID">
                <div class="card-divider"></div>

                <!-- Section 2 -->
                <div class="card-body row">

                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>* {{lang.la.as_c_card_customer}} </label>

                            <app-dropdown-select-type
                                    [arrayType]="0"
                                    [arrayListSelectedId]="inputCustomer.value"
                                    [(arrayListSelectedObject)]="assignment.customer"
                                    [error]="inputCustomer.error"
                                    (selectedItem)="onCustomerChange($event)"></app-dropdown-select-type>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>* {{lang.la.as_c_card_salesconsultant}}
                                <span class="text-is-grey">{{lang.la.pick_salesconsultant_before}}</span>
                            </label>

                            <app-dropdown-select
                                    [arrayList]="consultants"
                                    [arrayListName]="'user_firstname&user_lastname'"
                                    [arrayListId]="'user_id'"
                                    [(arrayListSelectedId)]="inputConsultant.value"
                                    [arrayListSelectedObject]="assignment.sales_consultant"
                                    [disabled]="inputConsultant.disabled"
                                    [error]="inputConsultant.error"
                                    (selectedItem)="inputConsultant.value = $event"></app-dropdown-select>


                        </div>
                    </div>
                </div>
            </ng-container>


            <div class="card-divider"></div>

            <!-- Section 3 -->
            <div class="card-body row" *ngIf="userService.user.user_role_id !== Constants.USER_ROLE_EMPLOYEE_ID">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="inputState"
                               [ngClass]="{'is-invalid': inputPosMaterial.error}">* {{lang.la.pos_material}}</label>
                        <select id="inputState" class="form-control" [(ngModel)]="inputPosMaterial.value"
                                name="inputPosMaterial">
                            <option [value]="0">{{lang.la.no}}</option>
                            <option [value]="1">{{lang.la.yes}}</option>
                        </select>
                        <div class="invalid-feedback d-inline" *ngIf="inputPosMaterial.error">
                            {{inputPosMaterial.error}}
                        </div>
                    </div>

                    <div class="form-group">
                        <label>{{lang.la.goods}}</label>
                        <input type="text" class="form-control" [(ngModel)]="inputProducts.value"
                               name="inputProducts" [ngClass]="{'is-invalid': inputProducts.error}"/>
                        <div class="invalid-feedback d-inline" *ngIf="inputProducts.error">
                            {{inputProducts.error}}
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>{{lang.la.contactperson}}</label>
                        <input type="text" class="form-control" [(ngModel)]="inputContactPerson.value"
                               (change)="inputContactPerson.error = ''"
                               name="inputContactPerson" [ngClass]="{'is-invalid': inputContactPerson.error}"/>
                        <div class="invalid-feedback d-inline" *ngIf="inputContactPerson.error">
                            {{inputContactPerson.error}}
                        </div>
                    </div>

                    <div class="form-group">
                        <label>{{lang.la.place_in_store}}</label>
                        <input type="text" class="form-control" [(ngModel)]="inputPlaceInStore.value"
                               name="inputPlaceInStore" [ngClass]="{'is-invalid': inputPlaceInStore.error}"/>
                        <div class="invalid-feedback d-inline" *ngIf="inputPlaceInStore.error">
                            {{inputPlaceInStore.error}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-divider"></div>

            <!-- Section 4 -->
            <div class="card-body row">

                <div class="col-sm-6">
                    <div class="form-group" *ngIf="userService.user.user_role_id !== Constants.USER_ROLE_EMPLOYEE_ID">
                        <label>* {{lang.la.freeorfixedtime}}</label>

                        <div class="input-group"></div>
                        <select [disabled]="inputTimeType.disabled"
                                [ngClass]=" {'disabled-cursor' : inputTimeType.disabled === true }"
                                class="custom-select"
                                (change)="onAssignmentChangeType($event.target)"
                                [(ngModel)]="inputTimeType.value" name="inputTimeType.value">
                            <option [value]="'frit'" selected>Frit</option>
                            <option [value]="'fast'">Fast</option>
                        </select>
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputTimeType.error">
                        {{inputTimeType.error}}
                    </div>
                </div>
                <div class="col-sm-6"></div>

                <div class="col-sm-6">
                    <div class="form-group">
                        <label>* {{lang.la.as_c_card_date}} </label>
                        <div class="input-group" [(bsValue)]="inputDateStart.value"
                             (bsValueChange)="onDateChange($event)"
                             #drp="bsDatepicker" [bsConfig]="dpConfig"
                             bsDatepicker>

                            <div class="input-group-prepend">
                                <span class="input-group-text">{{lang.la.date}}</span>
                            </div>

                            <input class="form-control" #datePicker
                                   [ngModel]="inputDateStart.value | mdate: 'DD/MM/YYYY'"
                                   name="inputDateStart"
                                   type="text"
                                   disabled>
                        </div>
                    </div>

                    <div class="form-group push-m-30-t" *ngIf="inputTimeType.value == 'frit'">
                        <label>* {{lang.la.as_c_card_period_date}}</label>
                        <div class="input-group" [(bsValue)]="inputDateEnd.value" #drp="bsDatepicker"
                             [bsConfig]="dpConfig"
                             bsDatepicker>

                            <div class="input-group-prepend">
                                <span class="input-group-text">{{lang.la.date}}</span>
                            </div>

                            <input class="form-control" [ngModel]="inputDateEnd.value | mdate: 'DD/MM/YYYY'"
                                   name="inputDateEnd"
                                   type="text"
                                   disabled>
                            <div class="invalid-feedback d-inline" *ngIf="inputDateEnd.error">
                                {{inputDateEnd.error}}
                            </div>
                        </div>
                    </div>
                </div>


                <!-- -->
                <div class="col-sm-6">

                    <!-- Start tidspunkt -->
                    <div class="form-group">
                        <timepicker [(ngModel)]="inputDateStart.value" [showMeridian]="false" [minuteStep]="15"
                                    (ngModelChange)="onTimeChange($event, 'start')"
                                    name="startTime"></timepicker>
                    </div>


                    <!-- Slut tidspunkt -->
                    <div class="form-group" *ngIf="inputTimeType.value == 'frit'">
                        <div class="form-group">
                            <timepicker [showMeridian]="false" [(ngModel)]="inputDateEnd.value" [minuteStep]="15"
                                        name="endtime" (ngModelChange)="onTimeChange($event, 'end')"></timepicker>
                        </div>
                    </div>
                </div>


                <!--TODO: if changes take place in here needs to be added in the assignment component as well-->

                <ng-container *ngIf="showReportForm">
                    <div class="col-12" *ngIf="showLoaderReportForm">
                        <app-loading></app-loading>
                    </div>
                    <div class="col-sm-12" *ngIf="!showLoaderReportForm">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>* {{lang.la.as_c_card_period_date}}</label>
                                    <div class="input-group">

                                        <div class="input-group-prepend">
                                            <span class="input-group-text">{{lang.la.date}}</span>
                                        </div>

                                        <input class="form-control"
                                               [ngModel]="inputDateStart.value | mdate: 'DD/MM/YYYY'"
                                               name="inputDateEnd" disabled/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <timepicker [(ngModel)]="inputDateEnd.value" [showMeridian]="false"
                                                [minuteStep]="15" name="endtime"
                                                (ngModelChange)="this.inputDateEnd.error = ''"></timepicker>
                                    <div class="invalid-feedback d-inline" *ngIf="inputDateEnd.error">
                                        {{inputDateEnd.error}}
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label>* {{lang.la.driving}}</label>
                                    <div class="input-group">
                                        <input type="number" class="form-control" [(ngModel)]="inputDriving.value"
                                               (blur)="onInputDriving($event.target.value)" name="inputDriving.value">
                                        <div class="input-group-append">
                                            <span class="input-group-text">km</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 push-m-20-t">
                                <div class="form-group">
                                    <label for="exampleFormControlTextarea2">* {{lang.la.comment + ' om afrapportering'}}
                                        <span class="text-is-grey small">(Hvad har du lavet?)</span></label>
                                    <textarea class="form-control" [(ngModel)]="inputDescription.value"
                                              id="exampleFormControlTextarea2" name="inputDescription.value"
                                              rows="3"
                                              (ngModelChange)="inputDescription.error = ''"
                                              [ngClass]="{'is-invalid': inputDescription.error}"></textarea>
                                    <div class="invalid-feedback d-inline" *ngIf="inputDescription.error">
                                        {{inputDescription.error}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">

                                <label>{{lang.la.images}}</label>
                                <app-inputupload (imageUploaderChange)="filesImages = $event"
                                                 accept="image/png,image/gif,image/jpeg"
                                                 [imageUploader]="filesImages"></app-inputupload>
                                <!-- If Editing -->
                                <div class="row">
                                    <div class="col-sm-12" *ngIf="executionsEditing">

                                        <label class="push-m-20-t">{{lang.la.formerly_added_images}}</label>

                                        <!-- Loop -->
                                        <div *ngFor="let item of assignmentExecutionImages; let pos = index"
                                             class="col-sm-12 push-m-10-b push-m-10-t">
                                            <div class="row">
                                                <div class="col-sm-11">
                                        <span class="float-left">
                                            <img [src]="utilService.onImageToView(item.images.image_link)" alt=""
                                                 class="fileuploader-image"/>
                                        </span>
                                                    <span class="float-left push-p-10-l">
                                            <span class="h6">
                                                {{ item.image_id }}
                                            </span><br/>
                                        </span>
                                                </div>
                                                <div class="col-sm-1">
                                        <span *ngIf="item.isLoading">
                                            <div class="loader-small"></div>
                                        </span>

                                                    <a (click)="onRemoveImage(pos)" *ngIf="!item.isLoading">
                                                        <svg fill="#ff0000" height="16" viewBox="0 0 24 24" width="16"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                                                            <path d="M0 0h24v24H0z" fill="none"/>
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>

                                        </div>
                                        <!-- /Loop -->

                                    </div>
                                </div>
                                <!-- /If Editing -->

                            </div>

                            <div class="col-md-6">
                                <label>{{lang.la.expenditures}}</label>
                                <app-inputappendix (imageUploaderChange)="onBeforeAppendixUpload($event)"
                                                   (totalAppendixValue)="inputExpenses.value = $event"
                                                   [hasExpense]="true"
                                                   [queueIsValid]="canSubmitForm" (validateQueueChange)="canSubmitForm = $event"
                                                   [imageUploader]="filesBilag"></app-inputappendix>
                                <!-- If Editing -->
                                <div class="col-sm-12" *ngIf="executionsEditing">
                                    <label class="push-m-20-t">{{lang.la.formerly_added_appendix}}</label>

                                    <!-- Loop -->
                                    <div *ngFor="let item of assignmentExecutionAppendix; let pos = index"
                                         class="col-sm-12 push-m-10-b push-m-10-t">
                                        <div class="row">
                                            <div class="col-sm-11">
                                    <span class="float-left">
                                        <img [src]="utilService.onAppendixToView(item.appendix_link)" alt=""
                                             class="fileuploader-image"/>
                                    </span>
                                                <span class="float-left push-p-10-l">
                                        <span class="h6">
                                            {{ item.appendix_link }}
                                        </span><br/>
                                        <span class="h7 text-is-grey">
                                            {{item.appendix_amount}}
                                        </span>

                                    </span>
                                            </div>
                                            <div class="col-sm-1">
                                    <span *ngIf="item.isLoading">
                                        <div class="loader-small"></div>
                                    </span>

                                                <a (click)="onRemoveAppendixFromExecutions(pos)"
                                                   *ngIf="!item.isLoading">
                                                    <svg fill="#ff0000" height="16" viewBox="0 0 24 24" width="16"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                                                        <path d="M0 0h24v24H0z" fill="none"/>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                    <!-- /Loop -->
                                </div>

                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!-- Section -->
            <ng-container *ngIf="!showReportForm">
                <div class="card-divider"></div>

                <div class="card-body row">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>* {{lang.la.as_c_card_estimate}} <span class="text-is-grey small">(Hvor lang tid estimerer du opgaven til at vare?)</span></label>
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">{{lang.la.ca}}</span>
                                </div>
                                <select class="custom-select" [(ngModel)]="inputEstimate.value"
                                        name="inputEstimate.value"
                                        (ngModelChange)="inputEstimate.error = ''">
                                    <option selected>{{lang.la.choose}}</option>
                                    <ng-container *ngFor="let timeoption of utilService.timeoption_quarter_12">
                                        <option [value]="timeoption">
                                            {{timeoption}}
                                        </option>
                                    </ng-container>

                                </select>
                                <div class="input-group-append">
                                    <span class="input-group-text">{{lang.la.hours}}</span>
                                </div>
                                <div class="invalid-feedback d-inline" *ngIf="inputEstimate.error">
                                    {{inputEstimate.error}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>


            <div class="card-divider"></div>

            <!-- Section 5 -->
            <div class="card-body row">
                <div class="col-sm-12">
                    <label>* {{lang.la.as_c_card_todo}}</label>
                    <div class="invalid-feedback d-inline" *ngIf="inputTodoFill.error">
                        {{inputTodoFill.error}}
                    </div>

                </div>
                <div class="col-sm-6">

                    <div class="form-group input-group">
                        <label type="text" for="check1" class="form-control"
                               [ngClass]="{'is-invalid': inputTodoFill.error}">{{lang.la.as_c_card_todo_filling}}</label>
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <input type="checkbox" aria-label="Opfyldning" id="check1"
                                       [(ngModel)]="inputTodoFill.value"
                                       name="inputTodoFill.value" (ngModelChange)="inputTodoFill.error = ''">
                            </div>
                        </div>
                    </div>
                    <div class="form-group input-group">
                        <label type="text" for="check2" class="form-control"
                               [ngClass]="{'is-invalid': inputTodoFill.error}">{{lang.la.as_c_card_todo_cleaning}}</label>
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <input type="checkbox" aria-label="Opfyldning" id="check2"
                                       [(ngModel)]="inputTodoCleaning.value"
                                       name="inputTodoCleaning.value" (ngModelChange)="inputTodoFill.error = ''">
                            </div>
                        </div>
                    </div>
                    <div class="form-group input-group">
                        <label type="text" for="check3" class="form-control"
                               [ngClass]="{'is-invalid': inputTodoFill.error}">{{lang.la.as_c_card_todo_show}}</label>
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <input type="checkbox" aria-label="Opfyldning" id="check3"
                                       [(ngModel)]="inputTodoShow.value"
                                       name="inputTodoShow.value" (ngModelChange)="inputTodoFill.error = ''">
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-sm-6">

                    <div class="form-group input-group">
                        <label type="text" for="check4" class="form-control"
                               [ngClass]="{'is-invalid': inputTodoFill.error}">{{lang.la.as_c_card_todo_deliveries}}</label>
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <input type="checkbox" aria-label="Opfyldning" id="check4"
                                       [(ngModel)]="inputTodoDev.value"
                                       name="inputTodoDev.value" (ngModelChange)="inputTodoFill.error = ''">
                            </div>
                        </div>
                    </div>
                    <div class="form-group input-group">
                        <label type="text" for="check5" class="form-control"
                               [ngClass]="{'is-invalid': inputTodoFill.error}">{{lang.la.as_c_card_todo_out}}</label>
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <input type="checkbox" aria-label="Opfyldning" id="check5"
                                       [(ngModel)]="inputTodoOut.value"
                                       name="inputTodoOut.value" (ngModelChange)="inputTodoFill.error = ''">
                            </div>
                        </div>
                    </div>
                    <div class="form-group input-group">
                        <label type="text" for="check6" class="form-control"
                               [ngClass]="{'is-invalid': inputTodoFill.error}">{{lang.la.as_c_card_todo_demo}}</label>
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <input type="checkbox" aria-label="Opfyldning" id="check6"
                                       [(ngModel)]="inputTodoDemo.value"
                                       name="inputTodoDemo.value" (ngModelChange)="inputTodoFill.error = ''">
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <label for="exampleFormControlTextarea1">{{lang.la.as_c_card_descr}} <span
                                class="text-is-grey small">(Ekstra information om opgaven)</span></label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                                  (ngModelChange)="inputDescr.error = ''" [(ngModel)]="inputDescr.value"
                                  name="inputDescr.value"></textarea>
                    </div>

                </div>
            </div>


            <ng-container *ngIf="!showReportForm">
                <div class="card-divider"></div>

                <!-- Section Upload stuff -->
                <div class="card-body row">
                    <div class="col-sm-12">
                        <label>Upload bilag til opgaven</label>
                    </div>
                    <div class="col-sm-12">

                        <app-inputappendix (imageUploaderChange)="onBeforeAppendixUpload($event)"
                                           [hasExpense]="false"
                                           [imageUploader]="filesBilag"></app-inputappendix>
                        <!-- If Editing -->
                        <div class="col-sm-12" *ngIf="isState('editing')">
                            <label class="push-m-20-t">{{lang.la.formerly_added_appendix}}</label>

                            <!-- Loop -->
                            <div *ngFor="let item of assignmentAppendix; let pos = index"
                                 class="col-sm-12 push-m-10-b push-m-10-t">
                                <div class="row">
                                    <div class="col-sm-11">
                                    <span class="float-left">
                                        <img [src]="utilService.onAppendixToView(item.appendix_link)" alt=""
                                             class="fileuploader-image"/>
                                    </span>
                                        <span class="float-left push-p-10-l">
                                        <span class="h6">
                                            {{ item.appendix_link }}
                                        </span><br/>
                                        <span class="h7 text-is-grey">
                                            {{item.appendix_amount}}
                                        </span>

                                    </span>
                                    </div>
                                    <div class="col-sm-1">
                                    <span *ngIf="item.isLoading">
                                        <div class="loader-small"></div>
                                    </span>

                                        <a (click)="onRemoveAppendixFromExecutions(pos)" *ngIf="!item.isLoading">
                                            <svg fill="#ff0000" height="16" viewBox="0 0 24 24" width="16"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                                                <path d="M0 0h24v24H0z" fill="none"/>
                                            </svg>
                                        </a>
                                    </div>
                                </div>

                            </div>
                            <!-- /Loop -->
                        </div>

                    </div>
                </div>

            </ng-container>

            <div class="card-divider"></div>

            <!-- Section 6 -->
            <div class="card-body row"
                 *ngIf="userService.getShowForUser(USER_RIGHT_TYPES.TYPE_ASSIGNMENT_ASSIGN) && (this.router.url.includes('copy') || assignment.assignment_status !== Constants.ASSIGNMENT_STATUS_ACCEPTED)">


                <div class="col-sm-4">
                    <div class="form-group input-group">
                        <label type="text" for="radio40" class="form-control"
                               [ngClass]="{'is-invalid': inputAssignSupply.error}">
                            {{lang.la.supply}} <br/>
                            <span class="text-is-grey h7">{{lang.la.supply_descr}}</span>
                        </label>
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <input type="radio" aria-label="Enkeltstående" id="radio40"
                                       (ngModelChange)="inputAssignSupply.error = ''"
                                       [(ngModel)]="inputAssignSupply.value"
                                       name="inputAssignSupply.value" [value]="true" (click)="setAssignState('supply')">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group input-group">
                        <label type="text" for="radio41" class="form-control"
                               [ngClass]="{'is-invalid': inputAssignOffer.error}">
                            {{lang.la.assign_to}} <br/>
                            <span class="text-is-grey h7">{{lang.la.assign_to_descr}}</span>
                        </label>
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <input type="radio" aria-label="Enkeltstående" id="radio41"
                                       (ngModelChange)="inputAssignOffer.error = ''"
                                       [(ngModel)]="inputAssignOffer.value"
                                       name="inputAssignOffer.value" [value]="true" (click)="setAssignState('offer')">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group input-group">
                        <label type="text" for="radio42" class="form-control"
                               [ngClass]="{'is-invalid': inputAssignLater.error}">
                            {{lang.la.save}}<br/>
                            <span class="text-is-grey h7"> {{lang.la.save_descr}}</span>
                        </label>
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <input type="radio" aria-label="inputAssignLater" id="radio42"
                                       (ngModelChange)="inputAssignLater.error = ''"
                                       [(ngModel)]="inputAssignLater.value"
                                       name="inputAssignLater.value" [value]="true" (click)="setAssignState('later')">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12" *ngIf="inputAssignOffer.value || inputAssignSupply.value ">
                    <label>*Vælg medarbejdere</label>
                    <div class="invalid-feedback d-inline" *ngIf="inputAssignSupply.error">
                        {{inputAssignSupply.error}}
                    </div>
                </div>

                <!-- Select multiple for 'udbyd' -->
                <div class="col-sm-12" *ngIf="inputAssignSupply.value">
                    <app-dropdown-select-type-multiple
                            [arrayType]="1"
                            (selectedItem)="inputUsers = $event"
                            [(arrayListSelected)]="inputUsers"></app-dropdown-select-type-multiple>
                </div>

                <!-- Only select one for 'tildel' -->
                <div class="col-sm-12" *ngIf="inputAssignOffer.value ">
                    <app-dropdown-select-type
                            [arrayType]="1"
                            [arrayListSelectedId]="getFirstInputUserId()"
                            [arrayListSelectedObject]="assignment.assigned_employee"
                            (selectedItem)="onSelectAssignOffer($event)"></app-dropdown-select-type>
                </div>

                <div class="col-sm-12 push-p-20-b">
                    <div class="invalid-feedback d-inline" *ngIf="inputUserError.length != 0">
                        {{inputUserError}}
                    </div>
                </div>


            </div>

            <!-- Footer -->
            <div class="card-body row">
                <div class="col-sm-12 has-text-right">
                    <button class="btn btn-secondary ml-2" [ngClass]="{'is-loading': isLoading}"
                            (click)="onGoBack()">{{lang.la.cancel}}</button>
                    <button class="btn btn-success ml-2" [disabled]="!canSubmitForm || isLoading"
                            [ngClass]="{'is-loading': isLoading}"
                            (click)="onCreateAssignment()" *ngIf="isState('creating') || router.url.includes('copy')">
                        <span class="is-fullwidth"
                              [ngClass]="{'disabled-cursor' : !canSubmitForm}"
                              [tooltip]="template"
                              [isDisabled]="canSubmitForm"
                              [placement]="'top'">{{lang.la.as_c_card_button_create}}</span>
                    </button>
                    <button class="btn btn-success ml-2" [ngClass]="{'is-loading': isLoading}"
                            [disabled]="!canSubmitForm || isLoading"
                            (click)="onEditAssignment()"
                            *ngIf="router.url.includes('edit')">
                        <span class="is-fullwidth"
                              [ngClass]="{'disabled-cursor' : !canSubmitForm}"
                              [tooltip]="template"
                              [isDisabled]="canSubmitForm"
                              [placement]="'top'">{{lang.la.save}}</span>
                    </button>
                </div>
            </div>

        </div>
    </form>
</ng-template>


<ng-template #template>En fejl opstod ved tilføjelse af bilag</ng-template>
