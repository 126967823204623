/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./imageuploader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ng2-file-upload/file-upload/file-drop.directive";
import * as i4 from "ng2-file-upload/file-upload/file-select.directive";
import * as i5 from "./imageuploader.component";
import * as i6 from "@angular/platform-browser";
var styles_ImageuploaderComponent = [i0.styles];
var RenderType_ImageuploaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageuploaderComponent, data: {} });
export { RenderType_ImageuploaderComponent as RenderType_ImageuploaderComponent };
function View_ImageuploaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "push-p-10-b row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", " col-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["style", "width: 20px; height: 20px;"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " (", " MB) "])), i1.ɵppd(4, 2), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "col-2 text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "a", [["class", "text-grey"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRemoveAgain(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, ":svg:svg", [["fill", "#ff0000"], ["height", "16"], ["viewBox", "0 0 24 24"], ["width", "16"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, ":svg:path", [["d", "M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, ":svg:path", [["d", "M0 0h24v24H0z"], ["fill", "none"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.file.imageimage; _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_v.context.$implicit == null) ? null : ((_v.context.$implicit.file == null) ? null : _v.context.$implicit.file.name)); var currVal_2 = i1.ɵunv(_v, 3, 1, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent, 0), ((((_v.context.$implicit == null) ? null : ((_v.context.$implicit.file == null) ? null : _v.context.$implicit.file.size)) / 1024) / 1024), ".2")); _ck(_v, 3, 0, currVal_1, currVal_2); }); }
function View_ImageuploaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "modal-body border-top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "col-sm-12 push-p-10-b"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h2", [["class", "h5"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Filer klar til upload"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageuploaderComponent_2)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageUploader.queue; _ck(_v, 7, 0, currVal_0); }, null); }
export function View_ImageuploaderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 35, "div", [["class", "modal  modal-show"], ["role", "dialog"], ["tabindex", "-1"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "modal-show": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 32, "div", [["class", "modal-dialog"], ["role", "document"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 31, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h5", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Upload billeder"])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.isUploadActive = !_co.isUploadActive) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(12, 0, null, null, 17, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 16, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 15, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 14, "label", [["class", "well my-drop-zone fileuploader fileContainer"], ["ng2FileDrop", ""]], null, [[null, "fileOver"], [null, "drop"], [null, "dragover"], [null, "dragleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("drop" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onDrop($event) !== false);
        ad = (pd_0 && ad);
    } if (("dragover" === en)) {
        var pd_1 = (i1.ɵnov(_v, 16).onDragOver($event) !== false);
        ad = (pd_1 && ad);
    } if (("dragleave" === en)) {
        var pd_2 = (i1.ɵnov(_v, 16).onDragLeave($event) !== false);
        ad = (pd_2 && ad);
    } if (("fileOver" === en)) {
        var pd_3 = (_co.fileOverBase($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 16384, null, 0, i3.FileDropDirective, [i1.ElementRef], { uploader: [0, "uploader"] }, { fileOver: "fileOver" }), (_l()(), i1.ɵeld(17, 0, null, null, 5, ":svg:svg", [["class", "push-m-30-t"], ["height", "91px"], ["version", "1.1"], ["viewBox", "0 0 75 91"], ["width", "75px"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, ":svg:defs", [], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 3, ":svg:g", [["fill", "none"], ["fill-rule", "evenodd"], ["id", "Page-1"], ["stroke", "none"], ["stroke-width", "1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 2, ":svg:g", [["id", "ic_file_upload_black_24px"], ["transform", "translate(-27.000000, -16.000000)"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 0, ":svg:polygon", [["id", "Shape"], ["points", "0 0 129 0 129 129 0 129"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 0, ":svg:path", [["d", "M48.4285714,85.5882353 L80.5714286,85.5882353 L80.5714286,53.4705882 L102,53.4705882 L64.5,16 L27,53.4705882 L48.4285714,53.4705882 L48.4285714,85.5882353 Z M27,96.2941176 L102,96.2941176 L102,107 L27,107 L27,96.2941176 Z"], ["fill", "#efefef"], ["fill-rule", "nonzero"], ["id", "Shape"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Hiv filer over eller tryk her "])), (_l()(), i1.ɵeld(26, 0, null, null, 1, "input", [["accept", "image/png,image/jpeg"], ["ng2FileSelect", ""], ["type", "file"]], [[8, "multiple", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 27).onChange() !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.onAddToQueue() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(27, 16384, null, 0, i4.FileSelectDirective, [i1.ElementRef], { uploader: [0, "uploader"] }, null), (_l()(), i1.ɵeld(28, 0, null, null, 1, "a", [["class", "uploader__button"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Upload"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageuploaderComponent_1)), i1.ɵdid(31, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(32, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(33, 0, null, null, 1, "button", [["class", "btn btn-grey"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.isUploadActive = !_co.isUploadActive) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Fortryd"])), (_l()(), i1.ɵeld(35, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAdd() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Tilf\u00F8j"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "modal  modal-show"; var currVal_1 = _ck(_v, 3, 0, _co.isUploadActive); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.imageUploader; _ck(_v, 16, 0, currVal_2); var currVal_4 = _co.imageUploader; _ck(_v, 27, 0, currVal_4); var currVal_5 = (((_co.imageUploader != undefined) && (_co.imageUploader.queue != undefined)) && (_co.imageUploader.queue.length != 0)); _ck(_v, 31, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.multiple; _ck(_v, 26, 0, currVal_3); }); }
export function View_ImageuploaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-imageuploader", [], null, null, null, View_ImageuploaderComponent_0, RenderType_ImageuploaderComponent)), i1.ɵdid(1, 114688, null, 0, i5.ImageuploaderComponent, [i6.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageuploaderComponentNgFactory = i1.ɵccf("app-imageuploader", i5.ImageuploaderComponent, View_ImageuploaderComponent_Host_0, { isUploadActive: "isUploadActive", imageUploader: "imageUploader", multiple: "multiple" }, { imageUploaderChange: "imageUploaderChange" }, []);
export { ImageuploaderComponentNgFactory as ImageuploaderComponentNgFactory };
