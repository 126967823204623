/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./graph-circle.component";
var styles_GraphCircleComponent = [];
var RenderType_GraphCircleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GraphCircleComponent, data: {} });
export { RenderType_GraphCircleComponent as RenderType_GraphCircleComponent };
export function View_GraphCircleComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { appendToChildEl: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["appendToChildEl", 1]], null, 0, ":svg:svg", [["style", "transform: rotate(-90deg); height: 200px; width: 200px;"], ["viewBox", "-1 -1 2 2"]], null, null, null, null, null))], null, null); }
export function View_GraphCircleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-graph-circle", [], null, null, null, View_GraphCircleComponent_0, RenderType_GraphCircleComponent)), i0.ɵdid(1, 114688, null, 0, i1.GraphCircleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GraphCircleComponentNgFactory = i0.ɵccf("app-graph-circle", i1.GraphCircleComponent, View_GraphCircleComponent_Host_0, {}, {}, []);
export { GraphCircleComponentNgFactory as GraphCircleComponentNgFactory };
