import {Component, OnDestroy, OnInit} from '@angular/core';
import {UtilService} from '../services/util.service';
import {DataService} from '../services/data.service';
import {LangService} from '../services/lang.service';
import {ApiService} from '../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Assignment} from '../models/assignment';
import {InputCheckbox} from '../models/inputcheckbox';
import {TableSettings} from '../models/tablesettings';
import {TableHeader} from '../models/tableheader';
import {TableSettingsEquals} from '../models/tablesettingsequals';
import {TableSettingsContains} from '../models/tablesettingscontains';
import * as moment from 'moment';
import {UserService} from '../services/user.service';
import {UserSettings} from '../models/usersettings';
import {NotificationService} from '../shared/notification.service';
import {CSVService} from '../services/csv.service';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {AssignmentStats} from '../models/assignment-stats';
import {Constants, USER_RIGHT_TYPES} from '../constants';
import {CustomersForUsers} from "../models/customersforusers";
import {Subject} from 'rxjs';
import {load} from "@angular/core/src/render3/instructions";

@Component({
    selector: 'app-assignment-list',
    templateUrl: './assignment-list.component.html',
    styleUrls: ['./assignment-list.component.scss']
})
export class AssignmentListComponent implements OnInit, OnDestroy {


    //Table
    tableData: Assignment[] = [];
    tableSettings: TableSettings;

    Constants: any = Constants;
    USER_RIGHT_TYPES: any = USER_RIGHT_TYPES;

    //View
    isLoadingView: boolean = false;
    isLoadingTable: boolean = false;
    page: number = 1;
    search: string = '';

    assignmentStats: AssignmentStats = new AssignmentStats();

    isDropdownUserActive: boolean = false;
    isDropdownSetupActive: boolean = false;

    inputFormer: InputCheckbox = new InputCheckbox();
    inputReport: InputCheckbox = new InputCheckbox();
    inputUpcoming: InputCheckbox = new InputCheckbox();

    assignmentStatsPeriodStart: string = "";
    assignmentStatsPeriodEnd: string = "";

    //input
    filterOffset: number = 20;
    filterType: string = "";
    filterStatus: string = "0";
    filterCustomer: string = "";
    filterConsultant: string = "";
    filterStore: string = "";
    filterEmployee: string = "";
    filterPictures: string = "";
    filterImage: string = "";
    filterDateRange: any;
    inputSearch: string = "";
    inputFilterPeriodSelected: number = -1;
    tabelheadersSort: any;

    dateFilterColumn: string = 'assignment_timestype_start';

    customer: CustomersForUsers;


    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

    destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
        public apiService: ApiService,
        public route: ActivatedRoute,
        public router: Router,
        public utilService: UtilService,
        public lang: LangService,
        public userService: UserService,
        public dataService: DataService,
        public notificationService: NotificationService,
        public csvService: CSVService,
    ) {
    }

    ngOnInit() {

        this.isLoadingView = true;

        let tableHeaders = this.getDefaultTableHeaders();

        this.tableSettings = this.getDefaultTableSettings(tableHeaders, true);

        // if the user has the customer role id we first need to fetch the customers related to the user and the append it as part of the request
        if (this.userService.user.user_role_id !== this.Constants.USER_ROLE_CUSTOMER_ID) {
            //Set date
            this.onFilter();
        } else {
            this.getCustomersForUser();
        }

        this.isFiltering();
    }

    getDefaultTableHeaders(){
        var tableHeaders: TableHeader[] = [];

        moment.locale("da-DK");

        this.dpConfig = this.utilService.getDatePickerConfig();

        //If agreement not accepted yet
        if ((this.userService.user.user_cooperation_agreements_date == undefined
            || this.userService.user.user_cooperation_agreements_date == "0000-00-00 00:00:00")
            && (this.userService.user.user_role_id == Constants.USER_ROLE_EMPLOYEE_ID)
        ) {
            this.router.navigate(["./app/deal"]);
        }

        //Check if logged in as another 'fake' user
        this.userService.isUserUpdated.subscribe(
            (updated) => {
                this.dataService.assignmentListPage = 1;
            }
        );

        //Load old config
        if (
            this.dataService != undefined
            && this.userService.userSettings != undefined
            && this.userService.userSettings.assignmentList != undefined
            && this.userService.userSettings.assignmentList.length != 0
        ) {
            tableHeaders = this.userService.userSettings.assignmentList;

            // Fix issue with sales consultant missing an s in column
            const sales_consultant = tableHeaders.findIndex(function (element) {
                return element.tablevalue === "assignment.user_id";
            });

            if (sales_consultant > 1) {
                tableHeaders[sales_consultant].tablevalue = 'assignments.user_id';
                this.userService.userSettings.assignmentList = tableHeaders;
                this.apiService.patchUserSettings(this.userService.userSettings).takeUntil(this.destroy$).subscribe((data) => {
                }, (error) => {
                });
            }

            // Force change butik by and butik postummer name value. (Saved in some user settings)
            const butik_by = tableHeaders.findIndex(function (element) {
                return element.value === "Butik by" || element.value === "Butikby";
            });
            const butik_postnummer = tableHeaders.findIndex(function (element) {
                return element.value === "Butik postnummer";
            });
            const butik_adr = tableHeaders.findIndex(function (element) {
                return element.value === "Butik adresse";
            });

            if (butik_by > -1) tableHeaders[butik_by].value = 'By';
            if (butik_postnummer > -1) tableHeaders[butik_postnummer].value = 'Postnummer';
            if (butik_adr > -1) tableHeaders[butik_adr].value = 'Adresse';

            // If any was forced changed, we change the user settings in the db
            if (butik_by > -1 || butik_postnummer > -1 || butik_adr > -1) {
                this.userService.userSettings.assignmentList = tableHeaders;
                this.apiService.patchUserSettings(this.userService.userSettings).takeUntil(this.destroy$).subscribe((data) => {
                }, (error) => {
                });
            }

            // If kørsel is not present i table headers, add as table header
            const drive_distance = tableHeaders.findIndex(function (element) {
                return element.value === 'Kørsel';
            });
            if (drive_distance === -1) {
                tableHeaders.push(new TableHeader('assignment_executions.assignment_execution_drive_distance', 'Kørsel'));
                this.userService.userSettings.assignmentList = tableHeaders;
                this.apiService.patchUserSettings(this.userService.userSettings).takeUntil(this.destroy$).subscribe((data) => {
                }, (error) => {
                });
            }

            const employee = tableHeaders.findIndex(function (element) {
                return element.value === "Medarbejder";
            });
            if (employee === -1) {
                tableHeaders.push({value: "Medarbejder", tablevalue: "user_name", asc: true, id: 0, checked: false});
                this.userService.userSettings.assignmentList = tableHeaders;
                this.apiService.patchUserSettings(this.userService.userSettings).takeUntil(this.destroy$).subscribe((data) => {
                }, (error) => {
                });
            } else if (tableHeaders[employee].tablevalue != 'user_name') {
                tableHeaders[employee].tablevalue = 'user_name';
                this.userService.userSettings.assignmentList = tableHeaders;
                this.apiService.patchUserSettings(this.userService.userSettings).takeUntil(this.destroy$).subscribe((data) => {
                }, (error) => {
                });
            }

            // Add execution comments, if not in table
            const execution_comments = tableHeaders.findIndex(function (element) {
                return element.value === "Kommentarer";
            });
            if (execution_comments === -1) {
                tableHeaders.push({
                    value: "Kommentarer",
                    tablevalue: "assignment_executions.assignment_execution_comments",
                    asc: true,
                    id: 0,
                    checked: false
                });
                this.userService.userSettings.assignmentList = tableHeaders;
                this.apiService.patchUserSettings(this.userService.userSettings).takeUntil(this.destroy$).subscribe((data) => {
                }, (error) => {
                });
            } else if (tableHeaders[execution_comments].tablevalue !== "assignment_executions.assignment_execution_comments") {
                tableHeaders[execution_comments].tablevalue = "assignment_executions.assignment_execution_comments";
                this.userService.userSettings.assignmentList = tableHeaders;
                this.apiService.patchUserSettings(this.userService.userSettings).takeUntil(this.destroy$).subscribe((data) => {
                }, (error) => {
                });
            }

            if (this.userService.user.user_role_id !== Constants.USER_ROLE_ADMIN_ID && this.userService.user.user_role_id !== Constants.USER_ROLE_TEAMPLAYER_ID) {
                const id_index = tableHeaders.findIndex(function (element) {
                    return element.value === 'ID';
                });
                if (id_index > -1) {
                    tableHeaders.splice(id_index, 1);
                }
                const fakturering_index = tableHeaders.findIndex(function (element) {
                    return element.value === 'Fakturering';
                });
                if (fakturering_index > -1) {
                    tableHeaders.splice(fakturering_index, 1);
                }
                const salery_index = tableHeaders.findIndex(function (element) {
                    return element.value === 'Lønkørsel';
                });
                if (salery_index > -1) {
                    tableHeaders.splice(salery_index, 1);
                }
            }
            // remove headers from table if not allowed to watch
            let employee_index;
            switch (this.userService.user.user_role_id) {

                case Constants.USER_ROLE_EMPLOYEE_ID:

                    employee_index = tableHeaders.findIndex(function (element) {
                        return element.value === 'Medarbejdere';
                    });
                    if (employee_index > -1) {
                        tableHeaders.splice(employee_index, 1);
                    }
                    break;

                case Constants.USER_ROLE_CONSULTANT_ID:

                    break;

                case Constants.USER_ROLE_CUSTOMER_ID:

                    employee_index = tableHeaders.findIndex(function (element) {
                        return element.value === 'Medarbejdere';
                    });
                    if (employee_index > -1) {
                        tableHeaders.splice(employee_index, 1);
                    }
                    const customer_index = tableHeaders.findIndex(function (element) {
                        return element.value === 'Kunde';
                    });
                    if (customer_index > -1) {
                        tableHeaders.splice(customer_index, 1);
                    }
                    break;

                default:
                    break;
            }

            const imageSettings = new TableHeader('assignment_execution_image', 'Billede');
            if (tableHeaders.find((setting: TableHeader) => {
                return setting.value === imageSettings.value;
            }) === undefined) {
                tableHeaders.push(imageSettings);
            }

        } else {
            return;
        }

        return tableHeaders;
    }

    getDefaultTableSettings(tableHeaders, loadSession : Boolean){
        let defaults = new TableSettings();

        defaults.page = this.dataService.assignmentListPage;
        defaults.tableHeaders = tableHeaders;

        const sessionFilter = JSON.parse(sessionStorage.getItem('assignmentListFilter')) || {};
        if(loadSession){

            //Set starttidspunkt to default sorting
            for (let index = 0; index < tableHeaders.length; index++) {
                const element = tableHeaders[index];
                if (!sessionFilter['sortBy'] && element.id == 6) {
                    tableHeaders[index].asc = false;
                    defaults.sortBy = tableHeaders[index];
                }
            }

            if (sessionFilter['sortBy']) {
                defaults.sortBy = sessionFilter['sortBy'];
                for (let i = 0; i < defaults.tableHeaders.length; i++) {
                    if (defaults.tableHeaders[i].tablevalue == sessionFilter['sortBy'].tablevalue) {
                        defaults.tableHeaders[i].asc = sessionFilter['sortBy'].asc;
                    }
                }
            }

            if (sessionFilter['search']) this.onSearch(sessionFilter['search'], false, defaults);
        }

        // Check if date needs to be set
        if (!loadSession || !sessionFilter['filterDateRange']) {
            this.onSelectPeriod(4);
        }

        defaults.searchColumn = tableHeaders[0];
        var columnsAssignment = "assignment_status,assignment_timestype_free,assignment_timestype_start,assignment_timestype_free_end,"
            + "assignment_id,assignment_type,assignment_time_estimate,assignment.assignment_approved,"
            + "assignment_description,store_id,stores.store_id,"
            + "stores.store_name,stores.store_postal_code,stores.store_address,stores.store_city,customer_id,customers.customer_name,customers.customer_id,assignment_contact_person,assignment_pos_material,"
            + "user_id,users.user_id,users.user_firstname,users.user_lastname";
        var columnsAssignmentExecution = ",assignment_execution_id,assignment_executions.assignment_execution_id,"
            + "assignment_executions.assignment_execution_drive_distance,assignment_executions.assignment_execution_expenses,assignment_executions.assignment_execution_time_spend"
            + "assignment_executions.assignment_execution_start_time,assignment_executions.assignment_execution_end_time,assignment_executions.salary_been_given";
        defaults.columns = columnsAssignment + columnsAssignmentExecution;
        defaults.isShowingDeactivated = true;
        defaults.tableSettingsEquals.push(new TableSettingsEquals("assignment_active", "1"));

        if (this.userService.user.user_role_id === this.Constants.USER_ROLE_CONSULTANT_ID) {
            defaults.tableSettingsEquals.push(new TableSettingsEquals("user_id", this.userService.getUserId()));
        }

        return defaults;
    }

    setDateFilterColumn(column){
        this.dateFilterColumn = column;
        let storedSessionStorage = JSON.parse(sessionStorage.getItem('assignmentListFilter')) || {};
        storedSessionStorage.dateFilterColumn = this.dateFilterColumn;
        sessionStorage.setItem('assignmentListFilter', JSON.stringify(storedSessionStorage));

        this.onFilter();
    }
    getCustomersForUser() {
        const customerTableSettings = new TableSettings();
        customerTableSettings.tableSettingsEquals.push(new TableSettingsEquals('user_id', this.userService.getUserId()));
        this.apiService.getUserCustomers(customerTableSettings).subscribe((data: any) => {
            if (data.main.length > 0) {
                this.customer = data.main[0];
                this.filterCustomer = this.customer.customer_id;
                this.onFilter();
            } else {
                // if no data is found stop loading view and display that no results have been found
                this.isLoadingView = false;
            }
        });
    }

    onOpenFilterOrSetting(filterOrSetting: string) {
        if (filterOrSetting == "FILTER") {
            this.isDropdownUserActive = !this.isDropdownUserActive;
            this.isDropdownSetupActive = false;
        } else {
            this.isDropdownUserActive = false;
            this.isDropdownSetupActive = !this.isDropdownSetupActive;
        }
    }

    onCloseFilterAndSetting() {
        this.isDropdownUserActive = false;
        this.isDropdownSetupActive = false;
    }

    getData(tableSettings: TableSettings) {
        this.tableData = [];

        this.isLoadingTable = true;

        /**
         * If employee only show somethings
         * Author: Tommy Jepsen - tommy@tonsstudio.com
         **/
        if (this.userService.user.user_role_id == this.Constants.USER_ROLE_EMPLOYEE_ID) {
            tableSettings.tableSettingsEquals.push(new TableSettingsEquals("assignment_for_users_mm.user_id", this.userService.getUserId()));
        }

        // if the greater and lower conditions are the same column type and have the exact same value, then the interval is supposed to be a single day, and
        if (this.tableSettings.tableSettingsGreater.length > 0 && this.tableSettings.tableSettingsLower.length > 0 && this.tableSettings.tableSettingsGreater[0].column == tableSettings.tableSettingsLower[0].column) {
            // then the timestamp should be changed from 00:00:00 to 23:59:59 to query correctly
            tableSettings.tableSettingsLower[0].value = tableSettings.tableSettingsLower[0].value.replace('00:00:00', '23:59:59');
        }

        this.apiService.getAssignmentsStats(tableSettings).takeUntil(this.destroy$).subscribe((data) => {
            this.assignmentStats = data['data']['main'];
        }, (error) => {
        });


        this.apiService.getAssignments(tableSettings).takeUntil(this.destroy$).subscribe((data) => {
            this.tableData = data.main;
            this.updateSession({page: data.tableOffset.current_offset});
            this.tableSettings.page = data.tableOffset.current_offset;
            this.tableSettings.tableOffset = data.tableOffset;
            this.dataService.saveAssignment(this.tableData);
            this.isLoadingView = false;
            this.isLoadingTable = false;
        }, (error) => {
            this.utilService.onRetryApi("getCustomers", () => {
                this.getData(this.tableSettings);
            }, () => {
                this.isLoadingView = false;
                this.isLoadingTable = false;
                this.notificationService.setNotification(this.lang.la.error_general_message + " " + error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
            });
        });
    }

    onGoToAssignment(pos, tablevalue) {
        if (tablevalue == 'salesconsultant') {
            this.router.navigate(['app/user/show/', this.tableData[pos]['users']['user_id']])
        } else if (tablevalue == 'assigned_users') {
            //Do nothing since a href in .html
        } else {
            this.router.navigate(['app/task/show/', this.tableData[pos].assignment_id]);
        }
    }

    onChangeSetupTabel() {
        this.isDropdownSetupActive = false;
        if (this.userService.userSettings == undefined) {
            this.userService.userSettings = new UserSettings();
        }
        this.userService.userSettings.assignmentList = this.tableSettings.tableHeaders;

        this.userService.setUserSettings(this.userService.userSettings);

        this.apiService.patchUserSettings(this.userService.userSettings).subscribe((data) => {
        }, (error) => {
        })
    }

    isFilterOn() {
        if (
            this.filterType.length != 0 || (this.filterStatus.length != 0 && this.filterStatus != "0")
            || (this.filterCustomer !== undefined && this.filterCustomer.length != 0)
            || (this.filterConsultant && this.filterConsultant.length != 0)
            || (this.filterStore.length != 0 && this.filterStore)
            || (this.filterEmployee && this.filterEmployee.length != 0)
            || (this.filterPictures && this.filterPictures.length != 0)
            || (this.inputSearch && this.inputSearch.length != 0)
            || (this.filterDateRange && this.filterDateRange.length != 0)
            || (this.filterImage && this.filterImage.length != 0)
        ) {
            return true;
        }
        return false;
    }

    getSetupList() {
        var setupListReturn = [];

        if (this.tableSettings.tableHeaders == undefined) {
            return [];
        }

        for (let i = 0; i < this.tableSettings.tableHeaders.length; i++) {
            if (this.tableSettings.tableHeaders[i].checked) {
                setupListReturn.push(this.tableSettings.tableHeaders[i]);
            }
        }
        return setupListReturn;
    }

    onFilterReset() {
        //this.isDropdownUserActive = false;

        sessionStorage.setItem('assignmentListFilter', JSON.stringify({}));

        // Table settings equals default
        this.tableSettings.tableSettingsEquals.length = 0;
        this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals('assignment_active', 1));
        if (this.userService.user.user_role_id === Constants.USER_ROLE_CONSULTANT_ID) {
            this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals("user_id", this.userService.getUserId()));
        }

        this.tableSettings.tableSettingsContains = [];

        // on reset default to the beginning and end of the current month
        this.tableSettings.tableSettingsLower.length = 0;
        this.tableSettings.tableSettingsGreater.length = 0;

        this.filterType = "";
        this.filterStatus = "0";
        this.filterConsultant = "";
        this.filterCustomer = "";
        this.filterImage = "";
        this.filterStore = "";
        this.filterEmployee = "";
        this.filterPictures = "";
        this.inputSearch = "";
        this.filterDateRange = [];
        this.inputFormer.value = false;
        this.inputReport.value = false;
        this.inputUpcoming.value = false;
        this.tableSettings.results = +this.filterOffset;

        if (this.userService.user.user_role_id === this.Constants.USER_ROLE_CUSTOMER_ID) {
            this.filterCustomer = this.customer.customer_id;
        }
        this.tableData = [];

        this.isDropdownUserActive = false;
        this.onSelectPeriod(4);
    }

    loadSessionFilter() {

        const sessionFilter = JSON.parse(sessionStorage.getItem('assignmentListFilter')) || {};

        this.dateFilterColumn = sessionFilter.dateFilterColumn || "assignment_timestype_start";
        this.filterType = sessionFilter.assignment_type || "";
        this.filterStatus = sessionFilter.assignment_status || "0";
        this.filterImage = sessionFilter.assignment_execution_image || "";
        this.filterCustomer = sessionFilter.customer_id || "";
        this.filterConsultant = sessionFilter['user_id'] || "";
        this.filterStore = sessionFilter.store_id || "";
        this.filterEmployee = sessionFilter['assignment_for_users_mm.user_id'] || "";
        this.inputFormer.value = sessionFilter['assignment_execution_id'] || null;
        this.inputReport.value = sessionFilter['assignment_execution_id'] || null;
        this.inputUpcoming.value = sessionFilter[this.dateFilterColumn] || null;
        this.page = sessionFilter['page'] || this.page;
        this.inputSearch = sessionFilter['search'] || this.inputSearch;

        // test
        const dates = sessionFilter['filterDateRange'] || [];
        if (dates.length == 2) {
            if (!dates[0] || !dates[1]) {
                // if the dates are not truthy, thus invalid dates (most commonly null), reset the dates
                this.onSelectPeriod(4);
            } else {
                //
                this.filterDateRange = [
                    moment(dates[0]).toDate(),
                    moment(dates[1]).toDate(),
                ];
            }
        }

        if (this.inputSearch != '') this.onSearch(this.inputSearch, false);

    }

    onFilter(reset = false, ignoreSession = false) {

        if (reset) {
            this.onFilterReset();
        }

        if (!ignoreSession) {
            this.loadSessionFilter();
        } else {
            this.page = 1;
        }

        this.isDropdownUserActive = false;

        this.tableSettings.tableSettingsEquals = [];
        this.tableSettings.tableSettingsNotEquals = [];
        this.tableSettings.tableSettingsContains = [];
        this.tableSettings.tableSettingsLower = [];
        this.tableSettings.tableSettingsGreater = [];
        const storedSessionStorage = {};

        this.tableSettings.results = +this.filterOffset;

        if (this.filterType != "") {
            this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals("assignment_type", this.filterType));
            storedSessionStorage['assignment_type'] = this.filterType;
        }

        if (this.filterStatus != "" && this.filterStatus != '0') {

            let filterStatusMapped = '';
            switch (this.filterStatus) {
                case "1": //Oprettet af kunde - afventer accept
                    filterStatusMapped = 'i-udbud-fra-kunde';
                    break;
                case "2": //Oprettet - afventer endelig info
                    filterStatusMapped = 'adhoc';
                    break;
                case "3": // I udbud - afventer medarbejder
                    filterStatusMapped = 'afventer-medarbejder-accept';
                    break;
                case "4": //Tildelt medarbejder - Afventer afrapport
                    filterStatusMapped = 'afventer-medarbejder-udfyldelse';
                    break;
                case "5": //Afventer godkendelse
                    filterStatusMapped = 'afventer-godkendelse';
                    break;
                case "6": //Godkendt
                    filterStatusMapped = 'godkendt';
                    break;
                default:
                    throw new Error("No filterStatus selected");
            }

            this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals("assignment_status", filterStatusMapped));
            storedSessionStorage['assignment_status'] = this.filterStatus;
        }

        if (this.filterImage != "") {
            this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals("assignment_execution_image", this.filterImage));
            storedSessionStorage['assignment_execution_image'] = this.filterImage;
        }

        if (this.filterCustomer != "") {
            this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals("customer_id", this.filterCustomer));
            storedSessionStorage['customer_id'] = this.filterCustomer;
        }

        if (this.filterConsultant != "") {
            this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals("user_id", this.filterConsultant));
            storedSessionStorage['user_id'] = this.filterConsultant;
        }

        if (this.filterStore != "") {
            this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals("store_id", this.filterStore));
            storedSessionStorage['store_id'] = this.filterStore;
        }

        if (this.filterDateRange != undefined && this.filterDateRange.length != 0) {
            this.tableSettings.tableSettingsGreater.push(new TableSettingsEquals(this.dateFilterColumn, moment(this.filterDateRange[0]).format("YYYY-MM-DD HH:mm:ss")));
            this.tableSettings.tableSettingsLower.push(new TableSettingsEquals(this.dateFilterColumn, moment(this.filterDateRange[1]).format("YYYY-MM-DD HH:mm:ss")));
            this.onSelectPeriodForStats(this.filterDateRange[0], this.filterDateRange[1]);
            storedSessionStorage['filterDateRange'] = [this.filterDateRange[0], this.filterDateRange[1]];
        }

        if (this.filterEmployee != "") {
            this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals("assignment_for_users_mm.user_id", this.filterEmployee));
            storedSessionStorage['assignment_for_users_mm.user_id'] = this.filterEmployee;
        }

        if (this.inputFormer.value) {
            this.tableSettings.tableSettingsNotEquals.push(new TableSettingsContains("assignment_execution_id", "null"));
            storedSessionStorage['assignment_execution_id'] = 'null';
        }
        if (this.inputReport.value) {
            this.tableSettings.tableSettingsEquals.push(new TableSettingsContains("assignment_execution_id", "null"));
            storedSessionStorage['assignment_execution_id'] = 'null';
        }
        if (this.inputUpcoming.value) {
            this.tableSettings.tableSettingsGreater.push(new TableSettingsEquals(this.dateFilterColumn, moment(new Date()).format("YYYY-MM-DD HH:mm:ss")));
            storedSessionStorage[this.dateFilterColumn] = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        }

        storedSessionStorage['dateFilterColumn'] = this.dateFilterColumn;

        //Always active
        this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals("assignment_active", "1"));

        this.tableSettings.page = this.page;

        if (this.page != 1) {
            storedSessionStorage['page'] = this.page;
        }

        // if consultant set the user id
        if (this.userService.user.user_role_id === Constants.USER_ROLE_CONSULTANT_ID) {
            this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals("user_id", this.userService.getUserId()));
            storedSessionStorage['user_id'] = this.userService.getUserId();
        }

        if (this.tableSettings.sortBy) storedSessionStorage['sortBy'] = this.tableSettings.sortBy;

        if (this.inputSearch != '') {
            storedSessionStorage['search'] = this.inputSearch;
            this.onSearch(this.inputSearch, false);
        }

        // Save settings in sessions storage
        sessionStorage.setItem('assignmentListFilter', JSON.stringify(storedSessionStorage));


        this.getData(this.tableSettings);
    }

    onSelectPeriodForStats(startDate, endDate) {
        this.assignmentStatsPeriodStart = moment(startDate).format("DD/MM-YYYY");
        this.assignmentStatsPeriodEnd = moment(endDate).format("DD/MM-YYYY");
    }

    onSort(sortTypeP) {
        this.tableData = [];
        if (sortTypeP == 0) {
            this.inputFormer.value = !this.inputFormer.value;
            this.inputReport.value = false;
            this.inputUpcoming.value = false;
        }
        if (sortTypeP == 1) {
            this.inputReport.value = !this.inputReport.value;
            this.inputFormer.value = false;
            this.inputUpcoming.value = false;
        }
        if (sortTypeP == 2) {
            this.inputUpcoming.value = !this.inputUpcoming.value;
            this.inputReport.value = false;
            this.inputFormer.value = false;
        }
        if (sortTypeP == 3) {
            if (this.filterStatus != "1") {
                this.filterStatus = "1";
            } else {
                this.filterStatus = "0";
            }
            this.onFilter();
        }
        if (sortTypeP == 4) {
            if (this.filterStatus != "5") {
                this.filterStatus = "5";
            } else {
                this.filterStatus = "0";
            }
            this.onFilter();
        }
        if (sortTypeP == 5) {
            if (this.filterStatus != "4") {
                this.filterStatus = "4";
            } else {
                this.filterStatus = "0";
            }
            this.onFilter();
        }

        this.onFilter();
    }

    /**
     * Table related stuff
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    onSortTable(tableHeader) {

        const storedSessionStorage = JSON.parse(sessionStorage.getItem('assignmentListFilter')) || {};

        for (let i = 0; i < this.tableSettings.tableHeaders.length; i++) {
            if (this.tableSettings.tableHeaders[i].tablevalue == tableHeader.tablevalue) {
                this.tableSettings.tableHeaders[i].asc = !this.tableSettings.tableHeaders[i].asc;
                this.tableSettings.sortBy = this.tableSettings.tableHeaders[i];
                storedSessionStorage['sortBy'] = this.tableSettings.sortBy;
                storedSessionStorage['orderBy'] = this.tableSettings.tableHeaders[i].asc
            }
        }

        this.tableSettings.page = 1;
        this.page = this.tableSettings.page;
        this.dataService.assignmentListPage = this.tableSettings.page;
        this.tableData = [];

        // Change sessions storage page
        storedSessionStorage['page'] = this.page;
        sessionStorage.setItem('assignmentListFilter', JSON.stringify(storedSessionStorage));

        this.getSetupList();
        this.page = 1;

        this.getData(this.tableSettings);
    }

    onPageChange(pageChange) {

        if (this.tableSettings.tableOffset.highest_offset < (this.tableSettings.page + pageChange)
            || (this.tableSettings.page + pageChange) <= 0) {
            return;
        }

        this.tableData = [];

        this.isLoadingTable = true;

        var newPageChange = this.tableSettings.page + pageChange;

        if (newPageChange < 1) {
            newPageChange = 1;
            return;
        }
        if (newPageChange > this.tableSettings.tableOffset.highest_offset) {
            newPageChange = this.tableSettings.tableOffset.highest_offset;
        }

        this.tableSettings.page = newPageChange;
        this.dataService.assignmentListPage = this.tableSettings.page;
        this.page = this.tableSettings.page;

        const storedSessionStorage = JSON.parse(sessionStorage.getItem('assignmentListFilter')) || {};
        storedSessionStorage['page'] = this.page;
        sessionStorage.setItem('assignmentListFilter', JSON.stringify(storedSessionStorage));

        this.getData(this.tableSettings);
    }

    onPageChangeFirst() {
        var newPageChange = 1;
        this.tableSettings.page = newPageChange;

        const storedSessionStorage = JSON.parse(sessionStorage.getItem('assignmentListFilter')) || {};
        this.page = this.tableSettings.page;
        storedSessionStorage['page'] = this.page;
        sessionStorage.setItem('assignmentListFilter', JSON.stringify(storedSessionStorage));

        this.getData(this.tableSettings);
    }

    onPageChangeLast() {
        var newPageChange = this.tableSettings.tableOffset.highest_offset;
        this.tableSettings.page = newPageChange;

        const storedSessionStorage = JSON.parse(sessionStorage.getItem('assignmentListFilter')) || {};
        this.page = this.tableSettings.page;
        storedSessionStorage['page'] = this.page;
        sessionStorage.setItem('assignmentListFilter', JSON.stringify(storedSessionStorage));

        this.getData(this.tableSettings);
    }

    onApproved(approve): string {
        if (approve) {
            return "Godkendt";
        } else {
            return "Afventer godkendelse";
        }
    }

    onSelectPeriod(period: number, setSession = true) {

        // 0 = Seneste 30 dage
        // 1 = Sidste måned
        // 2 = Denne måned
        // 3 = Næste måned
        this.filterDateRange = [];
        this.filterDateRange.push(new Date());
        this.filterDateRange.push(new Date());
        if (period == 0) {
            var newDate1 = moment().add(-30, 'days');
            var newDate2 = moment();
        } else if (period == 1) {
            var newDate1 = moment(new Date()).subtract(1, 'months').startOf('month');
            var newDate2 = moment(new Date()).subtract(1, 'months').endOf('month');
        } else if (period == 2) {
            var newDate1 = moment(new Date()).startOf('month');
            var newDate2 = moment(new Date()).endOf('month');
        } else if (period == 4) { //Start of last month and last day of this month
            var newDate1 = moment(new Date()).startOf('month');
            var newDate2 = moment(new Date()).add(1, 'months').endOf('month');
        } else {
            var newDate1 = moment(new Date()).add(1, 'months').startOf('month');
            var newDate2 = moment(new Date()).add(1, 'months').endOf('month');
        }
        this.filterDateRange[0] = newDate1.startOf('day').toDate();
        this.filterDateRange[1] = newDate2.endOf('day').toDate();

        this.inputFilterPeriodSelected = period;

        if(setSession){
            const storedSessionStorage = JSON.parse(sessionStorage.getItem('assignmentListFilter')) || {};
            storedSessionStorage['filterDateRange'] = this.filterDateRange;
            sessionStorage.setItem('assignmentListFilter', JSON.stringify(storedSessionStorage));
        }


        this.onSelectPeriodForStats(this.filterDateRange[0], this.filterDateRange[1]);
    }

    onQuickFilter(status) {
        if (this.filterStatus === status) {
            this.filterStatus = '0';
        } else {
            const sessionFilter = JSON.parse(sessionStorage.getItem('assignmentListFilter')) || {};
            this.filterStatus = status;
            this.page = 1;
            sessionFilter['assignment_status'] = this.filterStatus;
            sessionFilter['page'] = this.page;
            sessionStorage.setItem('assignmentListFilter', JSON.stringify(sessionFilter));
        }
        this.onFilter(false, true);
    }

    onSearch(searchValue: string, getData = true, tableSettings = this.tableSettings) {
        /*if(!tableSettings){
            // if not provided input tableSettings, use this.tablesettings
            tableSettings = this.tableSettings;
        }*/

        tableSettings.tableSettingsContains.length = 0;
        // if there is any search input value
        const sessionFilter = JSON.parse(sessionStorage.getItem('assignmentListFilter')) || {};
        if (searchValue) {

            // if employee search for stores,
            if(this.userService.userIsUserRoleId(Constants.USER_ROLE_EMPLOYEE_ID)){
                tableSettings.tableSettingsContains.push(new TableSettingsContains('stores.store_name', searchValue));
            } else {
                // if not employee search for employees
                tableSettings.tableSettingsContains.push(new TableSettingsContains('users.user_name', searchValue));
            }

            if (this.userService.user.user_role_id === this.Constants.USER_ROLE_EMPLOYEE || this.userService.user.user_role_id === this.Constants.USER_ROLE_CONSULTANT_ID) {
                const hasUserFilter = tableSettings.tableSettingsEquals.filter(setting => {
                    return setting.column === 'user_id';
                });
                if (hasUserFilter.length === 0) {
                    tableSettings.tableSettingsEquals.push(new TableSettingsEquals("user_id", this.userService.getUserId()));
                }
            }
            sessionFilter.search = searchValue;

            if(searchValue != sessionFilter['search']){
                // reset page if searchValue is new
                sessionFilter.page = 1;
                this.page = 1;
                tableSettings.page = 1;
            }

            sessionStorage.setItem('assignmentListFilter', JSON.stringify(sessionFilter));
            if (this.userService.user.user_role_id === this.Constants.USER_ROLE_CUSTOMER_ID) {
                tableSettings.tableSettingsEquals.push(new TableSettingsEquals("customer_id", this.customer.customer_id));
            }
        } else if (searchValue == '') {
            delete sessionFilter.search;
            sessionStorage.setItem('assignmentListFilter', JSON.stringify(sessionFilter));
        }

        this.tableSettings = tableSettings;
        if (getData) this.getData(tableSettings);
    }

    onImageFilterChange(event) {
        this.filterImage = event.checked === true ? '1' : '';
    }

    checkIfAllowed(listOfAllowedUsers: Array<number>) {
        return listOfAllowedUsers.includes(this.userService.user.user_role_id);
    }

    shortenText(text, allowed_chars = 32) {
        return text.length > allowed_chars ? text.slice(0, allowed_chars - 3) + '...' : text;
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    changeColumnOrder(index, offset){
        // moves the column by the amount set in offset
        this.tableSettings.tableHeaders.splice(index + offset, 0, this.tableSettings.tableHeaders.splice(index, 1)[0]);
    }

    isFiltering(){
        var filtering = false;
        const sessionListFilter = JSON.parse(sessionStorage.getItem('assignmentListFilter'));

        if(sessionListFilter.assignment_type) filtering = true;
        if(sessionListFilter.assignment_status) filtering = true;
        if(sessionListFilter.assignment_execution_image) filtering = true;
        if(sessionListFilter.customer_id) filtering = true;
        if(sessionListFilter.user_id && (this.userService.user.user_role_id !== this.Constants.USER_ROLE_CONSULTANT_ID)) filtering = true;
        if(sessionListFilter.store_id) filtering = true;
        if(sessionListFilter.search) filtering = true;
        if(sessionListFilter['assignment_for_users_mm.user_id']) filtering = true;

        if(sessionListFilter.filterDateRange){
            if (sessionListFilter.filterDateRange.length == 2) {
                var testStart = moment(new Date()).startOf('month');
                var testEnd = moment(new Date()).add(1, 'months').endOf('month');
                var start = moment(sessionListFilter.filterDateRange[0]);
                var end = moment(sessionListFilter.filterDateRange[1]);

                if(testStart.unix() != start.unix() || testEnd.unix() != end.unix()){
                    filtering = true;
                }
            }
        }

        return filtering;
    }

    updateSession(valueObject){
        let sessionFilter = JSON.parse(sessionStorage.getItem('assignmentListFilter')) || {};
        for (const [key, value] of Object.entries(valueObject)) {
            sessionFilter[key] = value;
        }
        sessionStorage.setItem('assignmentListFilter', JSON.stringify(sessionFilter));
    }
}
