<!-- Modal -->
<!-- <div class="modal" id="asd" tabindex="-1" role="dialog" aria-labelledby="asd" aria-hidden="true" [ngClass]="{'modal-show': isEditingCalendar}">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="asd">Rediger</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="isEditingCalendar = !isEditingCalendar">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="row">
					<div class="col-sm-12">
						<div class="form-group">
							<label>Type</label>
							<div class="input-group">
								<select class="custom-select" [(ngModel)]="inputEditType.value">
									<option selected>Type</option>
									<option value="0">Mercer</option>
									<option value="1">Demo</option>
								</select>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-body border-top">
				<div class="row">
					<div class="col-sm-12">
						<div class="form-group">
							<label>Overskrift</label>
							<div class="input-group">
								<input type="text" class="form-control" [(ngModel)]="inputEditHeadline.value">
							</div>
						</div>

						<div class="form-group">
							<label>Sted</label>
							<div class="input-group">
								<input type="text" class="form-control" [(ngModel)]="inputEditPlace.value">
							</div>
						</div>

					</div>
					<div class="col-sm-6">
						<div class="form-group">
							<label>Start dato</label>
							<div class="input-group">
								<input type="text" class="form-control" #dp="bsDatepicker" bsDatepicker [(bsValue)]="inputEditDateStart.value" />
							</div>
						</div>
					</div>
					<div class="col-sm-6">
						<div class="form-group">
							<label>Start tid</label>
							<div class="input-group">
								<input type="time" class="form-control" [(ngModel)]="inputEditTimeStart.value" />
							</div>
						</div>
					</div>

					<div class="col-sm-6">
						<div class="form-group">
							<label>Slut dato</label>
							<div class="input-group">
								<input type="text"	class="form-control" #dp="bsDatepicker" bsDatepicker [(bsValue)]="inputEditDateEnd.value" />
							</div>
						</div>
					</div>
					<div class="col-sm-6">
						<div class="form-group">
							<label>Slut tid</label>
							<div class="input-group">
								<input type="time" class="form-control" [(ngModel)]="inputEditTimeEnd.value" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-body border-top">
				<div class="row">
					<div class="col-sm-12">
						<label>Vælg en farve</label>
					</div>
					<div class="col-sm-12 has-text-centered">
						<div class="calendar-color cc-1" (click)="onColorChoose(0, false)" [ngClass]="{'is-active': inputBackgroundColorNumber == 0}"></div>
						<div class="calendar-color cc-2" (click)="onColorChoose(1, false)" [ngClass]="{'is-active': inputBackgroundColorNumber == 1}"></div>
						<div class="calendar-color cc-3" (click)="onColorChoose(2, false)" [ngClass]="{'is-active': inputBackgroundColorNumber == 2}"></div>
						<div class="calendar-color cc-4" (click)="onColorChoose(3, false)" [ngClass]="{'is-active': inputBackgroundColorNumber == 3}"></div>
						<div class="calendar-color cc-5" (click)="onColorChoose(4, false)" [ngClass]="{'is-active': inputBackgroundColorNumber == 4}"></div>
					</div>
				</div>
			</div>
			<div class="modal-body border-top">
				<div class="row">
					<div class="col-sm-12">
						Tryk kun på nedenstående tekst hvis du ønsker at slette:<br/>
						<a class="text-danger" (click)="onCalendarDelete()">Slet den permanent</a>
					</div>
				</div>
			</div>
			<div class="modal-footer ">
				<button class="btn  btn-light" (click)="isEditingCalendar = !isEditingCalendar">Fortryd</button>
				<button class="btn btn-primary" (click)="onCalendarEdit()">Rediger</button>
			</div>
		</div>
	</div>
</div> -->

<!-- Dropdown -->
<div class="row push-m-50-b">
    <div class="col-4">
        <h1 class="float-left h2">Kalender </h1>
    </div>
    <div class="col-8 has-text-right">
        <button class="btn btn-grey" (click)="onCalendarChangeLayout('agendaWeek')">Uge</button>
        <button class="btn btn-grey" (click)="onCalendarChangeLayout('listWeek')">Liste</button>
        <button class="btn btn-grey" (click)="onCalendarChangeLayout('month')">Måned</button>
        <!-- //TODO -->
        <!-- <button class="btn btn-primary push-m-20-l" (click)="isDropdownSetupActive = !isDropdownSetupActive">
            Opret ny
        </button> -->

        <div class="dropdown table-filter">
            <div class="dropdown-menu" [ngClass]="{'show': isDropdownSetupActive}">
                <h6 class="dropdown-header">Indsæt ny</h6>
                <div class="dropdown-divider"></div>
                <div class="dropdown-item">

                    <div class="row">
                        <div class="col-sm-12">

                            <div class="form-group">
                                <label>Type</label>
                                <div class="input-group">
                                    <select class="custom-select" [(ngModel)]="inputCreateType.value">
                                        <option selected>Type</option>
                                        <option value="0">Mercer</option>
                                        <option value="1">Demo</option>
                                    </select>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div class="dropdown-divider"></div>
                <div class="dropdown-item">

                    <div class="row">
                        <div class="col-sm-12">


                            <div class="form-group">
                                <label>Overskrift</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" [(ngModel)]="inputCreateHeadline.value">
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Sted</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" [(ngModel)]="inputCreatePlace.value">
                                </div>
                            </div>

                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Start dato</label>
                                <div class="input-group">
                                    <input type="text"
                                           class="form-control"
                                           #dp="bsDatepicker"
                                           bsDatepicker [(bsValue)]="inputCreateDateStart.value"
                                           disabled />
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Start tid</label>
                                <div class="input-group">
                                    <input type="time" class="form-control" [(ngModel)]="inputCreateTimeStart.value"/>
                                </div>
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Slut dato</label>
                                <div class="input-group">
                                    <input type="text"
                                           class="form-control"
                                           #dp="bsDatepicker"
                                           bsDatepicker [(bsValue)]="inputCreateDateEnd.value"
                                           disabled />
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Slut tid</label>
                                <div class="input-group">
                                    <input type="time" class="form-control" [(ngModel)]="inputCreateTimeEnd.value"/>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="dropdown-divider"></div>
                <div class="dropdown-item">

                    <div class="row">
                        <div class="col-sm-12">
                            <label>Vælg en farve</label>
                        </div>
                        <div class="col-sm-12 has-text-centered">
                            <div class="calendar-color cc-1" (click)="onColorChoose(0, true)"
                                 [ngClass]="{'is-active': inputBackgroundColorNumber == 0}"></div>
                            <div class="calendar-color cc-2" (click)="onColorChoose(1, true)"
                                 [ngClass]="{'is-active': inputBackgroundColorNumber == 1}"></div>
                            <div class="calendar-color cc-3" (click)="onColorChoose(2, true)"
                                 [ngClass]="{'is-active': inputBackgroundColorNumber == 2}"></div>
                            <div class="calendar-color cc-4" (click)="onColorChoose(3, true)"
                                 [ngClass]="{'is-active': inputBackgroundColorNumber == 3}"></div>
                            <div class="calendar-color cc-5" (click)="onColorChoose(4, true)"
                                 [ngClass]="{'is-active': inputBackgroundColorNumber == 4}"></div>
                        </div>
                    </div>
                </div>
                <div class="dropdown-divider"></div>
                <div class="dropdown-item text-right">

                    <button class="btn btn-primary" (click)="onCalendarAdd()">Indsæt</button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Loading -->
<!-- <app-loading *ngIf="isLoadingView"></app-loading> -->

<div class="row">
    <div class="col-sm-12 table-responsive">
        <app-calendarcomp [options]="calendarOptions"></app-calendarcomp>
    </div>
</div>
