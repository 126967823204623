import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {UserService} from './user.service';

import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';

import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/do';


/*
    This class creates a HTTP-interceptor for all HttpRequests
*/
@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    constructor(private router: Router, private userService: UserService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).do((event: HttpEvent<any>) => {
        }, (err: any) => {
            // if the retrieved error has the status 401 (unauthorized), we log out the user
            if (err instanceof HttpErrorResponse && err.status == 401) {
                // clear out the logged in user info in the userService effectively logging out the user in the frontend
                this.userService.deleteLogin();

                // route to login page
                this.router.navigate(['login']);
            }
        });
    }
}
