import {AssignmentTodo} from "./assignment-todo";

export class AssignmentTodosForAssignments {

    public primary_key: string = "assignment_todo_for_assignment_connection_id";

    public assignment_todo_for_assignment_connection_id: number;
    public assignment_todo_id: number;
    public assignment_id: number;
    public assigment_todos: AssignmentTodo;


    constructor() {
    }

    setValues(assignment_todo_idp, assignment_idp) {
        this.assignment_todo_id = assignment_todo_idp;
        this.assignment_id = assignment_idp;
    }

    public getPostJson() {
        return JSON.stringify({
            assignment_todo_id: this.assignment_todo_id,
            assignment_id: this.assignment_id
        })
    }

    public getPatchJson() {
        return JSON.stringify({
            assignment_todo_for_assignment_connection_id: this.assignment_todo_for_assignment_connection_id,
            assignment_todo_id: this.assignment_todo_id,
            assignment_id: this.assignment_id
        })
    }
}
