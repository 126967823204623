import {Component, OnInit} from '@angular/core';
import {InputString} from '../models/inputstring';
import {LangService} from '../services/lang.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '../services/api.service';
import {UtilService} from '../services/util.service';

@Component({
    selector: 'app-newpassword',
    templateUrl: './newpassword.component.html',
    styleUrls: ['./newpassword.component.scss']
})
export class NewpasswordComponent implements OnInit {

    isLoading: boolean = false;
    isSuccessful: boolean = false;
    failed: boolean = false;
    code: string = "";

    inputPw1: InputString = new InputString();
    inputPw2: InputString = new InputString();

    constructor(
        public lang: LangService,
        private route: ActivatedRoute,
        private router: Router,
        private apiService: ApiService,
        private utilService: UtilService
    ) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe(
            data => {
                this.code = data['code'];
            }
        );

    }

    onChangePassword() {
        if (this.utilService.checkMultipleIfvalid(this.inputPw1, this.inputPw2)) {
            return;
        }
        if (this.inputPw1.value != this.inputPw2.value) {
            this.inputPw2.error = "Matcher ikke";
            return;
        }
        if (!this.utilService.checkPassword(this.inputPw1)) {
            return;
        }
        if (!this.utilService.checkPassword(this.inputPw2)) {
            return;
        }

        this.isLoading = true;

        this.apiService.postNewPassword(this.code, this.inputPw1.value).subscribe((data) => {
            this.isSuccessful = true;
            this.isLoading = false;

        }, (error) => {
            this.failed = true;
            this.isLoading = false;

        })
    }

}
