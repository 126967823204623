import {Component, OnInit} from '@angular/core';
import {UtilService} from '../services/util.service';
import {DataService} from '../services/data.service';
import {LangService} from '../services/lang.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Assignment} from '../models/assignment';
import {FileUploader} from 'ng2-file-upload';
import {Constants, USER_RIGHT_TYPES} from '../constants';
import {InputString} from '../models/inputstring';
import {UserService} from '../services/user.service';
import {ApiService} from '../services/api.service';
import {TableSettings} from '../models/tablesettings';
import {NotificationService} from '../shared/notification.service';
import {TableSettingsEquals} from '../models/tablesettingsequals';
import {AssignmentExecutions} from '../models/assignment-executions';
import {AssignmentTodosForAssignments} from '../models/assignmenttodosforassignments';
import {Chain} from '../models/chain';
import {DomSanitizer} from '@angular/platform-browser';
import {AssignmentsForUsersMM} from '../models/assignments-for-users-mm';
import {AppendixForAssignmentExecutions} from '../models/appendix-for-assignment-executions';
import {InputDate} from '../models/inputdate';
import * as moment from 'moment-timezone';
import {TableHeader} from '../models/tableheader';
import {InputCheckbox} from "../models/inputcheckbox";
import {User} from "../models/user";
import * as EXIF from 'exif-js';
import {FileUploaderService} from "../services/fileUploaderService";
import {ImagesForAssignmentExecutions} from "../models/images-for-assignment-executions";
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";

@Component({
    selector: 'app-assignment',
    templateUrl: './assignment.component.html',
    styleUrls: ['./assignment.component.scss']
})
export class AssignmentComponent implements OnInit {
    //View
    isDeleteModalActive = false;
    isLoadingView = false;
    isLoading = false;
    isLoadingUsers = false;
    errorLoadingAssignment = false;

    isInvalidImageExist = false;
    isInvalidBilagExist = false;

    Constants: any = Constants;
    USER_RIGHT_TYPES: any = USER_RIGHT_TYPES;

    isSuppliedToAnyOne = false; // Flag for checking if someone already accepted it or have it "tildelt"
    isSuppliedToYou = false; //Flag if it is YOU who have got it "tildelt" or "accepted" the assignment
    isSuppliedUser: AssignmentsForUsersMM; // The user who has accepted it or "tildelt"

    //Executions
    executionsIsLoadingApproved = false;
    executionsProgress = 1;
    executionsProgressTemp: AssignmentExecutions;
    executionsEditing = false;

    //Data
    showId = 0;
    assignment: Assignment;
    assignmentExecution: AssignmentExecutions;
    assignmentTodos: AssignmentTodosForAssignments[];
    assignmentChain: Chain;
    assignmentUser: AssignmentsForUsersMM[];
    assignmentHistoric: any = [];
    assignmentExecutionImages: any;
    assignmentExecutionAppendix: any;
    assignmentAppendix: any;

    //Fileuploading
    isImageUploadActive = false;
    isBilagUploadActive = false;
    filesImages: FileUploader = new FileUploader({});
    filesBilag: FileUploader = new FileUploader({});

    //Inputs
    inputExpenses: InputString = new InputString();
    inputTimeUsed: InputString = new InputString();
    inputTimeUsedDate: InputDate = new InputDate();
    inputTimeUsedEnd: InputString = new InputString();
    inputTimeUsedStart: InputString = new InputString();
    inputDriving: InputString = new InputString();
    inputDescription: InputString = new InputString();

    inputDateStart: InputDate = new InputDate();
    inputDateEnd: InputDate = new InputDate();
    //Assignment execution control
    assignmentExecutionControl: AssignmentExecutions;

    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

    showEditWarning = {
        visible: false,
        timeAvailable: 0
    };
    //Maps url
    maps_url;

    inputAssignSupply: InputCheckbox = new InputCheckbox();
    inputAssignOffer: InputCheckbox = new InputCheckbox();
    inputUsers: User[];

    canSubmitForm: boolean = true;


    constructor(
        public userService: UserService,
        public route: ActivatedRoute,
        public router: Router,
        public utilService: UtilService,
        public lang: LangService,
        public apiService: ApiService,
        public dataService: DataService,
        public notificationService: NotificationService,
        public domSanitizer: DomSanitizer,
        public fileUploaderService: FileUploaderService,
    ) {
    }

    ngOnInit() {
        this.dpConfig = this.utilService.getDatePickerConfig();
        this.isLoadingView = true;
        this.inputDriving.value = '0';

        /**
         * Added this since you can change parameter when switching view from "Employees" to "Customers",
         * so you have to listen to changes
         * Author: Tommy Jepsen - tommy@tonsstudio.com
         **/
        this.route.params.subscribe(params => {
            this.getData();
        });
    }

    getData() {
        this.showId = this.route.snapshot.params['id'];

        if (this.showId == undefined) {
            this.notificationService.setNotification(this.lang.la.error_general, this.notificationService.CONSTANT_TYPE_WARNING);
            return;
        }

        let ts: TableSettings = new TableSettings();
        let assignments = 'assignment_id,assignment_type,assignment_creation,assignment_updated,assignment_time_estimate,assignment_product,assignment_place_in_store,'
            + 'assignment_description,store_id,customer_id,user_id,assignment_status,assignment_contact_person,assignment_pos_material,assignment_creation,'
            + 'assignment_execution_id,assignment_timestype_start,assignment_timestype_free_end,assignment_timestype_free,assignment_execution_id,assignment_created_by';
        let users = 'users.user_firstname,users.user_lastname,users.user_id,users.user_email,users.user_phone';
        let customers = 'customers.customer_id,customers.customer_name,customers.customer_address,customers.customer_city,customers.customer_postal_code,customers.customer_phone,customers.customer_email';
        let stores = 'stores.store_id,stores.store_name,stores.store_address,stores.store_postal_code,stores.store_city,stores.store_phone,stores.chain_id';
        ts.columns = assignments + ',' + users + ',' + customers + ',' + stores;

        this.apiService.getAssignment(this.showId).subscribe((data) => {
            this.assignment = new Assignment(data);
            if (this.assignment.assignment_status == Constants.ASSIGNMENT_STATUS_AWAITING_EXECUTION) {
                this.inputAssignOffer.value = true;
            } else if (this.assignment.assignment_status == Constants.ASSIGNMENT_STATUS_AWAITING_EMPLOYEE_ACCEPT) {
                this.inputAssignSupply.value = true;
            }


            this.executionsEditing = false;


            this.getDataAssignmentImages();
            this.getDataAssignmentExecutionAppendixes();

            this.getDataAssignmentAppendixes();

            this.getDataChains();

            //this.getDataAssignmentTodos();
            this.getDataAssignmentForUsers();

            //this.getDataExecutions();

            this.getDataLogs();

            this.onSetDateForExecutions();
            this.isLoadingView = false;

            this.showEditWarningToUser();

        }, (error) => {
            if (error.error.message == 'Opgaven er slettet') {
                this.notificationService.setNotification(error.error.message, this.notificationService.CONSTANT_TYPE_WARNING, 10000);
            } else {
                this.notificationService.setNotification(this.lang.la.error_general_message + ' ' + error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
            }

            this.errorLoadingAssignment = true;

            setTimeout(() => {
                this.router.navigate(['/app/task/list']);
            }, 10000);
        });
    }

    getDataChains() {
        //Get chain info and image
        let ts: TableSettings = new TableSettings();
        ts.columns = 'chain_id,chain_name,' + Constants.PARAMETER_IMAGES;
        if (this.assignment.store) {
            this.apiService.getChain(ts, this.assignment.store.chain_id).subscribe((data) => {
                if (data.main[0] != undefined) {
                    this.assignmentChain = data.main[0];
                }
            }, (error) => {
            });
        } else if (this.assignment.assignment_status === Constants.ASSIGNMENT_STATUS_GEM) {

        }
    }

    /**
     * Find out who have gotten this assignment assigned, and if it is the user, then show specific things on the view like accept button
     * Author: Tommy Jepsen - tommy@tommyjepsen.com
     **/
    getDataAssignmentForUsers() {
        this.isLoadingUsers = true;
        let tafu = new TableSettings();
        tafu.columns = 'assignments_for_users_connection_id,assignment_id,user_id,assignment_for_user_assigned,users.user_id,users.user_firstname,users.user_lastname,users.user_phone,images.image_link';
        tafu.tableSettingsEquals.push(new TableSettingsEquals('assignment_id', this.showId));
        this.apiService.getAssignmentForUsers(tafu).subscribe((data) => {
            this.assignmentUser = data.main;

            if (this.inputAssignOffer.value || this.inputAssignSupply.value) {
                var users = [];
                for (let i = 0; i < data.main.length; i++) {
                    const element = data.main[i];
                    var isAssigned = false;
                    if (element.assignment_for_user_assigned == 1) {
                        isAssigned = true;
                    }
                    users.push(element.users);
                }
                this.inputUsers = users;
            }

            //Check if anyone has accepted the assignment
            for (let i = 0; i < this.assignmentUser.length; i++) {

                if (this.assignmentUser[i].assignment_for_user_assigned) {
                    this.isSuppliedUser = this.assignmentUser[i];
                    this.isSuppliedToAnyOne = true; //Someone already accepted it
                }

                if (this.assignmentUser[i].user_id == this.userService.getUserId()) {
                    this.isSuppliedToYou = true;
                }
            }
            this.isLoadingUsers = false;
        }, (error) => {
            this.utilService.onRetryApi('getAssignmentForUsers', () => {
                this.getDataAssignmentForUsers();
            }, () => {
                this.isLoadingUsers = false;
            });
        });
    }

    getDataAssignmentImages() {
        let ts: TableSettings = new TableSettings();
        ts.columns = 'images_for_assignment_executions_active,assignment_execution_id,image_id,' + Constants.PARAMETER_IMAGES;
        ts.tableSettingsEquals.push(new TableSettingsEquals('assignment_execution_id', this.assignment.assignment_execution_id));
        ts.tableSettingsEquals.push(new TableSettingsEquals('images_for_assignment_executions_active', 1));
        this.apiService.getAssignmentExecutionImages(ts).subscribe((data) => {
            this.assignmentExecutionImages = data.main;
        }, (error) => {
        });
    }

    getDataAssignmentExecutionAppendixes() {
        let ts: TableSettings = new TableSettings();
        ts.columns = 'assignment_execution_id,appendix_amount,appendix_link';
        ts.tableSettingsEquals.push(new TableSettingsEquals('assignment_execution_id', this.assignment.assignment_execution_id));
        ts.tableSettingsEquals.push(new TableSettingsEquals('appendix_active', 1));

        this.apiService.getAssignmentExecutionAppendixes(ts).subscribe((data) => {
            this.assignmentExecutionAppendix = data.main;
        }, (error) => {
        });
    }

    getDataAssignmentAppendixes() {
        let ts: TableSettings = new TableSettings();
        ts.columns = 'assignment_id,appendix_amount,appendix_link,appendix_name';
        ts.tableSettingsEquals.push(new TableSettingsEquals('assignment_id', this.assignment.assignment_id));
        ts.tableSettingsEquals.push(new TableSettingsEquals('appendix_active', 1));

        this.apiService.getAssignmentAppendixes(ts).subscribe((data) => {
            /*
            let test = new Image;
            test.src = 'http://teamplayer.s8.baernholdt.com/api/appendixes/' + data.main[0].appendix_link;
            //http://teamplayer.s8.baernholdt.com/api/appendixes/efbb4a2e9bbcb87f05349d0a26f74f80.jpg
            EXIF.getData(test, function(){
            });
            */

            this.assignmentAppendix = data.main;
        }, (error) => {
        });
    }

    getDataLogs() {
        if (!this.userService.getShowForUser(USER_RIGHT_TYPES.TYPE_ASSIGMENT_EDIT)) return;

        let tablesettingsLogs: TableSettings = new TableSettings();
        tablesettingsLogs.columns = 'user_log_id,user_log_table_id,user_id,user_log_table,user_log_action,user_log_text,user_log_creation,user_log_updated,users.image_id,users.user_firstname,users.user_lastname,images.image_id,images.image_link';
        tablesettingsLogs.tableSettingsEquals.push(new TableSettingsEquals('user_log_table_id', this.assignment.assignment_id));
        tablesettingsLogs.tableSettingsEquals.push(new TableSettingsEquals('user_log_table', 'assignments'));
        tablesettingsLogs.sortBy = new TableHeader('user_log_updated', 'user_log_updated');

        this.apiService.getUserLogs(tablesettingsLogs).subscribe((data) => {
            if (data.main.length > 0) {
                this.assignmentHistoric = data.main.map((log: any) => {
                    // if log type is created return the json text else return the log to be of type {key: '' , from:'', to:''}
                    if (log.user_log_action !== 'created') {
                        const text = log.parsed_user_log_text;
                        const array = [];
                        Object.keys(text).forEach(key => {
                            if (key.includes('tid')) {
                                text[key].from = this.utilService.fromUTC(text[key].from).format('YYYY-MM-DD HH:mm:00');
                                text[key].to = this.utilService.fromUTC(text[key].to).format('YYYY-MM-DD HH:mm:00');
                            }
                            array.push({'fieldName': key, 'from': text[key].from, 'to': text[key].to});
                        });
                        log.user_log_text = array;
                    }
                    return log;
                });

            }
        }, (error) => {
        });
    }

    onSetDateForExecutions() {
        const currentTimestamp = this.utilService.fromUTC(this.assignment.assignment_timestype_start);
        this.inputDateStart.value = currentTimestamp.toDate();
        this.inputDateEnd.value = currentTimestamp.add(this.assignment.assignment_time_estimate, 'ms').toDate();
    }


    onAcceptAssignment() {
        this.isLoading = true;

        this.apiService.acceptAssignment(this.assignment.assignment_id).subscribe(response => {
            if (response) {
                this.isLoading = false;
                this.notificationService.setNotification('Opgaven er blevet tildelt dig.', this.notificationService.CONSTANT_TYPE_NORMAL);
                this.getData();
            }
        }, (error) => {
            this.isLoading = false;
            this.notificationService.setNotification(error.error.message, this.notificationService.CONSTANT_TYPE_WARNING, 10000);
            setTimeout(() => {
                this.router.navigate(['/app/task/list']);
            }, 10000);
        });
    }

    onDelete() {
        this.isLoading = true;

        let as: Assignment = new Assignment();
        as.assignment_id = this.assignment.assignment_id;
        as.assignment_active = 0;
        this.apiService.patchAssignment(as).subscribe((data) => {
            this.isLoading = false;
            this.notificationService.setNotification('Opgaven blev slettet', this.notificationService.CONSTANT_TYPE_NORMAL);
            setTimeout(() => {
                this.router.navigate(['app/task/list']);
            }, 200);
        }, (error) => {
            this.notificationService.setNotification(this.lang.la.error_general + ' ' + error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
            this.isLoading = false;
        });
    }

    onInputDriving(drivingDistance: number) {
        // return the next integer number if it has decimals
        this.inputDriving.value = '' + Math.ceil(drivingDistance);
        if (+this.inputDriving.value < 0) {
            this.inputDriving.value = '0';
        }
    }


    onAddressUrl() {
        //the maps url should be store locally as the inding to the src property of an iframe causes the iframe to reload every time change detection is run, even when the src value has not changed.
        if (!this.maps_url) {
            this.maps_url = this.domSanitizer.bypassSecurityTrustResourceUrl('https://www.google.com/maps/embed/v1/place?key=AIzaSyDQ4eTGMkQcurCYAFrlv5i3gauJrf8On-g&q=' + this.assignment.store.store_address + ', ' + this.assignment.store.store_postal_code);
        }
        return this.maps_url;
    }

    /**
     * Executions
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    checkIfExecutionIsValid() {
        if (moment(this.inputDateEnd.value).diff(moment(this.inputDateStart.value)) < 0) {
            this.inputDateEnd.error = 'Sluttidspunkt skal være senere end starttidspunkt';
            return false;
        }

        return true;
    }

    onPostExecutions() {
        if (this.checkIfExecutionIsValid()) {
            this.isLoading = true;

            const ae: AssignmentExecutions = new AssignmentExecutions();

            ae.assignment_id = +this.assignment.assignment_id;
            ae.assignment_execution_drive_distance = this.inputDriving.value;

            ae.assignment_execution_expenses = this.inputExpenses.value;
            ae.assignment_execution_comments = this.inputDescription.value;

            ae.assignment_execution_start_time = this.utilService.toUTC(this.inputDateStart.value).format('YYYY-MM-DD HH:mm:00');
            ae.assignment_execution_end_time = this.utilService.toUTC(this.inputDateEnd.value).format('YYYY-MM-DD HH:mm:00');

            ae.assignment_execution_time_spend = moment.duration(moment(this.inputDateEnd.value).diff(moment(this.inputDateStart.value))).asMilliseconds();

            if (!ae.assignment_execution_comments || ae.assignment_execution_comments.length < 1) {
                this.inputDescription.error = 'Kommentaren skal udfyldes';
                this.notificationService.setNotification('Udfyld venligst kommentaren for udførslen', this.notificationService.CONSTANT_TYPE_WARNING);
                this.isLoading = false;
                return;
            }

            if (!ae.assignment_execution_drive_distance || isNaN(+ae.assignment_execution_drive_distance)) {
                this.notificationService.setNotification('Kørsel skal være udfyldt, og skal være et tal', this.notificationService.CONSTANT_TYPE_WARNING);
                this.isLoading = false;
                return;
            }

            if (this.executionsEditing || this.assignmentExecutionControl != undefined) {

                if (this.assignmentExecutionControl != undefined) {
                    ae.assignment_execution_id = this.assignmentExecutionControl.assignment_execution_id;
                } else {
                    ae.assignment_execution_id = this.assignment.execution.assignment_execution_id;
                }

                this.apiService.patchAssignmentExecutions(ae).subscribe((data) => {
                    this.executionsProgressTemp = data['data'];
                    this.executionsProgress = 2;

                    this.assignmentExecutionControl = data['data'];

                    this.uploadFiles();

                }, (error) => {
                    this.notificationService.setNotification(this.lang.la.error_general + ' ' + error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
                    this.isLoading = false;
                });
            } else {
                this.apiService.postAssignmentExecutions(ae).subscribe((data) => {
                    this.executionsProgressTemp = data['data'];
                    this.executionsProgress = 2;

                    this.assignmentExecutionControl = data['data'];

                    this.uploadFiles();
                }, (error) => {
                    this.notificationService.setNotification(this.lang.la.error_general + ' ' + error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
                    this.isLoading = false;
                });
            }
        } else {
            this.notificationService.setNotification(this.lang.la.error_general + ' ', this.notificationService.CONSTANT_TYPE_WARNING);
            this.isLoading = false;
        }
    }

    showUploadErrorNotification() {
        this.notificationService.setImageUploadErrorNotification();
        this.isLoading = false;
    }

    uploadFiles() {
        Promise.all([
            this.onPostAppendix().catch((error) => {
                this.notificationService.setNotification("Der skete en fejl med upload af bilag. Tjek venligst at alle billag er blevet gemt korrekt.", this.notificationService.CONSTANT_TYPE_WARNING);
                this.isLoading = false;
                throw error;
            }),
            this.onPostImages().catch((error) => {
                this.showUploadErrorNotification();
                throw error;
            })
        ]).then((response) => {
            this.filesBilag.queue = [];
            this.filesImages.queue = [];
            this.onExecutionsDone();
        });
    }

    onPostAppendix() {
        const options = {
            type: 'appendix',
            module: 'executions',
            id: this.executionsProgressTemp.assignment_execution_id
        };
        return this.fileUploaderService.uploadAllInQueue(this.filesBilag, options);
    }

    onPostImages() {
        const options = {
            type: 'image',
            module: 'executions',
            id: this.executionsProgressTemp.assignment_execution_id
        };
        return this.fileUploaderService.uploadAllInQueue(this.filesImages, options);
    }


    onExecutionsDone() {
        this.notificationService.setNotification('Din afrapportering er nu blevet oprettet', this.notificationService.CONSTANT_TYPE_NORMAL);
        this.isLoading = false;

        window.scrollTo({left: 0, top: 0, behavior: 'smooth'});


        setTimeout(() => {
            this.getData();
        }, 500);
    }

    onShouldShowCompleteDate() {
        let startDay = moment(this.assignment.assignment_timestype_start).format('dd');
        let endDay = moment(this.assignment.assignment_timestype_free_end).format('dd');
        if (startDay == endDay) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * On edit executions
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    onExecutionsAccept() {
        this.executionsIsLoadingApproved = true;
        let ae: AssignmentExecutions = new AssignmentExecutions();
        ae.assignment_id = +this.assignment.assignment_id;
        ae.assignment_execution_approved = 1;
        ae.assignment_execution_id = this.assignment.execution.assignment_execution_id;
        this.apiService.patchAssignmentExecutions(ae).subscribe((data) => {
            this.notificationService.setNotification('Afrapporteringen er nu godkendt', this.notificationService.CONSTANT_TYPE_SUCCESS, 2000);
            this.executionsIsLoadingApproved = false;

            this.router.navigate(['../app/task/list/']);
        }, (error) => {
            this.notificationService.setNotification(this.lang.la.error_general + ' ' + error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
            this.executionsIsLoadingApproved = false;
        });
    }

    showCreateExecution() {
        return (!this.isLoadingView && (this.assignment == undefined || this.assignment.execution == undefined || this.executionsEditing) && this.isSuppliedToAnyOne && (this.isSuppliedToYou || this.userService.userIsOneOfUserRoleIds([Constants.USER_ROLE_TEAMPLAYER_ID, Constants.USER_ROLE_ADMIN_ID])));
    }

    showAcceptAssignment() {
        return (!this.isLoadingView && (this.assignmentExecution == undefined) && !this.isSuppliedToAnyOne && this.assignment.assignment_status == Constants.ASSIGNMENT_STATUS_AWAITING_EMPLOYEE_ACCEPT && this.isSuppliedToYou);
    }

    showEditExecutionButton(){
        return (
            (this.userService.userIsUserRoleId(Constants.USER_ROLE_EMPLOYEE_ID) && this.assignment.assignment_status !== Constants.ASSIGNMENT_STATUS_ACCEPTED)
            ||
            (this.userService.userIsOneOfUserRoleIds([Constants.USER_ROLE_ADMIN_ID, Constants.USER_ROLE_TEAMPLAYER_ID]) && !this.assignmentExported())
        ) && this.assignment.assignment_execution_id;
    }

    onEditExecutions() {
        this.executionsProgress = 1;
        this.executionsEditing = true;

        //Set inputs
        this.inputDriving.value = "" + this.assignment.execution.assignment_execution_drive_distance;
        this.inputExpenses.value = this.assignment.execution.assignment_execution_expenses;
        this.inputTimeUsed.value = this.utilService.onConvertMiliToHoursAndMili('' + this.assignment.execution.assignment_execution_time_spend);
        this.inputDescription.value = this.assignment.execution.assignment_execution_comments;
        this.inputDateStart.value = this.utilService.fromUTC(this.assignment.execution.assignment_execution_start_time).toDate(); //moment.tz(this.assignment.execution.assignment_execution_start_time, 'UTC').tz('Europe/Copenhagen');
        this.inputDateEnd.value = this.utilService.fromUTC(this.assignment.execution.assignment_execution_end_time).toDate(); //moment.tz(this.assignment.execution.assignment_execution_end_time, 'UTC').tz('Europe/Copenhagen');

        this.utilService.getDomElement('#reportingElement').then((element: HTMLElement) => {
            window.scrollTo({
                left: 0,
                top: element.scrollHeight,
                behavior: 'smooth'
            });
        });
    }

    onRemoveImageFromExecutions(pos) {
        let el: ImagesForAssignmentExecutions = this.assignmentExecutionImages[pos];
        this.assignmentExecutionImages[pos].isLoading = true;
        el.images_for_assignment_executions_active = 0;
        this.apiService.patchAssignmentExecutionImage(el).subscribe((data) => {
            this.assignmentExecutionImages[pos].isLoading = false;
            this.assignmentExecutionImages.splice(pos, 1);
        }, (error) => {
            this.assignmentExecutionImages[pos].isLoading = false;
        });
    }

    onRemoveAppendixFromExecutions(pos) {
        let el: AppendixForAssignmentExecutions = this.assignmentExecutionAppendix[pos];
        this.assignmentExecutionAppendix[pos].isLoading = true;
        el.appendix_active = 0;
        this.apiService.patchAssignmentExecutionAppendix(el).subscribe((data) => {
            this.assignmentExecutionAppendix[pos].isLoading = false;
            this.assignmentExecutionAppendix.splice(pos, 1);
        }, (error) => {
            this.assignmentExecutionAppendix[pos].isLoading = false;
        });
    }

    checkIfUserCanSeeAssignment(): boolean {
        // if conditions to see the assignment are met check for the user role id and add further verification
        if (!this.isLoadingView && this.assignment != undefined && this.assignment.execution != undefined && !this.executionsEditing) {
            // if customer or sales consultant and status is accepted can see assignment
            if ((this.userService.user.user_role_id === Constants.USER_ROLE_CUSTOMER_ID || this.userService.user.user_role_id === Constants.USER_ROLE_CONSULTANT_ID)) {
                if (this.assignment.assignment_status !== Constants.ASSIGNMENT_STATUS_ACCEPTED) {
                    return false;
                }
            }
            return true;
        }
        return false;
    }

    showEditWarningToUser() {
        // if the time of the assignment creation is > moment.now
        if ((this.userService.user.user_role_id === Constants.USER_ROLE_CONSULTANT_ID || this.userService.user.user_role_id === Constants.USER_ROLE_EMPLOYEE_ID)
            && this.currentUserIsOwnerOfAssignment() && moment(this.assignment.assignment_creation).add(10, 'minutes') >= moment() && this.assignment.assignment_status !== 'afventer-medarbejder-accept') {
            this.onCheckAssignmentEditableTime();
            const editTimer = setInterval(() => {
                if (!this.onCheckAssignmentEditableTime()) {
                    clearInterval(editTimer);
                }
            }, 10000);
        }
    }

    currentUserIsOwnerOfAssignment() {
        if (this.assignment.assignment_created_by !== null && this.assignment.assignment_created_by != this.userService.getUserId()) {
            return false;
        }
        return true;
    }

    showEditButton() {
        if (this.assignmentExported()) return false;

        // if admin/teamplayer
        if (this.userService.userIsOneOfUserRoleIds([Constants.USER_ROLE_ADMIN_ID, Constants.USER_ROLE_TEAMPLAYER_ID])) {
            return true;
        } else if (this.userService.getShowForUser(USER_RIGHT_TYPES.TYPE_ASSIGMENT_EDIT) || this.showEditWarning.visible) {
            // status must not be accepted / godkendt
            if (this.currentUserIsOwnerOfAssignment() && this.assignment.assignment_status !== Constants.ASSIGNMENT_STATUS_ACCEPTED) {
                return true;
            }
        }


        return false;
    }

    onCheckAssignmentEditableTime() {

        if (moment(this.assignment.assignment_creation).add(10, 'minutes').diff(moment()) > 0) {
            this.showEditWarning.timeAvailable = moment(this.assignment.assignment_creation).add(10, 'minutes').diff(moment(), 'minutes');
            this.showEditWarning.visible = moment(this.assignment.assignment_creation).add(10, 'minutes').diff(moment()) > 0;
            return true;
        }
        window.alert('Opgaven kan ikke længere blive redigeret');

        setTimeout(() => {
            location.reload();
        }, 1000);


        return false;
    }

    checkIfUserCanChangeEmployeesOfAssignment() {
        return this.userService.userIsOneOfUserRoleIds([Constants.USER_ROLE_ADMIN_ID, Constants.USER_ROLE_TEAMPLAYER_ID]) && this.assignment.assignment_status === 'afventer-medarbejder-accept';
    }

    // logic from the assignment-create for picking users
    onAssignPressed(which) {
        if (which === 0) {
            var users = [];
            // on change readd the assginment selected users
            for (let i = 0; i < this.assignmentUser.length; i++) {
                const element = this.assignmentUser[i];
                var isAssigned = false;
                if (element.assignment_for_user_assigned == 1) {
                    isAssigned = true;
                }
                users.push(element.users);
            }
            this.inputUsers = users;
            this.inputAssignOffer.value = false;
            this.inputAssignSupply.value = true;
        } else if (which === 1) {
            this.inputUsers = [];
            this.inputAssignOffer.value = true;
            this.inputAssignSupply.value = false;
        }
    }

    onCheckCopyToId() {
        if (this.inputUsers.length !== 0) {
            return this.inputUsers[0].user_id;
        } else {
            return 0;
        }
    }

    onSelectAssignOffer(user_id) {
        this.inputUsers = [];
        if (user_id) {
            const user = new User();
            user.user_id = user_id;
            this.inputUsers.push(user);
        }
    }


    onEditAssignmentUsers() {
        const as: Assignment = new Assignment(this.assignment);
        // set the new status of the assignment
        if (this.inputAssignSupply.value == true) {
            as.assignment_status = Constants.ASSIGNMENT_STATUS_AWAITING_EMPLOYEE_ACCEPT;
        } else if (this.inputAssignOffer.value == true) {
            as.assignment_status = Constants.ASSIGNMENT_STATUS_AWAITING_EXECUTION;
        }

        // add the newly picked users for
        as.user_ids = this.inputUsers.map(user => user.user_id);
        // remove properties that are not part of the assignment object
        delete as['store'];
        delete as['customer'];
        delete as['user'];
        delete as['sales_consultant'];
        delete as['assignment_for_users'];
        delete as['assigned_employee'];
        delete as['employees'];
        delete as['todos'];

        this.isLoading = true;
        this.apiService.patchAssignment(as).subscribe((data: any) => {
            this.isLoading = false;
            Object.keys(data.main).forEach(key => {
                this.assignment[key] = data.main[key];
            });
            this.notificationService.setNotification('Opgave opdateret', this.notificationService.CONSTANT_TYPE_SUCCESS);
            window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
            this.getDataAssignmentForUsers();
        }, (error) => {
            this.isLoading = false;
            this.notificationService.setNotification(error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
        }, () => {

        });
    }

    onBeforeAppendixUpload(event) {
        // when an appendix is uploaded filter for errors and if any disable the creation of the report
        this.filesBilag = event;
        this.canSubmitForm = this.filesBilag.queue.filter((image: any) => image.error).length <= 0;
    }

    addTimeZoning(timestamp) {
        return this.utilService.fromUTC(timestamp).format('YYYY-MM-DD HH:mm:ss');
    }

    changeEndDate(date) {
        date = moment(date);

        let tempDate = moment(this.inputDateEnd.value);
        tempDate.year(date.year()).month(date.month()).date(date.date());
        this.inputDateEnd.value = tempDate.toDate();
    }

    onExecutionTimeChange(type) {
        if (this.inputDateStart.value.getTime() <= this.inputDateEnd.value.getTime()) {
            this.inputDateEnd.error = "";
        } else {
            this.inputDateEnd.error = 'Sluttidspunkt skal være senere end starttidspunkt';
        }
    }

    assignmentExported() {
        return this.assignment.execution && (this.assignment.execution.invoice_been_run === 1 || this.assignment.execution.salary_been_given === 1);
    }

    rotateImage(img_tag) {

        EXIF.getData(img_tag, function () {
            const tags = EXIF.getAllTags(this);
            if (tags.Orientation) {
                switch (tags.Orientation) {
                    case 6:
                        img_tag.style.transform = 'rotate(90deg)';
                        break;
                    case 8:
                        img_tag.style.transform = 'rotate(170deg)';
                        break;
                    case 3:
                        img_tag.style.transform = 'rotate(180deg)';
                        break;

                }
            }
        });

    }

}
