import {Component, OnInit} from '@angular/core';
import {UtilService} from '../services/util.service';
import {DataService} from '../services/data.service';
import {LangService} from '../services/lang.service';
import {ApiService} from '../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';

import {Assignment} from '../models/assignment';
import {Calendar} from '../models/calendar';
import {Constants, USER_RIGHT_TYPES} from '../constants';

import * as moment from 'moment-timezone';
import * as $ from 'jquery';
import 'fullcalendar';
import {InputString} from '../models/inputstring';
import {InputDate} from '../models/inputdate';
import {TableSettings} from '../models/tablesettings';
import {TableSettingsEquals} from '../models/tablesettingsequals';
import {TableHeader} from '../models/tableheader';
import {UserService} from '../services/user.service';
import {Vacation} from '../models/vacation';

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss']
})
export class MyCalendarComponent implements OnInit {

    //View
    isLoadingView: boolean = false;
    month: string = "Januar";
    inputBackgroundColorNumber: Number = 0;

    COLORS: string[] = ["#81C926", "#26C1C9", "#AB7DF6", "#FACA00", "#FF6A6A"];

    //Data
    tableSettings: TableSettings;
    tableSettingsVacation: TableSettings;

    Constants: any = Constants;
    USER_RIGHT_TYPES: any = USER_RIGHT_TYPES;

    //Create
    isDropdownSetupActive: boolean = false;


    //Inputs
    inputCreateType: InputString = new InputString();
    inputCreateHeadline: InputString = new InputString();
    inputCreatePlace: InputString = new InputString();
    inputCreateDateStart: InputString = new InputString();
    inputCreateTimeStart: InputString = new InputString();
    inputCreateDateEnd: InputString = new InputString();
    inputCreateTimeEnd: InputString = new InputString();
    inputCreateBackgroundColor: string = "";

    //Edit
    isEditingCalendar: boolean = false;
    editPos: number = 0;

    inputEditType: InputString = new InputString();
    inputEditHeadline: InputString = new InputString();
    inputEditPlace: InputString = new InputString();
    inputEditTimeStart: InputString = new InputString();
    inputEditDateStart: InputDate = new InputDate();
    inputEditTimeEnd: InputString = new InputString();
    inputEditDateEnd: InputDate = new InputDate();

    inputEditBackgroundColor: string = "";

    //Calendar
    calendarOptions: Object;

    constructor(
        public apiService: ApiService,
        public route: ActivatedRoute,
        private router: Router,
        public utilService: UtilService,
        public lang: LangService,
        public dataService: DataService,
        public userService: UserService
    ) {
    }

    ngOnInit() {
        //If agreement not accepted yet
        if ((this.userService.user.user_cooperation_agreements_date == undefined
            || this.userService.user.user_cooperation_agreements_date == "0000-00-00 00:00:00")
            && (this.userService.user.user_role_id == Constants.USER_ROLE_EMPLOYEE_ID)
        ) {
            this.router.navigate(["./app/deal"]);
        }

        /**
         * Assignment table settings
         * Author: Tommy Jepsen - tommy@tonsstudio.com
         **/
        this.tableSettings = new TableSettings();
        var columnsAssignment = "assignment_status,assignment_timestype_free,assignment_timestype_start,assignment_timestype_free_end,"
            + "assignment_id,assignment_type,assignment_time_estimate,"
            + "assignment_description,store_id,stores.store_id,stores.store_address,stores.store_city,"
            + "stores.store_name,customer_id,customers.customer_name,customers.customer_id,assignment_contact_person,assignment_pos_material,"
            + "user_id,users.user_id,users.user_firstname,users.user_lastname";
        var columnsAssignmentExecution = ",assignment_execution_id,assignment_executions.assignment_execution_id,"
            + "assignment_executions.assignment_execution_drive_distance,assignment_executions.assignment_execution_expenses,"
            + "assignment_executions.assignment_execution_start_time,assignment_executions.assignment_execution_end_time";
        this.tableSettings.sortBy = new TableHeader("assignment_id", "assignment_id");
        this.tableSettings.columns = columnsAssignment + columnsAssignmentExecution;
        this.tableSettings.isShowingDeactivated = true;
        this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals("assignment_active", "1"));

        /**
         * Vacation table settings
         * Author: Tommy Jepsen - tommy@tonsstudio.com
         **/
        this.tableSettingsVacation = new TableSettings();
        this.tableSettingsVacation.columns = "vacations.vacation_id,vacations.user_id,vacations.vacation_start,vacations.vacation_end,vacations.vacation_active,users.user_id,users.user_firstname,users.user_lastname";
        this.tableSettingsVacation.tableSettingsEquals.push(new TableSettingsEquals("vacation_active", "1"));

        this.isLoadingView = true;

        moment.locale('da-DK');

        this.calendarOptions = {

            //Settings
            defaultView: "month",

            fixedWeekCount: false,
            editable: true,
            eventLimit: true,
            eventLimitText: "flere",

            locale: 'da',
            timezone: 'local',
            timeFormat: 'HH:mm',


            columnFormat: 'dddd DD/MM',

            allDayText: 'Hele dagen',
            slotLabelFormat: "HH:mm",
            // minTime: 0,
            // maxTime: 24,

            weekNumbersWithinDays: true,
            eventDurationEditable: true,
            eventResourceEditable: true,
            eventStartEditable: true,

            eventClick: (calEvent, jsEvent, view) => {
                for (let i = 0; i < this.calendarOptions['events'].length; i++) {
                    const element = this.calendarOptions['events'][i];
                    if (calEvent.type == "ferie") {
                        this.router.navigate(['./app/user/show/', calEvent.id]);
                    } else {
                        this.router.navigate(['./app/task/show/', calEvent.id]);
                    }
                }
                this.isEditingCalendar = !this.isEditingCalendar;
            },

            eventResize: function (event, delta, revertFunc) {
            }.bind(this),

            eventDrop: function (event, delta, revertFunc) {
            }.bind(this),

            //Events
            events: function (start, end, timezone, callback) {

                var startDate = moment(start); //Mmoment
                var endDate = moment(end); //Mmoment

                var startDateForm = startDate.format("YYYY-MM-DD HH:mm:ss");
                var endDateForm = endDate.format("YYYY-MM-DD HH:mm:ss");
                this.tableSettings.tableSettingsGreater = [];
                this.tableSettings.tableSettingsLower = [];
                this.tableSettings.tableSettingsGreater.push(new TableSettingsEquals("assignment_timestype_start", startDateForm));
                this.tableSettings.tableSettingsLower.push(new TableSettingsEquals("assignment_timestype_start", endDateForm));
                this.tableSettings.results = 500;
                this.tableSettings.tableSettingsNotEquals.push(new TableSettingsEquals("assignment_status", 'adhoc'));

                var eventsArray = [];

                /**
                 * If employee only show somethings
                 * Author: Tommy Jepsen - tommy@tonsstudio.com
                 **/
                if (this.userService.user.user_role_id == this.Constants.USER_ROLE_EMPLOYEE_ID) {
                    this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals("assignment_for_users_mm.user_id", this.userService.getUserId()));
                    this.tableSettingsVacation.tableSettingsEquals.push(new TableSettingsEquals("user_id", this.userService.getUserId()));
                }

                /**
                 * Get assignments
                 * Author: Tommy Jepsen - tommy@tonsstudio.com
                 **/
                this.apiService.getAssignments(this.tableSettings).subscribe((data) => {
                    for (let i = 0; i < data.main.length; i++) {
                        var element: Assignment = data.main[i];
                        var cal: Calendar = new Calendar();
                        cal.id = element.assignment_id;
                        cal.type = element.assignment_type;
                        cal.start_date = this.utilService.fromUTC(element.assignment_timestype_start).format("YYYY-MM-DD");
                        cal.start_time = this.utilService.fromUTC(element.assignment_timestype_start).format("HH:mm:ss");
                        cal.end_date = this.utilService.fromUTC(element.assignment_timestype_start).format("YYYY-MM-DD");
                        cal.end_time = this.utilService.fromUTC(element.assignment_timestype_start).format("HH:mm:ss");
                        cal.place = element.stores.store_name;


                        if (element.assignment_type == "mercer") {
                            cal.color = this.COLORS[0];
                            cal.title = this.utilService.onUppercase(element.assignment_type) + "\n" + element.stores.store_name + "\n" + moment.tz(element.assignment_timestype_start, 'UTC').tz('Europe/Copenhagen').format("HH:mm");
                        } else if (element.assignment_type == "projektstyring") {
                            cal.color = this.COLORS[1];
                            cal.title = this.utilService.onUppercase(element.assignment_type) + "\n" + this.utilService.onUppercase(element.assignment_description);
                        } else {
                            cal.color = this.COLORS[2];
                            cal.title = this.utilService.onUppercase(element.assignment_type) + "\n" + element.stores.store_name + "\n" + moment.tz(element.assignment_timestype_start, 'UTC').tz('Europe/Copenhagen').format("HH:mm");
                        }

                        eventsArray.push(cal.onGenerateForCalendarFull());
                    }

                    /**
                     * Get vacations
                     * Author: Tommy Jepsen - tommy@tonsstudio.com
                     **/
                    this.tableSettingsVacation.tableSettingsGreater.push(new TableSettingsEquals("vacation_start", startDateForm));
                    this.tableSettingsVacation.tableSettingsLower.push(new TableSettingsEquals("vacation_start", endDateForm));

                    this.apiService.getVacations(this.tableSettingsVacation).subscribe((data) => {

                        for (let i = 0; i < data.main.length; i++) {
                            var element: Vacation = data.main[i];
                            var cal: Calendar = new Calendar();
                            cal.id = element.users.user_id;
                            cal.type = "ferie";
                            cal.start_date = moment(element.vacation_start).format("YYYY-MM-DD");
                            cal.start_time = moment(element.vacation_start).format("HH:mm:ss");
                            cal.end_date = moment(element.vacation_end).format("YYYY-MM-DD");
                            cal.end_time = moment(element.vacation_end).format("HH:mm:ss");
                            cal.place = "place";

                            cal.color = this.COLORS[3];
                            cal.title = element.users.user_firstname + " " + element.users.user_lastname + " holder ferie";

                            eventsArray.push(cal.onGenerateForCalendarFull());
                        }

                        callback(eventsArray);

                    }, (error) => {
                        this.isLoadingView = false;
                        callback(eventsArray);

                    });
                }, (error) => {
                    this.isLoadingView = false;
                });
            }.bind(this)
        };

        // $('app-calendarcomp').fullCalendar('option', 'locale', 'da');
        this.onCalendarChangeLayout("agendaWeek");
    }

    onWhatColor(color): number {
        if (color == this.COLORS[0]) {
            return 0;
        } else if (color == this.COLORS[1]) {
            return 1;
        } else if (color == this.COLORS[2]) {
            return 2;
        } else if (color == this.COLORS[3]) {
            return 3;
        } else {
            return 4;
        }
    }

    onColorChoose(number, isCreating) {
        this.inputBackgroundColorNumber = number;
        var color: string = this.COLORS[number];

        if (isCreating) {
            this.inputCreateBackgroundColor = color;
        } else {
            this.inputCreateBackgroundColor = color;
        }
    }

    onCalendarChangeLayout(layout) {
        this.calendarOptions['defaultView'] = layout;

        this.onRestartCalendar();
    }

    onRestartCalendar() {
        $('app-calendarcomp').fullCalendar('destroy');
        $('app-calendarcomp').fullCalendar(this.calendarOptions);
        // $('app-calendarcomp').fullCalendar('option', 'locale', 'da');


    }

    onCalendarAdd() {
        var ca: Calendar = new Calendar();
        ca.title = this.inputCreateHeadline.value;
        ca.place = this.inputCreatePlace.value;
        ca.id = -1;

        ca.start_time = this.inputCreateTimeStart.value;
        ca.start_date = moment(this.inputCreateDateStart.value).format("YYYY-MM-DD");

        ca.end_time = this.inputCreateTimeEnd.value;
        ca.end_date = moment(this.inputCreateDateEnd.value).format("YYYY-MM-DD");

        ca.type = this.inputCreateType.value;

        var num: number = (+this.inputBackgroundColorNumber);
        ca.color = this.COLORS[num];

        if (this.calendarOptions['events'] != undefined) {
            this.calendarOptions['events'].push(ca.onGenerateForCalendarFull());
        } else {
            this.calendarOptions['events'] = [];
            this.calendarOptions['events'].push(ca.onGenerateForCalendarFull());
        }

        this.onRestartCalendar();

        this.isDropdownSetupActive = false;
        this.onClearInputs();
    }

    onClearInputs() {
        this.inputCreateType.value = "";
        this.inputCreateHeadline.value = "";
        this.inputCreatePlace.value = "";
        this.inputCreateDateStart.value = "";
        this.inputCreateTimeStart.value = "";
        this.inputCreateDateEnd.value = "";
        this.inputCreateTimeEnd.value = "";
        this.inputCreateBackgroundColor = "";
    }

    onCalendarDelete() {
        this.calendarOptions['events'].splice(this.editPos, 1);
        this.isEditingCalendar = false;
        this.onRestartCalendar();
        //TODO API
    }

    onCalendarEdit() {
        var ca: Calendar = new Calendar();
        ca.title = this.inputEditHeadline.value;
        ca.place = this.inputEditPlace.value;
        ca.id = -1;

        ca.start_time = this.inputEditTimeStart.value;
        ca.start_date = moment(this.inputEditDateStart.value).format("YYYY-MM-DD");

        ca.end_time = this.inputEditTimeEnd.value;
        ca.end_date = moment(this.inputEditDateEnd.value).format("YYYY-MM-DD");


        ca.type = this.inputEditType.value;
        ca.color = this.COLORS[(+this.inputBackgroundColorNumber)];

        this.calendarOptions['events'][this.editPos] = ca.onGenerateForCalendarFull();

        this.isEditingCalendar = false;

        //TODO API

        this.onRestartCalendar();
    }
}
