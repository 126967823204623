import { EventEmitter, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LangService } from '../../services/lang.service';
import { FileUploader } from 'ng2-file-upload';
import { Constants } from '../../constants';
import { Image } from '../../models/image';
import { ApiService } from "../../services/api.service";
import { NotificationService } from "../notification.service";
var InputUploadComponent = /** @class */ (function () {
    function InputUploadComponent(sanitizer, lang, apiService, notificationService) {
        this.sanitizer = sanitizer;
        this.lang = lang;
        this.apiService = apiService;
        this.notificationService = notificationService;
        this.allowedMimeType = ['image/png', 'image/gif', 'image/jpeg'];
        this.imageUploaderChange = new EventEmitter();
        this.isUploadActive = false;
        this.imageUploader = new FileUploader({ url: Constants.URL, allowedMimeType: this.allowedMimeType });
        this.checkExisting = true;
        this.multiple = true;
    }
    InputUploadComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.imageUploader.onAfterAddingFile = function (fileItem) {
            fileItem.withCredentials = false;
            fileItem.file['imageimage'] = _this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(fileItem._file)));
            fileItem['uploaded'] = false;
            fileItem['error'] = false;
            fileItem['uploading'] = false;
            fileItem['errorText'] = "";
        };
    };
    InputUploadComponent.prototype.fileOverBase = function (event) {
    };
    InputUploadComponent.prototype.onRemoveAgain = function (pos) {
        this.imageUploader.queue.splice(pos, 1);
    };
    InputUploadComponent.prototype.onAdd = function () {
        this.imageUploaderChange.emit(this.imageUploader);
        this.isUploadActive = false;
    };
    InputUploadComponent.prototype.onCheckIfImageExists = function () {
        var _this = this;
        if (!this.checkExisting)
            return;
        var _loop_1 = function (index) {
            // get the file and number of the file
            var image = new Image();
            image.image_file = this_1.imageUploader.queue[index]._file;
            image.appendix_number = this_1.imageUploader.queue[index]['appendixNumber'];
            // check if image exists in the backend
            this_1.apiService.checkAppendixExists(image).then(function (response) {
                // if it does not matches remove from array of pictures and set notification
                if (response.data.exists) {
                    _this.imageUploader.queue[index]['error'] = true;
                    _this.imageUploader.queue[index]['errorText'] = 'Billede eksisterer ';
                }
                else {
                    _this.imageUploader.queue[index]['error'] = false;
                    _this.imageUploader.queue[index]['errorText'] = '';
                }
                // after we upload all the apendixes set the notifications
                if (index === _this.imageUploader.queue.length - 1) {
                    var imagesNotificationMessage_1 = '';
                    // get the name of the existing files
                    _this.imageUploader.queue.forEach(function (image) {
                        if (image['error']) {
                            imagesNotificationMessage_1 += '\n' + image._file.name;
                        }
                    });
                    if (imagesNotificationMessage_1.length > 0) {
                        _this.notificationService.setNotification("Bilag eksisterer allerede " + imagesNotificationMessage_1, _this.notificationService.CONSTANT_TYPE_WARNING);
                    }
                }
                _this.imageUploaderChange.emit(_this.imageUploader);
            }).catch(function (err) {
            });
        };
        var this_1 = this;
        // loop all images in the upload queue
        for (var index = 0; index < this.imageUploader.queue.length; index++) {
            _loop_1(index);
        }
    };
    return InputUploadComponent;
}());
export { InputUploadComponent };
