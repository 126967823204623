/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/router";
import * as i3 from "./notification.component";
import * as i4 from "../notification.service";
var styles_NotificationComponent = [];
var RenderType_NotificationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotificationComponent, data: {} });
export { RenderType_NotificationComponent as RenderType_NotificationComponent };
function View_NotificationComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, ":svg:svg", [["class", "push-m-30-b push-m-30-t notification-image"], ["height", "100px"], ["version", "1.1"], ["viewBox", "0 0 100 100"], ["width", "100px"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, ":svg:g", [["fill", "none"], ["fill-rule", "evenodd"], ["id", "Page-1"], ["stroke", "none"], ["stroke-width", "1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, ":svg:g", [["id", "Artboard"], ["transform", "translate(-37.000000, -52.000000)"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, ":svg:g", [["id", "ic_done_white_36px"], ["transform", "translate(37.000000, 52.000000)"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, ":svg:polygon", [["id", "Shape"], ["points", "0 0 100 0 100 100 0 100"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, ":svg:circle", [["cx", "50"], ["cy", "50"], ["id", "Oval"], ["r", "47.5"], ["stroke", "#155724"], ["stroke-width", "5"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, ":svg:polygon", [["fill", "#155724"], ["fill-rule", "nonzero"], ["id", "Shape"], ["points", "40.3798492 61.3051981 28.0949622 49.2974781 24 53.3000515 40.3798492 69.3103448 75.4795259 35.0025734 71.3845636 31"]], null, null, null, null, null))], null, null); }
function View_NotificationComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "alert notification"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(2, { "alert-success": 0, "alert-danger": 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NotificationComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "alert notification"; var currVal_1 = _ck(_v, 2, 0, ((_co.notificationService.notificationType == 0) || (_co.notificationService.notificationType == 2)), (_co.notificationService.notificationType == 1)); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (_co.notificationService.notificationType == 2); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.notificationService.notificationText; _ck(_v, 5, 0, currVal_3); }); }
function View_NotificationComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "a", [["class", "btn btn-white"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(2, 2), (_l()(), i0.ɵted(-1, null, ["Kopier opgaven"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 2, 0, "/app/task/copy/", _co.notificationService.createNotification.id); _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_NotificationComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "alert notification alert-success alert-is-floating"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", " #", " blev oprettet."])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NotificationComponent_4)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 3, "a", [["class", "btn btn-white"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(7, 2), (_l()(), i0.ɵted(-1, null, ["G\u00E5 til siden"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.notificationService.createNotification.showCopy; _ck(_v, 4, 0, currVal_2); var currVal_5 = _ck(_v, 7, 0, _co.notificationService.createNotification.route, _co.notificationService.createNotification.id); _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notificationService.createNotification.model; var currVal_1 = _co.notificationService.createNotification.id; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = i0.ɵnov(_v, 6).target; var currVal_4 = i0.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_3, currVal_4); }); }
export function View_NotificationComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_NotificationComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "row push-m-0"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "has-centered-everything col-sm-12"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NotificationComponent_3)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.notificationService.notificationState && !_co.notificationService.showCreateNotificationView); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.notificationService.showCreateNotificationView; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_NotificationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-notification", [], null, null, null, View_NotificationComponent_0, RenderType_NotificationComponent)), i0.ɵdid(1, 114688, null, 0, i3.NotificationComponent, [i4.NotificationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationComponentNgFactory = i0.ɵccf("app-notification", i3.NotificationComponent, View_NotificationComponent_Host_0, {}, {}, []);
export { NotificationComponentNgFactory as NotificationComponentNgFactory };
