import {User} from "./user";

export class AssignmentsForUsersMM {

    public primary_key: string = "assignments_for_users_connection_id";

    public assignments_for_users_connection_id: number;
    public assignment_id: number;
    public user_id: number;
    public user_phone: any = '';
    public users: User[];
    public assignment_for_user_assigned: number;

    constructor() {
    }

    getPostObject() {
        var obj = this;
        delete obj.primary_key;
        delete obj.users;
        return JSON.stringify(obj);
    }

    getPatchObject() {
        var obj = this;
        delete obj.primary_key;
        delete obj.users;
        return JSON.stringify(obj);
    }
}