export class TableHeader {
    public value: string = "";
    public tablevalue: string = "";
    public asc: boolean = true;

    //Used in assignment list
    public id: number = 0;
    public checked: boolean = false;

    constructor(tablevalueP, valueP) {
        this.tablevalue = tablevalueP;
        this.value = valueP;
    }
}