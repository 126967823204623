<div class="row mb-3 mb-sm-5">
    <div class="col-sm-3 col-12">
        <h1 class="h2">{{lang.la.assignment_list}}</h1>
    </div>
    <div class="col-sm-9 col-12 list-action-buttons">
        <button class="btn btn-outline-primary" routerLink="../import"
                *ngIf="this.userService.user.user_role_id == Constants.USER_ROLE_TEAMPLAYER_ID || this.userService.user.user_role_id == Constants.USER_ROLE_ADMIN_ID">
            Import
        </button>
        <button class="btn btn-primary"
                *ngIf="this.userService.user.user_role_id !== Constants.USER_ROLE_CUSTOMER_ID"
                routerLink="../create">{{lang.la.create}}</button>
        <button class="btn btn-primary"
                [disabled]="csvService.isLoadingExport"
                [ngClass]="{'is-loading': csvService.isLoadingExport}"
                (click)="csvService.exportCSV(tableSettings)">{{lang.la.export_to_excel}}
            <!--  mr-2 mr-sm-0 ml-sm-2 mb-3 -->
        </button>
    </div>
</div>


<!-- Filtering -->
<div class="row ">
    <div class="col-sm-5 col-12">
        <div class="form-group input-group">
            <input #searchInput type="text" class="form-control"
                   (keyup.enter)="onSearch(searchInput.value)"
                   [(ngModel)]="inputSearch"
                   placeholder="Søg {{ userService.userIsUserRoleId(Constants.USER_ROLE_EMPLOYEE_ID) ? 'butik' : 'medarbejder' }}"/>
            <div class="input-group-append">
                <button class="input-group-text btn btn-outline-secondary  btn-is-form-multiple-right" type="button"
                        (click)="onSearch(searchInput.value)">{{lang.la.search}}
                </button>
            </div>
        </div>
    </div>
    <div class="col-sm-7 col-12">
        <div class="float-left push-m-10-r mb-3">
            <button class="btn btn-formcontrol btn-icon btn-input-height" (click)="onOpenFilterOrSetting('FILTER')"
                    [ngClass]="{'btn-primary': isFilterOn()}">
                <span>{{lang.la.filter}}</span>

                <svg fill="#adb5bd" height="18" viewBox="0 0 24 24" width="18" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"/>
                    <path d="M0 0h24v24H0z" fill="none"/>
                </svg>
            </button>
        </div>
        <div class="float-left push-m-10-r mb-3">
            <button class="btn btn-formcontrol btn-icon btn-input-height" (click)="onOpenFilterOrSetting('SETTING')">
                <span>{{lang.la.settings}}</span>
                <svg fill="#adb5bd" height="18" viewBox="0 0 24 24" width="18" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 0h24v24H0z" fill="none"/>
                    <path d="M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z"/>
                </svg>
            </button>
        </div>
        <div class="float-left mb-3">
            <div class="btn-group" dropdown>
                <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                        aria-controls="dropdown-basic">
                    {{ this.dateFilterColumn === 'assignment_timestype_start' ? 'Opgave Start' : 'Udførsel Start' }}
                    <svg fill="#adb5bd" height="18" viewBox="0 0 24 24" width="18" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"/>
                        <path d="M0 0h24v24H0z" fill="none"/>
                    </svg>
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"
                    role="menu" aria-labelledby="button-basic">
                    <li role="menuitem"><a class="dropdown-item" (click)="setDateFilterColumn('assignment_timestype_start')">Opgave Start</a></li>
                    <li role="menuitem"><a class="dropdown-item" (click)="setDateFilterColumn('assignment_executions.assignment_execution_start_time')">Udførsel Start</a></li>
                </ul>
            </div>
        </div>
    </div>
    <div class="col-12 d-none d-lg-block">

        <!-- Filter by employee -->
        <div class="float-left">
            <div class="form-group form-group-filter input-group"
                 *ngIf="userService.user.user_role_id !== Constants.USER_ROLE_EMPLOYEE_ID">
                <label type="text" for="filterStatus1" class="form-control">
                    Oprettet af kunde - afventer accept
                </label>
                <div class="input-group-append">
                    <div class="input-group-text">
                        <input type="radio" aria-label="filterStatus1" id="filterStatus1"
                               [checked]="filterStatus === '1'"
                               (click)="onQuickFilter('1')">
                    </div>
                </div>
            </div>

            <div class="form-group form-group-filter input-group"
                 *ngIf="userService.user.user_role_id === Constants.USER_ROLE_ADMIN_ID || userService.user.user_role_id === Constants.USER_ROLE_TEAMPLAYER_ID">
                <label type="text" for="filterStatus2" class="form-control">
                    Oprettet - afventer endelig info
                </label>
                <div class="input-group-append">
                    <div class="input-group-text">
                        <input type="radio" aria-label="filterStatus2" id="filterStatus2"
                               [checked]="filterStatus === '2'"
                               (click)="onQuickFilter('2')">
                    </div>
                </div>
            </div>

            <div class="form-group form-group-filter input-group">
                <label type="text" for="filterStatus3" class="form-control">
                    I udbud - afventer medarbejder
                </label>
                <div class="input-group-append">
                    <div class="input-group-text">
                        <input type="radio" aria-label="filterStatus3" id="filterStatus3"
                               [checked]="filterStatus === '3'"
                               (click)="onQuickFilter('3')">
                    </div>
                </div>
            </div>


            <div class="form-group form-group-filter input-group">
                <label type="text" for="filterStatus4" class="form-control">
                    Tildelt medarbejder - afventer afrapportering
                </label>
                <div class="input-group-append">
                    <div class="input-group-text">
                        <input type="radio" aria-label="filterStatus4" id="filterStatus4"
                               [checked]="filterStatus === '4'"
                               (click)="onQuickFilter('4')">
                    </div>
                </div>
            </div>

            <div class="form-group form-group-filter input-group">
                <label type="text" for="filterStatus5" class="form-control">
                    Afventer godkendelse
                </label>
                <div class="input-group-append">
                    <div class="input-group-text">
                        <input type="radio" aria-label="filterStatus5" id="filterStatus5"
                               [checked]="filterStatus === '5'"
                               (click)="onQuickFilter('5')">
                    </div>
                </div>
            </div>

            <div class="form-group form-group-filter input-group">
                <label type="text" for="filterStatus6" class="form-control">
                    Godkendt
                </label>
                <div class="input-group-append">
                    <div class="input-group-text">
                        <input type="radio" aria-label="filterStatus6" id="filterStatus6"
                               [checked]="filterStatus === '6'"
                               (click)="onQuickFilter('6')">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="dropdown-back-click" *ngIf="isDropdownUserActive || isDropdownSetupActive"
     (click)="onCloseFilterAndSetting()"></div>
<!-- Filter -->
<div class="dropdown table-filter">
    <div class="dropdown-menu" [ngClass]="{'show': isDropdownUserActive}">
        <h6 class="dropdown-header">{{lang.la.filter}}</h6>
        <div class="dropdown-divider"></div>
        <div class="dropdown-item">

            <div class="row">

                <div class="col-sm-6">

                    <div class="form-group">
                        <label for="type">{{lang.la.type}}</label>
                        <div class="input-group">
                            <select class="custom-select" [(ngModel)]="filterType">
                                <option [ngValue]="''" selected>{{lang.la.choose}}</option>
                                <option [ngValue]="'mercer'">{{lang.la.mercer}}</option>
                                <option [ngValue]="'demo'">{{lang.la.demo}}</option>
                                <option *ngIf="userService.user.user_role_id === Constants.USER_ROLE_ADMIN_ID || userService.user.user_role_id === Constants.USER_ROLE_TEAMPLAYER_ID"
                                        [value]="'projektstyring'">{{lang.la.projectmanagement}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="form-group" *ngIf="userService.getShowForUser(USER_RIGHT_TYPES.TEAMPLAYER_ONLY)">
                        <label for="type">{{lang.la.customer}}</label>

                        <app-dropdown-select-type
                                arrayType="0"
                                [arrayListSelectedId]="filterCustomer"
                                (selectedItem)="filterCustomer = $event"></app-dropdown-select-type>

                    </div>
                    <div class="form-group input-group mt-2">
                        <label for="inputFormer" class="form-control" type="text">Kun med billeder </label>
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <input aria-label="inputFormer" id="inputFormer" type="checkbox"
                                       (change)="onImageFilterChange($event.target)" [checked]="filterImage">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">

                        <label for="type">{{lang.la.status}}</label>
                        <div class="input-group">

                            <select class="custom-select" [(ngModel)]="filterStatus" name="filterStatus">
                                <option [ngValue]="'0'" selected>{{lang.la.choose}}</option>
                                <option *ngIf="userService.user.user_role_id !== Constants.USER_ROLE_EMPLOYEE_ID"
                                        [ngValue]="'1'">{{lang.la.created_awaiting_approval}}
                                </option>
                                <option *ngIf="userService.user.user_role_id == Constants.USER_ROLE_ADMIN_ID || userService.user.user_role_id === Constants.USER_ROLE_TEAMPLAYER_ID"
                                        [ngValue]="'2'">{{lang.la.created_awaiting_info}}
                                </option>
                                <option [ngValue]="'3'">{{lang.la.suppplied_waiting_employee}}</option>
                                <option [ngValue]="'4'">{{lang.la.assigned_employee_awaiting_report}}</option>
                                <option [ngValue]="'5'">{{lang.la.awaiting_approval}}</option>
                                <option [ngValue]="'6'">{{lang.la.approved}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="col-sm-6"
                     *ngIf="checkIfAllowed([Constants.USER_ROLE_ADMIN_ID,Constants.USER_ROLE_TEAMPLAYER_ID, Constants.USER_ROLE_EMPLOYEE_ID, Constants.USER_ROLE_CUSTOMER_ID])">
                    <div class="form-group">
                        <label for="type">{{lang.la.salesconsultant}}</label>

                        <app-dropdown-select-type
                                [arrayListSelectedId]="filterConsultant"
                                [arrayType]="userService.user.user_role_id === Constants.USER_ROLE_EMPLOYEE_ID || userService.user.user_role_id === Constants.USER_ROLE_CUSTOMER_ID ? 5 : 4"
                                (selectedItem)="filterConsultant = $event"></app-dropdown-select-type>

                    </div>
                </div>
                <div class="col-sm-6"
                     *ngIf="checkIfAllowed([Constants.USER_ROLE_ADMIN_ID,Constants.USER_ROLE_TEAMPLAYER_ID, Constants.USER_ROLE_EMPLOYEE_ID])">
                    <div class="form-group">
                        <label for="type">{{lang.la.customers}}</label>

                        <app-dropdown-select-type
                                [arrayListSelectedId]="filterCustomer"
                                [arrayType]="userService.user.user_role_id === Constants.USER_ROLE_EMPLOYEE_ID ? 6 : 0"
                                (selectedItem)="filterCustomer = $event"></app-dropdown-select-type>

                    </div>

                </div>

                <div class="col-sm-6"
                     *ngIf="checkIfAllowed([Constants.USER_ROLE_ADMIN_ID,Constants.USER_ROLE_TEAMPLAYER_ID])">
                    <div class="form-group">
                        <label for="type">{{lang.la.employee}}</label>

                        <app-dropdown-select-type
                                [arrayListSelectedId]="filterEmployee"
                                arrayType="1"
                                (selectedItem)="filterEmployee = $event"></app-dropdown-select-type>

                    </div>
                </div>

                <div class="col-sm-6">
                    <div class="form-group">
                        <label for="type">{{lang.la.stores}}</label>

                        <app-dropdown-select-type
                                [arrayListSelectedId]="filterStore"
                                arrayType="2"
                                (selectedItem)="filterStore = $event"></app-dropdown-select-type>

                    </div>
                </div>

                <!-- Time -->
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>{{lang.la.timeroom}}</label>
                    </div>
                </div>


                <div class="col-sm-6">
                    <div class="form-group">
                        <div class="input-group">
                            <button class="btn is-fullwidth btn-outline-secondary" (click)="onSelectPeriod(0, false)"
                                    [ngClass]="{'btn-primary': inputFilterPeriodSelected == 0}">Seneste 30 dage
                            </button>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-group">
                            <button class="btn is-fullwidth btn-outline-secondary" (click)="onSelectPeriod(1, false)"
                                    [ngClass]="{'btn-primary': inputFilterPeriodSelected == 1}">Sidste måned
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <div class="input-group">
                            <button class="btn is-fullwidth btn-outline-secondary" (click)="onSelectPeriod(2, false)"
                                    [ngClass]="{'btn-primary': inputFilterPeriodSelected == 2}">Denne måned
                            </button>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-group">
                            <button class="btn is-fullwidth btn-outline-secondary" (click)="onSelectPeriod(3, false)"
                                    [ngClass]="{'btn-primary': inputFilterPeriodSelected == 3}">Næste måned
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <div class="input-group">
                            <button class="btn is-fullwidth btn-outline-secondary btn-auto-height"
                                    (click)="onSelectPeriod(4, false)"
                                    [ngClass]="{'btn-primary': inputFilterPeriodSelected == 4}">Start denne måned,<br/>
                                slut næste måned
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group" *ngIf="filterDateRange && filterDateRange.length != 0">
                        <label>
                            Vælg specifikt tidsrum
                        </label>
                        <div class="input-group" [(bsValue)]="filterDateRange " #drp="bsDaterangepicker"
                             bsDaterangepicker [bsConfig]="dpConfig" (click)="inputFilterPeriodSelected = -1">
                            <input class="form-control"
                                   [value]="(filterDateRange[0] | mdate: 'DD/MM/YYYY') + '-' + (filterDateRange[1] | mdate: 'DD/MM/YYYY')"
                                   name="filterDateRange"
                                   disabled >
                        </div>
                    </div>
                    <div class="form-group" *ngIf="filterDateRange && filterDateRange.length == 0">
                        <label>
                            Vælg specifikt tidsrum
                        </label>
                        <div class="input-group" [(bsValue)]="filterDateRange " #drp="bsDaterangepicker"
                             bsDaterangepicker [bsConfig]="dpConfig" (click)="inputFilterPeriodSelected = -1">
                            <input class="form-control" name="filterDateRange" disabled>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="dropdown-divider"></div>

        <div class="dropdown-item text-right">
            <button class="btn btn-outlined-secondary" (click)="onFilter(true)">Nulstil</button>

            <button class="btn btn-primary" (click)="onFilter(false, true)">{{lang.la.search_btn}}</button>
        </div>
    </div>
</div>


<!-- Setup -->
<div class="dropdown table-filter">
    <div class="dropdown-menu" [ngClass]="{'show': isDropdownSetupActive}">
        <h6 class="dropdown-header">{{lang.la.setting_up_a_table}}</h6>
        <div class="dropdown-divider"></div>
        <div class="dropdown-item">

            <div class="row">
                <ul class="columnSettings">
                    <li *ngFor="let item of tableSettings.tableHeaders; let index = index">
                        <button class="btn btn-sm btn-primary" [disabled]="index == 0" (click)="changeColumnOrder(index, -1)"><i class="material-icons small-icon">keyboard_arrow_up</i></button>
                        <button class="btn btn-sm btn-primary" [disabled]="index == tableSettings.tableHeaders.length - 1" (click)="changeColumnOrder(index, 1)"><i class="material-icons small-icon">keyboard_arrow_down</i></button>
                        <label class="push-p-5-l push-p-5-t push-p-5-b push-p-5-r item">
                            <span class="push-m-10-r text-is-grey">{{index}}:</span>
                            <input type="checkbox" [(ngModel)]="item.checked" />
                            <span class="push-m-10-l">{{item.value}}</span>
                        </label>
                    </li>
                </ul>

            </div>

        </div>

        <div class="dropdown-divider"></div>

        <div class="dropdown-item">
            <button class="btn btn-primary" (click)="onChangeSetupTabel()">
                {{lang.la.save_settings}}
            </button>
        </div>
    </div>
</div>


<alert class="filter-alert" *ngIf="isFiltering()" type="warning" (click)="onFilter(true)">Listen herunder er filtreret - klik her for at nulstille filteret</alert>


<!-- Loading -->
<app-loading *ngIf="isLoadingView"></app-loading>

<!-- Table -->
<div class="card card-top" *ngIf="!isLoadingView">
    <div class="card-body card-table">
        <div class="table-responsive">
            <table class="table table-hover table-striped">
                <thead>
                <tr>
                    <th scope="col" *ngFor="let tableHeader of getSetupList(); let pos = index"
                        (click)="onSortTable(tableHeader)">

							<span class="push-m-5-r">
								{{tableHeader.value}}
							</span>

                        <!-- ASC OR DESC -->
                        <svg class="th-icon" fill="#6c757d" height="18" viewBox="0 0 24 24" width="18"
                             xmlns="http://www.w3.org/2000/svg"
                             *ngIf="tableSettings.sortBy.tablevalue == tableHeader.tablevalue && tableSettings.sortBy.asc">
                            <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"/>
                            <path d="M0-.75h24v24H0z" fill="none"/>
                        </svg>
                        <svg class="th-icon" fill="#6c757d" height="18" viewBox="0 0 24 24" width="18"
                             xmlns="http://www.w3.org/2000/svg"
                             *ngIf="tableSettings.sortBy.tablevalue == tableHeader.tablevalue && !tableSettings.sortBy.asc">
                            <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"/>
                            <path d="M0 0h24v24H0z" fill="none"/>
                        </svg>

                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of tableData; let pos = index">
                    <td *ngFor="let setup of getSetupList(); let setuppos = index" class="table-content"
                        (click)="onGoToAssignment(pos, setup.tablevalue)">
							<span [ngSwitch]="setup.tablevalue">
								<span *ngSwitchCase="'assignment_timestype_start'">
									{{utilService.onUppercase(utilService.onGetTableContent(item, 'assignment_timestype_start') | mdate: 'DD. MMM YYYY HH:mm')}}
								</span>
								<span *ngSwitchCase="'assignment_timestype_free_end'">
									<span *ngIf="utilService.onGetTableContent(item, setup.tablevalue) != '0000-00-00 00:00:00'">
										{{utilService.onUppercase(utilService.onGetTableContent(item, setup.tablevalue) | mdate: 'DD. MMM YYYY HH:mm')}}
									</span>
								</span>
                                <span *ngSwitchCase="'assignment_executions.assignment_execution_start_time'">
									{{utilService.onUppercase(utilService.onGetTableContent(item, 'assignment_executions.assignment_execution_start_time') | mdate: 'DD. MMM YYYY HH:mm')}}
								</span>
                                <span *ngSwitchCase="'assignment_executions.assignment_execution_end_time'">
									{{utilService.onUppercase(utilService.onGetTableContent(item, 'assignment_executions.assignment_execution_end_time') | mdate: 'DD. MMM YYYY HH:mm')}}
								</span>
								<span *ngSwitchCase="'assignment_executions.assignment_execution_expenses'">
									{{ utilService.onGetTableContent(item, setup.tablevalue) | currency: 'DKK' | formatedcurrency}}
								</span>
								<span *ngSwitchCase="'assignment_time_estimate'">
									{{utilService.onConvertMiliToHoursAndMili(utilService.onGetTableContent(item, setup.tablevalue))}}
								</span>
                                <span *ngSwitchCase="'assignment_executions.assignment_execution_time_spend'">
                                    {{utilService.onGetTableContent(item, setup.tablevalue) ? utilService.onConvertMiliToHoursAndMili(utilService.onGetTableContent(item, setup.tablevalue)) : '00:00'}}
								</span>
								<span *ngSwitchCase="'assignment_status'">
									<span [ngSwitch]="utilService.onGetTableContent(item, setup.tablevalue)">
										<span *ngSwitchCase="'i-udbud-fra-kunde'">Oprettet af kunde - afventer accept</span>
										<span *ngSwitchCase="'adhoc'">Oprettet - afventer endelig info</span>
										<span *ngSwitchCase="'afventer-medarbejder-accept'">I udbud - afventer medarbejder</span>
										<span *ngSwitchCase="'afventer-medarbejder-udfyldelse'">Afventer afrapportering</span>
										<span *ngSwitchCase="'afventer-godkendelse'">Afventer godkendelse</span>
										<span *ngSwitchCase="'godkendt'">Godkendt</span>
										<span *ngSwitchDefault>{{utilService.onGetTableContent(item, setup.tablevalue)}}</span>
									</span>
								</span>
								<span *ngSwitchCase="'assignment_executions.assignment_execution_drive_distance'">
									{{utilService.onGetTableContent(item, setup.tablevalue)}}
                                    <span *ngIf="utilService.onGetTableContent(item, setup.tablevalue) != ''">
										km
									</span>
								</span>
                                <span *ngSwitchCase="'assignment_executions.assignment_execution_comments'">
									{{shortenText(utilService.onGetTableContent(item, setup.tablevalue))}}
								</span>
								<span *ngSwitchCase="'user_name'">
									<span *ngIf="utilService.onGetTableContent(item, 'assigned_users') != undefined"
                                          class="profile-image-show-container">
                                        <ng-container
                                                *ngIf="this.userService.user.user_role_id == 1 || this.userService.user.user_role_id == 5; else notAdmin">
                                            <a *ngFor="let user of utilService.onGetTableContent(item, 'assigned_users')"
                                               [routerLink]="['../../user/show', user.user_id]">
											{{user.user_firstname}} {{user.user_lastname}}
										</a>
                                        </ng-container>
                                        <ng-template #notAdmin>
                                            <p *ngFor="let user of utilService.onGetTableContent(item, 'assigned_users')">
                                            {{user.user_firstname}} {{user.user_lastname}}
                                        </p>
                                        </ng-template>
									</span>
								</span>
								<span *ngSwitchCase="'assignments.user_id'">
									<a *ngIf="item.users != undefined">
										{{item.users.user_firstname}} {{item.users.user_lastname}}
									</a>
								</span>
								<span *ngSwitchCase="'assignment_execution_image'">
									<a *ngIf="item.assignment_execution_image">
										<img src="./assets/attached_file.svg" alt="">
									</a>
								</span>
								<span *ngSwitchCase="'assignment_status_id'">
									<h6>
										<span class="badge badge-success "
                                              *ngIf="item['assignment_statuses'][setup.tablevalue] == 1">{{item['assignment_statuses']['assignment_status_name']}}</span>
										<span class="badge badge-danger "
                                              *ngIf="item['assignment_statuses'][setup.tablevalue] == 2">{{item['assignment_statuses']['assignment_status_name']}}</span>
										<span class="badge badge-warning "
                                              *ngIf="item['assignment_statuses'][setup.tablevalue] == 3">{{item['assignment_statuses']['assignment_status_name']}}</span>
									</h6>
								</span>
                                <!--<span *ngSwitchCase="'assignment_executions.invoice_been_run'">-->
                                <!--<span>{{item['assignment_executions']['invoice_been_run'] === 1 ? '&#x2713;': ''}}</span>-->
                                <!--</span>-->
                                 <span *ngSwitchCase="'assignment_executions.salary_given_at'">
                                    <span *ngIf="utilService.onGetTableContent(item, setup.tablevalue)">
                                        {{utilService.onUppercase(utilService.onGetTableContent(item, setup.tablevalue) | mdate: 'DD. MMM YYYY HH:mm')}}
                                    </span>
                                </span>
                                 <span *ngSwitchCase="'assignment_executions.invoice_given_at'">
                                    <span *ngIf="utilService.onGetTableContent(item, setup.tablevalue)">
                                        {{utilService.onUppercase(utilService.onGetTableContent(item, setup.tablevalue) | mdate: 'DD. MMM YYYY HH:mm')}}
                                    </span>
                                </span>
								<span *ngSwitchDefault>
									{{utilService.onUppercase(utilService.onGetTableContent(item, setup.tablevalue))}}
								</span>
							</span>
                    </td>
                </tr>
                <!-- Loading -->
                <tr *ngIf="isLoadingTable">
                    <td [attr.colspan]="getSetupList().length" class="table-empty">
                        <div class="loader"></div>
                    </td>
                </tr>
                <!-- Empty -->
                <tr *ngIf="(tableData == undefined || tableData.length == 0) && !isLoadingTable && isFilterOn()">
                    <td [attr.colspan]="getSetupList().length" class="table-empty">
                        <p>
                            Kunne ikke finde noget med dine søgekriterier.<br/>
                            <span *ngIf="filterDateRange != undefined && filterDateRange.length != 0">
									Du søger bl.a. i perioden <span
                                    [innerHTML]="(filterDateRange[0] | mdate: 'DD/MM/YYYY') + '-' + (filterDateRange[1] | mdate: 'DD/MM/YYYY')"></span>
								</span>
                        </p>
                    </td>
                </tr>
                <!-- Empty -->
                <tr *ngIf="(tableData == undefined || tableData.length == 0) && !isLoadingTable && !isFilterOn()">
                    <td [attr.colspan]="getSetupList().length" class="table-empty">
                        <p>
                            {{lang.la.table_empty}}
                        </p>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-sm-6">
        <p class="text-is-grey"
           *ngIf="!isLoadingTable && assignmentStats != undefined && assignmentStats.total_assignments != undefined && assignmentStats.total_assignments != '0'">
            <b>I alt</b><br/>
            {{assignmentStats.total_assignments}} opgaver, heraf {{assignmentStats.total_approved_assignments}}
            godkendte opgaver<br/>
            {{utilService.onConvertMiliToHoursAndMili(assignmentStats.assignment_execution_time_spend)}} godkendte timer<br/>
            {{assignmentStats.assignment_execution_drive_distance}} godkendte km kørsel<br/>
            {{assignmentStats.assignment_execution_expenses| currency: 'DKK' | formatedcurrency}} godkendte udlæg<br/>
            for perioden: {{assignmentStatsPeriodStart}} - {{assignmentStatsPeriodEnd}}<br/>
        </p>
    </div>
    <div class="col-sm-6">
        <!-- Pagination -->
        <nav *ngIf="tableSettings != undefined && tableSettings.tableOffset.highest_offset != 1 && tableSettings.tableOffset.highest_offset != 0">
            <app-pagination [tableSettings]="tableSettings" (pageChange)="onPageChange($event)" (goToFirstPage)="onPageChangeFirst()" (goToLastPage)="onPageChangeLast()"></app-pagination>
        </nav>
    </div>
</div>


