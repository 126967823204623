import {User} from "./user";

export class AssignmentExecutions {

    public primary_key: string = "ssignment_execution_id";

    assignment_id: number = 0;
    assignment_execution_id: number = 0;
    assignment_execution_comments;
    string;
    assignment_execution_drive_distance: string;
    assignment_execution_expenses: string;
    assignment_execution_start_time: string;
    assignment_execution_end_time: string;
    assignment_execution_time_spend: number;
    assignment_execution_creation: string;
    assignment_execution_updated: string;
    assignment_execution_approved: number; //True og false
    salary_been_given?: number; //True og false
    invoice_been_run?: number; //True og false
    salary_given_at?: Date;
    invoice_given_at?: Date;
    user_id: string;
    users: User;

    public getPostObject() {
        var obj = this;
        delete obj.primary_key;
        delete obj.users;
        delete obj.user_id;
        delete obj.assignment_execution_creation;
        delete obj.assignment_execution_updated;
        return JSON.stringify(obj);
    }


    public getPatchObject() {
        var obj = this;
        delete obj.primary_key;
        delete obj.users;
        delete obj.user_id;
        delete obj.assignment_id;
        delete obj.assignment_execution_creation;
        delete obj.assignment_execution_updated;
        return JSON.stringify(obj);
    }
}