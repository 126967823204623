import {Component, OnInit} from '@angular/core';
import {UtilService} from '../services/util.service';
import {DataService} from '../services/data.service';
import {ApiService} from '../services/api.service';
import {LangService} from '../services/lang.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '../models/store';
import {InputString} from '../models/inputstring';
import {NotificationService} from '../shared/notification.service';
import {TableSettings} from '../models/tablesettings';
import {Location} from "@angular/common";

@Component({
    selector: 'app-store-create',
    templateUrl: './store-create.component.html',
    styleUrls: ['./store-create.component.scss']
})
export class StoreCreateComponent implements OnInit {

    isLoading: boolean = false;
    isLoadingView: boolean = false;

    inputName: InputString = new InputString();
    inputPhoneNo: InputString = new InputString();
    inputChain: InputString = new InputString();
    inputAddress: InputString = new InputString();
    inputPostalcode: InputString = new InputString();
    inputCity: InputString = new InputString();

    //Editing
    isEditing: boolean = false;
    isEditingId: number = -1;

    constructor(
        public apiService: ApiService,
        public route: ActivatedRoute,
        private router: Router,
        public utilService: UtilService,
        public lang: LangService,
        public notificationService: NotificationService,
        public dataService: DataService,
        private location: Location,
    ) {
    }

    ngOnInit() {
        this.onCheckIfEditing();
    }

    onCheckIfEditing() {
        this.isEditingId = this.route.snapshot.params['id'];
        if (this.isEditingId != undefined) {
            this.isEditing = true;
            this.isLoadingView = true;

            var ts: TableSettings = new TableSettings();
            ts.columns = "store_name,store_phone,store_address,store_city,store_postal_code,chain_id,chains.chain_name";

            this.apiService.getStore(ts, this.isEditingId).subscribe((data) => {
                if (data.main[0] != undefined) {
                    this.onSetInputs(data.main[0]);
                }
                this.isLoadingView = false;
            }, (error) => {
                this.isLoadingView = false;
            });
        }
    }

    resetInputs() {
        this.onSetInputs(new Store());
        this.inputChain = new InputString();
        window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
    }

    onSetInputs(store: Store) {
        this.inputName.value = store.store_name;
        this.inputPhoneNo.value = store.store_phone;
        this.inputChain.value = "" + store.chain_id;
        this.inputAddress.value = store.store_address;
        this.inputPostalcode.value = store.store_postal_code;
        this.inputCity.value = store.store_city;

    }

    onCheckForms(): boolean {
        return this.utilService.checkMultipleIfvalid(this.inputChain, this.inputName, this.inputAddress, this.inputPostalcode, this.inputCity);
    }

    onSetData(): Store {
        var s: Store = new Store();
        if (this.isEditingId != -1) {
            s.store_id = this.isEditingId;
        }
        s.store_name = this.inputName.value;
        s.store_phone = this.inputPhoneNo.value;
        s.store_address = this.inputAddress.value;
        s.store_postal_code = this.inputPostalcode.value;
        s.store_city = this.inputCity.value;
        s.chain_id = +this.inputChain.value;
        return s;
    }

    onCreateStore() {
        if (this.onCheckForms()) {
            this.notificationService.setNotification(this.lang.la.error_form_invalid, this.notificationService.CONSTANT_TYPE_WARNING);
            return;
        }

        this.isLoading = true;

        this.apiService.postStore(this.onSetData()).subscribe((response) => {
            this.isLoading = false;
            // @ts-ignore
            this.notificationService.showCreateNotification('Butik', response.data.store_id, '/app/store/show', 10000);
            this.resetInputs();
        }, (error) => {
            this.notificationService.setNotification(error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
            this.isLoading = false;
        });
    }

    onEditStore() {
        if (this.onCheckForms()) {
            this.notificationService.setNotification(this.lang.la.error_form_invalid, this.notificationService.CONSTANT_TYPE_WARNING);
            return;
        }

        this.isLoading = true;
        this.apiService.postStoreEdit(this.onSetData()).subscribe((response) => {
            this.isLoading = false;
            this.notificationService.setNotification(this.lang.la.success_updated, this.notificationService.CONSTANT_TYPE_NORMAL);
            // @ts-ignore
            this.router.navigate(['app/store/list/']);
        }, (error) => {
            this.notificationService.setNotification(error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
            this.isLoading = false;
        });
    }

    onGoBack() {
        this.location.back();
    }
}
