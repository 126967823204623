<ul class="pagination justify-content-md-end justify-content-center">
    <li class="page-item d-none d-md-block" (click)="onPageChangeFirst()" *ngIf="(tableSettings.page) > 3"><a class="page-link">{{lang.la.first}}</a></li>
    <li class="page-item" [ngClass]="{'disabled': tableSettings.page == 1}" (click)="onPageChange(-1)"><a class="page-link">{{lang.la.previous}}</a></li>

    <li class="page-item disabled d-none d-md-block" *ngIf="(tableSettings.page) >= 5"><span class="page-link">..</span></li>
    <li class="page-item d-none d-md-block"><a class="page-link" (click)="onPageChange(-3)" *ngIf="tableSettings.page >= 4">{{tableSettings.page - 3}}</a></li>
    <li class="page-item d-none d-md-block"><a class="page-link" (click)="onPageChange(-2)" *ngIf="tableSettings.page >= 3">{{tableSettings.page - 2}}</a></li>
    <li class="page-item "><a class="page-link" (click)="onPageChange(-1)" *ngIf="tableSettings.page >= 2">{{tableSettings.page - 1}}</a></li>
    <li class="page-item disabled"><a class="page-link" (click)="onPageChange(0)">{{tableSettings.page}}</a></li>
    <li class="page-item "><a class="page-link" (click)="onPageChange(1)" *ngIf="(tableSettings.page+1) <= tableSettings.tableOffset.highest_offset">{{tableSettings.page + 1}}</a></li>
    <li class="page-item d-none d-md-block"><a class="page-link" (click)="onPageChange(2)" *ngIf="(tableSettings.page+2) <= tableSettings.tableOffset.highest_offset">{{tableSettings.page + 2}}</a></li>
    <li class="page-item d-none d-md-block"><a class="page-link" (click)="onPageChange(3)" *ngIf="(tableSettings.page+3) <= tableSettings.tableOffset.highest_offset">{{tableSettings.page + 3}}</a></li>
    <li class="page-item disabled d-none d-md-block" *ngIf="(tableSettings.page+4) <= tableSettings.tableOffset.highest_offset"><span class="page-link">..</span></li>

    <li class="page-item" (click)="onPageChange(1)" [ngClass]="{'disabled': tableSettings.page == tableSettings.tableOffset.highest_offset}"><a class="page-link">{{lang.la.next}}</a></li>
    <li class="page-item d-none d-md-block" (click)="onPageChangeLast()" *ngIf="(tableSettings.page) != tableSettings.tableOffset.highest_offset"><a class="page-link">{{lang.la.last}}</a></li>
</ul>