import {Chain} from "./chain";

export class Store {

    public primary_key: string = "store_id";


    public store_id: number;
    public store_name: string;
    public chain_id: number;
    public chains: Chain;
    public store_phone: string;
    public store_address: string;
    public store_postal_code: string;
    public store_city: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }

    public getPostJson() {
        return JSON.stringify({
            store_name: this.store_name,
            store_phone: this.store_phone,
            store_address: this.store_address,
            store_postal_code: this.store_postal_code,
            store_city: this.store_city,
            chain_id: this.chain_id,
        })
    }

    public getPatchJson() {
        return JSON.stringify({
            store_id: this.store_id,
            store_name: this.store_name,
            store_phone: this.store_phone,
            store_address: this.store_address,
            store_postal_code: this.store_postal_code,
            store_city: this.store_city,
            chain_id: this.chain_id,
        })
    }
}