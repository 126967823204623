import {Injectable} from '@angular/core';
import {LangService} from "../services/lang.service";

@Injectable()
export class NotificationService {

    public CONSTANT_TYPE_NORMAL = 0;
    public CONSTANT_TYPE_WARNING = 1;
    public CONSTANT_TYPE_SUCCESS = 2;

    public notificationState: boolean = false;
    public notificationText: string = "";
    public notificationType: number = 0;

    public createNotification: any;
    public showCreateNotificationView: boolean = false;

    constructor(private lang: LangService) {

    }

    public setNotification(text: string, type: number, removeDelay: number = 5000) {
        this.notificationText = text;
        this.notificationType = type;
        this.notificationState = true;

        this.removeOnDelay(removeDelay);
    }

    public removeOnDelay(removeDelay) {
        if (removeDelay > 0) {
            setTimeout(() => {
                this.removeNotification();
            }, removeDelay);
        }
    }

    public showCreateNotification(model: string, id: number, route: string, removeDelay: number = 5000, showCopy: boolean = false) {
        this.createNotification = {
            model: model,
            id: id,
            showCopy: showCopy,
            route: route
        };
        this.showCreateNotificationView = true;

        this.removeOnDelay(removeDelay);
    }

    public removeNotification() {
        this.notificationState = false;
        this.showCreateNotificationView = false;
        this.createNotification = {};
    }

    public setImageUploadErrorNotification(message = 'Opgaven er gemt, men en eller flere billeder kunne ikke vedhæftes.'){
        this.setNotification(message + '<br /><br />'+this.lang.la.image_restrictions_explanation, this.CONSTANT_TYPE_WARNING, 30000);
    }
}
