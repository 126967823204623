import {Constants} from "../constants";

export class Image {
    public image_id: number;
    public image_link: string;
    public image_alternative: string;
    public image_description: string;
    public image_hash: string;
    public appendix_number: number;
    public image_active: number;

    //Local
    public image_file: File;

    public getImage() {
        return Constants.IMAGE_URL + this.image_file;
    }

    public getPostObject() {
        var obj = this;
        return JSON.stringify(obj);
    }

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}