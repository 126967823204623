<div class="row">
    <div class="col-sm-9 col-12 mb-3">
        <form (submit)="onSearchPressed()">
            <div class="input-group search-forms">
                <div class="input-cancel-button input-has-appended-items search-table-input">
                    <input type="text" class="form-control search-input" placeholder="Søg her"
                           [(ngModel)]="tableSettings.search" (change)="setSearch(tableSettings.search)"
                           name="tableSettings.search">
                    <img src="./assets/ic_cancel.svg" alt="" *ngIf="tableSettings.search.length != 0"
                         (click)="onClearSearch()">
                </div>

                <div class="input-group-append input-group-dropdown search-table-dropdown">
                    <button class="btn btn-outline-secondary dropdown-toggle btn-is-form-multiple"
                            (focus)="isDropdownSearch = true"
                            (blur)="onBlurSearch()"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            type="button">{{tableSettings.searchColumn.value}}</button>

                    <div class="dropdown-menu dropdown-table" [ngClass]="{'show': isDropdownSearch}">
                        <a class="dropdown-item" *ngFor="let tableHeader of tableSettings.tableHeaders; let pos = index"
                           (click)="onSearchSelect(pos)">
                            {{tableHeader.value}}
                        </a>
                    </div>

                    <button class="btn btn-outline-secondary btn-is-form-multiple-right" type="button"
                            (click)="onSearchPressed()">
                        Søg
                    </button>
                </div>

            </div>
        </form>
    </div>

    <div class="col-sm-3 col-12 mb-3 has-text-right" *ngIf="tableSettings.isShowingDeactivated">
        <button class="btn btn-outline-secondary" type="button" (click)="onShowDeactivated()"
                [ngClass]="{'is-active': tableSettings.tableSettingsEquals[0].value == '0'}">
            <span *ngIf="tableSettings.tableSettingsEquals[0].value == '1'">{{lang.la.show_deactivated}}</span>
            <span *ngIf="tableSettings.tableSettingsEquals[0].value == '0'">{{lang.la.show_active}}</span>
        </button>
    </div>
</div>