export class ExportListItem {

    public primary_key: string = "export_id";

    public export_id: number;
    public user_id: number = -1;
    public customer_id: number = -1;
    public name: string;
    public type: string;
    public export_json: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}