export var da = {

    //Util
    "choose": "Vælg...",
    "date": "Dato",
    "time": "Tid",
    "ca": "Ca.",
    "hours": "Timer",
    "create": "Opret",
    "edit": "Rediger",
    "delete": "Slet",
    "active": "Sæt aktiv",
    "import": "Importer",
    "save_skabelon": "Gem skabelon",
    "copy": "Kopier",
    "cancel": "Fortryd",
    "search": "Søg..",
    "search_btn": "Søg",
    "areyousure": "Er du sikker?",
    "inactive": 'Deaktiver',

    "role": "Rolle",
    "id": "ID",

    "chain": "Kæde",
    "name": "Navn",
    "storeno": "Butiksnr",
    "address": "Adresse",
    "postalcode": "Postnr.",
    "city": "By",

    "first": "Første",
    "last": "Sidste",

    "login": "Log ind",

    "my_profile": "Mine oplysninger ",

    "send": "Send",
    "send_email_sms": "Send email/SMS",
    "write_message_here": "Skriv din besked her",
    "smser": "SMS'er",
    "employee_selected": "medarbejdere valgt",
    "send_sms": "Send SMS",
    "send_email": "Send email",
    "deselect_all_mercer": "Fravælg alle mercer",
    "select_all_mercer": "Vælg alle mercer",
    "deselect_all_demo": "Fravælg alle demo",
    "select_all_demo": "Vælg alle demo",
    "deselect_all": "Fravælg alle",
    "select_all": "Vælg alle",

    "previous": "Forrige",
    "next": "Næste",
    "update": "Opdater",

    "assignment": "Opgave",

    "firstname": "Fornavn",
    "surname": "Efternavn",
    "email": "Email",
    "mercer": "Mercer",
    "demo": "Demo",
    "create_store": "Opret butik",
    "edit_store": "Rediger butik",
    "stores": "Butikker",
    "save_customer": "Gem",

    "historic": "Historik",

    "formerly_added_appendix": "Tidligere tilføjede udlæg",
    "formerly_added_images": "Tidligere tilføjede billeder",
    "upload_files": "Træk filer over eller tryk her",
    "user": "Bruger",

    "street": "Vej",
    "image": "Billede",

    "added": "tilføjet",
    "add_image_here": "Tilføj billede her",
    "image_exists_already": "Billede eksisterer allerede",

    "customer": "Kunde",
    "customers_maybe": "Kunde(r)",
    "chains": "Kæder",
    "create_chain": "Opret kæde",
    "edit_chain": "Rediger kæde",

    "contactperson": "Kontaktperson",
    "contactperson_position": "Kontaktpersons titel",
    "position": "Position",
    "drivingdate": "Kørselsdato",

    "customerandsales": "Kunde og salgskonsulent",
    "employee": "Medarbejder",
    "customers": "Kunder",
    "customersoverview": "Kunder",
    "create_customer": "Opret kunde",
    "edit_customer": "Rediger kunde",

    "employees": "Medarbejdere",
    "salesconsultant": "Salgskonsulenter",
    "teamplayers": "Teamplayere",
    "customerusers": "Kundebrugere",
    "create_user": "Opret bruger",
    "edit_user": "Rediger bruger",

    "afregning": "Afregning",
    "salaryandinvoice": "Løn- og fakturakørsel",
    "createexport": "Lav en eksport",

    "phoneno": "Tlf.nr.",
    "driving": "Kørsel",
    "comment": "Kommentar",
    "timeconsumed": "Tidsforbrug",
    "udlaeg": "Udlæg",
    "salary_give_at": "Lønkørsel",
    "invoice_give_at": "Faktura givet på",
    "images": "Billeder",
    "appendix": "Bilag",
    "expenditures": "Udlæg",
    "approve": "Godkend",
    "time_start": "Tidspunkt start",
    "time_end": "Tidspunkt slut",
    "pos_material": "POS-materiale",
    "place_in_store": "Placering i butik",

    "yes": "Ja",
    "no": "Nej",


    "hour": "Time",
    "minut": "Minut",

    "change_password_success": "Din adgangskode er succesfuldt ændret",
    "change_password_fail": "Din adgangskode kunne ikke ændres. Dette kan skyldes at du allerede har ændret adgangskoden.",

    "get_new_code": "Få en ny kode",
    "get_new_code_success": "En email med hvordan du sætter en ny kode er sendt til dig.",
    "go_to_login": "Gå til log ind",
    "get_new_code_email": "Skriv din e-mail for at få en ny kode",

    "duration": "Varighed",
    "goods": "Varer",
    "contact_person": "Kontaktperson",
    "assigned_users": "Tilknyttede brugere",
    "selected_users_for_assignment": "Udbudt til følgende brugere",
    "assigned_users_picked": "Valgt bruger der udfører opgaven",
    "assign": "Tilknyt",

    "supply": "Udbyd",
    "supply_descr": "til de valgte medarbejdere",
    "assign_to": "Tildel",
    "assign_to_descr": "og accepter på vegne af medarbejder",
    "save": "Gem",
    "save_descr": "til senere udbydelse",

    "add_filter": "Tilføj filter",
    "setting_up_a_table": "Opsætning af tabellen",
    "save_settings": "Gem opsætning",

    "export_to_excel": "Eksporter til Excel",
    "export_to_danloen": "Eksporter til Danløn",
    "create_an_export": "Lav en eksport",
    "customer_slash_employee": "Kunde / medarbejder",
    "period": "Periode",

    "select_a_period": "Vælg en periode",

    "status": "Status",
    "all": "Samtlige",
    "created_awaiting_approval": "Oprettet af kunde - afventer accept",
    "created_awaiting_info": "Oprettet - afventer endelig info",
    "suppplied_waiting_employee": "I udbud - afventer medarbejder",
    "assigned_employee_awaiting_report": "Tildelt medarbejder - afventer afrapportering",
    "awaiting_approval": "Afventer godkendelse",
    "approved": "Godkendt",

    "indstillinger": "Indstillinger",
    "indstillinger_1": "Marker eksporterede opgaver som værende kørte",
    "indstillinger_2": "Eksporter kun opgaver, der ikke tidligere har været kørt",

    "former_exports": "Tidligere eksporter",
    "create_export": "Opret eksport",

    "show_deactivated": "Vis deaktiverede",
    "show_active": "Vis aktive",

    "first_name": "Fornavn",
    "jobposition": "Stilling",
    "district": "Distrikt",
    "sur_name": "Efternavn",
    "cprnumber": "CPR-nummer",
    "reg_acc": "Reg.nr.",
    "acc_no": "Kontonr.",
    "select_contact_options": "Vælg kontaktmulighed",
    "sms": "SMS",

    "profile_tshirt": "Mål omkring bryst - til valg af t-shirt størrelse:",
    "password": "Adgangskode",
    "password_again": "Adgangskode (igen)",
    "change_password": "Ændre Adgangskode",

    "danloen": "Medarbejder-ID (Danløn)",
    "projectmanagement": "Projektstyring",

    "mercer_tax": "Timetakst (mercer)",
    "project_tax": "Projektstyrings-takst",
    "demo_tax": "Timetakst (demo)",
    "can_create_projects": "Kan lave projektstyring",

    "cooperation_accepted": "Samarbejdsaftale godkendt",
    "print_cooperation": "Udskriv samarbejdsaftalen",
    "press_to_print_coop": "Print samarbejdsaftalen",
    "cooperation_deal": "Samarbejdsaftale",
    "breastsize_pick": "Mål omkring bryst - til valg af t-shirt størrelse:",
    "delievering": "Levering",
    "packshop": "Pakkeshop",
    "delievering_on_info": "Levering på adresse angivet i dine oplysninger",
    "notes": "Noter",
    "notes_only_tp": "Noter (Kun Teamplayers)",
    "only_visible_by_teamplayer": "(kan kun ses af teamplayere)",

    "user_type": "Brugertype",

    // News
    "news_read_all": "Du har læst alle nyheder",
    "created_at": "Oprettet den",
    "load_more": "Hent flere",
    "news_press_read": "Tryk for læst",
    "news_press_unread": "Tryk for ulæst",
    "create_news": "Opret nyhed",
    "edit_news": "Rediger nyhed",
    "headline": "Overskrift",
    "your_news": "Din nyhed",

    // Assignment list
    "assignment_list": "Opgaveliste",
    "former_done_assignments": "Tidligere udførte opgaver",
    "assignments_for_report": "Opgaver til afrapportering",
    "upcoming_assignments": "Kommende opgaver",
    "filter": "Filter",
    "settings": "Opsætning",
    "type": "Opgavetype",
    "timeroom": "Tidsrum",
    "select_timeroom": "Vælg tidsrum",
    "store": "Butik",
    "amount_at_a_time": "Mængde ad gangen",
    "search_store": "Søg butik...",
    "accept_assignment": "Accepter opgave",
    "accept_assignment_descr": "Ønsker du at acceptere opgaven?",
    "save_assignment": "Gem opgave",

    //Appendix
    "create_report_now": "Afrapporter",
    "create_ex": "Opret afrapporteringen",
    "appendix_step1_pos_btn": "Gå til upload",
    "upload_images": "Upload billede(r)",
    "skip": "Spring over",
    "skip_and_finish": "Spring over og afslut",
    "upload_appendix_and_finish": "Upload bilag og afslut",

    //Assignment create
    "pick_salesconsultant_before": "(Du skal vælge kunde før du kan vælge salgskonsulent)",
    "freeorfixedtime": "Frit eller fast tidspunkt?",

    //Assignment
    "modal_delete_descr": "Er du sikker på, du vil slette?",
    "modal_delete_title": "Du er i gang med at slette",
    "modal_deactivate_title": "Du er i gang med at deaktivere",
    "modal_deactivate_descr": "Er du sikker på, du vil deaktivere?",

    "edit_report_from": "Rediger afrapportering oprettet ",
    "report": "Afrapportering",
    "edit_report": "Rediger udførelse",

    "assignment_saved": "Denne opgave er tilføjet som 'Gemt'",
    "edit_assignment": "Rediger opgaven",

    //Succes
    "success_cooperation_deal": "Du har accepteret samarbejdsaftalen",
    "success_already_logged_in": "Du er allerede logget ind.",
    "success_created": "Succesfuldt oprettet",
    "success_updated": "Succesfuldt opdateret",
    "success_deleted": "Succesfuldt slettet",

    //Errors
    "error_form_startandenddate_invalid": "Vælgte slut dato skal være senere end start dato.",
    "error_form_invalid": "Et eller flere felter mangler eller har fejl",
    "error_general_message": "Der skete en fejl. Fejl: ",
    "error_general": "Der skete en fejl. Prøv igen",
    "error_fileupload": "Der skete en fejl i dit upload af et billede. Prøv igen",

    //Table
    "table_empty": "Kunne ikke finde noget",

    //Dashboard
    "dashboard_frontpage": "Nyheder",
    "dashboard_createassignment": "Opret opgave",
    "dashboard_assignmentlist": "Opgaveliste",

    "dashboard_stores": "Butikker",
    "dashboard_stores_list": "Butikker",
    "dashboard_stores_create": "Opret butik",

    "dashboard_users": "Brugere",

    "dashboard_profile": "Mine oplysninger",
    "dashboard_calendar": "Kalender",
    "dashboard_businessintelligence": "Business Intelligence",
    "dashboard_logout": "Log ud",

    //News
    "news_title": "Nyheder",
    "news_archived_title": "Læste nyheder",

    //Assignment create
    "as_c_title": "Opret opgave",
    "as_c_title_editing": "Rediger opgave",
    "as_c_card_title": "Indtast informationer",
    "as_c_card_customer": "Kunde",
    "as_c_card_store": "Butik",
    "as_c_card_salesconsultant": "Salgskonsulent",
    "as_c_card_type": "Opgave type",
    "as_c_card_date": "Opgave start",
    "as_c_card_period_date": "Opgave slut",
    "as_c_card_time": "Tidspunkt start",
    "as_c_card_time_end": "Seneste afsluttet",
    "as_c_card_estimate": "Tidsestimat",
    "as_c_card_todo": "Skal gøres",
    "as_c_card_todo_filling": "Opfyldning",
    "as_c_card_todo_cleaning": "Rengøring",
    "as_c_card_todo_show": "Udstilling",
    "as_c_card_todo_deliveries": "Varebestilling",
    "as_c_card_todo_out": "Opsætning af stander",
    "as_c_card_todo_demo": "Demonstration",
    "as_c_card_descr": "Kommentar om opgave",
    "as_c_card_button_create": "Opret",

    "information_not_available": "Ingen information tilgængelig",

    "image_restrictions_explanation": "Godkendte filtyper er jpg og png., og filen må være maks. 16MB."
};
