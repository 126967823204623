import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TableHeader} from "../../models/tableheader";
import {UtilService} from "../../services/util.service";
import {LangService} from "../../services/lang.service";
import {NotificationService} from "../notification.service";

@Component({
    selector: 'app-import-previewer',
    templateUrl: './import-previewer.component.html',
    styleUrls: ['./import-previewer.component.scss']
})
export class ImportPreviewerComponent implements OnInit, OnChanges {
    @Input() type: string;
    @Input() csvHeaders: any[] = [];
    @Input() rowPages: any[] = [];
    @Input() helperData: any; // the object containing additional data to help validation (i.e. an array of stores to select a store from for assignments, if validation has failed)
    @Input() validateRow: Function;
    @Input() isLoading: boolean;
    @Input() isImported: boolean;
    @Input() isValidating: boolean;
    @Input() importProgressCount: number = 0;

    @Output() readyForImport: EventEmitter<boolean> = new EventEmitter();

    headers: TableHeader[] = [];
    currentPage: number = 0;
    totalRowCount: number = 0;

    constructor(
        public utilService: UtilService,
        public lang: LangService,
        public notificationService: NotificationService
    ) {
    }

    ngOnInit(): void {
        this.setTableHeaders();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.countRows();
    }

    setTableHeaders() {
        for (let [key, value] of Object.entries(this.csvHeaders)) {
            const header: TableHeader = new TableHeader(key, value);
            this.headers.push(header);
        }
    }

    changePage(page: number) {
        if (page >= this.rowPages.length || page < 0) return;

        this.currentPage = page;
    }

    getPagesWithMissingInfo() {
        let pagesWithMissingInfo = {};

        for (let page = 0; page < this.rowPages.length; page++) {
            for (let i = 0; i < this.rowPages[page].length; i++) {
                if (!this.validateRow(this.rowPages[page][i])) {
                    pagesWithMissingInfo[page + 1] = true;
                }
            }
        }

        return Object.keys(pagesWithMissingInfo);
    }

    validateAllPages() {
        const pagesWithMissingInfo = this.getPagesWithMissingInfo();

        if (pagesWithMissingInfo.length > 0) {
            // notify about missing information
            this.notificationService.setNotification('Venligst udfyld resterende info på de følgende sider: ' + pagesWithMissingInfo.toString(), 1);
            this.readyForImport.emit(false);
        } else {
            this.readyForImport.emit(true);
        }
    }

    countRows() {
        // reset before counting everything
        this.totalRowCount = 0;

        // count everything
        for (let i = 0; i < this.rowPages.length; i++) {
            this.totalRowCount = this.totalRowCount + this.rowPages[i].length;
        }
    }

    importProgress() {
        if (this.importProgressCount === 0) return '';

        return 'Importeret ' + this.importProgressCount.toString() + ' / ' + this.totalRowCount.toString();
    }
}
