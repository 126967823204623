<!-- Modal -->
<div class="modal fade modal-show" tabindex="-1" role="dialog" [ngClass]="{'modal-show': isSendModalActive}">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{lang.la.Send}}</h5>
                <button type="button" class="close" (click)="isSendModalActive = !isSendModalActive" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                Er du sikker på du vil sende til {{usersSelected.length}}
                medarbejder{{ (usersSelected.length > 1) ? 'e' : ''}}?
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="isSendModalActive = !isSendModalActive"
                        [ngClass]="{'is-loading': isSendingLoading}">Fortryd
                </button>
                <button type="button" class="btn btn-primary" (click)="onSend()"
                        [ngClass]="{'is-loading': isSendingLoading}">Send
                </button>
            </div>
        </div>
    </div>
</div>


<div class="row push-m-50-b">
    <div class="col-sm-8">
        <h2 class="float-left h2">{{lang.la.send_email_sms}}</h2>
    </div>
    <div class="col-sm-4 has-text-right">
    </div>
</div>
<div class="card push-m-20-b">
    <div class="card-body row">
        <div class="col-sm-4">
            <div class="form-group">
                <select class="form-control" (change)="actionType = $event.target.value">
                    <option value="sms">Send SMS</option>
                    <option value="email">Send Email</option>
                </select>
            </div>
        </div>
        <div class="col-12">
            <div class="form-group">
                <label>
                    {{lang.la.write_message_here}}
                </label>
                <input *ngIf="actionType === 'email'" [(ngModel)]="inputSubject" class="form-control mb-3" type="text"
                       placeholder="Subject">
                <textarea class="form-control" rows="4" maxlength="1377" [(ngModel)]="inputMessage"
                          (ngModelChange)="countNumberOfMessages($event)"></textarea>
            </div>
        </div>
        <div class="col-sm-4 has-text-left" *ngIf="actionType === 'sms'">
            {{inputMessage.length}} af {{inputMessageMax}}   ({{numberOfMessages}} af 3 SMS'er)
        </div>
        <div class="has-text-right" [ngClass]="actionType === 'sms' ? 'col-sm-8' :'col-sm-12'">
            <!-- <span class="push-m-10-r">
                {{inputMessage.length / 160 | number: '1.0-0' }} / 3 {{lang.la.smser}}
            </span> -->
            <span class="push-m-10-r">
				{{usersSelected.length}} {{'medarbejder' + (usersSelected.length > 1 ? 'e' : '') + ' valgt' }}
			</span>
            <button *ngIf="actionType ==='sms'" class="btn btn-primary ml-2"
                    (click)="onSendSMS()"
                    [disabled]="inputMessage.length == 0 || inputMessage.length > inputMessageMax"
            >{{lang.la.send_sms}}</button>
            <button *ngIf="actionType ==='email'" class="btn btn-primary ml-2"
                    [disabled]="inputMessage.length == 0 || inputSubject.length == 0"
                    (click)="onSendEmail()">{{lang.la.send_email}}</button>
        </div>
    </div>
</div>


<div class="row push-p-20-t">
    <div class="col-12">
        <app-search-table [(tableSettings)]="tableSettings" (refreshData)="getData()"></app-search-table>
    </div>

    <div class="col-12 mt-2 mt-sm-0">
        <button class="btn btn-outline-secondary mr-2 mb-2" (click)="onSelectAllByType('user_type_mercer')">
			<span *ngIf="isAllOfTypeSelected('user_type_mercer')">
				{{lang.la.deselect_all_mercer}}
			</span>
            <span *ngIf="!isAllOfTypeSelected('user_type_mercer')">
				{{lang.la.select_all_mercer}}
			</span>
        </button>
        <button class="btn btn-outline-secondary mr-2 mb-2" (click)="onSelectAllByType('user_type_demo')">
			<span *ngIf="isAllOfTypeSelected('user_type_demo')">
				{{lang.la.deselect_all_demo}}
			</span>
            <span *ngIf="!isAllOfTypeSelected('user_type_demo')">
				{{lang.la.select_all_demo}}
			</span>
        </button>
        <button class="btn btn-outline-secondary mr-2 mb-2" (click)="onSelectAllOnPage()">
			<span *ngIf="usersSelected.length == users.length">
				{{lang.la.deselect_all}}
			</span>
            <span *ngIf="usersSelected.length != users.length">
				{{lang.la.select_all}}
			</span>
        </button>
    </div>
</div>

<!-- Loading -->
<app-loading *ngIf="isLoadingView"></app-loading>

<div class="card card-top" *ngIf="!isLoadingView">
    <div class="card-body push-p-0-r push-p-0-t push-p-0-l">


        <div class="table-responsive">
            <table class="table table-hover table-striped">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Tilladelse</th>
                    <th scope="col" *ngFor="let tableHeader of tableSettings.tableHeaders; let pos = index"
                        (click)="onSortTable(pos)">
							<span class="push-m-5-r">
								{{tableHeader.value}}
							</span>

                        <!-- ASC OR DESC -->
                        <svg class="th-icon" fill="#6c757d" height="18" viewBox="0 0 24 24" width="18"
                             xmlns="http://www.w3.org/2000/svg"
                             *ngIf="tableSettings.sortBy.value == tableHeader.value && tableHeader.asc">
                            <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"/>
                            <path d="M0-.75h24v24H0z" fill="none"/>
                        </svg>
                        <svg class="th-icon" fill="#6c757d" height="18" viewBox="0 0 24 24" width="18"
                             xmlns="http://www.w3.org/2000/svg"
                             *ngIf="tableSettings.sortBy.value == tableHeader.value && !tableHeader.asc">
                            <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"/>
                            <path d="M0 0h24v24H0z" fill="none"/>
                        </svg>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let user of users; let pos = index" (click)="onSelectForSend(user)">
                    <td>
                        <input type="checkbox" name="selected" id="selected"
                               [disabled]="!canBeContactedByThisType(user)"
                               [checked]="userIsSelected(user)"
                               >
                    </td>
                    <td class="text-is-grey">
                        <i class="material-icons small-icon push-m-10-l"
                           [ngClass]="{'text-success': user.user_contact_on_email}">email</i>
                        <i class="material-icons small-icon push-m-10-l"
                           [ngClass]="{'text-success': user.user_contact_on_sms}">phone</i>
                    </td>
                    <td>{{user.user_firstname}} {{user.user_lastname}}</td>
                    <td>{{user.user_email}}</td>
                    <td>{{user.user_phone}}</td>
                    <td>{{user.user_address}}</td>
                    <td>{{user.user_postal_code}}</td>
                    <td>{{user.user_city}}</td>
                    <td>{{user.customer_list}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
