import {Component, ElementRef, OnInit, ViewChild} from '@angular/core'

@Component({
    selector: 'app-graph-circle',
    templateUrl: './graph-circle.component.html'
})
export class GraphCircleComponent implements OnInit {

    @ViewChild('appendToChildEl') appendToChildEl: ElementRef;

    slices: any[] = [
        {percent: 0.3, color: '#2278F3'},
        {percent: 0.3, color: '#38A5FC'},
        {percent: 0.4, color: '#69BBFD'},
    ];
    cumulativePercent: number = 0;

    getCoordinatesForPercent(percent) {
        const x = Math.cos(2 * Math.PI * percent);
        const y = Math.sin(2 * Math.PI * percent);
        return [x, y];
    }

    constructor() {
    }

    ngOnInit() {
        this.slices.forEach(slice => {

            // destructuring assignment sets the two variables at once
            var [startX, startY] = this.getCoordinatesForPercent(this.cumulativePercent);

            // each slice starts where the last slice ended, so keep a cumulative percent
            this.cumulativePercent += slice.percent;

            const [endX, endY] = this.getCoordinatesForPercent(this.cumulativePercent);

            // if the slice is more than 50%, take the large arc (the long way around)
            const largeArcFlag = slice.percent > .5 ? 1 : 0;

            // create an array and join it just for code readability
            const pathData = [
                `M ${startX} ${startY}`, // Move
                `A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY}`, // Arc
                `L 0 0`, // Line
            ].join(' ');

            var pathEl = document.createElementNS('http://www.w3.org/2000/svg', 'path');

            pathEl.setAttribute('d', pathData);
            pathEl.setAttribute('fill', slice.color);
            this.appendToChildEl.nativeElement.appendChild(pathEl);
        });

    }


}
