import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {LangService} from '../../services/lang.service';
import {FileUploader} from 'ng2-file-upload';
import {Constants} from '../../constants';
import {Image} from '../../models/image';
import {ApiService} from "../../services/api.service";
import {NotificationService} from "../notification.service";

@Component({
    selector: 'app-inputupload',
    templateUrl: './inputupload.component.html',
    styleUrls: ['./inputupload.component.scss']
})
export class InputUploadComponent implements OnInit {
    allowedMimeType = ['image/png', 'image/gif', 'image/jpeg'];

    @Output()
    imageUploaderChange: EventEmitter<FileUploader> = new EventEmitter<FileUploader>();

    @Input()
    isUploadActive: boolean = false;

    @Input()
    imageUploader: FileUploader = new FileUploader({url: Constants.URL, allowedMimeType: this.allowedMimeType});

    @Input() checkExisting: boolean = true;

    @Input()
    multiple: boolean = true;

    constructor(
        private sanitizer: DomSanitizer,
        public lang: LangService,
        private apiService: ApiService,
        private notificationService: NotificationService,
    ) {
    }

    ngOnInit() {
        this.imageUploader.onAfterAddingFile = (fileItem) => {
            fileItem.withCredentials = false;
            fileItem.file['imageimage'] = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(fileItem._file)));
            fileItem['uploaded'] = false;
            fileItem['error'] = false;
            fileItem['uploading'] = false;
            fileItem['errorText'] = "";
        };
    }

    fileOverBase(event) {
    }

    onRemoveAgain(pos) {
        this.imageUploader.queue.splice(pos, 1);
    }

    onAdd() {
        this.imageUploaderChange.emit(this.imageUploader);
        this.isUploadActive = false;
    }

    onCheckIfImageExists() {
        if (!this.checkExisting) return;

        // loop all images in the upload queue
        for (let index = 0; index < this.imageUploader.queue.length; index++) {

            // get the file and number of the file
            const image: Image = new Image();
            image.image_file = this.imageUploader.queue[index]._file;
            image.appendix_number = this.imageUploader.queue[index]['appendixNumber'];


            // check if image exists in the backend
            this.apiService.checkAppendixExists(image).then(response => {
                // if it does not matches remove from array of pictures and set notification
                if (response.data.exists) {
                    this.imageUploader.queue[index]['error'] = true;
                    this.imageUploader.queue[index]['errorText'] = 'Billede eksisterer ';
                } else {
                    this.imageUploader.queue[index]['error'] = false;
                    this.imageUploader.queue[index]['errorText'] = '';
                }
                // after we upload all the apendixes set the notifications
                if (index === this.imageUploader.queue.length - 1) {
                    let imagesNotificationMessage = '';
                    // get the name of the existing files
                    this.imageUploader.queue.forEach(image => {
                        if (image['error']) {
                            imagesNotificationMessage += '\n' + image._file.name;
                        }

                    });
                    if (imagesNotificationMessage.length > 0) {
                        this.notificationService.setNotification(`Bilag eksisterer allerede ${imagesNotificationMessage}`, this.notificationService.CONSTANT_TYPE_WARNING);
                    }
                }
                this.imageUploaderChange.emit(this.imageUploader);

            }).catch(err => {
            });
        }
    }
}
