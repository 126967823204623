import * as moment from 'moment';

export class Calendar {
    public id: number;
    public type: string;
    public title: string;
    public place: string;
    public start_date: string;
    public start_time: string;
    public end_date: string;
    public end_time: string;
    public color: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }

    public onGenerateForCalendarFull(): Object {
        return {
            id: this.id,
            type: this.type,
            title: this.title,
            place: this.place,
            start: moment(this.start_date + ' ' + this.start_time, 'YYYY-MM-DD HH:mm').format("YYYY-MM-DD HH:mm:00"),
            end: moment(this.end_date + ' ' + this.end_time, 'YYYY-MM-DD HH:mm').format("YYYY-MM-DD HH:mm:00"),
            editable: true,
            backgroundColor: this.color,
            borderColor: this.color
        }
    }

    public onParseForCalendarFull(event) {
        this.id = event['id'];
        this.type = event['type'];
        this.title = event['title'];
        this.place = event['place'];
        this.start_date = moment(event['start']).format('YYYY-MM-DD');
        this.start_time = moment(event['start']).format('HH:mm');
        this.end_date = moment(event['end']).format('YYYY-MM-DD');
        this.end_time = moment(event['end']).format('HH:mm');
        this.color = event['color'];
    }

    public getObject() {
        return {
            id: this.id,
            type: this.type,
            title: this.title,
            place: this.place,
            start_date: this.start_date,
            end_date: this.end_date,
            color: this.color
        }
    }
}
