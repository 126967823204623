import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'formatedcurrency'
})

export class CurrencyPipe implements PipeTransform {
    transform(value: any, format: string = ""): string {
        // Try and parse the passed value.
        if (value) {
            return value.replace('.', '');
        }

        // Otherwise, return the date formatted as requested.
        return value;
    }
}