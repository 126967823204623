import {TableHeader} from "./tableheader";
import {TableOffset} from "./tableoffset";
import {TableSettingsEquals} from "./tablesettingsequals";
import {TableSettingsContains} from "./tablesettingscontains";

export class TableSettings {

    public results: number = 20;

    public page: number = 1;

    public searchColumn: TableHeader = new TableHeader("", "");

    public search: string = "";

    public columns: string = "";

    public searchColumns: TableHeader[] = [];

    public sortBy: TableHeader = new TableHeader("", "");

    public tableHeaders: TableHeader[] = [];

    public tableOffset: TableOffset = new TableOffset();

    public tableSettingsContains: TableSettingsContains[] = [];

    public tableSettingsEquals: TableSettingsEquals[] = [];
    public tableSettingsNotEquals: TableSettingsEquals[] = [];

    public tableSettingsGreater: TableSettingsEquals[] = [];
    public tableSettingsLower: TableSettingsEquals[] = [];

    public isShowingDeactivated: boolean = false;

    constructor() {
    }
}