import {Component, OnInit} from '@angular/core';
import {UtilService} from '../services/util.service';
import {DataService} from '../services/data.service';
import {ApiService} from '../services/api.service';
import {LangService} from '../services/lang.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FileUploader} from 'ng2-file-upload';
import {Constants} from '../constants';
import {PapaParseService} from 'ngx-papaparse';
import {NotificationService} from '../shared/notification.service';


@Component({
    selector: 'app-store-import',
    templateUrl: './store-import.component.html',
    styleUrls: ['./store-import.component.scss']
})
export class StoreImportComponent implements OnInit {

    isImported: boolean = false;
    isLoading: boolean = false;
    isLoadingView: boolean = false;
    isError: boolean = false;
    isErrorMessage: string = "";

    isInvalidImageExist: boolean = false;
    imageUploader: FileUploader = new FileUploader({url: Constants.URL});

    csv: any = [];

    fileCsv: File;
    stores: any;
    storePages: any[] = [];
    chains: any[] = [];
    importID: number;

    columns: any = [];
    validation_columns: any = [];
    csvHeaders: any = [];

    private isValidating: boolean = false;
    private importSaveProgress: number = 0;

    constructor(private papa: PapaParseService,
                public apiService: ApiService,
                public route: ActivatedRoute,
                private router: Router,
                public utilService: UtilService,
                public lang: LangService,
                public dataService: DataService,
                private notificationService: NotificationService
    ) {
    }

    ngOnInit() {

        this.validation_columns = [
            {
                type: "store_name",
                title: "Butiksnavn",
                alias: "navn",
                required: true,
                picked_by: false
            },
            {
                type: "chain",
                title: "Kæde",
                alias: "kæde",
                required: true,
                picked_by: false
            },
            {
                type: "store_butik_nr",
                title: "Butiknummer",
                alias: "butiknummer",
                required: false,
                picked_by: false
            },
            {
                type: "store_address",
                title: "Addresse",
                alias: "addresse",
                required: true,
                picked_by: false
            },
            {
                type: "store_postal_code",
                title: "Postnummer",
                alias: "postnummer",
                required: true,
                picked_by: false
            },
            {
                type: "store_city",
                title: "By",
                alias: "by",
                required: true,
                picked_by: false
            },
            {
                type: "store_phone",
                title: "Telefonnummer",
                alias: "telefon",
                required: false,
                picked_by: false
            }
        ];

        this.csvHeaders = {
            "alreadyExistsAsStore": "Dublet?",
            "store_name": "Butiksnavn",
            "chain": "Kæde",
            "store_butik_nr": "Butiknummer",
            "store_address": "Addresse",
            "store_postal_code": "Postnummer",
            "store_city": "By",
        };


        //Uploader
        this.imageUploader.onAfterAddingFile = (fileItem) => {
            this.fileCsv = this.imageUploader.queue[0]._file;
            this.isLoadingView = true;
            let fileReader = new FileReader();
            fileReader.onload = (e) => {
                this.papa.parse(fileReader.result, {
                    complete: (results, file) => {
                        this.csv = results.data;

                        this.isLoadingView = false;
                    }
                });
            };
            fileReader.readAsText(fileItem._file);
        };
    }

    onUploadImport() {

        if (this.fileCsv == undefined) {
            this.notificationService.setNotification(this.lang.la.error_general, this.notificationService.CONSTANT_TYPE_WARNING);
            return;
        }

        // get mapped columns for the backend to map csv_headers to row values
        const mapped_columns = this.validation_columns.filter(col => col.csv_header);

        // Check if all required columns has been picked
        const missing_required = this.validation_columns.filter(col => col.required && col.picked_by === false).map(col => col.title);

        if (missing_required.length > 0) {
            // One or more required columns hasn't been chosen
            this.isError = true;
            this.isErrorMessage = 'Obligatoriske kolonner mangler: ' + missing_required.join(', ');
            return;
        }

        this.isLoading = true;

        this.isError = false;

        this.apiService.postImportValidation('stores', this.fileCsv, mapped_columns).subscribe((data) => {
            // @ts-ignore
            this.importID = data.import_id;
            this.isLoading = false;
            this.isValidating = true;

            // @ts-ignore
            this.storePages.push(data.data.stores);

            // @ts-ignore
            this.chains = data.data.chains;

            // if the validation is not complete continue untill complete
            if (!this.isImportValidationComplete(data)) {
                // @ts-ignore
                this.continueValidation(data.import_id, data.import_progress);
            } else {
                this.isValidating = false;
            }

        }, (error) => {

            this.isError = true;
            this.isErrorMessage = error.error.message;

            this.isLoading = false;
            this.isValidating = false;
        });
    }

    continueValidation(import_id, progress) {

        return this.apiService.postContinueImportValidation('stores', import_id, progress).subscribe((data) => {
            if (progress >= 1000) throw new Error('Prevented AJAX overflow');

            // @ts-ignore
            this.storePages.push(data.data.stores);

            if (!this.isImportValidationComplete(data)) {
                // @ts-ignore
                this.continueValidation(import_id, data.import_progress);
            } else {
                this.isValidating = false;
            }
        });
    }

    isImportValidationComplete(data) {
        return data.import_status == 'ready for import';
    }

    isRowValid(store) {
        let valid = true;

        if (store['alreadyExistsAsStore'] != undefined && store['alreadyExistsAsStore'].action == undefined) valid = false;
        if (!store['chain_id']) valid = false;

        return valid;
    }

    import(readyForImport) {
        if (!readyForImport) return;

        this.isLoading = true;
        this.continuousImport(0);
    }

    continuousImport(page) {
        // magic constant 21 is the amount of pages it takes to surpass 1000 records for a chunksize of 50
        if (page >= 21) {
            this.notificationService.setNotification('Der gik desværre noget galt med importen - for mange sider', 1);
            throw new Error('Too many pages to import');
        }

        this.apiService.postImport('stores', this.importID, this.storePages[page]).subscribe((data) => {

            // @ts-ignore
            this.importSaveProgress = data.import_progress;

            // @ts-ignore
            if (!data.success) {
                this.isError = true;
                this.notificationService.setNotification('Der gik desværre noget galt med importen', 1)
            } else {

                // @ts-ignore
                if (data.import_status === 'done') {
                    this.isLoading = false;
                    this.isImported = true;
                    this.notificationService.setNotification('Import fuldført - du er automatisk blevet sendt tilbage til opgavelisten', 0, 10000);
                    this.router.navigate(['app/store/list']);
                } else {
                    this.continuousImport(page + 1);
                }
            }

        }, (error) => {

            this.isError = true;
            this.isErrorMessage = error.error.message;

            this.isLoading = false;
        });
    }
}
