<h1 class="push-m-50-b h2">
    Business intelligence
</h1><br/>
<div class="row">

    <div class="col-6">
        <app-graph [graphList]="graphList"></app-graph>
    </div>

    <div class="col-6 has-centered-everything">
        <app-graph-circle></app-graph-circle>
    </div>

    <div class="col-6">
        <app-graph-line [graphList]="graphList2"></app-graph-line>
    </div>

    <div class="col-6">
        <app-graph [graphList]="graphList3"></app-graph>
    </div>

</div>
