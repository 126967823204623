import {User} from "./user";
import {Image} from "./image";
import {News} from "./news";

export class NewsForUsersMM {
    public primary_key: string = "news_for_user_connection_id";

    public news_for_user_connection_id: number;
    public user_id: number;
    public news_id: number;
    public news: News;
    public users: User;
    public creation: Date;
    public news_for_users_read: number;

    public image: Image;

    public getPostObject() {
        var object = this;
        delete object.primary_key;
        delete object.image;
        delete object.creation;
        return JSON.stringify(object);
    }

}