<svg width="460px" height="319px" viewBox="0 0 460 319" version="1.1" xmlns="http://www.w3.org/2000/svg"
>
    <defs></defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Artboard-2-Copy-4" transform="translate(-87.000000, -40.000000)">
            <g id="line-graph" transform="translate(87.000000, 38.000000)">

                <path d="M48.5, 8.5 L48.5, 290.515957" id="bg_line" stroke="#E6E6E6" stroke-linecap="square"></path>
                <path d="M237.5,8.5 L237.5, 290.515957" id="bg_line" stroke="#E6E6E6" stroke-linecap="square"></path>
                <path d="M111.5,8.5 L111.5, 290.515957" id="bg_line" stroke="#E6E6E6" stroke-linecap="square"></path>
                <path d="M300.5,8.5 L300.5,290.515957" id="bg_line" stroke="#E6E6E6" stroke-linecap="square"></path>
                <path d="M174.5,8.5 L174.5,290.515957" id="bg_line" stroke="#E6E6E6" stroke-linecap="square"></path>
                <path d="M363.5,8.5 L363.5,290.515957" id="bg_line" stroke="#E6E6E6" stroke-linecap="square"></path>
                <path d="M426.5,8.5 L426.5,290.515957" id="bg_line" stroke="#E6E6E6" stroke-linecap="square"></path>

                <polyline class="graph_line_line" stroke="#369FF4" stroke-width="2" stroke-linecap="square"
                          [attr.points]="graph.points"></polyline>

                <text id="side_1" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="0" y="290">{{graph.side_left[0]}}</tspan>
                </text>
                <text id="side_3" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="0" y="210">{{graph.side_left[2]}}</tspan>
                </text>
                <text id="side_6" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="0" y="90">{{graph.side_left[5]}}</tspan>
                </text>
                <text id="side_2" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="0" y="250">{{graph.side_left[1]}}</tspan>
                </text>
                <text id="side_4" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="0" y="170">{{graph.side_left[3]}}</tspan>
                </text>
                <text id="side_7" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="0" y="50">{{graph.side_left[6]}}</tspan>
                </text>
                <text id="side_5" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="0" y="130">{{graph.side_left[4]}}</tspan>
                </text>

                <text id="1" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="46.9411621" y="318">{{graph.graphs_elements_x[0]}}</tspan>
                </text>
                <text id="2" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="107.941162" y="318">{{graph.graphs_elements_x[1]}}</tspan>
                </text>
                <text id="3" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="170.941162" y="318">{{graph.graphs_elements_x[2]}}</tspan>
                </text>
                <text id="4" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="234.941162" y="318">{{graph.graphs_elements_x[3]}}</tspan>
                </text>
                <text id="5" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="297.941162" y="318">{{graph.graphs_elements_x[4]}}</tspan>
                </text>
                <text id="6" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="361.941162" y="318">{{graph.graphs_elements_x[5]}}</tspan>
                </text>
                <text id="7" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="422.941162" y="318">{{graph.graphs_elements_x[6]}}</tspan>
                </text>
            </g>
        </g>
    </g>
</svg>