import {Component, OnInit} from '@angular/core';
import {UtilService} from '../services/util.service';
import {DataService} from '../services/data.service';
import {ApiService} from '../services/api.service';
import {LangService} from '../services/lang.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Customer} from '../models/customer';
import {TableSettings} from '../models/tablesettings';

@Component({
    selector: 'app-customer',
    templateUrl: './customer.component.html',
    styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {

    isLoadingView: boolean = false;

    customer: Customer;
    customerId: number = 0;

    constructor(public apiService: ApiService, public route: ActivatedRoute, private router: Router, public utilService: UtilService, public lang: LangService, public dataService: DataService) {
    }

    ngOnInit() {
        this.customerId = this.route.snapshot.params['id'];
        this.getData();
    }

    getData() {
        this.isLoadingView = true;

        var ts: TableSettings = new TableSettings();
        ts.columns = "customer_id,customer_email,customer_name,customer_phone,customer_address,customer_city,customer_postal_code,customer_contact_name,customer_contact_position";

        this.apiService.getCustomer(ts, this.customerId).subscribe((data) => {
            if (data.main[0] != undefined) {
                this.customer = data.main[0];
            }
            this.isLoadingView = false;
        }, (error) => {
            this.isLoadingView = false;
        });
    }

}
