import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from '../services/user.service';

@Injectable({
    providedIn: 'root'
})
export class UserRoleGuard implements CanActivate {
    private allowedUsersRoles: Array<number> = [];

    constructor(public userService: UserService, private router: Router) {

    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        this.allowedUsersRoles = next.data['roles'] as Array<number>;
        if (this.allowedUsersRoles.includes(this.userService.user.user_role_id)) {
            return true;
        }
        this.router.navigate(['/app/news']);
        return false;
    }
}
