export var USER_RIGHT_TYPES = {
    ASSIGNMENT_CREATE: 0,
    ASSIGNMENT_LIST: 1,
    ASSIGNMENT_ACCEPT: 13,
    ASSIGNMENT_EDIT: 14,

    STORE_LIST: 2,

    USER_LIST: 3,

    EXPORT_LIST: 4,

    CUSTOMER_LIST: 5,

    CALENDAR: 6,

    BUSINESSINTELLIGENCE: 7,

    TYPE_PROJECTMANAGEMENT: 8,
    TYPE_NEWS_CREATE: 9,
    TYPE_ASSIGNMENT_ASSIGN: 10,
    TYPE_ASSIGMENT_EDIT: 11,
    TYPE_ASSIGMENT_DELETE: 12,
    TYPE_ASSIGMENT_COPY: 15,

    TYPE_USER_NOTES: 16,

    IS_TEAMPLAYER_ONLY: 17,
    CAN_VIEW_HISTORY: 18
};

export var Constants = {
    URL: "URL HERE",

    IMAGE_URL: '/api/images/',
    APPENDIX_URL: '/api/appendixes/',

    COMPLETE_DATE_TYPE_FORMAT: 'YYYY-MM-DD HH:mm',

    DATE_TYPE_FORMAT: 'YYYY-MM-DD',

    TIME_TYPE_FORMAT: 'HH:mm',

    USER_ROLE_EMPLOYEE_ID: 2,
    USER_ROLE_CONSULTANT_ID: 3,
    USER_ROLE_CUSTOMER_ID: 4,
    USER_ROLE_TEAMPLAYER_ID: 5,
    USER_ROLE_ADMIN_ID: 1,

    USER_ROLE_EMPLOYEE: "employee",
    USER_ROLE_CONSULTANT: "consultant",
    USER_ROLE_CUSTOMER: "customer",
    USER_ROLE_TEAMPLAYER: "teamplayer",
    USER_ROLE_ADMIN: "admin",

    USER_ROLES: {
        "employee": 2,
        "consultant": 3,
        "customer": 4,
        "teamplayer": 5,
        "Admin": 1
    },

    USER_ROLES_ID_TO_NAME: {
        2: "Medarbejder",
        3: "Salgskonsulent",
        4: "Kunde",
        5: "Teamplayer",
        1: "Admin"
    },


    /**
     * Add the rights for each employee here, and it automatically updates in the view
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    USER_RIGHTS: {
        2: [
            USER_RIGHT_TYPES.ASSIGNMENT_CREATE,
            USER_RIGHT_TYPES.ASSIGNMENT_LIST,
            USER_RIGHT_TYPES.CALENDAR,
            USER_RIGHT_TYPES.TYPE_ASSIGMENT_COPY
        ],
        3: [
            USER_RIGHT_TYPES.ASSIGNMENT_CREATE,
            USER_RIGHT_TYPES.ASSIGNMENT_LIST,
            USER_RIGHT_TYPES.CALENDAR,
            USER_RIGHT_TYPES.TYPE_ASSIGMENT_COPY
        ],
        4: [
            USER_RIGHT_TYPES.ASSIGNMENT_LIST,
            USER_RIGHT_TYPES.CALENDAR
        ],
        5: [ //TEAMPLAYER
            //Dashboard
            USER_RIGHT_TYPES.ASSIGNMENT_CREATE,
            USER_RIGHT_TYPES.ASSIGNMENT_LIST,
            USER_RIGHT_TYPES.STORE_LIST,
            USER_RIGHT_TYPES.USER_LIST,
            USER_RIGHT_TYPES.CUSTOMER_LIST,
            USER_RIGHT_TYPES.CALENDAR,

            //Single
            USER_RIGHT_TYPES.TYPE_PROJECTMANAGEMENT,
            USER_RIGHT_TYPES.TYPE_NEWS_CREATE,
            USER_RIGHT_TYPES.TYPE_ASSIGNMENT_ASSIGN,
            USER_RIGHT_TYPES.TYPE_ASSIGMENT_EDIT,
            USER_RIGHT_TYPES.TYPE_ASSIGMENT_DELETE,
            USER_RIGHT_TYPES.TYPE_ASSIGMENT_COPY,

            USER_RIGHT_TYPES.ASSIGNMENT_ACCEPT,
            USER_RIGHT_TYPES.ASSIGNMENT_EDIT,
            USER_RIGHT_TYPES.TYPE_USER_NOTES,

            USER_RIGHT_TYPES.IS_TEAMPLAYER_ONLY,
            USER_RIGHT_TYPES.CAN_VIEW_HISTORY
        ],
        1: [ //Admin
            //Dashboard
            USER_RIGHT_TYPES.ASSIGNMENT_CREATE,
            USER_RIGHT_TYPES.ASSIGNMENT_LIST,
            USER_RIGHT_TYPES.STORE_LIST,
            USER_RIGHT_TYPES.USER_LIST,
            USER_RIGHT_TYPES.EXPORT_LIST,
            USER_RIGHT_TYPES.CUSTOMER_LIST,
            USER_RIGHT_TYPES.CALENDAR,
            USER_RIGHT_TYPES.BUSINESSINTELLIGENCE,

            //Single
            USER_RIGHT_TYPES.TYPE_PROJECTMANAGEMENT,
            USER_RIGHT_TYPES.TYPE_NEWS_CREATE,
            USER_RIGHT_TYPES.TYPE_ASSIGNMENT_ASSIGN,
            USER_RIGHT_TYPES.TYPE_ASSIGMENT_EDIT,
            USER_RIGHT_TYPES.TYPE_ASSIGMENT_DELETE,
            USER_RIGHT_TYPES.TYPE_ASSIGMENT_COPY,

            USER_RIGHT_TYPES.ASSIGNMENT_ACCEPT,
            USER_RIGHT_TYPES.ASSIGNMENT_EDIT,
            USER_RIGHT_TYPES.TYPE_USER_NOTES,

            USER_RIGHT_TYPES.IS_TEAMPLAYER_ONLY,
            USER_RIGHT_TYPES.CAN_VIEW_HISTORY
        ],
    },


    /**
     * Parameters used when calling the API
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    PARAMETER_IMAGES: "image_id,images.image_id,images.image_link,images.image_description",
    PARAMETER_IMAGES_ONLY_ACTIVE: "image_id,images.image_id,images.image_link,images.image_description,images.image_active",

    /**
     * Assignment todos
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    ASSIGNMENT_TODO_EXHIBITION: 1,
    ASSIGNMENT_TODO_REFILL: 2,
    ASSIGNMENT_TODO_CLEANING: 3,
    ASSIGNMENT_TODO_DEMOSTRATION: 4,
    ASSIGNMENT_TODO_STOCKORDER: 5,
    ASSIGNMENT_TODO_SETTINGUP: 6,

    TODO_NAME: [
        "",
        "Udstilling",
        "Opfyldning",
        "Rengøring",
        "Demonstration",
        "Varebestilling",
        "Opsætning af stander",
    ],

    ASSIGNMENT_STATUS_AWAITING_EMPLOYEE_ACCEPT: "afventer-medarbejder-accept",
    ASSIGNMENT_STATUS_AWAITING_EXECUTION: "afventer-medarbejder-udfyldelse",
    ASSIGNMENT_STATUS_GEM: "adhoc",
    ASSIGNMENT_STATUS_AWAITING_ACCEPT: "afventer-godkendelse",
    ASSIGNMENT_STATUS_ACCEPTED: "godkendt"
};
