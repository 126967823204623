<div class="row push-m-50-b">
    <ng-container
            *ngIf="userService.user.user_role_id === Constants.USER_ROLE_ADMIN_ID || userService.user.user_role_id === Constants.USER_ROLE_TEAMPLAYER_ID">
        <div class="col-6">
            <!-- <h2 class="float-left h2">{{lang.la.user}} </h2> -->
            <h2 class="float-left h2"
                *ngIf="user != undefined">{{utilService.onUppercase(user.user_roles.user_role_name)}} </h2>
        </div>
        <div class="col-6 has-text-right">

            <button [routerLink]="['../../edit/', showId]"
                    class="btn btn-outline-secondary ml-2 mb-2">{{lang.la.edit}}</button>
            <button *ngIf="userService.userOriginal === undefined && user != undefined && user.user_active === 1"
                    class="btn btn-primary ml-2 mb-2" [disabled]="user.user_active && user.user_active === 0"
                    (click)="onCustomPressed()">
                {{user.user_active === 1 ? 'Log ind som' : 'Deaktiveret'}}
            </button>
        </div>
    </ng-container>
</div>

<!-- Loading -->
<app-loading *ngIf="isLoadingView"></app-loading>

<!-- Content -->
<div class="card push-m-20-b" *ngIf="!isLoadingView">
    <div class="card-body row pb-0 pb-sm-auto">
        <div class="col-sm-12">
            <div class="float-sm-left float-none text-center" *ngIf="user.images != undefined">
                <img [src]="utilService.onImageToView(user.images.image_link)" alt="Profile" class="profile-image"/>
            </div>
            <div class="float-sm-left float-none text-center" *ngIf="user.images == undefined">
                <img src="../assets/placeholder_profile.png" alt="Profile" class="profile-image"/>
            </div>
            <div class="float-sm-left float-none text-center ml-sm-3 mt-2 mt-sm-0">
                <h2 class="h1">{{user.user_firstname}} {{user.user_lastname}}</h2>
            </div>
        </div>
    </div>
    <div class="card-body row">
        <div class="col-sm-4">

            <div class="form-group">
                <label class="text-is-grey">{{lang.la.user_type}}</label>
                <p class="h5">{{Constants.USER_ROLES_ID_TO_NAME[user.user_role_id]}}</p>
            </div>

        </div>
        <div class="col-sm-4">

            <div class="form-group" *ngIf="user.user_type_demo != 0 || user.user_type_mercer != 0">
                <label class="text-is-grey">{{lang.la.role}}</label>
                <p class="h5">{{user.user_type_demo ? 'Demo' : 'Mercer' }}</p>
            </div>
        </div>

        <div class="col-sm-4" *ngIf="user.user_role_id !== Constants.USER_ROLE_CUSTOMER_ID">

            <div class="form-group">
                <label class="text-is-grey">{{lang.la.can_create_projects}}</label>
                <p class="h5" type="checkbox">{{user.can_create_project_management == 1 ? 'Ja' : 'Nej'}}</p>
            </div>

        </div>
    </div>

    <ng-container
            *ngIf="userService.user.user_role_id === Constants.USER_ROLE_TEAMPLAYER_ID || userService.user.user_role_id === Constants.USER_ROLE_ADMIN_ID">
        <div class="card-divider" *ngIf="user.user_role_id === Constants.USER_ROLE_EMPLOYEE_ID"></div>
        <div class="card-body row" *ngIf="user.user_role_id === Constants.USER_ROLE_EMPLOYEE_ID">
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="text-is-grey">{{lang.la.cprnumber}}</label>
                    <p class="h5">{{user.user_cpr}}</p>
                </div>
            </div>
        </div>
    </ng-container>

    <div class="card-divider"></div>
    <div class="card-body row">

        <div class="col-sm-6">
            <div class="form-group">
                <label class="text-is-grey">{{lang.la.phoneno}}</label>
                <p class="h5"><a href="tel:{{user.user_phone}}">{{user.user_phone}}</a></p>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="text-is-grey">{{lang.la.email}}</label>
                <p class="h5"><a href="mailto:{{user.user_email}}">{{user.user_email}}</a></p>
            </div>
        </div>
    </div>
    <ng-container
            *ngIf="userService.user.user_role_id === Constants.USER_ROLE_ADMIN_ID || userService.user.user_role_id === Constants.USER_ROLE_TEAMPLAYER_ID">
        <div class="card-divider"
             *ngIf="user.user_role_id !== Constants.USER_ROLE_CONSULTANT_ID && user.user_role_id !== Constants.USER_ROLE_CUSTOMER_ID"></div>
        <div class="card-body row"
             *ngIf="user.user_role_id !== Constants.USER_ROLE_CONSULTANT_ID && user.user_role_id !== Constants.USER_ROLE_CUSTOMER_ID">

            <div class="col-sm-6">
                <div class="form-group">
                    <label class="text-is-grey">{{lang.la.address}}</label>
                    <p class="h5">{{user.user_address}}</p>
                </div>

                <div class="form-group">
                    <label class="text-is-grey">{{lang.la.postalcode}}</label>
                    <p class="h5">{{user.user_postal_code}}</p>
                </div>

            </div>
            <div class="col-sm-6">

                <div class="form-group">
                    <label class="text-is-grey">{{lang.la.city}}</label>
                    <p class="h5">{{user.user_city}}</p>
                </div>

            </div>
        </div>

        <div class="card-divider" *ngIf="user.user_role_id === Constants.USER_ROLE_EMPLOYEE_ID"></div>
        <div class="card-body row" *ngIf="user.user_role_id === Constants.USER_ROLE_EMPLOYEE_ID">
            <div class="col-sm-12">
                <div class="form-group">
                    <label class="text-is-grey">Ferie</label>
                    <div class="row">
                        <div class="col-sm-12">
                            <ng-container *ngIf="vacations.length > 0;else noVacation">
								<span class="badge badge-success push-m-20-r mb-2"
                                      *ngFor="let vacation of vacations; let pos = index">
									<span class="h6 push-p-10">
										{{vacation.vacation_start | mdate: 'DD-MM-YYYY': false}}
                                        - {{vacation.vacation_end | mdate: 'DD-MM-YYYY': false}}
									</span>
								</span>
                            </ng-container>
                            <ng-template #noVacation>
                                <p class="h5">Brugeren har intet ferie planlagt</p>
                            </ng-template>

                        </div>
                    </div>

                </div>
            </div>
        </div>

    </ng-container>


    <ng-container
            *ngIf="userService.userIsUserRoleId(Constants.USER_ROLE_ADMIN_ID) && user.user_role_id === Constants.USER_ROLE_EMPLOYEE_ID">
        <div class="card-divider"></div>
        <div class="card-body row">
            <div class="col-sm-6">

                <div class="form-group">
                    <label class="text-is-grey">{{lang.la.reg_acc}}</label>
                    <p class="h5">{{user.user_bank_reg}}</p>
                </div>

            </div>
            <div class="col-sm-6">

                <div class="form-group">
                    <label class="text-is-grey">{{lang.la.acc_no}}</label>
                    <p class="h5">{{user.user_bank_account}}</p>
                </div>

            </div>
        </div>
        <div class="card-divider" *ngIf="user.user_role_id == Constants.USER_ROLE_EMPLOYEE_ID"></div>
        <div class="card-body row" *ngIf="user.user_role_id == Constants.USER_ROLE_EMPLOYEE_ID">
            <div class="col-sm-6">

                <div class="form-group">
                    <label class="text-is-grey">{{lang.la.mercer_tax}}</label>
                    <p class="h5">{{user.user_mercer_rate | currency: 'DKK' | formatedcurrency}}</p>
                </div>

                <div class="form-group">
                    <label class="text-is-grey">{{lang.la.danloen}}</label>
                    <p class="h5">{{user.user_danloen_id}}</p>

                </div>
            </div>
            <div class="col-sm-6">

                <div class="form-group">
                    <label class="text-is-grey">{{lang.la.demo_tax}}</label>
                    <p class="h5">{{user.user_demo_rate | currency: 'DKK' | formatedcurrency}}</p>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container
            *ngIf="userService.userIsUserRoleId(Constants.USER_ROLE_ADMIN_ID) && user.user_role_id === Constants.USER_ROLE_EMPLOYEE_ID">
        <div class="card-divider"></div>
        <div class="card-body row">
            <div class="col-sm-8">
                <div class="form-group" *ngIf="user.user_cooperation_agreements_date != '0000-00-00 00:00:00'">
                    <label for="type">{{lang.la.cooperation_accepted}}</label>
                    <div class="input-group">
                        <input class="text" class="is-fullwidth" id="samarbejdsaftalerdate" disabled
                               [value]="user.user_cooperation_agreements_date ? utilService.onUppercase(user.user_cooperation_agreements_date | mdate: 'dddd	DD. MMM YYYY HH:mm') : ''"/>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <div class="form-group">
                    <label>
                        {{lang.la.cooperation_deal}}
                    </label>
                    <textarea class="form-control" id="samarbejdsaftaler" rows="5"
                              disabled="true"
                              name="inputCollabText.value">{{user.user_cooperation_agreements}}</textarea>
                </div>
            </div>
            <div class="col-sm-6 has-text-left">
                <button class="btn btn-secondary" (click)="onPrint()"><span>{{lang.la.press_to_print_coop}}</span></button>
            </div>

        </div>
    </ng-container>

    <div class="card-divider"
         *ngIf="user.user_role_id !== Constants.USER_ROLE_ADMIN_ID && user.user_role_id !== Constants.USER_ROLE_TEAMPLAYER_ID"></div>
    <div class="card-body row"
         *ngIf="user.user_role_id !== Constants.USER_ROLE_ADMIN_ID && user.user_role_id !== Constants.USER_ROLE_TEAMPLAYER_ID">
        <div class="col-12">
            <div class="form-group">
                <label class="text-is-grey">
                    {{lang.la.customers}}
                </label><br/>
                <a *ngFor="let item of assignmentCustomers"
                   [routerLink]="['../../../customer/show/', item.customers.customer_id]" class="h5 link">
                    {{item.customers.customer_name}}
                </a>
            </div>
        </div>
    </div>
    <div class="card-divider" *ngIf="userService.getShowForUser(USER_RIGHT_TYPES.TYPE_USER_NOTES)"></div>
    <div class="card-body row"
         *ngIf="userService.getShowForUser(USER_RIGHT_TYPES.TYPE_USER_NOTES) && user.user_notes != undefined && user.user_notes.length != 0">
        <div class="col-12">
            <div class="form-group">
                <label class="text-is-grey">
                    {{lang.la.notes_only_tp}}
                </label>
                <p class="h5">{{user.user_notes}}</p>
            </div>
        </div>
    </div>

</div>

<ng-container
        *ngIf="userService.user.user_role_id === Constants.USER_ROLE_ADMIN_ID || userService.user.user_role_id === Constants.USER_ROLE_TEAMPLAYER_ID">

    <div class="row push-m-50-b push-m-50-t" *ngIf="!isLoadingView && assignmentHistoric.length != 0 && false">
        <div class="col-sm-6">
            <h1 class="h2">{{lang.la.historic}}</h1>
        </div>
        <div class="col-sm-6 has-text-right">
        </div>
    </div>

    <div class="row" *ngIf="!isLoadingView && assignmentHistoric.length != 0 && false">
        <div class="col-sm-12">
            <div class="row" *ngFor="let history of assignmentHistoric; let pos = index">
                <div class="col-2" [ngClass]="{'assignment-his-line': pos != assignmentHistoric.length-1}">
                    <img *ngIf="history.users != undefined && history.users.images != undefined"
                         [src]="utilService.onImageToViewThumbnail(history.users.images.image_link)"
                         class="assignment-his-number"/>
                    <img *ngIf="history.users == undefined || history.users.images == undefined"
                         src="./assets/placeholder_profile.png" class="assignment-his-number"/>
                </div>
                <div class="col-10 push-p-20-b">
                    <div class="card history-log">
                        <div class="card-body row">
                            <div class="col-12">
                                <p class="push-m-10-b">
                                    <a [routerLink]="['../../../user/show/', history.users.user_id]">
                                        {{history.users.user_firstname}} {{history.users.user_lastname}}
                                    </a>
                                    <span [innerHtml]="utilService.onDisplayLogText(history,user)"></span>
                                    <a class="log__date h7">{{history.user_log_updated}}</a>
                                </p>
                                <div class="card-divider"></div>
                                <div class="row log__header">
                                    <div class="col-sm-4">Felt</div>
                                    <div class="col-sm-4">Gammel værdi</div>
                                    <div class="col-sm-4">Ny værdi</div>
                                </div>
                                <ng-container *ngFor="let field of history.user_log_text">
                                    <div class="row log__text">
                                        <div class="col-sm-4">{{field.fieldName}}</div>
                                        <div class="col-sm-4">{{field.from}}</div>
                                        <div class="col-sm-4">{{field.to}}</div>
                                    </div>
                                </ng-container>

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>

</ng-container>
