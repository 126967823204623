import {Component, OnInit} from '@angular/core';
import {UtilService} from '../services/util.service';
import {DataService} from '../services/data.service';
import {LangService} from '../services/lang.service';
import {ApiService} from '../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FileUploader} from 'ng2-file-upload';
import {User} from '../models/user';
import {InputString} from '../models/inputstring';
import {Constants} from '../constants';
import {InputCheckbox} from '../models/inputcheckbox';
import {Customer} from '../models/customer';
import {TableSettings} from '../models/tablesettings';
import {NotificationService} from '../shared/notification.service';
import {CustomersForUsers} from '../models/customersforusers';
import {TableSettingsEquals} from '../models/tablesettingsequals';
import {Vacation} from '../models/vacation';
import * as moment from 'moment';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {TableHeader} from '../models/tableheader';
import {UserService} from '../services/user.service';
import {Location} from "@angular/common";
import {FileUploaderService} from "../services/fileUploaderService";

@Component({
    selector: 'app-user-create',
    templateUrl: './user-create.component.html',
    styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {

    Constants: any = Constants;

    //View
    isLoadingView: boolean = false;
    isLoading: boolean = false;
    isReloading: boolean = false;

    //
    isDeleteModalActive: boolean = false;

    //
    isVacationActive: boolean = false;
    isLoadingVacation: boolean = false;
    vacations: Vacation[] = [];
    inputVacationRange: Date[] = [];

    //Inputs
    inputRole: InputString = new InputString();
    inputRoleMercer: InputCheckbox = new InputCheckbox();
    inputRoleDemo: InputCheckbox = new InputCheckbox();
    inputProjectCreate: InputCheckbox = new InputCheckbox();

    inputCustomersId: string = ""; //Customer used for single select
    inputCustomersOriginal: CustomersForUsers[] = []; //Customers used for multiple slect
    inputCustomers: Customer[] = []; //Customers used for multiple slect
    inputCustomerError: InputString = new InputString(); // Only used for consistent error messages

    inputFirstName: InputString = new InputString();
    inputLastName: InputString = new InputString();
    inputPhoneNo: InputString = new InputString();
    inputPostalCode: InputString = new InputString();
    inputCPR: InputString = new InputString();
    inputAddress: InputString = new InputString();
    inputEmail: InputString = new InputString();
    inputCity: InputString = new InputString();
    inputReqNo: InputString = new InputString();
    inputAccNo: InputString = new InputString();
    inputMercer: InputString = new InputString();
    inputDanSalary: InputString = new InputString();
    inputCollabText: InputString = new InputString();

    inputPosition: InputString = new InputString();
    inputDistrict: InputString = new InputString();


    inputDemo: InputString = new InputString();

    inputPassword1: InputString = new InputString();
    inputPassword2: InputString = new InputString();

    inputBreastSize: InputString = new InputString();
    inputDelieveryShop: InputCheckbox = new InputCheckbox();
    inputDelieveryAddress: InputCheckbox = new InputCheckbox();

    inputNotes: InputString = new InputString();

    startingCustomers = [];

    //Different roles

    //Editing
    isEditingId: number = -1;
    isEditing: boolean = false;
    isEditingUser: User;

    //Image upload
    isImageUploadActive: boolean = false;
    filesImages: FileUploader = new FileUploader({});

    //IDS TO SHOW
    ID_TO_SHOW_CUSTOMERS: number = 0;
    ID_TO_SHOW_TYPES: number = 1;
    ID_TO_SHOW_CPR: number = 2;
    ID_TO_SHOW_DISTRICT: number = 3;
    ID_TO_SHOW_POSITION: number = 4;
    ID_TO_SHOW_POSTALCODE: number = 5;
    ID_TO_SHOW_ADDRESS: number = 6;
    ID_TO_SHOW_CITY: number = 7;
    ID_TO_SHOW_MERCER: number = 8;
    ID_TO_SHOW_DEMO: number = 9;
    ID_TO_SHOW_DANLOEN: number = 10;
    ID_TO_SHOW_REGACC: number = 12;
    ID_TO_SHOW_CUSTOMERS_MULTIPLE_SELECT: number = 13;
    ID_TO_SHOW_CUSTOMERS_SINGLE_SELECT: number = 14;
    ID_TO_SHOW_TSHIRT: number = 15;

    //View
    idsToShow: any;

    //
    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

    constructor(
        public apiService: ApiService,
        public route: ActivatedRoute,
        private router: Router,
        public utilService: UtilService,
        public lang: LangService,
        public dataService: DataService,
        public notificationService: NotificationService,
        public userService: UserService,
        private location: Location,
        private fileUploaderService: FileUploaderService,
    ) {
    }

    ngOnInit() {
        this.dpConfig = this.utilService.getDatePickerConfig();
        this.inputRole.value = "" + 2;

        this.route.params.subscribe(params => {
            this.onCheckIfEditing();
            this.onCheckData();
            if (params.userType) {
                this.inputRole.value = params.userType;
            }
        });

        this.idsToShow = {
            '2': [
                this.ID_TO_SHOW_CUSTOMERS,
                this.ID_TO_SHOW_TYPES,

                this.ID_TO_SHOW_CPR,
                this.ID_TO_SHOW_POSTALCODE,
                this.ID_TO_SHOW_ADDRESS,
                this.ID_TO_SHOW_CITY,

                this.ID_TO_SHOW_MERCER,
                this.ID_TO_SHOW_DEMO,
                this.ID_TO_SHOW_DANLOEN,

                this.ID_TO_SHOW_REGACC,

                this.ID_TO_SHOW_CUSTOMERS_MULTIPLE_SELECT,

                this.ID_TO_SHOW_TSHIRT
            ],
            '3': [
                this.ID_TO_SHOW_CUSTOMERS,


                this.ID_TO_SHOW_DISTRICT,
                this.ID_TO_SHOW_POSITION,

                this.ID_TO_SHOW_CUSTOMERS_MULTIPLE_SELECT,
            ],
            '4': [
                this.ID_TO_SHOW_CUSTOMERS,
                this.ID_TO_SHOW_POSITION,

                this.ID_TO_SHOW_CUSTOMERS_SINGLE_SELECT,
            ],
            '5': [

                this.ID_TO_SHOW_MERCER,
                this.ID_TO_SHOW_DEMO,
                this.ID_TO_SHOW_DANLOEN,
            ],
        }
    }

    onCheckIfEditing() {
        this.isEditingId = this.route.snapshot.params['id'];

        if (this.isEditingId != undefined) {
            this.isEditing = true;
            this.isLoadingView = true;

            let adminColumns = this.userService.userIsUserRoleId(Constants.USER_ROLE_ADMIN_ID) ? 'user_demo_rate,user_mercer_rate,' : '';

            var ts: TableSettings = new TableSettings();
            ts.columns = "user_id,user_email,user_firstname,user_lastname,user_password,user_login_attempts,user_blocked,user_type_mercer,user_type_demo,user_role_id,user_phone,user_cpr,user_address,user_postal_code,user_city,user_bank_reg,user_bank_account,user_danloen_id,user_notes,user_cooperation_agreements,user_cooperation_agreements_date,user_active,user_contact_on_email,user_contact_on_sms,user_contact_on_app,image_id,user_creation,user_home_delivery,can_create_project_management," + adminColumns + Constants.PARAMETER_IMAGES_ONLY_ACTIVE;

            this.apiService.getUser(ts, this.isEditingId).subscribe((data) => {
                if (data.main[0] != undefined) {
                    this.isEditingUser = data.main[0];
                    this.onSetInputs(data.main[0]);
                }

                //Get user customers
                var tscfu: TableSettings = new TableSettings();
                tscfu.tableSettingsEquals.push(new TableSettingsEquals("user_id", this.isEditingId));
                //tscfu.tableSettingsEquals.push(new TableSettingsEquals("customers.customer_active", 1));
                var customers = "customers.customer_id,customers.customer_name,customers.customer_active";
                tscfu.columns = "customer_for_user_connection_id,customer_id,user_id," + customers;
                tscfu.results = 500;

                this.apiService.getUserCustomers(tscfu).subscribe((data) => {
                    this.inputCustomersOriginal = data.main;

                    for (let i = 0; i < data.main.length; i++) {
                        const element = data.main[i];
                        this.inputCustomers.push(data.main[i].customers);
                    }

                    if (this.inputCustomers.length != 0) {
                        this.inputCustomersId = "" + this.inputCustomers[0].customer_id
                    }

                    this.isLoadingView = false;
                }, (error) => {
                    this.isLoadingView = false;
                });
                this.onGetVacation();

            }, (error) => {
                this.isLoadingView = false;
            });


        }
    }

    onCheckData() {
        var ts: TableSettings = new TableSettings();
        ts.sortBy = new TableHeader("customer_name", "customer_name");
        ts.tableSettingsEquals.push(new TableSettingsEquals("customer_active", 1));
        ts.sortBy.asc = !ts.sortBy.asc;
        ts.results = 500;
        this.apiService.getCustomers(ts).subscribe((data) => {
            this.dataService.saveCustomers(data.main);
        }, (error) => {
            this.utilService.onRetryApi("getCustomers", () => {
                this.onCheckData();
            }, () => {
                this.notificationService.setNotification(this.lang.la.error_general_message + " " + error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
            });
        })
    }

    onSetInputs(u: User) {

        this.inputFirstName.value = u.user_firstname;
        this.inputLastName.value = u.user_lastname;

        this.inputRoleMercer.value = u.user_type_mercer;
        this.inputRoleDemo.value = u.user_type_demo;


        this.inputRole.value = "" + u.user_role_id;

        this.inputPhoneNo.value = u.user_phone;
        this.inputPostalCode.value = u.user_postal_code;
        this.inputCPR.value = "" + u.user_cpr;
        this.inputAddress.value = u.user_address;
        this.inputEmail.value = u.user_email;
        this.inputCity.value = u.user_city;
        this.inputReqNo.value = u.user_bank_reg;
        this.inputAccNo.value = u.user_bank_account;
        this.inputMercer.value = u.user_mercer_rate;
        this.inputDanSalary.value = u.user_danloen_id;
        this.inputCollabText.value = u.user_cooperation_agreements;

        this.inputPosition.value = u.user_position;
        this.inputDistrict.value = u.user_district;

        this.inputDemo.value = u.user_demo_rate;

        this.inputDelieveryShop.value = u.user_home_delivery === '1' ? true : false;

        this.inputProjectCreate.value = u.can_create_project_management === 1;
        // this.inputBreastSize.value = u.breastsize;
        this.inputDelieveryAddress.value = false;//TODO

        this.inputNotes.value = u.user_notes;
    }

    resetInputs() {
        const inputRole = this.inputRole.value;
        this.onSetInputs(new User());
        this.inputRole.value = inputRole;
        this.inputCustomers = [];
        this.inputCustomersId = "";
        this.startingCustomers = [];
        this.inputPassword1 = new InputString();
        this.inputPassword2 = new InputString();
        this.inputCPR = new InputString();
        this.filesImages = undefined;

        window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
    }

    onChangeRole() {
        this.isReloading = true;
        setTimeout(() => {
            this.isReloading = false;
        }, 0);
    }

    onShouldShow(id): boolean {
        for (let i = 0; i < this.idsToShow[this.inputRole.value].length; i++) {
            if (this.idsToShow[this.inputRole.value][i] == id) {
                return true;
            }
        }
        return false;
    }

    onShouldShowMultiple(...id: any[]): boolean {
        var valid = 0;

        for (let b = 0; b < id.length; b++) {
            const element = id[b];
            for (let i = 0; i < this.idsToShow[this.inputRole.value].length; i++) {
                if (this.idsToShow[this.inputRole.value][i] == element) {
                    valid++;
                }
            }
        }

        if (valid != 0) {
            return true;
        } else {
            return false;
        }
    }

    onCheckForms(): boolean {
        var validForm = true;
        if (this.inputRole.value == Constants.USER_ROLE_EMPLOYEE_ID.toString()) {
            this.inputRoleMercer.error = this.utilService.checkIfChecked(this.inputRoleMercer, this.inputRoleDemo);
            if (this.inputRoleMercer.error) {
                validForm = false;
            }

            if ((this.inputPostalCode.error = this.utilService.checkFilled(this.inputPostalCode.value))) validForm = false;
            if ((this.inputAddress.error = this.utilService.checkFilled(this.inputAddress.value))) validForm = false;
            if ((this.inputCity.error = this.utilService.checkFilled(this.inputCity.value))) validForm = false;


            if (this.userService.userIsUserRoleId(Constants.USER_ROLE_ADMIN_ID)) {
                if (this.inputRoleMercer.value && (this.inputMercer.error = this.utilService.checkNumber(this.inputMercer.value)) || this.inputRoleMercer.value && (this.inputMercer.error = parseInt(this.inputMercer.value) == 0 ? 'Skal være over 0' : '')) validForm = false;
                if (this.inputRoleDemo.value && (this.inputDemo.error = this.utilService.checkNumber(this.inputDemo.value)) || this.inputRoleDemo.value && (this.inputDemo.error = parseInt(this.inputDemo.value) == 0 ? 'Skal være over 0' : '')) validForm = false;
            }
        }

        if ((this.inputRole.value == Constants.USER_ROLE_CONSULTANT_ID.toString() || this.inputRole.value == Constants.USER_ROLE_CUSTOMER_ID.toString()) && (this.getCustomerIds().length == 0 || this.getCustomerIds()[0] == '')){
            this.inputCustomerError.error = 'Vælg venligst kunde';
            validForm = false;
        }

        if (!this.isEditing || this.inputPassword1.value.length != 0) {
            if (this.inputPassword1.value != this.inputPassword2.value) {
                this.inputPassword1.error = "Adgangskoder er ikke ens";
                validForm = false;
            } else if (!this.utilService.checkPassword(this.inputPassword1)) {
                validForm = false;
            }
        }

        if (this.utilService.checkMultipleIfvalid(this.inputFirstName, this.inputLastName, this.inputEmail)) {
            validForm = false;
        }

        return validForm;
    }

    onGetUserWithData(): User {
        var u: User = new User();

        if (this.isEditingId != -1) {
            u.user_id = this.isEditingId;
        }

        u.user_firstname = this.inputFirstName.value;
        u.user_lastname = this.inputLastName.value;


        if (this.inputPassword1.value != "") {
            u.user_password = this.inputPassword1.value;
        }

        u.user_role_id = +this.inputRole.value;

        if (this.inputCPR.value == "") {
            u.user_cpr = null;
        } else {
            u.user_cpr = this.inputCPR.value;
        }

        u.user_home_delivery = this.inputDelieveryShop.value === true ? '1' : '0';

        u.user_phone = this.inputPhoneNo.value;
        u.user_postal_code = this.inputPostalCode.value;

        u.user_address = this.inputAddress.value;
        u.user_email = this.inputEmail.value;
        u.user_city = this.inputCity.value;
        u.user_bank_reg = this.inputReqNo.value;
        u.user_bank_account = this.inputAccNo.value;

        if (this.userService.user.user_role_id === Constants.USER_ROLE_ADMIN_ID) {
            u.user_mercer_rate = this.inputMercer.value;
            u.user_demo_rate = this.inputDemo.value;
            u.user_danloen_id = !this.inputDanSalary.value ? "0" : this.inputDanSalary.value;
        }

        u.user_position = this.inputPosition.value;
        u.user_district = this.inputDistrict.value;

        u.user_type_mercer = this.inputRoleMercer.value;
        u.user_type_demo = this.inputRoleDemo.value;

        u.user_breastsize = this.inputBreastSize.value;


        u.user_cooperation_agreements = this.inputCollabText.value;

        u.user_notes = this.inputNotes.value;

        u.can_create_project_management = this.inputProjectCreate.value === true ? 1 : 0;

        return u;
    }

    save(action) {
        if (this.abortSaveAction()) return;

        this.isLoading = true;

        this.getSaveAction(action).subscribe((data) => {
            this.afterSaveAction(data['data'], action);
        }, (error) => {
            this.notificationService.setNotification(error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
            this.isLoading = false;
        });
    }

    getSaveAction(action) {
        if (action == 'create') {
            return this.apiService.postUser(this.onGetUserWithData())
        } else {
            return this.apiService.patchUser(this.onGetUserWithData());
        }
    }

    abortSaveAction() {
        if (!this.onCheckForms()) {
            this.notificationService.setNotification(this.lang.la.error_form_invalid, this.notificationService.CONSTANT_TYPE_WARNING);
            return true;
        }

        return false
    }

    afterSaveAction(user: User, action) {
        // add customers, then add images
        this.addCustomers(user)
            .then(() => {
                console.log('aftersave action: addimages call');
                return this.addImages(user);
            })
            .then(() => {
                console.log('aftersave stop', action);
                this.isLoading = false;

                if (action == 'create') {
                    this.showCreateNotification(user);
                } else {
                    this.notificationService.setNotification(this.lang.la.success_updated, this.notificationService.CONSTANT_TYPE_NORMAL);
                    this.returnToListPage();
                }
            });
    }

    addCustomers(user: User) {
        // if the user is not one of the customers, return a promise so that we can chain on it
        if(user.user_role_id === Constants.USER_ROLE_TEAMPLAYER_ID){
            return new Promise((resolve,reject) => {
                resolve(true);
            })
        }

        const customer_ids = this.getCustomerIds();

        const data = {
            'user_id': user.user_id,
            'customer_ids': customer_ids
        };

        // put new customers
        return this.apiService.patchUserCustomersMultiple(data).toPromise();
    }

    addImages(c: User) {
        console.log('addImages');
        return this.fileUploaderService.uploadAllInQueue(this.filesImages, {
            type: 'image',
            module: 'users',
            id: c.user_id
        }).catch((error) => {
            console.log('catch');
            this.notificationService.setNotification('Brugeren er gemt, men en eller flere filer kunne ikke vedhæftes.<br /><br />'+ this.lang.la.image_restrictions_explanation, this.notificationService.CONSTANT_TYPE_WARNING, 30000);
            this.isLoading = false;
            throw error;
        });
    }

    returnToListPage() {
        var type = "teamplayer";
        if (this.inputRole.value == "2") {
            type = "employee";
        } else if (this.inputRole.value == "3") {
            type = "consultant";
        } else if (this.inputRole.value == "4") {
            type = "customer";
        }
        this.router.navigate(['./app/user/list/', type]);
    }

    getCustomerIds() {
        let ids = new Set();

        if (this.onShouldShow(this.ID_TO_SHOW_CUSTOMERS_SINGLE_SELECT)) {
            ids.add(this.inputCustomersId);
        } else if (this.onShouldShow(this.ID_TO_SHOW_CUSTOMERS_MULTIPLE_SELECT)) {
            for (let i = 0; i < this.inputCustomers.length; i++) {
                ids.add(this.inputCustomers[i].customer_id);
            }
        }

        return Array.from(ids);
    }

    showCreateNotification(user) {
        this.notificationService.showCreateNotification('Bruger', user.user_id, '/app/user/show');
        this.resetInputs();
    }

    //Vacation
    onGetVacation() {
        var ts: TableSettings = new TableSettings();
        ts.tableSettingsEquals.push(new TableSettingsEquals("vacation_active", "1"));
        ts.tableSettingsEquals.push(new TableSettingsEquals("user_id", this.isEditingId));
        ts.sortBy = new TableHeader("vacation_start", "vacation_start");
        this.apiService.getVacations(ts).subscribe((data) => {
            this.vacations = data.main;
        }, (error) => {
        })
    }

    onAddVacation() {
        this.isLoadingVacation = true;
        var v: Vacation = new Vacation();
        v.user_id = this.isEditingId;
        v.vacation_start = moment(this.inputVacationRange[0]).format("YYYY-MM-DD HH:mm:ss");
        v.vacation_end = moment(this.inputVacationRange[1]).format("YYYY-MM-DD HH:mm:ss");
        this.apiService.postVacation(v).subscribe((data) => {
            this.onGetVacation();
            this.isLoadingVacation = false;
            this.isVacationActive = false;
        }, (error) => {
            this.isLoadingVacation = false;
            this.notificationService.setNotification(error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
        });
    }

    onDeleteVacation(pos) {
        this.isLoadingVacation = true;
        this.vacations[pos].vacation_active = 0;
        this.apiService.patchVacation(this.vacations[pos]).subscribe((data) => {
            this.onGetVacation();
            this.isLoadingVacation = false;
            this.isVacationActive = false;
            this.notificationService.setNotification("Ferie slettet.", this.notificationService.CONSTANT_TYPE_NORMAL);

        }, (error) => {
            this.isLoadingVacation = false;
            this.notificationService.setNotification(this.lang.la.error_general_message, this.notificationService.CONSTANT_TYPE_WARNING);
        });
    }


    onChangeActive(active: number) {
        var c: User = new User();
        c.user_id = this.isEditingId;
        c.user_active = active;

        this.apiService.patchUser(c).subscribe((data) => {
            this.notificationService.setNotification(this.lang.la.success_updated, this.notificationService.CONSTANT_TYPE_NORMAL);
            this.onCheckIfEditing();
            this.isDeleteModalActive = false;
            this.isLoading = false;
        }, (error) => {
            this.notificationService.setNotification(error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
            this.isLoading = false;
        });
    }

    onGoBack() {
        this.location.back();
    }
}
