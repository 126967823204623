export class CustomersForUsers {

    public primary_key: string = "customer_for_user_connection_id";

    public customer_for_user_connection_id: number;
    public customer_id: string;
    public user_id: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }

    setData(customer_idP, user_idP) {
        this.customer_id = customer_idP;
        this.user_id = user_idP;
    }

    public getPostJson() {
        var object = this;
        delete object.primary_key;
        return JSON.stringify(this);
    }

}