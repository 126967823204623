import {Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {LangService} from '../../services/lang.service';
import {ApiService} from '../../services/api.service';
import {TableSettings} from '../../models/tablesettings';
import {TableSettingsEquals} from '../../models/tablesettingsequals';
import {TableHeader} from '../../models/tableheader';
import {UtilService} from '../../services/util.service';
import {UserService} from '../../services/user.service';
import {Constants} from '../../constants';
import 'rxjs-compat/add/operator/takeUntil';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-dropdown-select-type',
    templateUrl: './dropdown-select-type.component.html'
})
export class DropdownSelectTypeComponent implements OnInit, OnChanges, OnDestroy {
    public DROPDOWN_SELECT_TYPE_CUSTOMERS: number = 0;
    public DROPDOWN_SELECT_TYPE_EMPLOYEES: number = 1;
    public DROPDOWN_SELECT_TYPE_STORES: number = 2;
    public DROPDOWN_SELECT_TYPE_CHAINS: number = 3;
    public DROPDOWN_SELECT_TYPE_CONSULTANTS: number = 4;
    public DROPDOWN_SELECT_CONSULTANTS_FOR_EMPLOYEES: number = 5;
    public DROPDOWN_SELECT_CUSTOMERS_FOR_EMPLOYEES: number = 6;

    @Input() arrayList: any[] = [];
    @Input() arrayListName: string = "name";
    @Input() arrayListId: string = "id";
    @Input() arrayListSelectedId: string = "";
    @Input() arrayListSelectedObject: any;
    @Input() arrayType: number = -1;
    @Output() selectedItem = new EventEmitter();
    @Input() error: string = "";

    isLoadingMore: boolean = false;
    isNoMoreToLoad: boolean = false;
    isAItemSelected: boolean = false;
    @Input()
    isDropdownActive: boolean = false;

    inputDropdown: string = "";

    arrayListForView: any[] = [];

    tableSettings: TableSettings = new TableSettings();

    timeout: any;

    done: boolean = false;

    localValuesForEmployeesSearch;

    destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(
        public lang: LangService,
        public apiService: ApiService,
        private el: ElementRef,
        private utilService: UtilService,
        private userService: UserService
    ) {
    }

    ngOnInit() {
        this.tableSettings.page = 1;
        this.tableSettings.results = 150;

        //Get data when something is selected so we can show it
        if (this.arrayListSelectedId != "" && this.arrayList.length == 0) {
            this.setDataForArrayType();
            this.getData();
        }
    }

    ngOnChanges() {
        if (this.arrayListSelectedObject || this.arrayListSelectedId != "" && this.arrayList.length == 0) {
            this.setDataForArrayType();
            this.getData();
        }
        if (this.arrayListSelectedId == "" && this.done) {
            this.onClearSearch();
        }
    }

    getData() {
        this.isNoMoreToLoad = false;
        this.tableSettings.results = 150;
        if (this.arrayType == this.DROPDOWN_SELECT_TYPE_CUSTOMERS) {
            //Make sure only teamplayer can see all customers
            this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals("customers.customer_active", 1));
            if (this.userService.user.user_role_id != Constants.USER_ROLE_TEAMPLAYER_ID && this.userService.user.user_role_id != Constants.USER_ROLE_ADMIN_ID) {
                this.apiService.getUserCustomers(this.tableSettings).takeUntil(this.destroy$).subscribe((data) => this.onDataComplete(data), error => this.onDataError(error));
            } else {
                this.apiService.getCustomers(this.tableSettings).takeUntil(this.destroy$).subscribe((data) => this.onDataComplete(data), error => this.onDataError(error));
            }

        } else if (this.arrayType == this.DROPDOWN_SELECT_TYPE_EMPLOYEES) {
            this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals("users.user_active", 1));
            this.apiService.getUsers(this.tableSettings).takeUntil(this.destroy$).subscribe((data) => this.onDataComplete(data), error => this.onDataError(error));

        } else if (this.arrayType == this.DROPDOWN_SELECT_TYPE_STORES) {
            this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals("stores.active", 1));
            this.apiService.getStores(this.tableSettings).takeUntil(this.destroy$).subscribe((data) => this.onDataComplete(data), error => this.onDataError(error));

        } else if (this.arrayType == this.DROPDOWN_SELECT_TYPE_CHAINS) {
            this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals("chains.active", 1));
            this.apiService.getChains(this.tableSettings).takeUntil(this.destroy$).subscribe((data) => this.onDataComplete(data), error => this.onDataError(error));

        } else if (this.arrayType == this.DROPDOWN_SELECT_TYPE_CONSULTANTS) {
            this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals("users.user_active", 1));
            this.apiService.getUsers(this.tableSettings).takeUntil(this.destroy$).subscribe((data) => this.onDataComplete(data), error => this.onDataError(error));

        } else if (this.arrayType == this.DROPDOWN_SELECT_CUSTOMERS_FOR_EMPLOYEES || this.arrayType == this.DROPDOWN_SELECT_CONSULTANTS_FOR_EMPLOYEES) {
            this.apiService.getSalesConsultants().takeUntil(this.destroy$).subscribe((data) => this.onDataComplete(data), error => this.onDataError(error));
        }
    }

    onDataComplete(data) {

        if (this.arrayType !== this.DROPDOWN_SELECT_CONSULTANTS_FOR_EMPLOYEES && this.arrayType !== this.DROPDOWN_SELECT_CUSTOMERS_FOR_EMPLOYEES) {
            this.arrayList = this.arrayList.concat(data.main);
            this.tableSettings.tableOffset = data.tableOffset;
            if (data.main.length == 0 || this.tableSettings.tableOffset.highest_offset == this.tableSettings.page) {
                this.isNoMoreToLoad = true;
            }
        } else if (this.arrayType === this.DROPDOWN_SELECT_CONSULTANTS_FOR_EMPLOYEES) {
            this.arrayList = this.arrayList.concat(this.utilService.sortData(data.data.main.sales_consultants, 'user_name', true));
            this.isNoMoreToLoad = true;
            this.tableSettings.tableOffset = data.data.offset;
        } else if (this.arrayType === this.DROPDOWN_SELECT_CUSTOMERS_FOR_EMPLOYEES) {
            this.arrayList = this.arrayList.concat(this.utilService.sortData(data.data.main.customers, 'customer_name', true));
            this.isNoMoreToLoad = true;
            this.tableSettings.tableOffset = data.data.offset;
        }

        this.arrayListForView = this.arrayList;

        this.setDataForArrayType();


        this.isLoadingMore = false;

        if(!this.done){
            this.onSetSelected();
        }

        this.done = true;
    }

    onDataError(error) {
        this.utilService.onRetryApi("" + this.arrayType, () => {
            this.getData();
        }, () => {
            this.isLoadingMore = false;
        });
    }

    setDataForArrayType() {
        this.tableSettings.tableSettingsEquals = [];

        if (this.arrayType == this.DROPDOWN_SELECT_TYPE_CUSTOMERS) {
            this.tableSettings.searchColumn = new TableHeader("customers.customer_name", "");

            this.tableSettings.sortBy = new TableHeader("customers.customer_name", "customers.customer_name");
            this.tableSettings.sortBy.asc = !this.tableSettings.sortBy.asc;


            if (this.userService.user.user_role_id != Constants.USER_ROLE_TEAMPLAYER_ID && this.userService.user.user_role_id != Constants.USER_ROLE_ADMIN_ID) {
                this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals("user_id", this.userService.getUserId()));
                this.tableSettings.columns = "customer_for_user_connection_id,customer_id,user_id,customers.customer_name,customers.customer_id";
                this.arrayListName = "customers.customer_name";
                this.arrayListId = "customers.customer_id";
            } else {
                this.arrayListName = "customer_name";
                this.arrayListId = "customer_id";
            }

        } else if (this.arrayType == this.DROPDOWN_SELECT_TYPE_EMPLOYEES) {
            this.tableSettings.searchColumn = new TableHeader("user_name", "");

            this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals("user_role_id", "2"));
            this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals('user_active', 1));

            this.tableSettings.sortBy = new TableHeader("user_firstname", "user_firstname");
            this.tableSettings.sortBy.asc = !this.tableSettings.sortBy.asc;

            this.arrayListName = "user_firstname&user_lastname";
            this.arrayListId = "user_id";
        } else if (this.arrayType == this.DROPDOWN_SELECT_TYPE_STORES) {
            this.tableSettings.searchColumn = new TableHeader("store_name", "");

            this.tableSettings.sortBy = new TableHeader("store_name", "store_name");
            this.tableSettings.sortBy.asc = !this.tableSettings.sortBy.asc;

            this.arrayListName = "store_name";
            this.arrayListId = "store_id";
        } else if (this.arrayType == this.DROPDOWN_SELECT_TYPE_CHAINS) {
            this.tableSettings.searchColumn = new TableHeader("chain_name", "");

            this.tableSettings.sortBy = new TableHeader("chain_name", "chain_name");
            this.tableSettings.sortBy.asc = !this.tableSettings.sortBy.asc;

            this.arrayListName = "chain_name";
            this.arrayListId = "chain_id";
        } else if (this.arrayType == this.DROPDOWN_SELECT_TYPE_CONSULTANTS) {
            this.tableSettings.searchColumn = new TableHeader("user_name", "");
            this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals("user_role_id", "3"));

            this.tableSettings.sortBy = new TableHeader("user_firstname", "user_firstname");
            this.tableSettings.sortBy.asc = !this.tableSettings.sortBy.asc;

            this.arrayListName = "user_firstname&user_lastname";
            this.arrayListId = "user_id";
        } else if (this.arrayType == this.DROPDOWN_SELECT_CONSULTANTS_FOR_EMPLOYEES) {
            this.arrayListName = "user_name";
            this.arrayListId = "user_id";
        } else if (this.arrayType == this.DROPDOWN_SELECT_CUSTOMERS_FOR_EMPLOYEES) {
            this.arrayListName = "customer_name";
            this.arrayListId = "customer_id";
        }
    }

    onSetSelected() {
        if (this.arrayListSelectedObject != undefined) {
            this.inputDropdown = this.utilService.onGetTableContent(this.arrayListSelectedObject, this.arrayListName);
            if (!this.inputDropdown && this.arrayListName.includes('.')) {
                // when table is supplied and the selected object does not contain a table-based value (e.g. customers.customer_name) remove the table part of the attribute and try again
                this.inputDropdown = this.utilService.onGetTableContent(this.arrayListSelectedObject, this.arrayListName.split('.')[1]);
            }
            return;
        }
        if (this.arrayListSelectedId != "") {
            for (let i = 0; i < this.arrayList.length; i++) {
                if (this.utilService.onGetTableContent(this.arrayList[i], this.arrayListId) == this.arrayListSelectedId) {
                    this.inputDropdown = this.utilService.onGetTableContent(this.arrayList[i], this.arrayListName);
                    return;
                }
            }
        }
    }

    onLoadMorePressed() {
        this.isLoadingMore = true;

        if (this.tableSettings.tableOffset.highest_offset == this.tableSettings.page) {
            this.isNoMoreToLoad = true;
            return;
        }

        this.tableSettings.page = (+this.tableSettings.page) + 1;

        this.getData();
    }

    onClearSearch() {
        this.inputDropdown = "";
        this.setDataForArrayType();
        this.tableSettings.search = "";
        this.selectedItem.emit("");
        this.arrayList = [];
        this.getData();
    }

    /**
     * Searching through the results
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    onChangeInputDropdown() {
        // check if the arrayType is for the employees
        if (this.arrayType == this.DROPDOWN_SELECT_CUSTOMERS_FOR_EMPLOYEES || this.arrayType == this.DROPDOWN_SELECT_CONSULTANTS_FOR_EMPLOYEES) {
            // if input is empty return the array list values received fron backend
            if (this.inputDropdown.length === 0) {
                this.arrayListForView = this.arrayList;
            } else {
                setTimeout(() => {
                    this.arrayListForView = this.arrayList.filter(arrayItem => {
                        const item = arrayItem[this.arrayType == this.DROPDOWN_SELECT_CUSTOMERS_FOR_EMPLOYEES ? 'customer_name' : 'user_name'];
                        if(item && typeof item == 'string'){
                            return item.toLowerCase().includes(this.inputDropdown.toLowerCase());
                        }
                        return false;
                    });
                }, 500);
            }

            // }

        } else {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.isLoadingMore = true;

                this.error = "";
                this.arrayListForView = [];
                this.arrayList = [];

                this.tableSettings.page = 1;
                this.tableSettings.search = this.inputDropdown;
                this.getData();
            }, 500);
        }
    }

    removeDuplicates(column) {

        if (this.arrayList.length < 1 || !this.arrayList[0][column]) return;

        this.arrayList = this.arrayList
            .map(e => e[column])
            .map((e, i, final) => final.indexOf(e) === i && i)
            .filter(e => this.arrayList[e]).map(e => this.arrayList[e]);

    }

    onOpenDropdown() {
        this.removeDuplicates('customer_for_user_connection_id');
        this.arrayListForView = this.arrayList;
        this.isDropdownActive = !this.isDropdownActive;
        if (this.arrayList.length == 0) {
            this.isLoadingMore = true;

            this.setDataForArrayType();
            this.getData();
        }
    }

    onItemSelected(pos) {
        if (this.arrayListForView.length < pos) {
            return;
        }
        this.error = "";
        this.isAItemSelected = true;

        this.arrayListSelectedObject = this.arrayListForView[pos];

        this.inputDropdown = this.utilService.onGetTableContent(this.arrayListForView[pos], this.arrayListName);

        this.selectedItem.emit(this.utilService.onGetTableContent(this.arrayListForView[pos], this.arrayListId));
        this.isDropdownActive = false;
    }

    onFocusOut() {
        setTimeout(() => {
            if (!this.isAItemSelected) {
                this.inputDropdown = "";
            }
            this.isDropdownActive = false;
        }, 500);
    }

    onDropdownBackPressed() {

        this.isDropdownActive = false;
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
