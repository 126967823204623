import {Component, OnInit} from '@angular/core';
import {UtilService} from '../services/util.service';
import {DataService} from '../services/data.service';
import {ApiService} from '../services/api.service';
import {LangService} from '../services/lang.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Customer} from '../models/customer';
import {Export} from '../models/export';
import {TableHeader} from '../models/tableheader';
import {TableSettings} from '../models/tablesettings';
import {User} from '../models/user';
import * as moment from 'moment';
import {CSVService} from '../services/csv.service';
import {UserService} from '../services/user.service';
import {Constants} from '../constants';
import {NotificationService} from '../shared/notification.service';

@Component({
    selector: 'app-export',
    templateUrl: './export.component.html',
    styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit {


    Constants: any = Constants;

    isLoadingView: boolean = false;

    //TableExport
    tableExportHeaders: TableHeader[];
    tableExportSettings: TableSettings;

    typeForExport: string = "customer";
    customerOrUserId: number = 0;

    isLoadingTable: boolean = false;
    tableData: Export[];
    tableHeaders: TableHeader[] = [];
    tableSettings: TableSettings;

    exportData: any;

    //View

    customer: Customer;
    user: User;
    startDate: string = "";
    endDate: string = "";
    export: Object = {};
    urlParams: any;

    constructor(
        public apiService: ApiService,
        public route: ActivatedRoute,
        private router: Router,
        public utilService: UtilService,
        public userService: UserService,
        public lang: LangService,
        private notificationService: NotificationService,
        public dataService: DataService,
        public csvService: CSVService,
    ) {
        this.urlParams = this.route.snapshot.params;
        this.tableSettings = new TableSettings();
    }

    ngOnInit() {
        /**
         * Make sure they have selected from the list and dont deeplink
         * Author: Tommy Jepsen
         **/
        /*if(this.csvService.exportCSVdata == null) {
            this.notificationService.setNotification("Du skal vælge den fra listen", this.notificationService.CONSTANT_TYPE_WARNING);
            this.router.navigate(["../app/export/list"]);
        } else {*/
        this.onSetTableHeaders();
        this.getDataExport();
        //}
    }

    getDataExport() {
        this.tableExportHeaders = [];

        this.tableExportSettings = new TableSettings();
        this.tableExportSettings.sortBy = this.tableExportHeaders[0];
        this.tableExportSettings.tableHeaders = this.tableExportHeaders;
        this.tableExportSettings.searchColumn = this.tableExportHeaders[0];
        // this.tableExportSettings.columns = "export_id,user_id,export_json,export_creation,export_updated,users.user_firstname,users.user_lastname";

        this.isLoadingView = false;
        this.isLoadingTable = true;

        this.apiService.getExportAssignments(this.tableExportSettings, this.urlParams.id).subscribe(data => this.onGetExportDataResult(data), (error) => {
            this.isLoadingTable = false;
            this.isLoadingView = false;
        });
    }

    onGetExportDataResult(data) {

        this.exportData = data;
        this.tableData = data.data;

        if (data.hasOwnProperty('offset') && data.offset.highest_offset) {
            this.tableSettings.page = data.offset.highest_offset;
        } else {
            this.tableSettings.page = 0;
        }

        this.isLoadingTable = false;
        this.isLoadingView = false;
        this.setExportMetaData();
    }

    setExportMetaData() {
        this.startDate = moment(this.exportData.export.export_start).format("DD-MM-YYYY");
        this.endDate = moment(this.exportData.export.export_end).format("DD-MM-YYYY");

        this.export = this.exportData.export;
    }

    onSetTableHeaders() {
        var columnsAssignment = "assignment_status,assignment_timestype_free,assignment_timestype_start,assignment_timestype_free_end,"
            + "assignment_id,assignment_type,assignment_time_estimate,assignment.assignment_approved,"
            + "assignment_description,store_id,stores.store_id,"
            + "stores.store_name,stores.store_postal_code,stores.store_address,stores.store_city,customer_id,customers.customer_name,customers.customer_id,assignment_contact_person,assignment_pos_material,"
            + "user_id,users.user_id,users.user_firstname,users.user_lastname";
        var columnsAssignmentExecution = ",assignment_execution_id,assignment_executions.assignment_execution_id,"
            + "assignment_executions.assignment_execution_drive_distance,assignment_executions.assignment_execution_expenses,"
            + "assignment_executions.assignment_execution_start_time,assignment_executions.assignment_execution_end_time";
        this.tableSettings.columns = columnsAssignment + columnsAssignmentExecution;

        //Add table headers
        var headers = {
            "has_image": "",
            "assignment_type": "Type",
            "assignment_status": "Status",
            "short_description": "Opgave",
            "employee": "Medarbejder",
            "store": "Butik",
            "customer_and_sales_consultant": "Kunde og salgskonsulent",
        };

        let counter = 0;
        for (let [key, value] of Object.entries(headers)) {
            const header: TableHeader = new TableHeader(key, value);
            header.id = counter;
            header.checked = true;
            this.tableHeaders.push(header);

            counter++;
        }

        this.tableSettings.tableHeaders = this.tableHeaders;

        //Set starttidspunkt to default sorting
        for (let index = 0; index < this.tableHeaders.length; index++) {
            const element = this.tableHeaders[index];
            if (element.id == 6) {
                this.tableSettings.sortBy = this.tableHeaders[index];
            }
        }
    }


    onGetTableContent(item, tablevalue): string {
        if (tablevalue.indexOf(".") !== -1) {
            var splitTableValue = tablevalue.split(".");

            if (item[splitTableValue[0]] == undefined) {
                return "";
            }

            if (Array.isArray(item[splitTableValue[0]])) {
                var returnString = "";
                for (let i = 0; i < item[splitTableValue[0]].length; i++) {
                    const element = item[splitTableValue[0]][i][splitTableValue[1]];
                    returnString = returnString + "\n" + element;
                }
                return returnString;
            }

            if (item[splitTableValue[0]][splitTableValue[1]] == undefined) {
                return "";
            }

            return item[splitTableValue[0]][splitTableValue[1]];
        }
        return item[tablevalue];
    }
}
