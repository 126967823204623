import {TableHeader} from "./tableheader";

export class UserSettings {
    public assignmentList: TableHeader[] = [];
    public customerIDForUser: number;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }

    public postObject() {
        var obj = this;
        return JSON.stringify(obj);
    }
}