import {AfterViewInit, Component, ElementRef, Input, OnInit} from '@angular/core';
import * as $ from 'jquery';
import 'fullcalendar';

@Component({
    template: '<div></div>',
    selector: 'app-calendarcomp'
})
export class CalendarCompComponent implements OnInit, AfterViewInit {

    @Input() options: Object;
    text: string;
    calendarInitiated: boolean;

    constructor(private element: ElementRef) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        setTimeout(() => {
            $('app-calendarcomp').fullCalendar(this.options);
        }, 100)
    }

    updateEvent(event) {
        return $(this.element.nativeElement).fullCalendar('updateEvent', event);
    }

    clientEvents(idOrFilter) {
        return $(this.element.nativeElement).fullCalendar('clientEvents', idOrFilter);
    }
}