export class SendSMSOREmail {

    public message: string = "";
    public users: Array<number> = [];
    public send_email: number = 0;
    public send_sms: number = 0;
    public subject?: string;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }

    public getPostJson() {
        return JSON.stringify(this)
    }
}