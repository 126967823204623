import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-businessintelligence',
    templateUrl: './businessintelligence.component.html',
    styleUrls: ['./businessintelligence.component.scss']
})
export class BusinessintelligenceComponent implements OnInit {

    graphList: any = [4045, 777, 4845, 3215, 5444, 2111, 3445];
    graphList1: any = [10, 30, 87, 87, 54, 89, 99];
    graphList2: any = [20, 45, 54, 78, 12, 54, 201];
    graphList3: any = [879978, 78978, 54654, 45648, 789789, 12, 456454];

    constructor() {
    }

    ngOnInit() {
    }
}
