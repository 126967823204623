import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from "rxjs/Observable";
import {UserService} from './user.service';
import {User} from '../models/user';
import {Assignment} from '../models/assignment';
import {Store} from '../models/store';
import {Customer} from '../models/customer';
import {Export} from '../models/export';
import {News} from '../models/news';
import {Calendar} from '../models/calendar';
import {Chain} from '../models/chain';
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/catch'
import 'rxjs/Rx';
import {TableSettings} from '../models/tablesettings';
import {TableData} from '../models/tabledata';
import {Data} from '../models/data';
import {Image} from '../models/image';
import {CustomersForUsers} from '../models/customersforusers';
import {AssignmentTodosForAssignments} from '../models/assignmenttodosforassignments';
import {NewsForUsersMM} from '../models/news-for-users-mm';
import {ImagesForNews} from '../models/images-for-news';
import {AssignmentExecutions} from '../models/assignment-executions';
import {AssignmentsForUsersMM} from '../models/assignments-for-users-mm';
import {UserSettings} from '../models/usersettings';
import {ImagesForAssignmentExecutions} from '../models/images-for-assignment-executions';
import {AppendixForAssignmentExecutions} from '../models/appendix-for-assignment-executions';
import {SendSMSOREmail} from '../models/send-message';
import {UserLogs} from '../models/user-logs';
import {Vacation} from '../models/vacation';
import {AppendixForAssignment} from '../models/appendix-for-assignment';
import {TableDataExport} from '../models/tabledataexport';
import {Notification} from "../models/notification";

@Injectable()
export class ApiService {

    //API_URL : string = "http://teamplayer.s8.baernholdt.com/api/public/"; //Development
    //API_URL : string = "http://teamplayer-intra2.baernholdt.com/api/public/"; //Staging
    API_URL: string = "/api/public/"; //Production


    headers: HttpHeaders;

    debug: boolean = false;

    constructor(
        private http: HttpClient,
        private userService: UserService
    ) {
    }

    setHeaders() {
        if (this.userService.user != undefined && this.userService.user.user_token != undefined) {
            this.headers = new HttpHeaders().set('Authorization', this.userService.user.user_token);
        }
    }

    setPostPatchHeaders() {
        if (this.userService.user !== undefined && this.userService.user.user_token !== undefined) {
            this.headers = new HttpHeaders().set('Authorization', this.userService.user.user_token).set('Content-Type', 'application/json');
        }
    }

    /**
     * Building the endpoints and adding all the parameters
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    onBuildEndpoint(endpoint: string, tableSettings: TableSettings, forceUseHeaders = false): string {

        var url = this.API_URL + endpoint;

        //Pagination
        url = url + "?offset=" + tableSettings.page;

        //Columns
        if (tableSettings.columns.length != 0 && !forceUseHeaders) {
            url = url + "&columns=" + encodeURIComponent(tableSettings.columns);
        } else if (tableSettings.tableHeaders.length != 0) {
            var newColumns = "";
            for (let i = 0; i < tableSettings.tableHeaders.length; i++) {
                if (tableSettings.tableHeaders[i].tablevalue !== "" && tableSettings.tableHeaders[i].checked) {
                    newColumns = newColumns.length != 0 ?
                        newColumns + "," + tableSettings.tableHeaders[i].tablevalue
                        :
                        tableSettings.tableHeaders[i].tablevalue;
                }
            }
            url = url + "&columns=" + encodeURIComponent(newColumns);
        }

        //Results
        url = url + "&results=" + tableSettings.results;

        //Searching
        if (tableSettings.search.length != 0) {
            url = url + "&contains[" + tableSettings.searchColumn.tablevalue + "]=" + encodeURIComponent(tableSettings.search);
        }

        //Filtering
        if (tableSettings.tableSettingsGreater.length != 0) {
            for (let i = 0; i < tableSettings.tableSettingsGreater.length; i++) {
                const element = tableSettings.tableSettingsGreater[i];
                url = url + "&greater[" + tableSettings.tableSettingsGreater[i].column + "]=" + encodeURIComponent(tableSettings.tableSettingsGreater[i].value);
            }
        }

        if (tableSettings.tableSettingsLower.length != 0) {
            for (let i = 0; i < tableSettings.tableSettingsLower.length; i++) {
                const element = tableSettings.tableSettingsLower[i];
                url = url + "&lower[" + tableSettings.tableSettingsLower[i].column + "]=" + encodeURIComponent(tableSettings.tableSettingsLower[i].value);
            }
        }

        if (tableSettings.tableSettingsEquals.length != 0) {
            for (let i = 0; i < tableSettings.tableSettingsEquals.length; i++) {
                const element = tableSettings.tableSettingsEquals[i];
                url = url + "&equals[" + tableSettings.tableSettingsEquals[i].column + "]=" + encodeURIComponent(tableSettings.tableSettingsEquals[i].value);
            }
        }

        if (tableSettings.tableSettingsNotEquals.length != 0) {
            for (let i = 0; i < tableSettings.tableSettingsNotEquals.length; i++) {
                const element = tableSettings.tableSettingsNotEquals[i];
                url = url + "&notequals[" + tableSettings.tableSettingsNotEquals[i].column + "]=" + encodeURIComponent(tableSettings.tableSettingsNotEquals[i].value);
            }
        }

        if (tableSettings.tableSettingsContains.length != 0) {
            for (let i = 0; i < tableSettings.tableSettingsContains.length; i++) {
                const element = tableSettings.tableSettingsContains[i];
                url = url + "&contains[" + tableSettings.tableSettingsContains[i].column + "]=" + encodeURIComponent(tableSettings.tableSettingsContains[i].value);
            }
        }

        //Sorting
        if (tableSettings.sortBy != undefined && tableSettings.sortBy.value != "") {
            var ascOrDesc = tableSettings.sortBy.asc ? "-" : "";
            url = url + "&sortBy=" + ascOrDesc + "" + encodeURIComponent(tableSettings.sortBy.tablevalue);
        }

        return url;
    }

    /**
     * Generic parsing of the responses for tables only!
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    genericParsing(res, model): TableData {
        var dataData: TableData = new TableData();

        if (this.debug) {
        }

        var parsedArray = [];

        /**
         * Section for related_objects to find many_to_many - top lists/objects ending on _mm
         * Author: Tommy Jepsen - tommy@tonsstudio.com
         **/
        function onRecursiveRelatedObjectsForManyToMany(object) {
            var relatedObjectsToAddToUser = [];
            var userObjectPos = -1;
            var objectToAddAs = "";

            //Ending up looping something like {customer_id:1,user_id:1}
            Object.keys(object).forEach(key => {
                if (typeof object[key] === 'object') {
                    onRecursiveRelatedObjectsForManyToMany(object[key]);
                } else {
                    //Now at the bottom of the object on like user_id

                    var singleKeySplit = key.split("_");
                    var singleTable = "";

                    for (let o = 0; o < singleKeySplit.length; o++) {
                        if (o != singleKeySplit.length - 1) {
                            singleTable = singleTable.length == 0 ? singleKeySplit[o] : singleTable + "_" + singleKeySplit[o];
                        }
                    }

                    var gotFound = false;
                    if (singleTable.slice(-1) != "s") {
                        singleTable = singleTable + "s";
                    } else {
                        singleTable = singleTable.slice(0, -1) + "ies";
                    }

                    //Find user id so we can add it to user
                    for (let i = 0; i < res.data.main.length; i++) {
                        const element = res.data.main[i];

                        if (Object.keys(element)[0] == key && element[key] == object[key]) { //Check if user_id(sliced to user) == table
                            userObjectPos = i;
                            gotFound = true;
                        }
                    }

                    //Find table like customers so we can get that data to the user
                    if (res.data.related_objects[singleTable] != undefined) {
                        //Now I have customer it belongs to user, now add it to user
                        relatedObjectsToAddToUser.push(res.data.related_objects[singleTable][object[key]]);
                        gotFound = true;
                    }

                    if (!gotFound) {
                        objectToAddAs = singleTable;
                    }
                }

            });

            if (userObjectPos != -1) {
                if (res.data.main[userObjectPos][objectToAddAs] == undefined) {
                    res.data.main[userObjectPos][objectToAddAs] = relatedObjectsToAddToUser;
                } else {
                    res.data.main[userObjectPos][objectToAddAs] = res.data.main[userObjectPos][objectToAddAs].concat(relatedObjectsToAddToUser);
                }
            }
        }

        //Start to check if many to many
        if (res.data.related_objects != undefined) {
            Object.keys(res.data.related_objects).forEach(relatedObjectKey => {
                var relatedObjects = res.data.related_objects;
                //Connected table only
                if (relatedObjectKey.slice(-7) == "mm") {
                    onRecursiveRelatedObjectsForManyToMany(relatedObjects[relatedObjectKey]);

                    /**
                     * Checking for images in related_objects to add it to users in releated_objects if users has an image_id
                     * Can optimize to not hardcoded into parsing but in models
                     * Author: Tommy Jepsen - tommy@tonsstudio.com
                     **/
                } else if (relatedObjectKey == "images") {
                    Object.keys(res.data.related_objects).forEach(relatedObjectKeyCheck => {
                        if (relatedObjectKeyCheck == "users") {
                            Object.keys(res.data.related_objects[relatedObjectKeyCheck]).forEach(user_id => {
                                Object.keys(res.data.related_objects[relatedObjectKeyCheck][user_id]).forEach(image_id => {
                                    if (image_id == "image_id") {
                                        if (res.data.related_objects.images[res.data.related_objects[relatedObjectKeyCheck][user_id][image_id]] != undefined) {
                                            var imageObj = res.data.related_objects.images[res.data.related_objects[relatedObjectKeyCheck][user_id][image_id]];
                                            res.data.related_objects[relatedObjectKeyCheck][user_id]['images'] = imageObj;
                                        }
                                    }
                                });
                            });
                        }
                    });
                }
            });
        }


        /**
         * Section for Main to find one_to_one or one_to_many
         * Author: Tommy Jepsen - tommy@tonsstudio.com
         **/
        function onLoopRelatedObjectsToAddToMain(table, main, key, callback) {
            var tableObj: any = res.data.related_objects[table];
            var tableFinalObj: any;

            var findValueToFindObjectInRelatedObject = main[key];

            if (tableObj != undefined && tableObj[findValueToFindObjectInRelatedObject] != undefined) {
                main[table] = tableObj[findValueToFindObjectInRelatedObject];
            }

            if (model instanceof User) {
                main.user_fullname = main.user_firstname + " " + main.user_lastname;
                callback(new User(main))
            } else if (model instanceof Store) {
                callback(new Store(main))
            } else if (model instanceof Customer) {
                callback(new Customer(main))
            } else if (model instanceof Assignment) {
                callback(new Assignment(main))
            } else if (model instanceof CustomersForUsers) {
                callback(new CustomersForUsers(main))
            } else {
                callback(main);
            }
        }

        function onAddToMainItem(mainItem) {
            var alreadyExistsPos = -1;
            for (let i = 0; i < parsedArray.length; i++) {
                if (parsedArray[i][model.primary_key] == mainItem[model.primary_key]) {
                    alreadyExistsPos = i;
                }
            }

            if (alreadyExistsPos == -1) {
                parsedArray.push(mainItem);
            } else {
                parsedArray[alreadyExistsPos] = mainItem;
            }
        }

        //Looping through main and sees if there is any ending on _id
        function onLoopMain(mainItem) {
            var foundIdKey = false;
            //Looping through all items in object to see if any tables from related should be put into the object
            Object.keys(mainItem).forEach(key => {
                if (key.slice(-2) == "id" && key != model.primary_key && mainItem[key] != null) {

                    var table = "";
                    var keySplit = key.split("_");
                    var tableFrom = keySplit[0];

                    for (let o = 0; o < keySplit.length; o++) {
                        if (o != keySplit.length - 1) {
                            table = (table.length == 0 ? keySplit[o] : table + "_" + keySplit[o]);
                        }
                    }

                    //TODO REMOVE NEWS AND MAKE GENERIC
                    if (table.slice(-1) == "s" && table != "news") {
                        table = table + "es";
                    } else if (table.slice(-1) == "y") {
                        table = table.slice(0, -1) + "ies";
                    } else if (keySplit.length <= 3 && table != "news") {
                        table = table + "s";
                    }

                    foundIdKey = true;

                    //Find table in related objects and add it to main(E.g. user)
                    onLoopRelatedObjectsToAddToMain(table, mainItem, key, (newDataParsed) => {
                        onAddToMainItem(newDataParsed);
                    })
                }
            });
            //Found no keys ending on _id, so no object in related_objects for this
            if (!foundIdKey) {
                onAddToMainItem(mainItem);
            }
        }

        //Start looping of main
        res.data.main.forEach(mainItem => {
            onLoopMain(mainItem);
        });

        /**
         * Console log the paresd data
         * Author: Tommy Jepsen - tommy@tonsstudio.com
         **/
        if (this.debug) {
        }

        dataData.main = parsedArray;

        // Find offset
        dataData.tableOffset = res.data.offset;


        var meta = res.meta;

        //Implemented to make sure it updates everytime
        if (this.userService.user != undefined) {
            this.userService.setUser(meta.user_details);
            this.userService.setUserSettings(meta.user_settings);
        }

        return dataData;
    }

    onDataAndMetaParsing(res): Data {
        var data: Data = new Data();

        data.data = res.data;
        data.meta = res.meta;

        //Implemented to make sure it updates everytime
        if (this.userService.user != undefined) {
            this.userService.setUser(data.meta.user_details);
            this.userService.setUserSettings(data.meta.user_settings);
        }

        return data;
    }

    /**
     * User
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    postLogin(email, password) {
        this.setHeaders();
        return this.http.post<Data>(this.API_URL + 'login', {email, password})
            .map(res => this.onDataAndMetaParsing(res));
    }

    postForgotPassword(email) {
        this.setHeaders();
        return this.http.post<Data>(this.API_URL + 'lost_password', {email})
            .map(res => this.onDataAndMetaParsing(res));
    }

    postNewPassword(code, password) {
        this.setHeaders();
        return this.http.post<Data>(this.API_URL + 'change_lost_password', {code, password})
            .map(res => this.onDataAndMetaParsing(res));
    }

    getVacations(ts: TableSettings) {
        this.setHeaders();
        return this.http.get<TableData>(this.onBuildEndpoint('vacations', ts), {headers: this.headers})
            .map(res => this.genericParsing(res, new Vacation()));
    }

    postVacation(vacation: Vacation) {
        this.setPostPatchHeaders();
        return this.http.post<Vacation>(this.API_URL + 'vacations', vacation.getPostObject(), {headers: this.headers});
    }

    patchVacation(vacation: Vacation) {
        var v: Vacation = vacation;
        this.setPostPatchHeaders();
        return this.http.patch<Vacation>(this.API_URL + 'vacations/' + v.vacation_id, JSON.stringify(vacation), {headers: this.headers});
    }


    /**
     * User
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    getNews(page: number, news_for_users_read: number) {
        this.setHeaders();
        return this.http.get<TableData>(this.API_URL + 'user/' + this.userService.getUserId() + "/news?offset=" + page + "&read=" + news_for_users_read + "&sortBy=news_id", {headers: this.headers})
    }

    getSingleNews(tableSettings: TableSettings) {
        this.setHeaders();
        return this.http.get<TableData>(this.onBuildEndpoint('news', tableSettings), {headers: this.headers})
            .map(res => this.genericParsing(res, new NewsForUsersMM()));
    }

    getNewsImage(tableSettings: TableSettings) {
        this.setHeaders();
        return this.http.get<TableData>(this.onBuildEndpoint('images_for_news', tableSettings), {headers: this.headers})
            .map(res => this.genericParsing(res, new ImagesForNews()));
    }

    postNews(news: News) {
        this.setPostPatchHeaders();
        return this.http.post<News>(this.API_URL + 'news', news.getPostObject(), {headers: this.headers});
    }

    patchNews(news: News) {
        this.setPostPatchHeaders();
        return this.http.patch<News>(this.API_URL + 'news/' + news.news_id, news.getPostObject(), {headers: this.headers});
    }

    patchNewsForUsers(news: NewsForUsersMM) {
        this.setPostPatchHeaders();
        return this.http.patch<NewsForUsersMM>(this.API_URL + 'news_for_users_mm/' + news.news_for_user_connection_id, news.getPostObject(), {headers: this.headers});
    }

    patchUserSettings(userSettings: UserSettings) {
        var us = new UserSettings(userSettings);
        this.setPostPatchHeaders();
        return this.http.patch<UserSettings>(this.API_URL + 'user_settings', us.postObject(), {headers: this.headers});
    }

    getUserSettings(str: string) {
        this.setHeaders();
        return this.http.get<any>(this.API_URL + 'user_settings', {headers: this.headers});
    }


    /**
     * Assignments/Tasks
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    getAssignments(tableSettings: TableSettings) {
        this.setHeaders();
        return this.http.get<TableData>(this.onBuildEndpoint('assignments', tableSettings), {headers: this.headers})
            .map(res => this.genericParsing(res, new Assignment()));
    }

    getAssignmentsStats(tableSettings: TableSettings) {
        this.setHeaders();
        return this.http.get<TableData>(this.onBuildEndpoint('assignments_stats', tableSettings), {headers: this.headers});
    }

    getAssignment(id: number) {
        this.setHeaders();
        return this.http.get(this.onBuildEndpoint('assignments/' + id, new TableSettings()), {headers: this.headers});
    }

    getAssignmentTodos(tableSettings: TableSettings) {
        this.setHeaders();
        return this.http.get<TableData>(this.onBuildEndpoint('assignment_todos_for_assignments_mm', tableSettings), {headers: this.headers})
            .map(res => this.genericParsing(res, new AssignmentTodosForAssignments()));
    }

    getAssignmentForUsers(tableSettings: TableSettings) {
        this.setHeaders();
        return this.http.get<TableData>(this.onBuildEndpoint('assignment_for_users_mm', tableSettings), {headers: this.headers})
            .map(res => this.genericParsing(res, new AssignmentsForUsersMM()));
    }

    postAssignmentForUsers(a: AssignmentsForUsersMM) {
        this.setPostPatchHeaders();
        return this.http.post<AssignmentsForUsersMM>(this.API_URL + 'assignment_for_users_mm', a.getPostObject(), {headers: this.headers});
    }

    patchAssignmentForUsers(a: AssignmentsForUsersMM) {
        this.setPostPatchHeaders();
        return this.http.patch<AssignmentsForUsersMM>(this.API_URL + 'assignment_for_users_mm/' + a.assignments_for_users_connection_id, a.getPatchObject(), {headers: this.headers});
    }

    postAssignment(a: Assignment) {
        this.setPostPatchHeaders();
        return this.http.post<Assignment>(this.API_URL + 'assignments', a.getPostObject(), {headers: this.headers});
    }

    postAssignmentMM(a: AssignmentTodosForAssignments) {
        this.setPostPatchHeaders();
        return this.http.post<AssignmentTodosForAssignments>(this.API_URL + 'assignment_todos_for_assignments_mm', a.getPostJson(), {headers: this.headers});
    }

    patchAssignment(a: Assignment) {
        this.setPostPatchHeaders();
        return this.http.patch<Assignment>(this.API_URL + 'assignments/' + a.assignment_id, a.getPatchObject(), {headers: this.headers});
    }

    acceptAssignment(id) {
        this.setPostPatchHeaders();
        return this.http.patch<Assignment>(this.API_URL + 'assignments/' + id + '/accept', {}, {headers: this.headers});
    }

    postImportValidation(type: String, storecsv: File, columnSettings: any) {
        const formData: FormData = new FormData();
        formData.append('import', storecsv);
        formData.append('column_settings', JSON.stringify(columnSettings));
        this.setHeaders();
        return this.http.post(this.API_URL + 'import/' + type + '/validate', formData, {headers: this.headers});
    }

    postContinueImportValidation(type, import_id, progress) {
        this.setHeaders();
        return this.http.post(this.API_URL + 'import/' + type + '/validate?import_id=' + import_id + '&progress=' + progress, {}, {headers: this.headers});
    }

    postImport(type, importID, data) {
        this.setHeaders();
        return this.http.post(this.API_URL + 'import/' + importID + '/' + type, data, {headers: this.headers})
    }

    getAssignmentAppendixes(tableSettings: TableSettings) {
        this.setHeaders();
        return this.http.get<TableData>(this.onBuildEndpoint('appendixes_for_assignments', tableSettings), {headers: this.headers})
            .map(res => this.genericParsing(res, new AppendixForAssignment()));
    }

    patchAssignmentAppendixes(ae: AppendixForAssignment) {
        var ae1: AppendixForAssignment = new AppendixForAssignment(ae);
        this.setPostPatchHeaders();
        return this.http.patch<AppendixForAssignment>(this.API_URL + 'appendixes_for_assignments/' + ae.appendix_for_assignment_id, ae1.getPostObject(), {headers: this.headers});
    }

    checkAppendixExists(image: Image): Promise<any> {
        this.setHeaders();
        const formData: FormData = new FormData();
        formData.append('appendix', image.image_file);
        formData.append('appendix_amount', "" + image.appendix_number);
        return this.http.post<Image>(this.API_URL + 'exists', formData, {headers: this.headers}).toPromise();
    }

    /**
     * Assignment executions
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    getAssignmentExecutions(tableSettings: TableSettings) {
        this.setHeaders();
        return this.http.get<TableData>(this.onBuildEndpoint('assignment_executions', tableSettings), {headers: this.headers})
            .map(res => this.genericParsing(res, new AssignmentExecutions()));
    }

    getAssignmentExecutionImages(tableSettings: TableSettings) {
        this.setHeaders();
        return this.http.get<TableData>(this.onBuildEndpoint('images_for_assignment_executions', tableSettings), {headers: this.headers})
            .map(res => this.genericParsing(res, new ImagesForAssignmentExecutions()));
    }

    getAssignmentExecutionAppendixes(tableSettings: TableSettings) {
        this.setHeaders();
        return this.http.get<TableData>(this.onBuildEndpoint('appendixes_for_assignment_executions', tableSettings), {headers: this.headers})
            .map(res => this.genericParsing(res, new AppendixForAssignmentExecutions()));
    }

    postAssignmentExecutions(ae: AssignmentExecutions) {
        this.setPostPatchHeaders();
        return this.http.post<AssignmentExecutions>(this.API_URL + 'assignment_executions', ae.getPostObject(), {headers: this.headers});
    }

    patchAssignmentExecutions(ae: AssignmentExecutions) {
        this.setPostPatchHeaders();
        return this.http.patch<AssignmentExecutions>(this.API_URL + 'assignment_executions/' + ae.assignment_execution_id, ae.getPatchObject(), {headers: this.headers});
    }

    patchAssignmentExecutionImage(ae: ImagesForAssignmentExecutions) {
        var ae1: ImagesForAssignmentExecutions = new ImagesForAssignmentExecutions(ae);
        this.setPostPatchHeaders();
        return this.http.patch<ImagesForAssignmentExecutions>(this.API_URL + 'images_for_assignment_executions/' + ae.images_for_assignment_executions_connection_id, ae1.getPostObject(), {headers: this.headers});
    }

    patchAssignmentExecutionAppendix(ae: AppendixForAssignmentExecutions) {
        var ae1: AppendixForAssignmentExecutions = new AppendixForAssignmentExecutions(ae);
        this.setPostPatchHeaders();
        return this.http.patch<AppendixForAssignmentExecutions>(this.API_URL + 'appendixes_for_assignment_executions/' + ae.appendix_for_assignment_execution_id, ae1.getPostObject(), {headers: this.headers});
    }

    postAssignmentAppendix(image: Image, image_module: string, module_id: number) {
        this.setHeaders();
        const formData: FormData = new FormData();
        formData.append('appendix', image.image_file);
        formData.append('appendix_amount', "" + image.appendix_number);
        return this.http.post<Image>(this.API_URL + 'appendix/assignments?id=' + module_id, formData, {headers: this.headers})
    }


    /**
     * Calendar
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    getCalendar(month) {
        this.setHeaders();
        return this.http.get<[Calendar]>(this.API_URL + 'calendar/get/' + month, {headers: this.headers});
    }

    postEvent(c: Calendar) {
        this.setPostPatchHeaders();
        return this.http.post<Calendar>(this.API_URL + 'calendar/create', c.getObject(), {headers: this.headers});
    }

    postEditEvent(c: Calendar) {
        this.setPostPatchHeaders();
        return this.http.post<Calendar>(this.API_URL + 'calendar/edit', c.getObject(), {headers: this.headers});
    }

    /**
     * Stores
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    getStores(tableSettings: TableSettings) {
        this.setHeaders();
        return this.http.get<TableData>(this.onBuildEndpoint('stores', tableSettings), {headers: this.headers})
            .map(res => this.genericParsing(res, new Store()));
    }

    getStore(tableSettings: TableSettings, id: number) {
        this.setHeaders();
        return this.http.get<TableData>(this.onBuildEndpoint('stores/' + id, tableSettings), {headers: this.headers})
            .map(res => this.genericParsing(res, new Store()));
    }

    postStore(s: Store) {
        this.setPostPatchHeaders();
        return this.http.post<Store>(this.API_URL + 'stores', s.getPostJson(), {headers: this.headers});
    }

    postStoreEdit(s: Store) {
        this.setPostPatchHeaders();
        return this.http.patch<Store>(this.API_URL + 'stores/' + s.store_id, s.getPatchJson(), {headers: this.headers});
    }

    postSaveImport(name: string, table: string, value: string) {
        this.setPostPatchHeaders();
        return this.http.post<Store>(this.API_URL + 'imports', {
            'import_name': name,
            'import_table': table,
            'import_aliases': value
        }, {headers: this.headers});
    }

    getSaveImport() {
        this.setHeaders();
        return this.http.get<TableData>(this.onBuildEndpoint('imports', new TableSettings()), {headers: this.headers})
    }

    postStoreImportCSV(storecsv: File, structure: any) {
        const formData: FormData = new FormData();
        formData.append('import', storecsv);
        formData.append('aliases', JSON.stringify(structure));

        this.setHeaders();
        return this.http.post<Store>(this.API_URL + 'import/stores', formData, {headers: this.headers});
    }

    /**
     * Users
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    getUsers(tableSettings: TableSettings): Observable<TableData> {
        this.setHeaders();
        return this.http.get<TableData>(this.onBuildEndpoint('users', tableSettings), {headers: this.headers})
            .map(res => this.genericParsing(res, new User()));
    }

    postUser(u: User) {
        this.setPostPatchHeaders();
        return this.http.post<User>(this.API_URL + 'users', u.getPostJson(), {headers: this.headers});
    }

    postUserCustomers(u: CustomersForUsers) {
        this.setPostPatchHeaders();
        return this.http.post<CustomersForUsers>(this.API_URL + 'customers_for_users_mm', u.getPostJson(), {headers: this.headers});
    }

    patchUserCustomers(u: CustomersForUsers) {
        this.setPostPatchHeaders();
        return this.http.patch<CustomersForUsers>(this.API_URL + 'customers_for_users_mm/' + u.customer_for_user_connection_id, u.getPostJson(), {headers: this.headers});
    }

    patchUserCustomersMultiple(data) {
        this.setPostPatchHeaders();
        return this.http.patch<CustomersForUsers>(this.API_URL + 'customers_for_users_mm', JSON.stringify(data), {headers: this.headers});
    }

    deleteUserCustomers(user_id, customersForDelete: string) {
        this.setPostPatchHeaders();
        return this.http.delete<CustomersForUsers>(this.API_URL + 'customers_for_users_mm?user_id=' + user_id + "&customer_ids=" + customersForDelete, {headers: this.headers});
    }

    patchUser(u: User) {
        this.setPostPatchHeaders();
        return this.http.patch<User>(this.API_URL + 'users/' + u.user_id, u.getPatchJson(), {headers: this.headers});
    }

    getUser(tableSettings: TableSettings, id: number) {
        this.setHeaders();
        return this.http.get<TableData>(this.onBuildEndpoint('users/' + id, tableSettings), {headers: this.headers})
            .do(data => {
                // @ts-ignore
                this.userService.setCustomerIDForUser(data.meta.user_details.customer_id);
            },)
            .map(res => this.genericParsing(res, new User()));
    }

    getUserCustomers(tableSettings: TableSettings) {
        this.setHeaders();
        return this.http.get<TableData>(this.onBuildEndpoint('customers_for_users_mm', tableSettings), {headers: this.headers})
            .map(res => this.genericParsing(res, new CustomersForUsers()));
    }

    getUserCustomersSelect(customer_id: number) {
        this.setHeaders();
        return this.http.get<any>(this.API_URL + '/change_sub_user?id=' + customer_id, {headers: this.headers});
    }

    getToken(user_id: number) {
        this.setHeaders();
        return this.http.get<TableData>(this.API_URL + '/login_as_user?id=' + user_id, {headers: this.headers});
    }

    getSalesConsultants() {
        return this.http.get<any>(this.API_URL + '/users_for_filter', {headers: this.headers});
    }


    /**
     * Customers
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    getCustomers(tableSettings: TableSettings) {
        this.setHeaders();
        return this.http.get<TableData>(this.onBuildEndpoint('customers', tableSettings, true), {headers: this.headers})
            .map(res => this.genericParsing(res, new Customer()));
    }

    getCustomer(tableSettings: TableSettings, id: number) {
        this.setHeaders();
        return this.http.get<TableData>(this.onBuildEndpoint('customers/' + id, tableSettings), {headers: this.headers})
            .map(res => this.genericParsing(res, new Customer()));
    }

    postCustomer(c: Customer) {
        this.setPostPatchHeaders();
        return this.http.post<[Customer]>(this.API_URL + 'customers', c.getPostJson(), {headers: this.headers});
    }

    patchCustomer(c: Customer) {
        this.setPostPatchHeaders();
        return this.http.patch<[Customer]>(this.API_URL + 'customers/' + c.customer_id, c.getPatchJson(), {headers: this.headers});
    }


    /**
     * Exports
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    getExports(tableSettings: TableSettings) {
        this.setHeaders();
        return this.http.get<TableData>(this.onBuildEndpoint('exports', tableSettings), {headers: this.headers})
            .map(res => this.genericParsing(res, new Export()));
    }

    /**
     * Parameter: page, user_id OR/NONE customer_id, type, salary_been_given(Doesnt require a value), and then all the other from onBuildEndpoint
     * Author: Tommy Jepsen
     **/
    getExportAssignments(tableSettings: TableSettings, export_id) {
        this.setHeaders();
        const export_id_string = '&export_id=' + export_id;
        return this.http.get<TableDataExport>(this.onBuildEndpoint('assignments_for_export', tableSettings) + export_id_string, {headers: this.headers});
    }

    getExportsValues(parameter: string) {
        this.setHeaders();
        return this.http.get<TableData>(this.API_URL + 'export_salary?parameter=' + parameter, {headers: this.headers});
    }

    postExport(exporto: Export) {
        this.setPostPatchHeaders();
        var exportnew = new Export(exporto);
        return this.http.post<[Export]>(this.API_URL + 'exports', exportnew.getPostJson(), {headers: this.headers});
    }


    /**
     * Chains
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    getChains(tableSettings: TableSettings) {
        this.setHeaders();
        return this.http.get<TableData>(this.onBuildEndpoint('chains', tableSettings), {headers: this.headers})
            .map(res => this.genericParsing(res, new Chain()));
    }

    postChain(s: Chain) {
        this.setPostPatchHeaders();
        return this.http.post<Chain>(this.API_URL + 'chains', s.getPostJson(), {headers: this.headers});
    }

    patchChain(s: Chain) {
        this.setPostPatchHeaders();
        return this.http.patch<Chain>(this.API_URL + 'chains/' + s.chain_id, s.getPostJson(), {headers: this.headers});
    }

    getChain(tableSettings: TableSettings, id: number) {
        this.setHeaders();
        return this.http.get<TableData>(this.onBuildEndpoint('chains/' + id, tableSettings), {headers: this.headers})
            .map(res => this.genericParsing(res, new Chain()));
    }

    /**
     * Post image
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    uploadFile(fileData: any, endpoint: string, relationsship: string, id: number) {
        this.setHeaders();
        const formData: FormData = new FormData();

        for (const [key, value] of Object.entries(fileData)) {
            // @ts-ignore
            formData.append(key, value)
        }

        return this.http.post<Image>(this.API_URL + endpoint + '/' + relationsship + '?id=' + id, formData, {headers: this.headers})
    }

    patchImage(ae: Image) {
        var ae1: Image = new Image(ae);
        this.setPostPatchHeaders();
        return this.http.patch<Image>(this.API_URL + 'images/' + ae.image_id, ae1.getPostObject(), {headers: this.headers});
    }

    /**
     * Get user roles
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    getUserRoles() {
        this.setHeaders();
        return this.http.get<TableData>(this.API_URL + 'user_roles', {headers: this.headers});
    }

    /**
     * Send email or sms
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    postSendEmailOrSms(sendSMSOREmail: SendSMSOREmail) {
        this.setPostPatchHeaders();
        return this.http.post<Chain>(this.API_URL + 'send_message', sendSMSOREmail.getPostJson(), {headers: this.headers});
    }


    /**
     * Notification
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    getNotifications(user_id) {
        this.setHeaders();
        return this.http.get<any>(this.API_URL + 'notifications?columns=notification_id,user_id,notifiable_id,notifiable_object,notification_type,notification_data,notification_read,notification_creation,notification_updated,users.user_firstname,users.user_lastname&results=10&equals[user_id]=' + user_id, {headers: this.headers})
            .map(res => this.genericParsing(res, new Notification()));
    }

    postNotifications(notification_ids: any[]) {
        this.setPostPatchHeaders();
        return this.http.patch<Notification>(this.API_URL + 'notifications/' + notification_ids[0], {
            notification_ids: notification_ids,
            notification_read: 1
        }, {headers: this.headers});
    }

    /**
     * User logs
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    getUserLogs(tableSettings: TableSettings) {
        this.setHeaders();
        return this.http.get<TableData>(this.onBuildEndpoint('user_logs', tableSettings), {headers: this.headers})
            .map(res => this.genericParsing(res, new UserLogs()));
    }
}

