import {Component, OnInit} from '@angular/core';
import {UtilService} from '../services/util.service';
import {DataService} from '../services/data.service';
import {LangService} from '../services/lang.service';
import {ApiService} from '../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../models/user';
import {Constants, USER_RIGHT_TYPES} from '../constants';
import {TableSettings} from '../models/tablesettings';
import {TableSettingsEquals} from '../models/tablesettingsequals';
import {Customer} from '../models/customer';
import {NotificationService} from '../shared/notification.service';
import {TableHeader} from '../models/tableheader';
import {UserService} from '../services/user.service';
import {Vacation} from "../models/vacation";
import * as moment from "moment";

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

    isLoadingView: boolean = false;

    showId: number = 0;

    user: User;

    Constants: any = Constants;
    USER_RIGHT_TYPES: any = USER_RIGHT_TYPES;

    assignmentHistoric: any = [];
    assignmentCustomers: Customer[] = [];

    vacations: Vacation[] = [];

    constructor(
        public apiService: ApiService,
        public route: ActivatedRoute,
        private router: Router,
        public utilService: UtilService,
        public lang: LangService,
        public dataService: DataService,
        public notificationService: NotificationService,
        public userService: UserService
    ) {
    }

    ngOnInit() {

        this.route.params.subscribe(params => {
            this.getData();
        });
    }

    getData() {
        this.showId = this.route.snapshot.params['id'];

        if (this.showId != undefined) {
            this.isLoadingView = true;

            var ts: TableSettings = new TableSettings();
            ts.columns = "user_id,user_email,user_firstname,user_lastname,user_postal_code,user_city,user_address,user_password,user_login_attempts,user_blocked,user_type_mercer,user_type_demo,user_role_id,user_phone,user_city,user_cpr,user_address,user_postal_code,user_bank_reg,user_bank_account,user_demo_rate,user_mercer_rate,user_danloen_id,user_notes,user_cooperation_agreements,user_cooperation_agreements_date,user_active,user_contact_on_email,user_contact_on_sms,user_contact_on_app,user_roles.user_role_name,image_id,user_creation,can_create_project_management," + Constants.PARAMETER_IMAGES_ONLY_ACTIVE;
            ts.results = 500;

            this.apiService.getUser(ts, this.showId).subscribe((data) => {
                this.user = data.main[0];
                this.isLoadingView = false;
                this.getDataLogs();
                this.onGetVacation();

            }, (error) => {
                this.notificationService.setNotification(this.lang.la.error_general, this.notificationService.CONSTANT_TYPE_WARNING);
                this.isLoadingView = false;
            });

            var tscfu: TableSettings = new TableSettings();
            tscfu.tableSettingsEquals.push(new TableSettingsEquals("user_id", this.showId));
            var customers = "customers.customer_id,customers.customer_name";
            tscfu.columns = "customers_for_users_mm.customer_for_user_connection_id,customers_for_users_mm.customer_id,customers_for_users_mm.user_id," + customers;
            tscfu.results = 500;

            this.apiService.getUserCustomers(tscfu).subscribe((data) => {
                this.assignmentCustomers = data.main;

            }, (error) => {
            });

        } else {
            this.notificationService.setNotification(this.lang.la.error_general, this.notificationService.CONSTANT_TYPE_WARNING);
            this.router.navigate(['./app/user/list/employee']);
        }
    }

    onCustomPressed() {
        if (this.user.user_active === 0) return;
        this.apiService.getToken(this.user['user_id']).subscribe((data) => {
            Object.keys(data['meta']['user_details']).forEach(key => {
                this.user[key] = data['meta']['user_details'][key];
            });
            this.user.user_token = data['data']['token'];
            this.userService.loginAsFakeUser(this.user, data['meta']['user_settings']);

        }, (error) => {

        })
    }

    onGetVacation() {
        var ts: TableSettings = new TableSettings();
        ts.tableSettingsEquals.push(new TableSettingsEquals("vacation_active", "1"));
        ts.tableSettingsEquals.push(new TableSettingsEquals("user_id", this.user.user_id));
        this.apiService.getVacations(ts).subscribe((data) => {
            this.vacations = data.main;
        }, (error) => {
        })
    }

    getDataLogs() {
        var tablesettingsLogs: TableSettings = new TableSettings();
        tablesettingsLogs.columns = "user_log_id,user_log_table_id,user_id,user_log_table,user_log_action,user_log_text,user_log_creation,user_log_updated,users.image_id,users.user_firstname,users.user_lastname,images.image_id,images.image_link";
        tablesettingsLogs.tableSettingsEquals.push(new TableSettingsEquals("user_log_table_id", this.user.user_id));
        tablesettingsLogs.tableSettingsEquals.push(new TableSettingsEquals("user_log_table", "users"));
        tablesettingsLogs.sortBy = new TableHeader("user_log_updated", "user_log_updated");

        this.apiService.getUserLogs(tablesettingsLogs).subscribe((data) => {
            this.assignmentHistoric = data.main.map(log => {
                let text;
                try {
                    text = log.parsed_user_log_text;
                } catch (e) {
                    text = [log.user_log_text];
                }
                const array = [];
                Object.keys(text).forEach(key => {
                    if (key.includes('tid')) {
                        text[key].from = moment(text[key].from).format('YYYY-MM-DD HH:mm:00');
                        text[key].to = moment(text[key].to).format('YYYY-MM-DD HH:mm:00');
                    }
                    array.push({'fieldName': key, 'from': text[key].from, 'to': text[key].to});
                });
                log.user_log_text = array;
                return log;
            });
        }, (error) => {
        });
    }

    onPrint() {

        var childWindow = window.open('', 'childWindow', 'location=yes, menubar=yes, toolbar=yes');
        childWindow.document.open();
        childWindow.document.write('<html><head></head><body>');
        childWindow.document.write('Samarbejdsaftale godkendt: ' + document.getElementById('samarbejdsaftalerdate')['value'].replace(/\n/gi, '<br>'));
        childWindow.document.write('<br/><br/>');
        childWindow.document.write(document.getElementById('samarbejdsaftaler')['value'].replace(/\n/gi, '<br>'));
        childWindow.document.write('</body></html>');
        childWindow.print();
        childWindow.document.close();
        childWindow.close();
    }

}
