/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dealchecker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./dealchecker.component";
import * as i5 from "../services/user.service";
import * as i6 from "../shared/notification.service";
import * as i7 from "../services/lang.service";
import * as i8 from "../services/api.service";
var styles_DealcheckerComponent = [i0.styles];
var RenderType_DealcheckerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DealcheckerComponent, data: {} });
export { RenderType_DealcheckerComponent as RenderType_DealcheckerComponent };
function View_DealcheckerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "textarea", [["class", "form-control"], ["disabled", "true"], ["id", "samarbejdsaftaler"], ["rows", "10"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.agreement = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(4, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = "true"; var currVal_8 = _co.agreement; _ck(_v, 4, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_DealcheckerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "col-sm-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "card card-top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "card-body "]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "col-sm-12 push-p-30-b text-center push-p-30-t"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h1", [["class", "h4"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Underskriv samarbejdsaftalen for at komme igang"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DealcheckerComponent_2)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "col-sm-12 text-center push-p-30-t push-p-30-b"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAcceptAgreement() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(12, { "is-loading": 0 }), (_l()(), i1.ɵted(-1, null, [" Accepter "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userService.user.user_cooperation_agreements; _ck(_v, 8, 0, currVal_0); var currVal_1 = "btn btn-primary"; var currVal_2 = _ck(_v, 12, 0, _co.isLoading); _ck(_v, 11, 0, currVal_1, currVal_2); }, null); }
export function View_DealcheckerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DealcheckerComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.userService.user; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DealcheckerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dealchecker", [], null, null, null, View_DealcheckerComponent_0, RenderType_DealcheckerComponent)), i1.ɵdid(1, 114688, null, 0, i4.DealcheckerComponent, [i5.UserService, i6.NotificationService, i7.LangService, i8.ApiService, i3.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DealcheckerComponentNgFactory = i1.ɵccf("app-dealchecker", i4.DealcheckerComponent, View_DealcheckerComponent_Host_0, {}, {}, []);
export { DealcheckerComponentNgFactory as DealcheckerComponentNgFactory };
