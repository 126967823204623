<td>
    <i class="material-icons"
       [ngClass]="validateRow(store) ? 'text-is-success' : 'text-is-warning'">{{ validateRow(store) ? 'check' : 'warning' }}</i>
</td>
<td *ngFor="let header of headers; let key = index;" [ngSwitch]="header.tablevalue">
    <!--
    -
    - Dropdowns for selecting chain if need be
    -
    -->
    <span *ngSwitchCase="'chain'">
        <span *ngIf="!store['chain_id']" class="text-is-danger small">{{ store['raw'][header.tablevalue] }}</span>
        {{ !!store[header.tablevalue] ? store[header.tablevalue].chain_name : ''}}
        <app-dropdown-select
                *ngIf="!store[header.tablevalue]"
                [arrayList]="chains"
                [loadMore]="false"
                arrayListName="chain_name"
                arrayListId="chain_id"
                [arrayListSelectedObject]="store['chain']"
                [arrayListSelectedId]="store['chain_id']"
                (selectedItem)="store['chain_id'] = $event">
        </app-dropdown-select>
    </span>
    <span class="position-relative storeHandling" *ngSwitchCase="'alreadyExistsAsStore'">
        <ng-container *ngIf="store['alreadyExistsAsStore'] != undefined">
            <i class="material-icons icon-fixed"
               [ngClass]="store['alreadyExistsAsStore'].action ? 'text-is-success' : 'text-is-danger'"
               (click)="store['expandHandleExistingStore'] = !store['expandHandleExistingStore']">{{ store['expandHandleExistingStore'] ? 'close' : 'store'}}</i>

            <span class="push-p-30-l d-block z-0">
                <app-dropdown-select
                        *ngIf="store['expandHandleExistingStore']"
                        [arrayList]="handleExistingStoreOptions"
                        [loadMore]="false"
                        arrayListName="title"
                        arrayListId="value"
                        (selectedItem)="selectHandleExistingStoreAction($event, store)">
            </app-dropdown-select>
            </span>

        </ng-container>
    </span>

    <span *ngSwitchDefault>{{ store[header.tablevalue] }}</span>
</td>