//Angular
import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {registerLocaleData} from '@angular/common';
import localeDa from '@angular/common/locales/da';
//Outers
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {FileUploadModule} from 'ng2-file-upload/ng2-file-upload';
import {SortableModule} from 'ngx-bootstrap/sortable';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {defineLocale} from 'ngx-bootstrap/chronos';
import {PapaParseModule} from 'ngx-papaparse';
import {daLocale} from 'ngx-bootstrap/locale';
// Import Bugsnag and the Angular integration
import bugsnag from '@bugsnag/js'
import {BugsnagErrorHandler} from '@bugsnag/plugin-angular'
//Services
import {UserService} from './services/user.service';
import {ApiService} from './services/api.service';
import {LangService} from './services/lang.service';
import {UtilService} from './services/util.service';
import {DataService} from './services/data.service';
// Request Interceptor
import {RequestInterceptor} from './services/request-interceptor';
//Components
import {AppComponent} from './app/app.component';

import {LoginComponent} from './login/login.component';
import {DashboardComponent} from './dashboard/dashboard.component';

import {NewsComponent} from './news/news.component';
import {NewsCreateComponent} from './news-create/news-create.component';


import {ProfileComponent} from './profile/profile.component';

import {AssignmentCreateComponent} from './assignment-create/assignment-create.component';
import {AssignmentComponent} from './assignment/assignment.component';
import {AssignmentListComponent} from './assignment-list/assignment-list.component';

import {StoreComponent} from './store/store.component';
import {StoreListComponent} from './store-list/store-list.component';
import {StoreCreateComponent} from './store-create/store-create.component';
import {StoreImportComponent} from './store-import/store-import.component';

import {UserListComponent} from './user-list/user-list.component';
import {UserSendComponent} from './user-send/user-send.component';
import {UserCreateComponent} from './user-create/user-create.component';
import {UserComponent} from './user/user.component';

import {CustomerComponent} from './customer/customer.component';
import {CustomerListComponent} from './customer-list/customer-list.component';
import {CustomerCreateComponent} from './customer-create/customer-create.component';

import {ExportListComponent} from './export-list/export-list.component';
import {ExportCreateComponent} from './export-create/export-create.component';
import {ExportComponent} from './export/export.component';

import {MyCalendarComponent} from './calendar/calendar.component';

import {BusinessintelligenceComponent} from './businessintelligence/businessintelligence.component';

import {ChainComponent} from './chain/chain.component';
import {ChainListComponent} from './chain-list/chain-list.component';
import {ChainCreateComponent} from './chain-create/chain-create.component';

import {LostpasswordComponent} from './lostpassword/lostpassword.component';
import {NewpasswordComponent} from './newpassword/newpassword.component';
//Components and directives for other views
import {CalendarCompComponent} from './shared/calendar-comp/calendar-comp.component';

import {DropdownSelectTypeComponent} from './shared/dropdown-select-type/dropdown-select-type.component';
import {DropdownSelectComponent} from './shared/dropdown-select/dropdown-select.component';

import {GraphComponent} from './shared/graph/graph.component';
import {GraphCircleComponent} from './shared/graph-circle/graph-circle.component';

import {DropdownSelectMultipleComponent} from './shared/dropdown-select-multiple/dropdown-select-multiple.component';
import {GraphLineComponent} from './shared/graph-line/graph-line.component';
import {ImageuploaderComponent} from './shared/imageuploader/imageuploader.component';
import {AppendixUploaderComponent} from './shared/appendixuploader/appendixuploader.component';
import {TableComponent} from './shared/table/table.component';
import {NotificationService} from './shared/notification.service';
import {NotificationComponent} from './shared/notification/notification.component';
import {LoadingComponent} from './shared/loading/loading.component';
import {InputUploadComponent} from './shared/inputupload/inputupload.component';
import {InputAppendixComponent} from './shared/inputappendix/inputappendix.component';
import {DropdownSelectMulitpleTypeComponent} from './shared/dropdown-select-type-multiple/dropdown-select-type-multiple.component';

import {CSVService} from './services/csv.service';
import {MdatePipe} from './shared/mdate.pipe';
import {DealcheckerComponent} from './dealchecker/dealchecker.component';
import {Linkify} from './shared/linkify.pipe';
import {AssignmentImportComponent} from './assignment-import/assignment-import.component';
import {UserRoleGuard} from './guards/user-role.guard';
import {Constants} from './constants';
import {CurrencyPipe} from "./shared/currency.pipe";
// @ts-ignore
import {version} from '../../package.json';
import * as process from 'process'
import {ImportColumnMapperComponent} from "./shared/import-column-mapper/import-column-mapper.component";
import {ImportPreviewerComponent} from "./shared/import-previewer/import-previewer.component";
import {ImportAssignmentPreviewRow} from "./shared/import-previewer/assignment-previewer/import-assignment-preview-row";
import {ImportStorePreviewRow} from "./shared/import-previewer/store-previewer/import-store-preview-row";
import {SearchTableComponent} from "./shared/search-table/search-table.component";
import {TableSettingsService} from "./services/table-settings.service";
import {FileUploaderService} from "./services/fileUploaderService";
import {TeamplayerPaginationComponent} from "./shared/pagination/pagination.component";
import {AlertModule, BsDropdownModule} from "ngx-bootstrap";
import {CustomerSwitcherComponent} from "./customer-switcher/customer-switcher.component";

registerLocaleData(localeDa, 'da');

defineLocale('da', daLocale);


// configure Bugsnag asap
const bugsnagClient = bugsnag({
    apiKey: 'cebf8cd02e24473285f11b7797f17de1',
    appVersion: version,
    notifyReleaseStages: ['production', 'staging'],
    releaseStage: process.env.NODE_ENV
});

// create a factory which will return the bugsnag error handler
export function errorHandlerFactory() {
    return new BugsnagErrorHandler(bugsnagClient)
}

const appRoutes: Routes = [
    {path: '', component: LoginComponent},
    {path: 'login', component: LoginComponent},
    {path: 'lostpassword', component: LostpasswordComponent},
    {path: 'newpassword', component: NewpasswordComponent},
    {
        path: 'app',  data : {version : version}, component: DashboardComponent, children: [
            {path: '', redirectTo: 'news', pathMatch: 'full'},
            {path: 'news', component: NewsComponent},
            {path: 'news/create', component: NewsCreateComponent},
            {path: 'news/edit/:id', component: NewsCreateComponent},

            {path: 'task/create', component: AssignmentCreateComponent},
            {path: 'task/import', component: AssignmentImportComponent},
            {path: 'task/copy/:id', component: AssignmentCreateComponent},
            {path: 'task/edit/:id', component: AssignmentCreateComponent},
            {path: 'task/show/:id', component: AssignmentComponent},
            {path: 'task/list', component: AssignmentListComponent},

            {path: 'profile/my', component: ProfileComponent},

            {path: 'store/list', component: StoreListComponent},
            {path: 'store/import', component: StoreImportComponent},
            {
                path: 'store/create',
                component: StoreCreateComponent,
                canActivate: [UserRoleGuard],
                data: {roles: [Constants.USER_ROLE_ADMIN_ID, Constants.USER_ROLE_TEAMPLAYER_ID]}
            },
            {path: 'store/edit/:id', component: StoreCreateComponent},
            {path: 'store/show/:id', component: StoreComponent},

            {path: 'user/list', component: UserListComponent},
            {path: 'user/list/:type', component: UserListComponent},
            {path: 'user/send', component: UserSendComponent},
            {
                path: 'user/create',
                component: UserCreateComponent,
                canActivate: [UserRoleGuard],
                data: {roles: [Constants.USER_ROLE_ADMIN_ID, Constants.USER_ROLE_TEAMPLAYER_ID]}
            },
            {path: 'user/edit/:id', component: UserCreateComponent},
            {
                path: 'user/show/:id',
                component: UserComponent,
                canActivate: [UserRoleGuard],
                data: {roles: [Constants.USER_ROLE_ADMIN_ID, Constants.USER_ROLE_TEAMPLAYER_ID]}
            },

            {path: 'customer/list', component: CustomerListComponent},
            {path: 'customer/show/:id', component: CustomerComponent},
            {
                path: 'customer/create', component: CustomerCreateComponent,
                data: {roles: [Constants.USER_ROLE_ADMIN_ID, Constants.USER_ROLE_TEAMPLAYER_ID]}
            },
            {path: 'customer/edit/:id', component: CustomerCreateComponent},

            {path: 'export/show/:id', component: ExportComponent},
            {path: 'export/list', component: ExportListComponent},
            {path: 'export/create', component: ExportCreateComponent},

            {path: 'calendar', component: MyCalendarComponent},

            {path: 'chain/list', component: ChainListComponent},
            {path: 'chain/create', component: ChainCreateComponent},
            {path: 'chain/edit/:id', component: ChainCreateComponent},
            {path: 'chain/show/:id', component: ChainComponent},

            {path: 'businessintelligence', component: BusinessintelligenceComponent},
            {path: 'deal', component: DealcheckerComponent},
        ],
    },
    { path: '**', redirectTo: 'app/news' }
];

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        DashboardComponent,
        NewsComponent,

        AssignmentCreateComponent,
        AssignmentComponent,
        AssignmentImportComponent,
        AssignmentListComponent,

        ProfileComponent,

        StoreComponent,
        StoreListComponent,
        StoreCreateComponent,

        UserListComponent,
        UserSendComponent,
        UserCreateComponent,
        UserComponent,

        CustomerComponent,
        CustomerListComponent,
        CustomerCreateComponent,
        CustomerSwitcherComponent,

        ExportListComponent,
        ExportCreateComponent,
        ExportComponent,

        ImportColumnMapperComponent,
        ImportPreviewerComponent,
        ImportAssignmentPreviewRow,
        ImportStorePreviewRow,

        StoreImportComponent,

        NewsCreateComponent,

        MyCalendarComponent,
        BusinessintelligenceComponent,

        ChainListComponent,
        ChainCreateComponent,
        ChainComponent,

        DealcheckerComponent,

        //Other view components
        DropdownSelectComponent,
        DropdownSelectTypeComponent,
        DropdownSelectMultipleComponent,
        DropdownSelectMulitpleTypeComponent,

        CalendarCompComponent,

        GraphComponent,
        GraphCircleComponent,
        GraphLineComponent,

        ImageuploaderComponent,
        AppendixUploaderComponent,

        TableComponent,
        SearchTableComponent,
        TeamplayerPaginationComponent,

        NotificationComponent,
        LoadingComponent,

        InputUploadComponent,
        InputAppendixComponent,

        MdatePipe,
        Linkify,
        CurrencyPipe,

        LostpasswordComponent,

        NewpasswordComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        PapaParseModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule.forRoot(
            appRoutes
        ),
        TimepickerModule.forRoot(),
        TooltipModule.forRoot(),
        BsDatepickerModule.forRoot(),
        FileUploadModule,
        BsDropdownModule.forRoot(),
        AlertModule.forRoot(),
    ],
    providers: [
        UserService,
        ApiService,
        LangService,
        UtilService,
        DataService,
        CSVService,
        NotificationService,
        TableSettingsService,
        FileUploaderService,
        {provide: LOCALE_ID, useValue: "da-DK"},
        {provide: ErrorHandler, useFactory: errorHandlerFactory},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

