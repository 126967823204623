export class TableOffset {
    public current_offset: number = 0;
    public highest_offset: number = 0;
    public rows_count: number = 0;

    constructor() {
    }

    public setValuesForTableOffset(current_offsetP, highest_offsetP, rows_countP) {
        this.current_offset = current_offsetP;
        this.highest_offset = highest_offsetP;
        this.rows_count = rows_countP;
    }
}