<h1 class="push-m-50-b h2">Importer butikker</h1>

<div class="card card-top" *ngIf="!importID">

    <div class="card-body row">

        <div class="col-sm-12">

            <label>Vælg en CSV nedenfor</label>
            <div class="custom-file">
                <input type="file" class="custom-file-input" [ngClass]="{'is-invalid': isInvalidImageExist}"
                       accept="text/csv"
                       id="validatedCustomFile" ng2FileSelect [uploader]="imageUploader">
                <label class="custom-file-label" for="validatedCustomFile">
					<span *ngFor="let item of imageUploader.queue">
						{{ item?.file?.name }} ({{ item?.file?.size / 1024 / 1024 | number:'.2' }} MB)
					</span>
                </label>
            </div>
        </div>

        <!-- Error -->
        <div class="row is-fullwidth" *ngIf="isError">
            <div class="col-sm-12 is-fullwidth">
                <div class="alert is-fullwidth push-m-20 alert-danger">
                    {{isErrorMessage}}
                </div>
            </div>
        </div>

        <!-- Loader -->
        <div class="row">
            <div class="col-sm-12">
                <div class="loader" *ngIf="isLoadingView"></div>
            </div>
        </div>

        <!-- Column mapping of uploaded CSV -->
        <app-import-column-mapper class="is-fullwidth" [csv]="this.csv"
                                  [validation_columns]="this.validation_columns"></app-import-column-mapper>

        <div class="col-sm-12 has-text-right push-p-20-t" *ngIf="csv.length != 0">
            <button class="btn btn-icon btn-primary" [ngClass]="{'is-loading': isLoading}" (click)="onUploadImport()">
                <span>Forhåndsvisning</span>
            </button>
        </div>
    </div>
</div>

<div *ngIf="importID">
    <app-import-previewer
            type="stores"
            [validateRow]="isRowValid"
            [helperData]="{chains: chains}"
            [csvHeaders]="csvHeaders"
            [rowPages]="storePages"
            [isLoading]="isLoading"
            [isImported]="isImported"
            [isValidating]="isValidating"
            [importProgressCount]="importSaveProgress"
            (readyForImport)="import($event)"
    >
    </app-import-previewer>
</div>
