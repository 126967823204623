import {Injectable} from '@angular/core';
import {da} from './lang_da';

@Injectable()
export class LangService {
    public la: any = da;

    constructor() {
    }
}
