<!-- Search -->
<app-search-table [(tableSettings)]="tableSettings" (refreshData)="emitGetData(tableSettings)"
                  *ngIf="canSearch"></app-search-table>

<!-- Table -->
<div class="card card-top">
    <div class="card-body card-table">
        <div class="table-responsive">
            <table class="table table-hover table-striped">
                <thead>
                <tr>
                    <th scope="col" *ngFor="let tableHeader of tableSettings.tableHeaders; let pos = index"
                        (click)="onSortTable(pos)">
							<span class="push-m-5-r">
								{{tableHeader.value}}
							</span>

                        <!-- ASC OR DESC -->
                        <svg class="th-icon" fill="#6c757d" height="18" viewBox="0 0 24 24" width="18"
                             xmlns="http://www.w3.org/2000/svg"
                             *ngIf="tableSettings.sortBy.value == tableHeader.value && tableHeader.asc">
                            <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"/>
                            <path d="M0-.75h24v24H0z" fill="none"/>
                        </svg>
                        <svg class="th-icon" fill="#6c757d" height="18" viewBox="0 0 24 24" width="18"
                             xmlns="http://www.w3.org/2000/svg"
                             *ngIf="tableSettings.sortBy.value == tableHeader.value && !tableHeader.asc">
                            <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"/>
                            <path d="M0 0h24v24H0z" fill="none"/>
                        </svg>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of tableData; let pos = index">
                    <td *ngFor="let tableHeader of tableSettings.tableHeaders"
                        (click)="onRowLinkPressed(pos, tableHeader.tablevalue)">
                        <span *ngIf="tableHeader.tablevalue != 'login'">
                            {{utilService.onGetTableContent(item, tableHeader.tablevalue)}}
                        </span>
                        <span *ngIf="tableHeader.tablevalue == 'login' && userService.userOriginal === undefined">
                            <button *ngIf="userService.user.user_id !== item.user_id"
                                    class="btn btn-primary btn-table-right btn-sm"
                                    [disabled]="item['user_active'] === 0"
                                    [ngClass]="{'is-loading': item['isLoading']}"
                                    (click)="onCustomLinkPressed(pos)">
                                {{item['user_active'] === 1 ? 'Log ind som' : 'Deaktiveret'}}
                            </button>
                        </span>
                    </td>
                </tr>
                <!-- Loading -->
                <tr *ngIf="isLoadingTable">
                    <td [attr.colspan]="tableSettings.tableHeaders.length" class="table-empty">
                        <div class="loader"></div>
                    </td>
                </tr>
                <!-- Empty -->
                <tr *ngIf="(tableData == undefined || tableData.length == 0) && !isLoadingTable">
                    <td [attr.colspan]="tableSettings.tableHeaders.length" class="table-empty">
                        <p>
                            {{lang.la.table_empty}}
                        </p>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<!-- Pagination -->
<nav *ngIf="tableSettings != undefined && tableSettings.tableOffset.highest_offset != 1 && tableSettings.tableOffset.highest_offset != 0">
    <app-pagination [tableSettings]="tableSettings" (pageChange)="onPageChange($event)" (goToFirstPage)="onPageChangeFirst()" (goToLastPage)="onPageChangeLast()"></app-pagination>
</nav>
