import {Component, Input} from '@angular/core';
import {TableHeader} from "../../../models/tableheader";
import {LangService} from "../../../services/lang.service";
import {UtilService} from "../../../services/util.service";

@Component({
    selector: '[app-import-store-preview-row]',
    templateUrl: './import-store-preview-row.html',
    styleUrls: ['./import-store-preview-row.scss']
})
export class ImportStorePreviewRow {
    @Input() store: any;
    @Input() headers: TableHeader[] = [];
    @Input() chains: any = [];
    @Input() validateRow: Function;

    handleExistingStoreOptions: any[];

    constructor(
        public lang: LangService,
        public utilService: UtilService
    ) {
        this.handleExistingStoreOptions = [
            {
                'value': 'ignoreRow',
                'title': 'Importer ikke denne butik'
            },
            {
                'value': 'setExistingInactive',
                'title': 'Deaktiver eks. butik og importer'
            },
        ]
    }

    selectHandleExistingStoreAction($event, store) {
        store['alreadyExistsAsStore'].action = $event;
        store['expandHandleExistingStore'] = false;
    }
}
