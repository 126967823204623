<div class="row push-m-50-b ">

    <div class="col-4"><h1 class="h2">Kunde</h1></div>
    <div class="col-8 has-text-right" *ngIf="!isLoadingView">
        <button class="btn btn-outline-secondary" routerLink="../../edit/{{customer.customer_id}}">Rediger</button>
    </div>
</div>

<!-- Loading -->
<app-loading *ngIf="isLoadingView"></app-loading>
<!-- Card -->
<div class="card card-top" *ngIf="!isLoadingView">
    <div class="card-body row">

        <div class="col-sm-6">

            <div class="form-group">
                <label class="text-is-grey">{{lang.la.name}}</label>
                <p class="h5">{{customer.customer_name}}</p>
            </div>

            <div class="form-group">
                <label class="text-is-grey">{{lang.la.email}}</label>
                <p class="h5">{{customer.customer_email}}</p>
            </div>

        </div>

        <div class="col-sm-6">
            <div class="form-group">
                <label class="text-is-grey">{{lang.la.phoneno}}</label>
                <p class="h5">{{customer.customer_phone}}</p>
            </div>
        </div>
    </div>

    <div class="card-divider"></div>

    <div class="card-body row">
        <div class="col-sm-6">
            <div class="form-group">
                <label class="text-is-grey">{{lang.la.street}}</label>
                <p class="h5">{{customer.customer_address}}</p>
            </div>
            <div class="form-group">
                <label class="text-is-grey">{{lang.la.city}}</label>
                <p class="h5">{{customer.customer_city}}</p>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="text-is-grey">{{lang.la.postalcode}}</label>
                <p class="h5">{{customer.customer_postal_code}}</p>
            </div>
        </div>
    </div>

    <div class="card-divider"></div>

    <div class="card-body row">
        <div class="col-sm-6">
            <div class="form-group">
                <label class="text-is-grey">{{lang.la.contactperson}}</label>
                <p class="h5">{{customer.customer_contact_name}}</p>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="text-is-grey">{{lang.la.contactperson_position}}</label>
                <p class="h5">{{customer.customer_contact_position}}</p>
            </div>
        </div>
    </div>
</div>
