import {Injectable} from '@angular/core';
import {InputCheckbox} from '../models/inputcheckbox';
import {InputString} from '../models/inputstring';
import * as moment from 'moment-timezone';
import {Constants} from '../constants';
import {Retry} from '../models/retry';
import {UserLogs} from '../models/user-logs';
import {Router} from '@angular/router';
import {User} from '../models/user';
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";


@Injectable()
export class UtilService {

    timeoption_quarter: any = ["00:00", "00:15", "00:30", "00:45", "01:00", "01:15", "01:30", "01:45", "02:00", "02:15", "02:30", "02:45", "03:00", "03:15", "03:30", "03:45", "04:00", "04:15", "04:30", "04:45", "05:00", "05:15", "05:30", "05:45", "06:00", "06:15", "06:30", "06:45", "07:00", "07:15", "07:30", "07:45", "08:00", "08:15", "08:30", "08:45", "09:00", "09:15", "09:30", "09:45", "10:00", "10:15", "10:30", "10:45", "11:00", "11:15", "11:30", "11:45", "12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45", "14:00", "14:15", "14:30", "14:45", "15:00", "15:15", "15:30", "15:45", "16:00", "16:15", "16:30", "16:45", "17:00", "17:15", "17:30", "17:45", "18:00", "18:15", "18:30", "18:45", "19:00", "19:15", "19:30", "19:45", "20:00", "20:15", "20:30", "20:45", "21:00", "21:15", "21:30", "21:45", "22:00", "22:15", "22:30", "22:45", "23:00", "23:15", "23:30", "23:45"];
    timeoption_quarter_12: any = ["00:00", "00:15", "00:30", "00:45", "01:00", "01:15", "01:30", "01:45", "02:00", "02:15", "02:30", "02:45", "03:00", "03:15", "03:30", "03:45", "04:00", "04:15", "04:30", "04:45", "05:00", "05:15", "05:30", "05:45", "06:00", "06:15", "06:30", "06:45", "07:00", "07:15", "07:30", "07:45", "08:00", "08:15", "08:30", "08:45", "09:00", "09:15", "09:30", "09:45", "10:00", "10:15", "10:30", "10:45", "11:00", "11:15", "11:30", "11:45", "12:00"];

    constructor() {
    }

    sortData(table, sorting_table, asc) {
        var compare = (a, b) => {
            a = typeof a === "string" ? a.toLowerCase() : a;
            b = typeof b === "string" ? b.toLowerCase() : b;
            if (asc) {
                if (a[sorting_table] < b[sorting_table]) {
                    return -1;
                } else if (a[sorting_table] > b[sorting_table]) {
                    return 1;
                }
            } else {
                if (a[sorting_table] < b[sorting_table]) {
                    return 1;
                } else if (a[sorting_table] > b[sorting_table]) {
                    return -1;
                }
            }
            return 0;
        };
        return table.sort(compare);
    }

    public onFormatToEstimate(ms: number) {
        let minutes = ms / 60 / 1000;
        return minutes.toString();
    }


    /**
     * View utils
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    onUppercase(str) {
        if (str != undefined && str.length > 1) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        } else {
            return str;
        }
    }

    public onDisplayLogTextPressed(router: Router, log: UserLogs) {
        if (log.user_log_table == "assignments") {
            router.navigate(['./app/task/show/', log.user_log_table_id]);
        } else {
            router.navigate(['./app/user/show/', log.user_log_table_id]);
        }
    }

    public onDisplayLogText(log: UserLogs, u: User) {
        let text = '';

        switch (log.user_log_action) {
            case 'edited':
                if (log.user_log_table == "assignments") {
                    text = `redigerede  i opgave <a>#${log.user_log_table_id}</a>`;
                } else {
                    text = `redigerede  hos <a>${u.user_firstname + '' + u.user_lastname}</a>`;
                }
                break;
            case 'accepted':
                text = 'accepterede opgaven';
                break;
            case 'salary_been_given':
                text = `har <a href="/app/export/show/${log.parsed_user_log_text.export_id}">kørt løn</a> for opgaven`;
                break;
            case 'invoice_been_run':
                text = `har <a href="/app/export/show/${log.parsed_user_log_text.export_id}">faktureret</a> opgaven`;
                break;
            default:
                if (log.user_log_table == "assignments") {
                    text = `oprettede opgave <a>#${log.user_log_table_id}</a>`;
                } else {
                    text = `oprettede <a>${u.user_firstname + '' + u.user_lastname}</a>`;
                }
                break;
        }

        return text;
    }

    public onImageToView(image_name: string) {
        return Constants.IMAGE_URL + image_name + ".jpg";
    }

    public onImageToViewThumbnail(image_name: string) {
        return Constants.IMAGE_URL + image_name + "_thumb.jpg";
    }

    public onAppendixToView(image_name: string) {
        image_name = image_name.toLowerCase();
        let imageType = image_name ? image_name.substring(image_name.lastIndexOf('.'), image_name.length).replace('.', '') : 'others';
        imageType = imageType.toLowerCase();

        const imageFormats = ['png', 'jpeg', 'jpg', 'gif'];
        const wordFormats = ['doc', 'dot', 'wbk', 'docx', 'dotx', 'dotm', 'docb'];
        const excelFormats = ['xls', 'xlt', 'xlm', 'xlsx', 'xlsm', 'xltx', 'xltm', 'xlsb', 'xla', 'xlam', 'xll', 'xlw'];
        const powerPointFormats = ['ppt', 'pot', 'pps', 'pptx', 'pptm', 'potx', 'potm', 'ppam', 'ppsx', 'ppsm', 'sldx', 'sldm'];
        const pdfFormat = ['pdf'];
        const csvFormat = ['csv'];

        if (imageFormats.includes(imageType)) {
            return Constants.APPENDIX_URL + image_name;
        } else if (wordFormats.includes(imageType)) {
            return '../assets/placeholder_doc.svg';
        } else if (excelFormats.includes(imageType)) {
            return '../assets/placeholder_xls.svg';
        } else if (powerPointFormats.includes(imageType)) {
            return '../assets/placeholder_ppt.svg';
        } else if (pdfFormat.includes(imageType)) {
            return '../assets/placeholder_pdf.svg';
        } else if (csvFormat.includes(imageType)) {
            return '../assets/placeholder_csv.svg';
        } else {
            return '../assets/placeholder.svg';
        }
    }

    public onAppendixToDownload(image_name: string) {
        return Constants.APPENDIX_URL + image_name;
    }

    public onConvertMiliToHoursAndMili(duration: string): string {
        var durationNumber = parseInt(duration);

        if (durationNumber < 1000) {
            return "00:00";
        }

        var m = (durationNumber / 1000 / 60) % 60;
        var h = ((durationNumber - m) / 1000 / 60 / 60);

        // @ts-ignore
        var minutes = parseInt(m).toString(10);
        // @ts-ignore
        var hours = parseInt(h).toString(10);

        var hoursString = (hours < 10) ? "0" + hours : hours;
        var minutesString = (minutes < 10) ? "0" + minutes : minutes;

        return hoursString + ":" + minutesString;
    }

    public onConvertHHmmFormatToMili(format: string): number {
        var hhmmformat = format.split(":");
        var hh: number = Math.floor(+hhmmformat[0] * 3600000);
        var mm: number = Math.floor(+hhmmformat[1] * 60000);
        return (hh + mm);
    }

    public onConvertMiliToFormat(duration: string): string {
        var durationNumber = parseInt(duration);

        if (durationNumber < 1000) {
            return "00:00:00";
        }

        var seconds = parseInt("" + (durationNumber / 1000) % 60);
        var minutes = parseInt("" + (durationNumber / (1000 * 60)) % 60);
        var hours = parseInt("" + (durationNumber / (1000 * 60 * 60)) % 24);

        var hoursString = (hours < 10) ? "0" + hours : hours;
        var minutesString = (minutes < 10) ? "0" + minutes : minutes;
        var secondsString = (seconds < 10) ? "0" + seconds : seconds;

        return hoursString + ":" + minutesString + ":" + secondsString;
    }

    public onConvertDatesToDurationsMiliseconds(datestart: any, dateend: any): number {
        var ms: number = moment(dateend).diff(moment(datestart));
        return +moment.duration(ms);
    }

    public convertDatesToFormat(datestart: any, dateend: any): string {
        return this.onConvertMiliToFormat("" + this.onConvertDatesToDurationsMiliseconds(datestart, dateend));
    }

    public convertMiliToHours(mili: any): number {
        var hours = +mili / 1000 / 60 / 60;
        return hours;
    }

    public convertEstimateToMili(mili: string): number {
        var miliSplit = mili.split(":");
        var miliReturn = ((+miliSplit[0] * 60 * 60) + (+miliSplit[1] * 60)) * 1000;
        return miliReturn;
    }

    public retry: {} = {};

    public onRetryApi(identifier: string, callback, error) {
        if (this.retry[identifier] == undefined) {
            this.retry[identifier] = new Retry(identifier)
        } else {
            this.retry[identifier].retry_number = this.retry[identifier].retry_number + 1;
        }

        if (this.retry[identifier].retry_number == 3) {
            delete this.retry[identifier];
            error();
            return;
        } else {
            setTimeout(() => {
                callback();
            }, 1000);
        }
    }

    public onGetTableContentDot(item, tablevalue): string {
        if (tablevalue.indexOf(".") !== -1) {
            var splitTableValue = tablevalue.split(".");

            if (item[splitTableValue[0]] == undefined) {
                return "";
            }

            if (Array.isArray(item[splitTableValue[0]])) {
                var returnString = "";
                for (let i = 0; i < item[splitTableValue[0]].length; i++) {
                    const element = item[splitTableValue[0]][i][splitTableValue[1]];
                    returnString = returnString + "\n" + element;
                }
                return returnString;
            }

            if (item[splitTableValue[0]][splitTableValue[1]] == undefined) {
                return "";
            }

            return item[splitTableValue[0]][splitTableValue[1]];
        }
        return item[tablevalue];

    }

    public onGetTableContent(item, tablevalue, dropdown = false): string {
        if (tablevalue.indexOf(".") !== -1 && tablevalue.indexOf("&") === -1) {
            return this.onGetTableContentDot(item, tablevalue);
        }

        if (tablevalue.indexOf("&") !== -1) {

            var splitTableValue = tablevalue.split("&");


            var firstValue = "";
            var lastValue = "";

            if (splitTableValue[0].indexOf(".") !== -1) {
                firstValue = this.onGetTableContentDot(item, splitTableValue[0]);
            } else {
                firstValue = item[splitTableValue[0]];
            }

            if (splitTableValue[1].indexOf(".") !== -1) {
                lastValue = this.onGetTableContentDot(item, splitTableValue[1]);
            } else {
                lastValue = item[splitTableValue[1]];
            }

            return firstValue + " " + lastValue;
        }

        if (tablevalue === 'store_name' && dropdown) {
            return `${item[tablevalue]} (${item['store_address']})`;
        }
        if (tablevalue === 'customers' && item['customers'].length) {
            return item['customers'].map(customer => customer.customer_name).join(', ');
        }
        if (tablevalue === 'user_firstname') {
            return item['user_firstname'] + ' ' + item['user_lastname'];
        }
        return item[tablevalue];
    }

    /**
     * Validations
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    public checkFilled(control: string): string {
        if (control == undefined || control.length == 0) {
            return "Skal udfyldes";
        } else {
            return "";
        }
    }

    public checkIfChecked(...boxes: InputCheckbox[]): string {
        var anyCheckd = false;
        for (let i = 0; i < boxes.length; i++) {
            if (boxes[i].value) {
                anyCheckd = true;
            }
        }
        if (anyCheckd) {
            return "";
        } else {
            return "Vælg mindst én opgave";
        }
    }

    public checkMultipleIfChecked(...inputCheckboxs: InputCheckbox[]): boolean {
        var anyCheckd = false;
        for (let i = 0; i < inputCheckboxs.length; i++) {
            if (inputCheckboxs[i].error = this.checkIfChecked(inputCheckboxs[i])) {
                anyCheckd = false;
            } else {
                anyCheckd = true;
            }
        }
        return anyCheckd;
    }

    public checkMultipleIfvalid(...inputStrings: InputString[]) {
        var anyCheckd = false;
        for (let i = 0; i < inputStrings.length; i++) {
            if (inputStrings[i].error = this.checkFilled(inputStrings[i].value)) {
                anyCheckd = true;
            }
        }
        return anyCheckd;
    }

    public resetErrors(...inputStrings: InputString[]) {
        for (let i = 0; i < inputStrings.length; i++) {
            inputStrings[i].error = "";
        }
    }

    public checkNumber(control: string): string {
        var pattern = /^\d+$/;

        if (control == undefined || !pattern.test(control)) {
            return "Skal være et nummer";
        } else {
            return "";
        }
    }

    public checkLength(control: string, nolength: number): string {

        if (control == undefined || control.length > nolength) {
            return "Må ikke være længere end " + nolength;
        } else {
            return "";
        }
    }

    public checkMail(control: string): string {
        var emailFilter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/;
        if (!emailFilter.test(control)) {
            return "Ikke gyldig email";
        }
        return "";
    }

    public checkPhoneNumber(control: string) {
        if (control == undefined || control.length < 8) {
            return "Ikke gyldigt telefonnummer"
        }
        return "";
    }

    public checkPassword(input: InputString) {
        if (input.value.length < 8) {
            input.error = "Mindst 8";
        }
        if (input.value.length > 40) {
            input.error = "For stor";
        }
        var pwFilter = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
        if (!pwFilter.test(input.value)) {
            input.error = "Adgangskoden skal være mindst 8 tegn, indeholde mindst et stort og et småt bogstav og mindst et tal";
        }

        return input.error === '';
    }

    public implodeTitle(array, key: string) {
        if (typeof array == 'string') return array;

        return array
            .map(item => {
                return ' ' + this.onUppercase(item[key]).trim()
            })
            .toString()
            .trim();
    }

    public chunkArray(array: any[], size) {
        let chunks = [];
        for (let i = 0; i < Math.ceil(array.length / size); i++) {
            chunks.push(array.slice(size * i, size * i + size));
        }

        return chunks;
    }

    public fromUTC(date: string | Date) {
        return moment.tz(date, 'UTC').tz('Europe/Copenhagen');
    }

    public toUTC(date: string | Date) {
        return moment.tz(date, 'Europe/Copenhagen').tz('UTC');
    }

    public getDomElement(selector){
        return new Promise((resolve, reject) => {
            let element = document.querySelector(selector);
            const interval = setInterval(() => {
                if(!element){
                    element = document.querySelector(selector);
                }

                if(element){
                    clearInterval(interval);
                    resolve(element);
                }
            }, 50);
        });
    }

    public downloadFile(fileName, contentType, xhr){
        var blob = new Blob([xhr.response], {type: contentType});
        var link = document.createElement('a');

        if (window.navigator.msSaveOrOpenBlob) {
            //IE11 & Edge
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
            //Other browsers
            link.href = window.URL.createObjectURL(blob);
        }

        link.download = fileName;
        link.style.cssText = 'display:none;';
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    public getDatePickerConfig(){
        let config = new BsDatepickerConfig();
        config['locale'] = 'da';
        return config;
    }
}
