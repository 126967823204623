export class Consultant {
    public id: number;
    public name: string;
    public customer_id: number;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }

    //
    public getObject() {
        return {
            id: this.id,
        }
    }
}