<div class="row push-m-50-b">
    <div class="col-4">
        <h1 class="float-left h2">{{lang.la.chain}}</h1>
    </div>
    <div class="col-8 has-text-right" *ngIf="chain != undefined">
        <button class="btn btn-outline-secondary"
                [routerLink]="['../../edit/', chain.chain_id]">{{lang.la.edit}}</button>
    </div>
</div>


<!-- Loading -->
<div class="card card-top  card-loading" *ngIf="isLoadingView">
    <div class="card-body">
        <div class="loader"></div>
    </div>
</div>
<div class="card card-top" *ngIf="!isLoadingView">
    <div class="card-body row">

        <div class="col-sm-6">
            <div class="form-group">
                <label class="text-is-grey">{{lang.la.name}}</label>
                <p class="h5">{{chain.chain_name}}</p>
            </div>
        </div>

        <div class="col-sm-6" *ngIf="chain.images != undefined">
            <a target="_blank" href="{{utilService.onImageToView(chain.images.image_link)}}">
                <img [src]="utilService.onImageToView(chain.images.image_link)" class="chain-image" alt="">
            </a>
        </div>

    </div>

</div>
