import {Component, OnInit} from '@angular/core';
import {LangService} from '../services/lang.service';
import {InputString} from '../models/inputstring';
import {ApiService} from '../services/api.service';
import {UtilService} from '../services/util.service';

@Component({
    selector: 'app-lostpassword',
    templateUrl: './lostpassword.component.html',
    styleUrls: ['./lostpassword.component.scss']
})
export class LostpasswordComponent implements OnInit {

    isLoading: boolean = false;
    isSuccessful: boolean = false;

    inputEmail: InputString = new InputString();

    constructor(
        public lang: LangService,
        public apiService: ApiService,
        public utilService: UtilService,
    ) {
    }

    ngOnInit() {
    }

    onForgotPassword() {
        if (this.utilService.checkMultipleIfvalid(this.inputEmail)) {
            return;
        }

        this.isLoading = true;
        this.apiService.postForgotPassword(this.inputEmail.value).subscribe((data) => {
            this.isSuccessful = true;
            this.isLoading = false;
        }, (error) => {
            this.inputEmail.error = error.error.message;
            this.isLoading = false;
        });

    }

}
