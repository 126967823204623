import { OnChanges } from '@angular/core';
var ImportColumnMapperComponent = /** @class */ (function () {
    function ImportColumnMapperComponent() {
        this.csv = [];
        this.validation_columns = [];
    }
    ImportColumnMapperComponent.prototype.ngOnChanges = function (changes) {
        this.mapKnownColumns();
    };
    ImportColumnMapperComponent.prototype.isColumnSelected = function (csv_header, index) {
        return !!this.validation_columns.find(function (col) { return csv_header == col.csv_header && col.picked_by == index; });
    };
    ImportColumnMapperComponent.prototype.onColumnSelected = function (csv_header, type, position) {
        if (type == -1) {
            // unset the csv_header to remove the selection
            var col = this.validation_columns.find(function (col) { return col.csv_header == csv_header; });
            col.picked_by = false;
            col.csv_header = undefined;
        }
        else {
            // set the csv_header to add the selection
            var col = this.validation_columns.find(function (col) { return col.type == type; });
            col.csv_header = csv_header;
            col.picked_by = position;
        }
    };
    ImportColumnMapperComponent.prototype.maxLengthString = function (str) {
        if (str.length > 35) {
            return str.slice(0, 35) + "..";
        }
        else {
            return str;
        }
    };
    ImportColumnMapperComponent.prototype.mapKnownColumns = function () {
        if (this.csv.length < 1)
            return;
        var csv_headers = this.csv[0];
        var column_idx = 0;
        var _loop_1 = function (csv_header) {
            var column = this_1.validation_columns.find(function (col) { return col.alias === csv_header.toLowerCase(); });
            if (column && column.picked_by === false) {
                column.picked_by = column_idx;
                column.csv_header = csv_header;
            }
            column_idx++;
        };
        var this_1 = this;
        for (var _i = 0, csv_headers_1 = csv_headers; _i < csv_headers_1.length; _i++) {
            var csv_header = csv_headers_1[_i];
            _loop_1(csv_header);
        }
    };
    return ImportColumnMapperComponent;
}());
export { ImportColumnMapperComponent };
