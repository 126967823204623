import {Image} from "./image";

export class Chain {

    public primary_key: string = "chain_id";

    public chain_id: number;
    public chain_name: string;
    public images: Image;

    public getObject() {
        return {
            chain_id: this.chain_id,
        }
    }

    public getPostJson() {
        return JSON.stringify({
            chain_name: this.chain_name,
        })
    }

    public getPatchJson() {
        return JSON.stringify({
            chain_id: this.chain_id,
            chain_name: this.chain_name,
        })
    }
}