import {Injectable} from '@angular/core';
import {Store} from '../models/store';
import {Assignment} from '../models/assignment';
import {Customer} from '../models/customer';
import {Type} from '../models/type';
import {Chain} from '../models/chain';
import {User} from '../models/user';
import {ApiService} from './api.service';
import {TableSettings} from '../models/tablesettings';
import {TableHeader} from '../models/tableheader';
import {UserRole} from '../models/user-role';
import {TableSettingsEquals} from '../models/tablesettingsequals';
import {Constants} from '../constants';
import {UserService} from './user.service';
import {Notification} from '../models/notification';

@Injectable()
export class DataService {

    //DUMMY DELETE
    names1 = ["Jackson", "Aiden", "Liam", "Lucas", "Noah", "Mason", "Jayden", "Ethan", "Jacob", "Jack", "Caden", "Logan", "Benjamin", "Michael", "Caleb", "Ryan", "Alexander", "Elijah", "James", "William", "Oliver", "Connor", "Matthew", "Daniel", "Luke", "Brayden", "Jayce", "Henry", "Carter", "Dylan", "Gabriel", "Joshua", "Nicholas", "Isaac", "Owen", "Nathan", "Grayson", "Eli", "Landon", "Andrew", "Max", "Samuel", "Gavin", "Wyatt", "Christian", "Hunter", "Cameron", "Evan", "Charlie", "David", "Sebastian", "Joseph", "Dominic", "Anthony", "Colton", "John", "Tyler", "Zachary", "Thomas", "Julian", "Levi", "Adam", "Isaiah", "Alex", "Aaron", "Parker", "Cooper", "Miles", "Chase", "Muhammad", "Christopher", "Blake", "Austin", "Jordan", "Leo", "Jonathan", "Adrian", "Colin", "Hudson", "Ian", "Xavier", "Camden", "Tristan", "Carson", "Jason", "Nolan", "Riley", "Lincoln", "Brody", "Bentley", "Nathaniel", "Josiah", "Declan", "Jake", "Asher", "Jeremiah", "Cole", "Mateo", "Micah", "Elliot"];

    //Data not saved in localstorage but for session
    public assignmentListPage: number = 1;
    public newsUnread: number = 0;
    public notificationsUnread: boolean = false;

    //Data saved in local storage
    public notifications: Notification[] = [];
    public chains: Chain[] = [];
    public types: Type[] = [];
    public userRoles: UserRole[] = [];
    public stores: Store[] = [];
    public assignments: Assignment[] = [];
    public customers: Customer[] = [];
    public customersForUsers: Customer[] = [];
    public consultants: User[] = [];
    public employees: User[] = [];

    //Constants
    CONSTANT_DATA_ASSIGNMENTS = "data_assignments";
    CONSTANT_DATA_STORES = "data_stores";
    CONSTANT_DATA_CUSTOMERS = "data_customers";
    CONSTANT_DATA_CHAINS = "data_chains";
    CONSTANT_DATA_USER_ROLES = "data_user_roles";
    CONSTANT_DATA_EMPLOYEES = "data_employee";
    CONSTANT_DATA_CONSULTANTS = "data_consultants";
    CONSTANT_DATA_CUSTOMERS_FOR_USERS = "data_customers_for_users";

    constructor(
        private apiService: ApiService,
        private userService: UserService
    ) {
    }

    public loadData() {
        if (localStorage.getItem(this.CONSTANT_DATA_ASSIGNMENTS) != undefined) {
            this.assignments = JSON.parse(localStorage.getItem(this.CONSTANT_DATA_ASSIGNMENTS)) as Assignment[];
        }
        if (localStorage.getItem(this.CONSTANT_DATA_STORES) != undefined) {
            this.stores = JSON.parse(localStorage.getItem(this.CONSTANT_DATA_STORES)) as Store[];
        }
        if (localStorage.getItem(this.CONSTANT_DATA_CUSTOMERS) != undefined) {
            this.customers = JSON.parse(localStorage.getItem(this.CONSTANT_DATA_CUSTOMERS)).filter(customer => customer.customer_active === 1) as Customer[];
        }
        if (localStorage.getItem(this.CONSTANT_DATA_CHAINS) != undefined) {
            this.chains = JSON.parse(localStorage.getItem(this.CONSTANT_DATA_CHAINS)) as Chain[];
        }
        if (localStorage.getItem(this.CONSTANT_DATA_EMPLOYEES) != undefined) {
            this.employees = JSON.parse(localStorage.getItem(this.CONSTANT_DATA_EMPLOYEES)) as User[];
        }
        if (localStorage.getItem(this.CONSTANT_DATA_USER_ROLES) != undefined) {
            this.userRoles = JSON.parse(localStorage.getItem(this.CONSTANT_DATA_USER_ROLES)) as UserRole[];
        }
        if (localStorage.getItem(this.CONSTANT_DATA_CONSULTANTS) != undefined) {
            this.consultants = JSON.parse(localStorage.getItem(this.CONSTANT_DATA_CONSULTANTS)) as User[];
        }
    }

    public saveAssignment(assignmentsParam) {
        this.assignments = assignmentsParam;
        localStorage.setItem(this.CONSTANT_DATA_ASSIGNMENTS, JSON.stringify(this.assignments));
    }

    public saveStores(storesParam) {
        this.stores = storesParam;
        localStorage.setItem(this.CONSTANT_DATA_STORES, JSON.stringify(this.stores));
    }

    public saveCustomers(customersParam) {
        this.customers = customersParam;
        localStorage.setItem(this.CONSTANT_DATA_CUSTOMERS, JSON.stringify(this.customers));
    }

    public saveChains(chainsParam) {
        this.chains = chainsParam;
        localStorage.setItem(this.CONSTANT_DATA_CHAINS, JSON.stringify(this.chains));
    }

    public saveEmployees(employeeParam) {
        this.employees = employeeParam;
        localStorage.setItem(this.CONSTANT_DATA_EMPLOYEES, JSON.stringify(this.employees));
    }

    public saveUserRoles(userTypesP) {
        this.userRoles = userTypesP;
        localStorage.setItem(this.CONSTANT_DATA_USER_ROLES, JSON.stringify(this.userRoles));
    }

    public saveConsltants(u) {
        this.consultants = u;
        localStorage.setItem(this.CONSTANT_DATA_CONSULTANTS, JSON.stringify(this.consultants));
    }

    public saveCustomersForUsers(u) {
        this.customersForUsers = u;
        localStorage.setItem(this.CONSTANT_DATA_CUSTOMERS_FOR_USERS, JSON.stringify(this.customersForUsers));
    }

    public getStores() {
        var ts: TableSettings = new TableSettings();
        ts.results = 50;
        this.apiService.getStores(ts).subscribe((data) => {
            this.saveStores(data.main);
        }, (error) => {
        });
    }

    public getCustomers() {
        var ts: TableSettings = new TableSettings();
        ts.sortBy = new TableHeader("customer_name", "customer_name");
        ts.sortBy.asc = !ts.sortBy.asc;
        ts.results = 50;
        this.apiService.getCustomers(ts).subscribe((data) => {
            this.saveCustomers(data.main);
        }, (error) => {
        });
    }

    public getCustomersForUsers() {
        var tscfu: TableSettings = new TableSettings();
        if (!this.userService.hasOwnProperty('user') || this.userService.user === undefined) {
            this.userService.setUser(this.userService.getUserLocalStorage());
        }
        tscfu.tableSettingsEquals.push(new TableSettingsEquals("user_id", this.userService.getUserId()));
        var customers = "customers.customer_id,customers.customer_name";
        tscfu.columns = "customers_for_users_mm.user_id,customers_for_users_mm.customer_for_user_connection_id,customers_for_users_mm.customer_id,customers_for_users_mm.user_id," + customers;
        tscfu.sortBy = new TableHeader("customers.customer_name", "customers.customer_name");
        tscfu.sortBy.asc = !tscfu.sortBy.asc;

        this.apiService.getUserCustomers(tscfu).subscribe((data) => {
            this.saveCustomersForUsers(data.main);
        }, (error) => {
        })
    }

    public getCustomersForUsersCB(CB) {
        var tscfu: TableSettings = new TableSettings();
        if (this.userService.hasOwnProperty('user') && this.userService.getUserId() !== undefined) {
            tscfu.tableSettingsEquals.push(new TableSettingsEquals("user_id", this.userService.getUserId()));
            var customers = "customers.customer_id,customers.customer_name";
            tscfu.columns = "customers_for_users_mm.user_id,customers_for_users_mm.customer_for_user_connection_id,customers_for_users_mm.customer_id,customers_for_users_mm.user_id," + customers;
            tscfu.sortBy = new TableHeader("customers.customer_name", "customers.customer_name");
            tscfu.sortBy.asc = !tscfu.sortBy.asc;
            this.apiService.getUserCustomers(tscfu).subscribe((data) => {
                this.saveCustomersForUsers(data.main);
                CB();
            }, (error) => {
            })
        }
    }

    public getSalesConsultants() {
        var ts: TableSettings = new TableSettings();
        ts.results = 50;
        ts.sortBy = new TableHeader("user_name", "user_name");
        ts.sortBy.asc = !ts.sortBy.asc;
        ts.tableSettingsEquals.push(new TableSettingsEquals("user_role_id", Constants.USER_ROLE_CONSULTANT_ID));
        this.apiService.getUsers(ts).subscribe((data) => {
            this.saveConsltants(data.main);
        }, (error) => {
        });
    }

    public getChains() {
        var ts: TableSettings = new TableSettings();
        ts.results = 50;
        this.apiService.getChains(ts).subscribe((data) => {
            this.saveChains(data.main);
        }, (error) => {
        });
    }

    public getUserRoles() {

        this.apiService.getUserRoles().subscribe((data) => {
            this.saveUserRoles(data['data'].main);
        }, (error) => {
        });
    }

    public getEmployees() {
        var ts: TableSettings = new TableSettings();
        ts.results = 500;
        ts.tableSettingsEquals.push(new TableSettingsEquals("user_role_id", Constants.USER_ROLE_EMPLOYEE_ID));
        this.apiService.getUsers(ts).subscribe((data) => {
            this.saveEmployees(data.main);
        }, (error) => {
        });
    }

    public getNotifications() {
        if(!this.userService.getUserId()) return;

        this.apiService.getNotifications(this.userService.getUserId()).subscribe((data) => {
            this.notifications = data.main;
            this.isNotificationsUnread();
        }, (error) => {
        });
    }

    public isNotificationsUnread() {
        var found = false;

        for (let i = 0; i < this.notifications.length; i++) {
            const element = this.notifications[i];
            if (element.notification_read == 0) {
                found = true;
                this.notificationsUnread = true;
            }
        }

        if (!found) {
            this.notificationsUnread = false;
        }
    }


    //Find
    public getCustomersForUsersPosFromId(id: any): number {
        if (id == null) {
            return -1;
        }
        for (let i = 0; i < this.customersForUsers.length; i++) {
            const element = this.customersForUsers[i];
            if (element.customer_id == id) {
                return i;
            }
        }
    }
}
