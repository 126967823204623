import {User} from "./user";

export class Vacation {
    public primary_key: string = "vacation_id";

    public user_id: number;
    public vacation_id: number;
    public vacation_start: string;
    public vacation_end: string;
    public vacation_active: number;
    public users: User;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }

    public getPostObject() {
        var object = this;
        delete object.primary_key;
        return JSON.stringify(object);
    }
}