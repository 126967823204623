import {Component, OnInit} from '@angular/core';
import {UtilService} from '../services/util.service';
import {DataService} from '../services/data.service';
import {ApiService} from '../services/api.service';
import {LangService} from '../services/lang.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TableSettings} from '../models/tablesettings';
import {Chain} from '@angular/compiler';
import {Constants} from '../constants';

@Component({
    selector: 'app-chain',
    templateUrl: './chain.component.html',
    styleUrls: ['./chain.component.scss']
})
export class ChainComponent implements OnInit {

    isLoadingView: boolean = false;

    storeId: number = -1;
    chain: Chain;

    constructor(
        public apiService: ApiService,
        public route: ActivatedRoute,
        private router: Router,
        public utilService: UtilService,
        public lang: LangService,
        public dataService: DataService
    ) {
    }

    ngOnInit() {
        this.storeId = this.route.snapshot.params['id'];
        this.getData();
    }

    getData() {
        this.isLoadingView = true;

        var ts: TableSettings = new TableSettings();
        ts.columns = "chain_id,chain_name," + Constants.PARAMETER_IMAGES_ONLY_ACTIVE;

        this.apiService.getChain(ts, this.storeId).subscribe((data) => {

            if (data.main[0] != undefined) {
                this.chain = data.main[0];
            }
            this.isLoadingView = false;
        }, (error) => {
            this.isLoadingView = false;
        });
    }

}
