import {Component, OnInit} from '@angular/core';
import {UserService} from '../services/user.service';
import {User} from '../models/user';
import {NotificationService} from '../shared/notification.service';
import {LangService} from '../services/lang.service';
import {ApiService} from '../services/api.service';
import * as moment from 'moment';
import {Location} from '@angular/common';

@Component({
    selector: 'app-dealchecker',
    templateUrl: './dealchecker.component.html',
    styleUrls: ['./dealchecker.component.scss']
})
export class DealcheckerComponent implements OnInit {

    isLoading: boolean = false;
    agreement = '';

    constructor(
        public userService: UserService,
        public notificationService: NotificationService,
        public lang: LangService,
        public apiService: ApiService,
        public location: Location
    ) {
    }

    ngOnInit() {
        this.agreement = this.userService.user.user_cooperation_agreements.replace(/<[^>]*>?/gm, '');
    }

    onAcceptAgreement() {
        var u: User = new User();
        u.user_id = this.userService.getUserId();
        u.user_cooperation_agreements_date = moment().format("YYYY-MM-DD HH:mm");

        this.apiService.patchUser(u).subscribe((data) => {
            this.notificationService.setNotification(this.lang.la.success_cooperation_deal, this.notificationService.CONSTANT_TYPE_NORMAL);
            this.userService.setUser(new User(data['data']));
            this.location.back();
        }, (error) => {
            this.isLoading = false;
            this.notificationService.setNotification(this.lang.la.error_general, this.notificationService.CONSTANT_TYPE_WARNING);
        })
    }

}
