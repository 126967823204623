<div class="card card-top">
    <div class="card-body card-table">
        <div class="table-responsive">
            <table class="table table-hover table-striped import-table">
                <thead>
                <tr>
                    <th>Klar?</th>
                    <th *ngFor="let header of headers; let pos = index;">{{header.value}}</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngIf="type === 'assignments'">
                    <tr *ngFor="let rowData of rowPages[currentPage];"
                        app-import-assignment-preview-row
                        [validateRow]="validateRow"
                        [headers]="headers"
                        [assignment]="rowData"
                        [assignmentTodos]="helperData.assignmentTodos"
                        [stores]="helperData.stores"
                        [employees]="helperData.employees"
                        [salesConsultants]="helperData.salesConsultants"
                        [customers]="helperData.customers">
                    </tr>
                </ng-container>
                <ng-container *ngIf="type === 'stores'">
                    <tr *ngFor="let rowData of rowPages[currentPage];"
                        app-import-store-preview-row
                        [validateRow]="validateRow"
                        [headers]="headers"
                        [store]="rowData"
                        [chains]="helperData.chains">
                    </tr>
                </ng-container>
                </tbody>
            </table>
        </div>
        <nav>
            <div class="push-m-30-t">
                <span class="float-left push-m-15-l push-m-15-b">
                    Antal opgaver: {{ isValidating ? 'Opdaterer..' : this.totalRowCount }}<br>
                    Antal sider: {{ this.rowPages.length }}<br>
                    {{ isValidating ? 'Disse tal vil ændre sig, mens siden validerer resten af den uploadede fil' : importProgress()}}
                </span>
                <button class="btn btn-primary float-right push-m-15-r" (click)="validateAllPages()"
                        [ngClass]="{'is-loading': isLoading}"
                        [disabled]="isLoading || isImported || isValidating">{{ isValidating ? 'Validerer stadig..' : 'Importer'}}</button>
            </div>
            <ul class="pagination justify-content-center push-m-5-t" *ngIf="this.rowPages.length > 1">
                <li class="page-item disabled" [ngClass]="{'disabled': this.currentPage == 0}"
                    (click)="changePage(this.currentPage - 1)">
                    <a class="page-link">{{lang.la.previous}}</a>
                </li>
                <li class="page-item disabled"><a class="page-link">{{this.currentPage + 1}}</a></li>
                <li class="page-item" (click)="this.changePage(this.currentPage + 1)"
                    [ngClass]="{'disabled': this.currentPage + 1 === this.rowPages.length}">
                    <a class="page-link">{{lang.la.next}}</a>
                </li>
            </ul>
        </nav>
    </div>
</div>