import {Image} from "./image";

export class ImagesForAssignmentExecutions {

    public primary_key: string = "images_for_assignment_executions_connection_id";

    public images_for_assignment_executions_connection_id: number;
    public assignment_execution_id: number;
    public image_id: string;
    public images_for_assignment_executions_active: number;
    public isLoading: boolean = false;

    public images: Image;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }

    public getPostObject(): string {
        var obj = this;
        delete obj.primary_key;
        delete obj.images;
        delete obj.isLoading;
        return JSON.stringify(obj);
    }
}