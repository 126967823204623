import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {LangService} from '../../services/lang.service';
import {UtilService} from '../../services/util.service';

@Component({
    selector: 'app-dropdown-select',
    templateUrl: './dropdown-select.component.html'
})
export class DropdownSelectComponent implements OnInit, OnChanges {

    @Input() arrayList: any[] = [];
    @Input() arrayListName: string = "name";
    @Input() arrayListId: string = "id";
    @Input() arrayListSelectedId: string = "";
    @Input() arrayListSelectedObject: any;
    @Input() disabled ?: boolean;
    @Input() loadMore: boolean = true;
    @Output() selectedItem = new EventEmitter();
    @Output() onLoadMore = new EventEmitter();

    isLoadingMore: boolean = false;
    isNoMoreToLoad: boolean = false;

    isAItemSelected: boolean = false;

    @Input() error: string = "";

    inputDropdown: string = "";

    arrayListForView: any[] = [];

    @Input()
    isDropdownActive: boolean = false;

    constructor(
        public lang: LangService,
        public utilService: UtilService
    ) {
    }

    ngOnInit() {
        if (!this.loadMore) this.isNoMoreToLoad = true;
        this.arrayListForView = this.arrayList;
        if (this.arrayListSelectedId != "") {
            for (let i = 0; i < this.arrayList.length; i++) {
                if (this.arrayList[i][this.arrayListId].toString() == this.arrayListSelectedId) {
                    this.inputDropdown = this.utilService.onGetTableContent(this.arrayList[i], this.arrayListName);
                }
            }
        }

        if (this.arrayListSelectedObject != undefined) {
            this.inputDropdown = this.utilService.onGetTableContent(this.arrayListSelectedObject, this.arrayListName);
        }
    }

    ngOnChanges() {
        this.arrayListForView = this.arrayList;
        this.inputDropdown = "";

        if (this.arrayListSelectedId != "") {
            for (let i = 0; i < this.arrayList.length; i++) {
                if (this.arrayList[i][this.arrayListId] == this.arrayListSelectedId) {
                    this.inputDropdown = this.utilService.onGetTableContent(this.arrayListForView[i], this.arrayListName);
                }
            }
        }
        // if(this.arrayListSelectedObject != undefined) {
        // 	this.inputDropdown = this.utilService.onGetTableContent(this.arrayListSelectedObject, this.arrayListName);
        // }
    }

    onAddedNewArray() {
    }

    onLoadMorePressed() {
        this.isLoadingMore = true;
        this.onLoadMore.emit();
    }

    onClearSearch() {
        this.inputDropdown = "";
        this.selectedItem.emit("");
    }

    /**
     * Searching through the results
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    onChangeInputDropdown() {
        this.error = "";
        this.arrayListForView = [];
        for (let i = 0; i < this.arrayList.length; i++) {
            const list_items = this.arrayListName.split('&');
            let list_items_values = {};
            let item_found = false;
            for (let a = 0; a < list_items.length; a++) {
                if (this.arrayList[i][list_items[a]]) list_items_values[list_items[a]] = this.arrayList[i][list_items[a]].toLowerCase();
                if (this.arrayList[i][list_items[a]] && this.arrayList[i][list_items[a]].toLowerCase().match(this.inputDropdown.toLowerCase())) {
                    this.arrayListForView.push(this.arrayList[i]);
                    item_found = true;
                    break;
                }
            }

            //If item is not found, join all columns searchin for, andmath against that
            if (!item_found && list_items.length > 1) {
                let values_combined = Object.keys(list_items_values).map(function (item) {
                    return list_items_values[item];
                });
                if (values_combined.join(' ').toLowerCase().match(this.inputDropdown.toLowerCase())) {
                    this.arrayListForView.push(this.arrayList[i]);
                    item_found = true;
                }
            }

        }
    }

    onItemSelected(pos) {
        if (this.arrayListForView.length < pos) {
            return;
        }

        this.error = "";

        this.isAItemSelected = true;

        this.inputDropdown = this.utilService.onGetTableContent(this.arrayListForView[pos], this.arrayListName);

        this.selectedItem.emit(this.arrayListForView[pos][this.arrayListId]);

        this.isDropdownActive = false;
    }

    onFocusOut() {
        setTimeout(() => {
            if (!this.isAItemSelected) {
                this.inputDropdown = "";
            }
            this.isDropdownActive = false;
        }, 500);
    }
}
