<!-- Modal -->

<div class="col-12">
    <div class="row border-top border-bottom border-right border-left">

        <div class="col-12 fadeIn fileupload-first"
             *ngIf="imageUploader == undefined || imageUploader.queue == undefined || imageUploader.queue.length == 0">
            <label ng2FileDrop (fileOver)="fileOverBase($event)" [uploader]="imageUploader"
                   class="well my-drop-zone fileuploader fileContainer">

                <svg style="height: 50px; width: auto;" width="75px" height="91px" viewBox="0 0 75 91" version="1.1"
                     xmlns="http://www.w3.org/2000/svg">
                    <defs></defs>
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="ic_file_upload_black_24px" transform="translate(-27.000000, -16.000000)">
                            <polygon id="Shape" points="0 0 129 0 129 129 0 129"></polygon>
                            <path d="M48.4285714,85.5882353 L80.5714286,85.5882353 L80.5714286,53.4705882 L102,53.4705882 L64.5,16 L27,53.4705882 L48.4285714,53.4705882 L48.4285714,85.5882353 Z M27,96.2941176 L102,96.2941176 L102,107 L27,107 L27,96.2941176 Z"
                                  id="Shape" fill="#efefef" fill-rule="nonzero"></path>
                        </g>
                    </g>
                </svg>
                <br/>

                {{lang.la.upload_files}}

                <input type="file" ng2FileSelect [multiple]="multiple" [uploader]="imageUploader"
                       (change)="onCheckIfAppedixExists()"/>
                <a class="btn btn-light">Upload</a>
            </label>

        </div>

        <div class="col-12 fadeIn uploader mb-2"
             *ngIf="imageUploader != undefined && imageUploader.queue != undefined && imageUploader.queue .length != 0">

            <label ng2FileDrop (fileOver)="fileOverBase($event)" [uploader]="imageUploader"
                   (change)="onCheckIfAppedixExists()"
                   class="well my-drop-zone fileuploader fileContainer push-m-0-b push-p-0-b">
                <svg style="height: 50px; width: auto;" width="75px" height="91px" viewBox="0 0 75 91" version="1.1"
                     xmlns="http://www.w3.org/2000/svg">
                    <defs></defs>
                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="ic_file_upload_black_24px" transform="translate(-27.000000, -16.000000)">
                            <polygon id="Shape" points="0 0 129 0 129 129 0 129"></polygon>
                            <path d="M48.4285714,85.5882353 L80.5714286,85.5882353 L80.5714286,53.4705882 L102,53.4705882 L64.5,16 L27,53.4705882 L48.4285714,53.4705882 L48.4285714,85.5882353 Z M27,96.2941176 L102,96.2941176 L102,107 L27,107 L27,96.2941176 Z"
                                  id="Shape" fill="#efefef" fill-rule="nonzero"></path>
                        </g>
                    </g>
                </svg>
                <br/>
                {{lang.la.upload_files}}

                <input type="file" ng2FileSelect [multiple]="multiple" [uploader]="imageUploader"
                       (change)="onCheckIfAppedixExists()"/>
                <a class="btn btn-light mb-2">Upload</a>
            </label>
        </div>
        <div class="col-sm-12 push-p-15-t fadeIn"
             *ngIf="imageUploader != undefined && imageUploader.queue != undefined && imageUploader.queue .length != 0">

            <ng-container *ngIf="hasExpense">
                <div class="row">
                    <p class="col-sm-8">Udlæg</p>
                    <p class="col-sm-4">Indtast beløb *</p>
                </div>
                <div class="card-divider push-m-10-b"></div>
            </ng-container>

            <!-- Loop -->
            <ng-container *ngFor="let item of imageUploader.queue; let pos = index">
                <div class="row push-m-10-b">
                    <div [ngClass]="hasExpense ? 'col-sm-8':'col-sm-12'">
                        <span *ngIf="item.uploading">
								<div class="loader-small"></div>
							</span>

                        <span *ngIf="item.uploaded && !item.error && !item.uploading">
								<svg fill="#38A645" height="18" viewBox="0 0 24 24" width="18"
                                     xmlns="http://www.w3.org/2000/svg">
									<path d="M0 0h24v24H0z" fill="none"/>
									<path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
								</svg>
							</span>

                        <a class="close-icon" (click)="onRemoveAgain(pos,item)"
                           *ngIf="(!item.uploaded || item.error) && !item.uploading">
                            <svg fill="#ff0000" height="16" viewBox="0 0 24 24" width="16"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                                <path d="M0 0h24v24H0z" fill="none"/>
                            </svg>
                        </a>
                        <!--///////////////////////<!--///////////////////////<!--///////////////////////<!--///////////////////////-->
                        <span class="float-left">
								<img [src]="showPlaceholder(item)" class="fileuploader-image">
							</span>
                        <span class="float-left push-p-10-l">
								<span class="h6">
									{{ item?.file?.name | slice: 0:20 }} <span
                                        *ngIf="item?.file?.name.length > 20">...</span>
									<span *ngIf="item.error" class="badge badge-danger">{{item.errorText}}</span>
								</span><br/>
								<span class="h7 text-is-grey">
									({{ item?.file?.size / 1024 / 1024 | number:'.2' }} MB)
								</span>
							</span>
                    </div>
                    <div class="col-sm-4" *ngIf="hasExpense">
                        <input #appendix type="number" class="form-control is-fullwidth"
                               [(ngModel)]="item.appendixNumber" (keyup)="onInputChange($event)"
                               [ngClass]="this.checkIfValidExpense(item) ? '' : 'is-invalid'"
                               required
                               placeholder="Indtast beløb"/>
                    </div>
                </div>

                <hr *ngIf="pos < imageUploader.queue.length-1">
                <!-- /Loop -->

            </ng-container>

        </div>
    </div>
</div>

<div class="col-12 push-p-15-t d-flex end fadeIn" *ngIf="imageUploader.queue.length > 0 && hasExpense">
    <div class="row">
        <div class="col-12">
            <span class="h5">{{lang.la.udlaeg}} : {{totalAppendix | currency: 'DKK' | formatedcurrency}}</span>
        </div>
    </div>
</div>