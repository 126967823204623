import {Component, OnInit} from '@angular/core';
import {UtilService} from '../services/util.service';
import {DataService} from '../services/data.service';
import {ApiService} from '../services/api.service';
import {LangService} from '../services/lang.service';
import {ActivatedRoute, Router} from '@angular/router';
import {News} from '../models/news';
import {InputString} from '../models/inputstring';
import {FileUploader} from 'ng2-file-upload';
import {NotificationService} from '../shared/notification.service';
import {TableSettings} from '../models/tablesettings';
import {TableSettingsEquals} from '../models/tablesettingsequals';
import {Constants} from '../constants';
import {Image} from '../models/image';
import {ImagesForNews} from '../models/images-for-news';
import {InputCheckbox} from '../models/inputcheckbox';
import {FileUploaderService} from "../services/fileUploaderService";

@Component({
    selector: 'app-news-create',
    templateUrl: './news-create.component.html',
    styleUrls: ['./news-create.component.scss']
})
export class NewsCreateComponent implements OnInit {

    isLoadingView: boolean = false;
    isLoading: boolean = false;

    inputHeadline: InputString = new InputString();
    inputNewsText: InputString = new InputString();

    inputAllCheckbox: InputCheckbox = new InputCheckbox();
    inputCustomerUserCheckbox: InputCheckbox = new InputCheckbox();
    inputSalesConsultantCheckbox: InputCheckbox = new InputCheckbox();
    inputEmployeeCheckbox: InputCheckbox = new InputCheckbox();

    isDeleteModalActive: boolean = false;

    newsUploading: News;


    //Editing
    images: ImagesForNews[];
    newsEditing: News;
    isEditing: boolean = false;
    isEditingId: number = -1;

    //Image upload
    isImageUploadActive: boolean = false;
    filesImages: FileUploader = new FileUploader({});

    constructor(
        public apiService: ApiService,
        public route: ActivatedRoute,
        private router: Router,
        public utilService: UtilService,
        public lang: LangService,
        public dataService: DataService,
        public notificationService: NotificationService,
        private fileUploaderService: FileUploaderService
    ) {
        this.inputAllCheckbox.value = true;
    }

    ngOnInit() {
        this.onCheckIfEditing();
    }

    onCheckIfEditing() {
        this.isEditingId = this.route.snapshot.params['id'];
        if (this.isEditingId != undefined) {
            this.isEditing = true;
            this.getData();
        }
    }

    getData() {
        this.isLoadingView = true;
        var ts: TableSettings = new TableSettings();
        ts.columns = "news_id,news_title,news_description,news_creation,news_updated,news_for_all,news_for_customer_users,news_for_sales_consultants,news_for_employees";
        ts.tableSettingsEquals.push(new TableSettingsEquals("news_id", this.isEditingId));
        this.apiService.getSingleNews(ts).subscribe((data) => {
            this.newsEditing = data.main[0];
            this.inputHeadline.value = data.main[0]['news_title'];
            this.inputNewsText.value = data.main[0]['news_description'];
            this.inputAllCheckbox.value = data.main[0]['news_for_all'];
            this.inputCustomerUserCheckbox.value = data.main[0]['news_for_customer_users'];
            this.inputSalesConsultantCheckbox.value = data.main[0]['news_for_sales_consultants'];
            this.inputEmployeeCheckbox.value = data.main[0]['news_for_employees'];

            this.isLoadingView = false;
            this.getDataImages();
        }, (error) => {
            this.notificationService.setNotification(this.lang.la.error_general, this.notificationService.CONSTANT_TYPE_WARNING);
            this.isLoadingView = false;
        });
    }

    getDataImages() {
        var tableSettingsImages: TableSettings = new TableSettings();
        tableSettingsImages.columns = "images_for_news_connection_id,news_id,image_id," + Constants.PARAMETER_IMAGES_ONLY_ACTIVE;
        tableSettingsImages.tableSettingsEquals.push(new TableSettingsEquals("news_id", this.newsEditing.news_id));
        tableSettingsImages.tableSettingsEquals.push(new TableSettingsEquals("images.image_active", 1));
        //tableSettingsImages.tableSettingsEquals.push(new TableSettingsEquals("images.image_active", 1));

        this.apiService.getNewsImage(tableSettingsImages).subscribe((data) => {
            this.images = data.main;

        }, (error) => {
        });
    }

    onCheckBoxChange(input) {

        // If any other checkbox is clicked, "alle" checkbox should be removed
        if (this.inputCustomerUserCheckbox.value || this.inputSalesConsultantCheckbox.value || this.inputEmployeeCheckbox.value) {
            this.inputAllCheckbox.value = false;
        }

        // If none has been picked, "alle" should be checked
        if (!this.inputCustomerUserCheckbox.value && !this.inputSalesConsultantCheckbox.value && !this.inputEmployeeCheckbox.value) {
            this.inputAllCheckbox.value = true;
        }

    }

    onAllCheckbox() {
        // If any other checkboxes are active, set them all to inactive, and add checked to "alle"
        if (this.inputCustomerUserCheckbox.value || this.inputSalesConsultantCheckbox.value || this.inputEmployeeCheckbox.value) {
            this.inputCustomerUserCheckbox.value = false;
            this.inputSalesConsultantCheckbox.value = false;
            this.inputEmployeeCheckbox.value = false;
            this.inputAllCheckbox.value = true;
        }
    }

    onCheckForms(): boolean {
        var validForm = true;
        if (this.utilService.checkMultipleIfvalid(this.inputHeadline, this.inputNewsText)) {
            validForm = false;
        }
        return validForm;
    }

    onSetNews(): News {
        var news: News = new News();
        if (this.isEditingId != -1) {
            news.news_id = this.isEditingId;
        }
        news.news_title = this.inputHeadline.value;
        news.news_description = this.inputNewsText.value;
        news.news_active = 1;
        news.news_for_all = this.inputAllCheckbox.value;
        news.news_for_customer_users = this.inputCustomerUserCheckbox.value;
        news.news_for_sales_consultants = this.inputSalesConsultantCheckbox.value;
        news.news_for_employees = this.inputEmployeeCheckbox.value;
        return news;
    }

    onCreateNews() {
        if (!this.onCheckForms()) {
            return;
        }

        this.isLoading = true;

        this.apiService.postNews(this.onSetNews()).subscribe((data) => {
            this.newsUploading = data['data'];
            this.isEditingId = this.newsUploading.news_id;
            this.onPostImages();
        }, (error) => {
            this.notificationService.setNotification(error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
            this.isLoading = false;
        });
    }

    onEditNews() {
        if (!this.onCheckForms()) {
            return;
        }

        this.isLoading = true;

        this.apiService.patchNews(this.onSetNews()).subscribe((data) => {
            this.newsUploading = data['data'];

            this.onPostImages();
        }, (error) => {
            this.notificationService.setNotification(error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
            this.isLoading = false;
        });
    }

    onDeleteNews(reload_page = true) {
        var news: News = this.onSetNews();
        news.news_active = 0;
        this.isLoading = true;
        this.apiService.patchNews(news).subscribe((data) => {
            this.isDeleteModalActive = false;
            this.isLoading = false;
            if (reload_page) {
                this.notificationService.setNotification(this.lang.la.success_deleted, this.notificationService.CONSTANT_TYPE_WARNING);
                this.router.navigate(['/app/news']);
            }
        }, (error) => {
            this.notificationService.setNotification(this.lang.la.error_general, this.notificationService.CONSTANT_TYPE_WARNING);
            this.isLoading = false;
        });
    }

    onRemoveImage(pos) {
        var el: Image = this.images[pos].images;
        this.images[pos].isLoading = true;
        el.image_active = 0;
        this.apiService.patchImage(el).subscribe((data) => {
            this.images[pos].isLoading = false;
            this.notificationService.setNotification(this.lang.la.success_deleted, this.notificationService.CONSTANT_TYPE_NORMAL);
            this.images.splice(pos, 1);
        }, (error) => {
        });
    }

    onPostImages() {
        const options = {
            type: 'image',
            module: 'news',
            id: this.newsUploading.news_id
        };
        return this.fileUploaderService.uploadAllInQueue(this.filesImages, options)
            .then(() => {
                this.onImageDone();
            })
            .catch(() => {
                this.notificationService.setImageUploadErrorNotification('Nyheden er gemt, men billedet kunne ikke uploades.');
                this.isLoading = false;
            });
    }

    onImageDone() {
        if (this.isEditing) {
            this.notificationService.setNotification(this.lang.la.success_created, this.notificationService.CONSTANT_TYPE_NORMAL);
        } else {
            this.notificationService.setNotification(this.lang.la.success_updated, this.notificationService.CONSTANT_TYPE_NORMAL);
        }
        this.router.navigate(['./app/news']);
    }

}
