import {Component, OnInit} from '@angular/core';
import {UtilService} from '../services/util.service';
import {DataService} from '../services/data.service';
import {ApiService} from '../services/api.service';
import {LangService} from '../services/lang.service';
import {ActivatedRoute, Router} from '@angular/router';
import {InputString} from '../models/inputstring';
import {FileUploader} from 'ng2-file-upload';
import {Constants} from '../constants';
import {PapaParseService} from 'ngx-papaparse';
import {NotificationService} from '../shared/notification.service';


@Component({
    selector: 'app-assignment-import',
    templateUrl: './assignment-import.component.html',
    styleUrls: ['./assignment-import.component.scss']
})
export class AssignmentImportComponent implements OnInit {

    isImported: boolean = false;
    isLoading: boolean = false;
    isLoadingView: boolean = false;
    isError: boolean = false;
    isErrorMessage: string = "";

    inputTemplate: InputString = new InputString();

    isInvalidImageExist: boolean = false;
    imageUploader: FileUploader = new FileUploader({url: Constants.URL});

    csv: any = [];

    fileCsv: File;

    skabelonName: string = "";

    csv_sorted: any = [];

    assignments: any;
    assignment_headers: any = [];
    assignmentPages: any[] = [];
    currentPage: number = 0;
    assignmentCount: number = 0;
    chunkSize = 50;
    importID: number;

    isSavingSkabelonActive: boolean = false;
    isLoadingSkabelon: boolean = false;

    req_column_names: any = [];
    columns: any = [];
    validation_columns: any = [];
    csvHeaders: any = [];

    stores: any;
    customers: any;
    employees: any;
    salesConsultants: any;
    assignmentTodos: any;
    private isValidating: boolean = false;
    private importSaveProgress: number = 0;

    constructor(private papa: PapaParseService,
                public apiService: ApiService,
                public route: ActivatedRoute,
                private router: Router,
                public utilService: UtilService,
                public lang: LangService,
                public dataService: DataService,
                private notificationService: NotificationService
    ) {
    }

    ngOnInit() {

        this.validation_columns = [
            {
                type: "assignment_type",
                title: "Opgavetype (Mercer, demo)",
                alias: "type",
                required: true,
                picked_by: false
            },
            {
                type: "user",
                title: "Salgskonsulent",
                alias: "salgskonsulent",
                required: true,
                picked_by: false
            },
            {
                type: "customer",
                title: "Kunde",
                alias: "kunde",
                required: true,
                picked_by: false
            },
            {
                type: "employee",
                title: "Medarbejder",
                alias: "merchandiser",
                required: true,
                picked_by: false
            },
            {
                type: "assignment_time_estimate",
                title: "Tidsestimat",
                alias: "tidsestimat",
                required: true,
                picked_by: false
            },
            {
                type: "store",
                title: "Butiksnavn",
                alias: "butik",
                required: true,
                picked_by: false
            },
            {
                type: "address",
                title: "Vej",
                alias: "adresse",
                required: true,
                picked_by: false
            },
            {
                type: "zip_code",
                title: "Postnummer",
                alias: "post nr.",
                required: true,
                picked_by: false
            },
            {
                type: "assignment_pos_material",
                title: "POS materiale",
                alias: "pos materiale",
                required: true,
                picked_by: false
            },
            {
                type: "assignment_date",
                title: "Dato (d/m/yy)",
                alias: "dato",
                required: true,
                picked_by: false
            },
            {
                type: "assignment_start",
                title: "Opgave start",
                alias: "tidspunkt",
                required: true,
                picked_by: false
            },
            {
                type: "assignment_end",
                title: "Opgave slut",
                alias: "",
                required: false,
                picked_by: false
            },
            {
                type: "assignment_description",
                title: "Kommentar til opgave",
                alias: "beskrivelse",
                required: false,
                picked_by: false
            },
            {
                type: "assignment_todo_names",
                title: "Skal gøres",
                alias: "skal gøres",
                required: true,
                picked_by: false
            },
        ];

        this.csvHeaders = {
            "customer": "Kunde",
            "store": "Butik",
            "employee": "Medarbejder",
            "sales_consultant": "Salgskonsulent",
            "assignment_type": "Type",
            "assignment_timestype_start": "Opgave start",
            "assignment_time_estimate": "Tidsestimat",
            "assignment_timestype_free_end": "Evt. opgave slut",
            'assignment_todos': 'Skal gøres',
            "assignment_pos_material": "POS Materiale",
            "assignment_description": "Beskrivelse",
        };


        //Uploader
        this.imageUploader.onAfterAddingFile = (fileItem) => {
            this.fileCsv = this.imageUploader.queue[0]._file;
            this.isLoadingView = true;
            let fileReader = new FileReader();
            fileReader.onload = (e) => {
                this.papa.parse(fileReader.result, {
                    complete: (results, file) => {
                        this.csv = results.data;

                        this.isLoadingView = false;
                    }
                });
            };
            fileReader.readAsText(fileItem._file);
        };
    }

    onUploadImport() {

        if (this.fileCsv == undefined) {
            this.notificationService.setNotification(this.lang.la.error_general, this.notificationService.CONSTANT_TYPE_WARNING);
            return;
        }

        // get mapped columns for the backend to map csv_headers to row values
        const mapped_columns = this.validation_columns.filter(col => col.csv_header);

        // Check if all required columns has been picked
        const missing_required = this.validation_columns.filter(col => col.required && col.picked_by === false).map(col => col.title);

        if (missing_required.length > 0) {
            // One or more required columns hasn't been chosen
            this.isError = true;
            this.isErrorMessage = 'Obligatoriske kolonner mangler: ' + missing_required.join(', ');
            return;
        }

        this.isLoading = true;

        this.isError = false;

        this.apiService.postImportValidation('assignments', this.fileCsv, mapped_columns).subscribe((data) => {
            // @ts-ignore
            this.importID = data.import_id;
            this.isLoading = false;
            this.isValidating = true;

            // @ts-ignore
            this.assignmentPages.push(data.data.assignments);

            // @ts-ignore
            this.stores = data.data.stores;
            // @ts-ignore
            this.customers = data.data.customers;
            // @ts-ignore
            this.employees = data.data.employees;
            // @ts-ignore
            this.salesConsultants = data.data.salesConsultants;
            // @ts-ignore
            this.assignmentTodos = data.data.assignmentTodos;

            // if the validation is not complete continue untill complete
            if (!this.isImportValidationComplete(data)) {
                // @ts-ignore
                this.continueValidation(data.import_id, data.import_progress);
            } else {
                this.isValidating = false;
            }

        }, (error) => {

            this.isError = true;
            this.isErrorMessage = error.error.message;

            this.isLoading = false;
            this.isValidating = false;
        });
    }

    continueValidation(import_id, progress) {

        return this.apiService.postContinueImportValidation('assignments', import_id, progress).subscribe((data) => {
            if (progress >= 1000) throw new Error('Prevented AJAX overflow');

            // @ts-ignore
            this.assignmentPages.push(data.data.assignments);

            if (!this.isImportValidationComplete(data)) {
                // @ts-ignore
                this.continueValidation(import_id, data.import_progress);
            } else {
                this.isValidating = false;
            }
        });
    }

    isImportValidationComplete(data) {
        return data.import_status == 'ready for import';
    }

    isRowValid(assignment) {
        let valid = true;

        if (!assignment['store_id']) valid = false;
        if (!assignment['employee_id']) valid = false;
        if (!assignment['customer_id']) valid = false;
        if (!assignment['user_id']) valid = false;
        if (assignment['assignment_todos'].length < 1) valid = false;

        return valid;
    }

    import(readyForImport) {
        if (!readyForImport) return;

        this.isLoading = true;
        this.continuousImport(0);
    }

    continuousImport(page) {
        // magic constant 21 is the amount of pages it takes to surpass 1000 records for a chunksize of 50
        if (page >= 21) {
            this.notificationService.setNotification('Der gik desværre noget galt med importen - for mange sider', 1);
            throw new Error('Too many pages to import');
        }

        this.apiService.postImport('assignments', this.importID, this.assignmentPages[page]).subscribe((data) => {

            // @ts-ignore
            this.importSaveProgress = data.import_progress;

            // @ts-ignore
            if (!data.success) {
                this.isError = true;
                this.notificationService.setNotification('Der gik desværre noget galt med importen', 1)
            } else {

                // @ts-ignore
                if (data.import_status === 'done') {
                    this.isLoading = false;
                    this.isImported = true;
                    this.notificationService.setNotification('Import fuldført - du er automatisk blevet sendt tilbage til opgavelisten', 0, 10000);
                    this.router.navigate(['app/task/list']);
                } else {
                    this.continuousImport(page + 1);
                }
            }

        }, (error) => {

            this.isError = true;
            this.isErrorMessage = error.error.message;

            this.isLoading = false;
        });
    }
}
