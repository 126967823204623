import {Component, OnInit} from '@angular/core';
import {UtilService} from '../services/util.service';
import {DataService} from '../services/data.service';
import {ApiService} from '../services/api.service';
import {LangService} from '../services/lang.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Chain} from '../models/chain';
import {TableHeader} from '../models/tableheader';
import {TableSettings} from '../models/tablesettings';
import {NotificationService} from '../shared/notification.service';
import {CSVService} from '../services/csv.service';
import {UserService} from '../services/user.service';
import {Constants} from '../constants';

@Component({
    selector: 'app-chain-list',
    templateUrl: './chain-list.component.html',
    styleUrls: ['./chain-list.component.scss']
})
export class ChainListComponent implements OnInit {

    Constants: any = Constants;

    //Table
    isLoadingTable: boolean = true;
    tableData: Chain[] = [];
    tableHeaders: TableHeader[];
    tableSettings: TableSettings;

    isLoadingView: boolean = false;

    constructor(public apiService: ApiService,
                public route: ActivatedRoute,
                private router: Router,
                public utilService: UtilService,
                public lang: LangService,
                public dataService: DataService,
                public userService: UserService,
                public notificationService: NotificationService,
                public csvService: CSVService
    ) {
    }

    ngOnInit() {
        this.tableHeaders = [
            new TableHeader("chain_name", this.lang.la.name)
        ];

        this.tableSettings = new TableSettings();
        this.tableSettings.sortBy = this.tableHeaders[0];
        this.tableSettings.tableHeaders = this.tableHeaders;
        this.tableSettings.tableHeaders[0].asc = false;
        this.tableSettings.searchColumn = this.tableHeaders[0];
        this.tableSettings.columns = "chain_id,chain_name";
    }

    getData(tableSettings: TableSettings) {
        this.tableSettings = tableSettings;
        this.isLoadingTable = true;

        this.apiService.getChains(tableSettings).subscribe((data) => {

            this.isLoadingView = false;
            this.isLoadingTable = false;

            this.tableData = data.main;
            this.tableSettings.tableOffset = data.tableOffset;

            this.dataService.saveStores(this.tableData);

        }, (error) => {
            this.utilService.onRetryApi("getChains", () => {
                this.getData(this.tableSettings);
            }, () => {
                this.isLoadingView = false;
                this.isLoadingTable = false;
                this.notificationService.setNotification(this.lang.la.error_general_message + " " + error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
            });
        });

    }

    onRowPressed(event) {
        this.router.navigate(['app/chain/show/', this.tableData[event.pos].chain_id]);
    }
}