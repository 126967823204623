import {Customer} from "./customer";
import {Image} from "./image";
import {UserRole} from "./user-role";

export class User {

    public primary_key: string = "user_id";

    public customer_id: number; //If employee then you have pre-selected a customer_id

    public user_id: number;
    public user_blocked: number;

    public user_token: string;

    public user_email: string;

    public user_fullname: string;
    public user_firstname: string;
    public user_lastname: string;

    public user_password: string;

    public user_role: string; //ENUM employee, consultant, customer, teamplayer
    public user_role_id: number;

    public user_type_mercer: boolean;
    public user_type_demo: boolean;

    public user_phone: string;
    public user_cpr: string;

    public user_position: string;
    public user_district: string;

    public user_home_delivery: string;
    public user_vatno: string;

    public user_address: string;
    public user_postal_code: string;
    public user_city: string;

    public user_bank_reg: string;
    public user_bank_account: string;

    public user_demo_rate: string;
    public user_mercer_rate: string;
    public user_project_rate: string;

    public user_danloen_id: string;
    public user_notes: string;

    public user_cooperation_agreements_date: string;
    public user_cooperation_agreements: string;

    public active: string;

    public user_breastsize: string;

    public user_contact_on_email: string;
    public user_contact_on_sms: string;
    public user_contact_on_app: string;

    public user_creation: string;

    public user_updated: string;

    public user_customer: Customer;

    public relation_objects: any;

    public user_roles: UserRole;

    public user_active: number;

    public customer_user_connects: Customer[];

    public image: Image;

    public images: Image;

    //used in send SMS/Email
    public is_selected: boolean = false;
    public isLoading: boolean = false;

    public can_create_project_management: number;

    public customers: any;
    public customer_list: any;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }

    public getUserName() {
        return this.user_firstname + ' ' + this.user_lastname;
    }

    public getPostJson() {
        var object = this;
        Object.keys(object).forEach(element => {
            if (object[element] == undefined || object[element] == null || object[element].length == 0) {
                delete object[element];
            }
        });
        delete object.primary_key;
        delete object.is_selected;
        delete object.isLoading;
        return JSON.stringify(object);
    }


    public getPatchJson() {
        var object: any = this;
        Object.keys(object).forEach(element => {
            if (object[element] == undefined || object[element] == null || object[element].length == 0) {
                delete object[element];
            }
        });
        delete object.primary_key;
        delete object.is_selected;
        delete object.isLoading;
        return JSON.stringify(object);
    }

    public setUserDetails(data: User) {
        Object.keys(data).forEach(key => {
            this[key] = data[key];
        });
    }
}