<div class="dropdown dropdown-select">

    <!-- Input -->
    <div class="input-cancel-button input-cancel-button--dropdown input-has-appended-items">
        <input type="text" class="form-control dropdown-toggle" placeholder="{{lang.la.search}}"
               (click)="isDropdownActive = !isDropdownActive" [(ngModel)]="inputDropdown"
               [ngClass]="{'is-invalid': error, 'disabled-cursor' : disabled}"
               (ngModelChange)="onChangeInputDropdown()" on-focusout="onFocusOut()"
               (keydown.tab)="onItemSelected(0)"
               [disabled]="disabled">
        <img src="./assets/ic_cancel.svg" class="input-cancel-button-img" alt=""
             *ngIf="!disabled && inputDropdown != undefined && inputDropdown.length != 0" (click)="onClearSearch()">
    </div>

    <!-- Dropdown -->
    <div class="dropdown-menu" [ngClass]="{'show': isDropdownActive}"
         *ngIf="!disabled && arrayListForView != undefined && arrayListForView.length != 0">
        <a class="dropdown-item" *ngFor="let item of arrayListForView; let pos = index" (click)="onItemSelected(pos)">
            {{utilService.onGetTableContent(item, arrayListName)}}
        </a>
        <a class="dropdown-item dropdown-item-btn"
           *ngIf="!isLoadingMore && inputDropdown != undefined && inputDropdown.length == 0 && arrayList.length > 20 && !isNoMoreToLoad"
           (click)="onLoadMorePressed()">Hent flere</a>
        <a class="dropdown-item" *ngIf="isLoadingMore">
            <div class="loader"></div>
        </a>
    </div>

    <img *ngIf="!disabled" src="./assets/dropdown.svg" alt="" class="dropdown-select-arrow"
         [ngClass]="{'disabled-cursor' : disabled}" (click)="isDropdownActive = !isDropdownActive">

    <div class="invalid-feedback d-inline" *ngIf="error">
        {{error}}
    </div>
</div>
<div class="dropdown-back-click" *ngIf="isDropdownActive" (click)="isDropdownActive = false"></div>
