import {Component, Input, OnInit} from '@angular/core';
import {TableHeader} from "../../../models/tableheader";
import {LangService} from "../../../services/lang.service";
import {UtilService} from "../../../services/util.service";

@Component({
    selector: '[app-import-assignment-preview-row]',
    templateUrl: './import-assignment-preview-row.html',
    styleUrls: []
})
export class ImportAssignmentPreviewRow implements OnInit {
    @Input() assignment: any;
    @Input() assignmentTodos: any = [];
    @Input() headers: TableHeader[] = [];
    @Input() stores: any = [];
    @Input() employees: any = [];
    @Input() salesConsultants: any = [];
    @Input() customers: any = [];
    @Input() validateRow: Function;

    constructor(
        public lang: LangService,
        public utilService: UtilService
    ) {
    }

    ngOnInit(): void {
        this.assignment.selected_assignment_todos = {
            'opfyldning': undefined,
            'rengoering': undefined,
            'udstilling': undefined,
            'varebestilling': undefined,
            'opsaetning-af-stander': undefined,
            'demonstration': undefined,
        }
    }

    onTodoChange(assignment, key) {
        const todo = this.assignmentTodos.find(function (assignmentTodo) {
            return assignmentTodo.assignment_todo_name === key;
        });

        if (todo && assignment['selected_assignment_todos'][key]) {
            assignment['assignment_todos'].push(todo);
        } else if (todo) {
            assignment['assignment_todos'].splice(assignment['assignment_todos'].indexOf(todo), 1);
        }
    }
}
