import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UtilService} from '../../services/util.service';
import {TableSettings} from '../../models/tablesettings';
import {LangService} from '../../services/lang.service';
import {TableSettingsService} from "../../services/table-settings.service";
import {UserService} from "../../services/user.service";

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

    @Input()
    isLoadingTable: boolean = true;

    //Search
    isDropdownSearch: boolean = false;

    //Inputs
    @Input()
    tableSettings: TableSettings;


    @Input()
    canSearch: boolean = true;

    @Input()
    tableData: any[] = [];

    //Outputs
    @Output()
    onRowPressed: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    onCustomPressed: EventEmitter<number> = new EventEmitter<number>();

    @Output()
    getData: EventEmitter<TableSettings> = new EventEmitter<TableSettings>();

    constructor(
        public utilService: UtilService,
        public lang: LangService,
        private sessionTableSettings: TableSettingsService,
        private userService: UserService,
    ) {
    }

    ngOnInit() {
        this.init();
    }

    init() {
        this.updateTableSettingsFromSession();
        this.emitGetData(this.tableSettings);
    }

    updateTableSettingsFromSession() {
        const sessionSearch = this.sessionTableSettings.getTableSettings();
        if (!sessionSearch) {
            return;
        }

        if (sessionSearch['search']) {
            this.tableSettings.search = sessionSearch['search'];
        }
        if (sessionSearch['searchColumn']) {
            this.tableSettings.searchColumn = sessionSearch['searchColumn'];
        }
        if (sessionSearch['sortBy']) {
            this.tableSettings.sortBy = sessionSearch['sortBy'];
        }
        if (sessionSearch['page']) {
            this.tableSettings.page = sessionSearch['page'];
        }
        if (sessionSearch['showActive']) {
            this.tableSettings.tableSettingsEquals[0].value = sessionSearch['showActive'];
        }
    }

    onSortTable(pos) {
        this.tableSettings.page = 1;
        this.tableData = [];

        this.tableSettings.tableHeaders[pos].asc = !this.tableSettings.tableHeaders[pos].asc;
        this.tableSettings.sortBy = this.tableSettings.tableHeaders[pos];

        this.emitGetData(this.tableSettings)
    }

    onRowLinkPressed(pos, tableValue) {
        this.onRowPressed.emit({pos: pos, tableValue: tableValue});
    }

    onPageChange(pageChange) {

        if (this.tableSettings.tableOffset.highest_offset < (this.tableSettings.page + pageChange)
            || (this.tableSettings.page + pageChange) <= 0) {
            return;
        }

        var newPageChange = this.tableSettings.page + pageChange;

        if (newPageChange < 1) {
            newPageChange = 1;
            return;
        }
        if (newPageChange > this.tableSettings.tableOffset.highest_offset) {
            newPageChange = this.tableSettings.tableOffset.highest_offset;
        }

        this.tableSettings.page = newPageChange;
        this.emitGetData(this.tableSettings)
    }


    onPageChangeFirst() {
        var newPageChange = 1;
        this.tableSettings.page = newPageChange;
        this.emitGetData(this.tableSettings)
    }

    onPageChangeLast() {
        var newPageChange = this.tableSettings.tableOffset.highest_offset;
        this.tableSettings.page = newPageChange;
        this.emitGetData(this.tableSettings)
    }

    onCustomLinkPressed(pos) {
        this.onCustomPressed.emit(pos);
    }

    emitGetData(tableSettings: TableSettings) {
        this.updateSessionTableSettings();
        this.tableData = [];
        this.getData.emit(tableSettings)
    }

    updateSessionTableSettings() {
        this.sessionTableSettings.setTableSettings('sortBy', this.tableSettings.sortBy);
        this.sessionTableSettings.setTableSettings('page', this.tableSettings.page);
    }
}
