import {Component, HostListener, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../services/user.service';
import {ApiService} from '../services/api.service';
import {UtilService} from '../services/util.service';
import {DataService} from '../services/data.service';
import {LangService} from '../services/lang.service';
import {Constants, USER_RIGHT_TYPES} from '../constants';
import {NotificationService} from '../shared/notification.service';
import {TableSettings} from '../models/tablesettings';
import * as moment from 'moment';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    //Notifications
    isNotification: boolean = true;

    //
    isRedrawing: boolean = false;
    Constants: any = Constants;

    //Dropdowns
    isDropdownUserActive: boolean = false;
    isSidebarActive: boolean = true;
    isStoreActive: boolean = false;
    isUserDropdownActive: boolean = false;
    isCustomerDropdownActive: boolean = false;
    isExportDropdownActive: boolean = false;
    USER_RIGHT_TYPES: any = USER_RIGHT_TYPES;
    notificationInterval: any;
    notificationStyle: any;

    version: string;


    constructor(
        public utilService: UtilService,
        public lang: LangService,
        public dataService: DataService,
        public route: ActivatedRoute,
        public userService: UserService,
        private router: Router,
        private apiService: ApiService,
        private notificationService: NotificationService
    ) {

        moment.locale('da-DK');

        //Responsive optimization
        if (window.innerWidth >= 1020) {
            this.isSidebarActive = true;
        } else {
            this.isSidebarActive = false;
        }
    }

    //Responsive optimization
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (window.innerWidth >= 960) {
            this.isSidebarActive = true;
        } else {
            this.isSidebarActive = false;
        }

        this.setNotificationContainerStyle();
    }

    ngOnInit() {
        this.route
            .data
            .subscribe(data => this.version = data.version);

        this.setNotificationContainerStyle();
        //Load localstorage stuff
        this.dataService.loadData();

        if (this.userService.user == undefined || this.userService.user.user_token == undefined) {
            this.notificationService.setNotification("Der skete en fejl med din bruger. Prøv log ind igen.", this.notificationService.CONSTANT_TYPE_WARNING);
            this.onLogout();
        } else {
            //Update user to have newest informations and settings
            var ts: TableSettings = new TableSettings();
            ts.columns = "user_id,user_email,user_firstname,user_lastname,user_password,user_role_id,user_phone,user_cpr,user_address,user_postal_code,user_bank_reg,user_bank_account,user_demo_rate,user_mercer_rate,user_danloen_id,user_notes,user_contact_on_email,user_contact_on_sms,user_contact_on_app,user_creation,user_updated,user_cooperation_agreements,user_cooperation_agreements_date,user_login_attempts,user_blocked,user_active," + Constants.PARAMETER_IMAGES_ONLY_ACTIVE;

            this.apiService.getUser(ts, this.userService.getUserId()).subscribe((data) => {
                data.main[0].user_token = this.userService.user.user_token;
                this.userService.setUser(data.main[0]);
                this.onCheckIfCanCreateAssignments();
            }, (error) => {
            });
        }
        //Check if logged in as another 'fake' user
        this.userService.isUserUpdated.subscribe(
            (updated) => {
                this.isRedrawing = true;
                var ts: TableSettings = new TableSettings();
                ts.columns = "user_id,user_email,user_firstname,user_lastname,user_password,user_role_id,user_phone,user_cpr,user_address,user_postal_code,user_bank_reg,user_bank_account,user_demo_rate,user_mercer_rate,user_danloen_id,user_notes,user_contact_on_email,user_contact_on_sms,user_contact_on_app,user_creation,user_updated,user_cooperation_agreements,user_cooperation_agreements_date,user_login_attempts,user_blocked,user_active," + Constants.PARAMETER_IMAGES_ONLY_ACTIVE;

                this.apiService.getUser(ts, this.userService.getUserId()).subscribe((data) => {
                    data.main[0].user_token = this.userService.user.user_token;
                    this.userService.setUser(data.main[0]);
                    this.dataService.getCustomersForUsers();
                    this.dataService.getNotifications();
                    this.router.navigate(['./app/news']);
                    setTimeout(() => {
                        this.isRedrawing = false;
                    }, 100);
                }, (error) => {
                });
            }
        );


        /**
         * Remove notifications on site change
         * Author: Tommy Jepsen - tommy@tonsstudio.com
         **/
        this.router.events.subscribe((val) => {
            if (this.notificationService.notificationType == this.notificationService.CONSTANT_TYPE_WARNING) {
                this.notificationService.removeNotification();
            }
        });

        //Get notifications every minute
        this.dataService.getNotifications();
        this.notificationInterval = setInterval(() => this.dataService.getNotifications(), 60000);

        this.userService.logoutEvent.subscribe((val) => {
            if (this.notificationInterval) clearInterval(this.notificationInterval);
        });

        //Get customers for this user to load in dropdown
        this.dataService.getCustomersForUsers();
    }

    onClickOnNotification(pos) {
        this.dataService.notifications[pos].notification_read = 1;
        this.apiService.postNotifications([this.dataService.notifications[pos].notification_id]).subscribe((data) => {
            this.dataService.isNotificationsUnread();
        });

        setTimeout(() => {
            switch (this.dataService.notifications[pos].notifiable_object) {
                case 'User':
                    this.router.navigate(['./app/user/show/', this.dataService.notifications[pos].notifiable_id]);
                    break;
                case 'Assignment':
                    this.router.navigate(['./app/task/show/', this.dataService.notifications[pos].notifiable_id]);
                    break;
                default:
                    throw new Error('Invalid notifiable object for notification: ' + this.dataService.notifications[pos].notifiable_object);
            }

            this.isDropdownUserActive = false;
        }, 25);
    }

    onShowNotifications() {
        this.isDropdownUserActive = !this.isDropdownUserActive;
        this.isNotification = false;
    }


    onLogout() {
        this.router.navigate(['./']);
        this.userService.deleteLogin();
    }

    onLogoutAsFake() {
        this.dataService.getNotifications();
        this.userService.logoutAsFakeUser();
    }

    onCloseDropdown() {
        if (window.innerWidth < 1020 && this.isSidebarActive) {
            this.isSidebarActive = false;
        }
        this.onCloseAllTabs();
    }

    onStoreDropdown() {
        this.onCloseAllTabs();
        this.isStoreActive = !this.isStoreActive;
    }

    onUserDropdown() {
        this.onCloseAllTabs();
        this.isUserDropdownActive = !this.isUserDropdownActive;
    }

    onExportDropdown() {
        this.onCloseAllTabs();
        this.isExportDropdownActive = !this.isExportDropdownActive;
    }

    onCustomerDropdown() {
        this.onCloseAllTabs();
        this.isCustomerDropdownActive = !this.isCustomerDropdownActive;
    }

    onSubmenuCloseDropdown() {
        if (window.innerWidth < 1020 && this.isSidebarActive) {
            this.isSidebarActive = false;
        }
    }

    onCloseAllTabs() {
        this.isStoreActive = false;
        this.isUserDropdownActive = false;
        this.isExportDropdownActive = false;
        this.isCustomerDropdownActive = false;
    }

    onCheckIfCanCreateAssignments() {
        return !(this.userService.user.user_role_id === Constants.USER_ROLE_CUSTOMER_ID);

    }

    setNotificationContainerStyle() {
        this.notificationStyle = {
            'maxHeight': Math.abs(window.innerHeight / 100 * 85) + 'px'
        };
    }

    pageContentClick(){
        if(this.isSidebarActive && window.innerWidth < 992) this.isSidebarActive = false;
    }
}

