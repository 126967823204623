/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./customer-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../shared/loading/loading.component.ngfactory";
import * as i4 from "../shared/loading/loading.component";
import * as i5 from "../shared/table/table.component.ngfactory";
import * as i6 from "../shared/table/table.component";
import * as i7 from "../services/util.service";
import * as i8 from "../services/lang.service";
import * as i9 from "../services/table-settings.service";
import * as i10 from "../services/user.service";
import * as i11 from "@angular/router";
import * as i12 from "./customer-list.component";
import * as i13 from "../services/api.service";
import * as i14 from "../services/data.service";
import * as i15 from "../shared/notification.service";
import * as i16 from "../services/csv.service";
var styles_CustomerListComponent = [i0.styles];
var RenderType_CustomerListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomerListComponent, data: {} });
export { RenderType_CustomerListComponent as RenderType_CustomerListComponent };
function View_CustomerListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "btn btn-primary"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.csvService.exportGeneralCSV(_co.tableSettings, "export_customers") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "is-loading": 0 }), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "btn btn-primary"; var currVal_2 = _ck(_v, 2, 0, _co.csvService.isLoadingExport); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.csvService.isLoadingExport; _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.lang.la.export_to_excel; _ck(_v, 3, 0, currVal_3); }); }
function View_CustomerListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loading", [], null, null, null, i3.View_LoadingComponent_0, i3.RenderType_LoadingComponent)), i1.ɵdid(1, 114688, null, 0, i4.LoadingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_CustomerListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-table", [], null, [[null, "onRowPressed"], [null, "getData"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onRowPressed" === en)) {
        var pd_0 = (_co.onRowPressed($event) !== false);
        ad = (pd_0 && ad);
    } if (("getData" === en)) {
        var pd_1 = (_co.getData($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i5.View_TableComponent_0, i5.RenderType_TableComponent)), i1.ɵdid(2, 114688, null, 0, i6.TableComponent, [i7.UtilService, i8.LangService, i9.TableSettingsService, i10.UserService], { isLoadingTable: [0, "isLoadingTable"], tableSettings: [1, "tableSettings"], tableData: [2, "tableData"] }, { onRowPressed: "onRowPressed", getData: "getData" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoadingTable; var currVal_1 = _co.tableSettings; var currVal_2 = _co.tableData; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_CustomerListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "row mb-3 mb-sm-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "col-sm-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [["class", "h2"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "col-sm-9 list-action-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["routerLink", "../create"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, null, 0, i11.RouterLink, [i11.Router, i11.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerListComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerListComponent_2)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CustomerListComponent_3)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "../create"; _ck(_v, 6, 0, currVal_1); var currVal_3 = ((_co.userService.user.user_role_id == _co.Constants.USER_ROLE_TEAMPLAYER_ID) || (_co.userService.user.user_role_id == _co.Constants.USER_ROLE_ADMIN_ID)); _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.isLoadingView; _ck(_v, 11, 0, currVal_4); var currVal_5 = !_co.isLoadingView; _ck(_v, 13, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.lang.la.customer; _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.lang.la.create; _ck(_v, 7, 0, currVal_2); }); }
export function View_CustomerListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-customer-list", [], null, null, null, View_CustomerListComponent_0, RenderType_CustomerListComponent)), i1.ɵdid(1, 114688, null, 0, i12.CustomerListComponent, [i13.ApiService, i11.ActivatedRoute, i11.Router, i7.UtilService, i8.LangService, i14.DataService, i10.UserService, i15.NotificationService, i16.CSVService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomerListComponentNgFactory = i1.ɵccf("app-customer-list", i12.CustomerListComponent, View_CustomerListComponent_Host_0, {}, {}, []);
export { CustomerListComponentNgFactory as CustomerListComponentNgFactory };
