<!-- Modal -->
<div class="modal  modal-show" tabindex="-1" role="dialog" [ngClass]="{'modal-show': isVacationActive}">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Tilføj ny ferie dato</h5>
                <button type="button" class="close" (click)="isVacationActive = !isVacationActive" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label for="type">Vælg ferie periode</label>
                            <div class="input-group" [(bsValue)]="inputVacationRange " #drp="bsDaterangepicker"
                                 bsDaterangepicker [bsConfig]="dpConfig">
                                <div *ngIf="inputVacationRange.length != 0">
                                    <input class="form-control"
                                           [value]="(inputVacationRange[0] | mdate: 'DD/MM/YYYY' : false) + '-' + (inputVacationRange[1] | mdate: 'DD/MM/YYYY' : false)"
                                           name="inputVacationRange"
                                           disabled >
                                </div>
                                <div *ngIf="inputVacationRange.length == 0">
                                    <input class="form-control" placeholder="Fra - til" name="inputVacationRange" disabled>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-grey" (click)="isVacationActive = !isVacationActive">Fortryd
                </button>
                <button type="button" class="btn btn-primary" [ngClass]="{'is-loading': isLoadingVacation}"
                        (click)="onAddVacation()">Tilføj
                </button>
            </div>
        </div>
    </div>
</div>

<app-imageuploader (imageUploaderChange)="filesImages = $event" [imageUploader]="filesImages"
                   [(isUploadActive)]="isImageUploadActive"></app-imageuploader>

<div class="row push-m-50-b">
    <div class="col-sm-8">
        <h2 class="float-left h2">{{lang.la.my_profile}}</h2>
    </div>
    <div class="col-sm-4 has-text-right">
    </div>
</div>

<form>
    <div class="card push-m-20-b">
        <div class="card-body row">
            <div class="col-sm-12">
                <div class="float-sm-left float-none text-center"
                     *ngIf="userService.user != undefined && userService.user.images != undefined && userService.user.images.image_link != undefined">
                    <img [src]="utilService.onImageToView(userService.user.images.image_link)" alt="Profile"
                         class="profile-image"/>
                </div>
                <div class="float-sm-left float-none text-center"
                     *ngIf="userService.user == undefined || userService.user.images == undefined || userService.user.images.image_link == undefined">
                    <img src="../assets/placeholder_profile.png" alt="Profile" class="profile-image"/>
                </div>
                <div class="float-sm-left float-none text-center ml-sm-3 mt-2 mt-sm-0">
                    <h2 class="h2 push-m-10-t">
                        {{userService.user.user_firstname}}
                        {{userService.user.user_lastname}}
                    </h2>
                </div>
            </div>
        </div>

        <div class="card-body row">
            <div class="col-sm-6">

                <div class="form-group">
                    <label for="type">* {{lang.la.first_name}}</label>
                    <div class="input-group">
                        <input type="text" class="form-control"
                               (ngModelChange)="inputFirstName.error = ''" [(ngModel)]="inputFirstName.value"
                               name="inputFirstName.value" [ngClass]="{'is-invalid': inputFirstName.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputFirstName.error">
                        {{inputFirstName.error}}
                    </div>
                </div>

                <!-- File upload -->
                <div class="form-group">
                    <label>{{lang.la.image}}</label>
                    <div class="input-group">
                        <button class="btn btn-outline-secondary is-fullwidth text-left"
                                (click)="isImageUploadActive = !isImageUploadActive">
                            {{lang.la.add_image_here}}
                            <span class="float-right"
                                  *ngIf="filesImages != undefined && filesImages.queue != undefined && filesImages.queue.length != 0">
								({{filesImages.queue.length}} tilføjet)
							</span>
                        </button>
                    </div>
                    <div class="invalid-feedback">
                        {{lang.la.image_exists_already}}
                    </div>
                </div>
                <!-- File upload ^ -->

            </div>
            <div class="col-sm-6">

                <div class="form-group">
                    <label for="type">* {{lang.la.sur_name}}</label>
                    <div class="input-group">
                        <input type="text" class="form-control"
                               (ngModelChange)="inputLastName.error = ''" [(ngModel)]="inputLastName.value"
                               name="inputLastName.value" [ngClass]="{'is-invalid': inputLastName.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputLastName.error">
                        {{inputLastName.error}}
                    </div>
                </div>


            </div>
        </div>
        <div class="card-divider"></div>
        <div class="card-body row">

            <div class="col-sm-6">

                <div class="form-group">
                    <label for="type">* {{lang.la.phoneno}}</label>
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text">+45</span>
                        </div>
                        <input type="number" class="form-control"
                               (ngModelChange)="inputPhoneNo.error = ''" [(ngModel)]="inputPhoneNo.value"
                               name="inputPhoneNo.value" [ngClass]="{'is-invalid': inputPhoneNo.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputPhoneNo.error">
                        {{inputPhoneNo.error}}
                    </div>
                </div>


            </div>
            <div class="col-sm-6">

                <div class="form-group">
                    <label for="type">* {{lang.la.email}}</label>
                    <div class="input-group">
                        <input type="email" class="form-control"
                               (ngModelChange)="inputEmail.error = ''" [(ngModel)]="inputEmail.value"
                               name="inputEmail.value" [ngClass]="{'is-invalid': inputEmail.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputEmail.error">
                        {{inputEmail.error}}
                    </div>
                </div>

            </div>
        </div>
        <div class="card-divider"></div>
        <div class="card-body row">
            <div class="col-sm-6">

                <div class="form-group">
                    <label>* {{lang.la.password}}</label>
                    <div class="input-group"
                         tooltip="Adgangskoden skal være mindst 8 tegn, indeholde mindst et stort og et småt bogstav og mindst et tal"
                         placement="bottom">
                        <input type="password" class="form-control"
                               (ngModelChange)="inputPassword1.error = ''" [(ngModel)]="inputPassword1.value"
                               name="inputPassword1.value" [ngClass]="{'is-invalid': inputPassword1.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputPassword1.error">
                        {{inputPassword1.error}}
                    </div>
                </div>

            </div>
            <div class="col-sm-6">

                <div class="form-group">
                    <label>* {{lang.la.password_again}}</label>
                    <div class="input-group">
                        <input type="password" class="form-control"
                               (ngModelChange)="inputPassword2.error = ''" [(ngModel)]="inputPassword2.value"
                               name="inputPassword2.value" [ngClass]="{'is-invalid': inputPassword2.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputPassword2.error">
                        {{inputPassword2.error}}
                    </div>
                </div>

            </div>
        </div>
        <div class="card-divider"
             *ngIf="userService.user.user_role_id !== Constants.USER_ROLE_CONSULTANT_ID && userService.user.user_role_id !== Constants.USER_ROLE_CUSTOMER_ID"></div>
        <div class="card-body row"
             *ngIf="userService.user.user_role_id !== Constants.USER_ROLE_CONSULTANT_ID && userService.user.user_role_id !== Constants.USER_ROLE_CUSTOMER_ID">

            <div class="col-sm-6">
                <div class="form-group">
                    <label for="type">{{lang.la.cprnumber}}</label>
                    <div class="input-group">
                        <input type="text" class="form-control"
                               (ngModelChange)="inputPersonalNumber.error = ''" [(ngModel)]="inputPersonalNumber.value"
                               name="inputPersonalNumber.value" [ngClass]="{'is-invalid': inputPersonalNumber.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputPersonalNumber.error">
                        {{inputPersonalNumber.error}}
                    </div>
                </div>


                <div class="form-group">
                    <label for="type">
                        <span *ngIf="userService.user.user_role_id === Constants.USER_ROLE_EMPLOYEE_ID">*</span>
                        {{lang.la.postalcode}}
                    </label>
                    <div class="input-group">
                        <input type="number" class="form-control"
                               (ngModelChange)="inputPostalCode.error = ''" [(ngModel)]="inputPostalCode.value"
                               name="inputPostalCode.value" [ngClass]="{'is-invalid': inputPostalCode.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputPostalCode.error">
                        {{inputPostalCode.error}}
                    </div>
                </div>

            </div>
            <div class="col-sm-6">

                <div class="form-group">
                    <label for="type">
                        <span *ngIf="userService.user.user_role_id === Constants.USER_ROLE_EMPLOYEE_ID">*</span>
                        {{lang.la.address}}
                    </label>
                    <div class="input-group">
                        <input type="text" class="form-control"
                               (ngModelChange)="inputAddress.error = ''" [(ngModel)]="inputAddress.value"
                               name="inputAddress.value" [ngClass]="{'is-invalid': inputAddress.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputAddress.error">
                        {{inputAddress.error}}
                    </div>
                </div>

                <div class="form-group">
                    <label for="type">
                        <span *ngIf="userService.user.user_role_id === Constants.USER_ROLE_EMPLOYEE_ID">*</span>
                        {{lang.la.city}}
                    </label>
                    <div class="input-group">
                        <input type="text" class="form-control"
                               (ngModelChange)="inputCity.error = ''" [(ngModel)]="inputCity.value"
                               name="inputCity.value" [ngClass]="{'is-invalid': inputCity.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputCity.error">
                        {{inputCity.error}}
                    </div>
                </div>

            </div>
        </div>
        <div class="card-divider" *ngIf="userService.user.user_role_id === Constants.USER_ROLE_EMPLOYEE_ID"></div>
        <div class="card-body row" *ngIf="userService.user.user_role_id === Constants.USER_ROLE_EMPLOYEE_ID">
            <div class="col-sm-6">

                <div class="form-group">
                    <label for="type">* {{lang.la.reg_acc}}</label>
                    <div class="input-group">
                        <input type="number" class="form-control"
                               max="9999"
                               min="0000"
                               maxlength="4"
                               (ngModelChange)="inputRegNo.error = ''" [(ngModel)]="inputRegNo.value"
                               name="inputRegNo.value" [ngClass]="{'is-invalid': inputRegNo.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputRegNo.error">
                        {{inputRegNo.error}}
                    </div>
                </div>

            </div>
            <div class="col-sm-6">

                <div class="form-group">
                    <label for="type">* {{lang.la.acc_no}}</label>
                    <div class="input-group">
                        <input type="number" class="form-control"
                               (ngModelChange)="inputAccountNo.error = ''" [(ngModel)]="inputAccountNo.value"
                               name="inputAccountNo.value" [ngClass]="{'is-invalid': inputAccountNo.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputAccountNo.error">
                        {{inputAccountNo.error}}
                    </div>
                </div>

            </div>
        </div>
        <ng-container *ngIf="userService.user.user_role_id === Constants.USER_ROLE_EMPLOYEE_ID">
            <div class="card-divider"></div>
            <div class="card-body row">
                <div class="col-sm-12">
                    <label for="type">Ferie</label>
                </div>
                <div class="col-sm-12">
				<span class="badge badge-success push-m-20-r" *ngFor="let vacation of vacations; let pos = index">
					<span class="h6 push-p-10">
						{{vacation.vacation_start | mdate: 'DD-MM-YYYY': false}}
                        - {{vacation.vacation_end | mdate: 'DD-MM-YYYY': false}}
					</span>


					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"
                         (click)="onDeleteVacation(pos)">
						<path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
						<path d="M0 0h24v24H0z" fill="none"/>
					</svg>

				</span>
                    <button class="btn btn-outline-secondary" (click)="isVacationActive = !isVacationActive">Tilføj
                        ferie
                    </button>
                </div>
            </div>
        </ng-container>
        <div class="card-divider" *ngIf="userService.user.user_role_id === Constants.USER_ROLE_EMPLOYEE_ID"></div>
        <div class="card-body row" *ngIf="userService.user.user_role_id === Constants.USER_ROLE_EMPLOYEE_ID">
            <div class="col-sm-12">
                <label for="type">{{lang.la.select_contact_options}}</label>
            </div>
            <div class="col-sm-6">

                <div class="form-group input-group">
                    <label type="text" for="email" class="form-control">{{lang.la.email}}</label>
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <input type="checkbox" aria-label="Email" id="email"
                                   (ngModelChange)="inputContactEmail.error = ''" [(ngModel)]="inputContactEmail.value"
                                   name="inputContactEmail.value" [ngClass]="{'is-invalid': inputContactEmail.error}">
                        </div>
                    </div>

                </div>


            </div>
            <div class="col-sm-6">

                <div class="form-group input-group">
                    <label type="text" for="sms" class="form-control">{{lang.la.sms}}</label>
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <input type="checkbox" aria-label="SMS" id="sms"
                                   (ngModelChange)="inputContactSMS.error = ''" [(ngModel)]="inputContactSMS.value"
                                   name="inputContactSMS.value" [ngClass]="{'is-invalid': inputContactSMS.error}">
                        </div>
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputContactSMS.error">
                        {{inputContactSMS.error}}
                    </div>
                </div>

            </div>
        </div>

        <ng-container *ngIf="userService.user.user_role_id === Constants.USER_ROLE_EMPLOYEE_ID">
            <div class="card-divider"></div>
            <div class="card-body row">
                <div class="col-sm-8">
                    <div class="form-group"
                         *ngIf="userService.user.user_cooperation_agreements_date != '0000-00-00 00:00:00'">
                        <label for="type">{{lang.la.cooperation_accepted}}</label>
                        <div class="input-group">
                            <input class="text" class="is-fullwidth" id="samarbejdsaftalerdate" disabled
                                   [value]="utilService.onUppercase(userService.user.user_cooperation_agreements_date | mdate: 'dddd	DD. MMM YYYY HH:mm')"/>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group">
                        <label for="exampleFormControlTextarea1">
                            {{lang.la.cooperation_deal}}
                        </label>
                        <textarea class="form-control" id="samarbejdsaftaler" rows="5"
                                  disabled="true"
                                  (ngModelChange)="inputCollabText.error = ''" [(ngModel)]="inputCollabText.value"
                                  name="inputCollabText.value"
                                  [ngClass]="{'is-invalid': inputCollabText.error}"></textarea>
                    </div>
                </div>
                <div class="col-sm-6 has-text-left">
                    <button class="btn btn-secondary" (click)="onPrint()">{{lang.la.press_to_print_coop}}</button>
                </div>
                <div class="col-sm-6 text-right">
                    <button class="btn btn-success"
                            *ngIf="userService.user.user_cooperation_agreements_date == '0000-00-00 00:00:00'"
                            (click)="onAcceptCooperationDeal()">Godkend samarbejdsaftale
                    </button>
                </div>

            </div>
        </ng-container>

        <div class="card-divider" *ngIf="userService.user.user_role_id === Constants.USER_ROLE_EMPLOYEE_ID"></div>
        <div class="card-body row" *ngIf="userService.user.user_role_id === Constants.USER_ROLE_EMPLOYEE_ID">
            <div class="col-6">
                <div class="form-group">
                    <label for="type">{{lang.la.breastsize_pick}}</label>
                    <div class="input-group">
                        <input type="email" class="form-control" (ngModelChange)="inputBreastSize.error = ''"
                               [(ngModel)]="inputBreastSize.value"
                               name="inputBreastSize.value" [ngClass]="{'is-invalid': inputBreastSize.error}">
                        <div class="input-group-append">
                            <span class="input-group-text">cm</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group input-group">
                    <label for="type">{{lang.la.delievering}}</label><br/>
                    <div class="input-group">
                        <label type="text" for="inputDelieveryShop" class="form-control">{{lang.la.packshop}}</label>
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <input type="radio" aria-label="Løbende" id="inputDelieveryShop"
                                       (change)="inputDelieveryShop.value = !inputDelieveryShop.value"
                                       name="inputDelieveryShop.value" [checked]="inputDelieveryShop.value === true"
                                       [ngClass]="{'is-invalid': inputDelieveryShop.error}">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group input-group">
                    <label type="text" for="inputDelieveryAddress"
                           class="form-control">{{lang.la.delievering_on_info}}</label>
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <input type="radio" aria-label="Løbende" id="inputDelieveryAddress"
                                   (change)="inputDelieveryShop.value = !inputDelieveryShop.value"
                                   name="inputDelieveryShop.value" [checked]="inputDelieveryShop.value === false"
                                   [ngClass]="{'is-invalid': inputDelieveryShop.error}">
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="card-divider"></div>
        <div class="card-body row">
            <div class="col-sm-12 has-text-right">
                <button class="btn btn-secondary ml-2" [ngClass]="{'is-loading': isLoading}"
                        (click)="onGoBack()">{{lang.la.cancel}}</button>
                <button class="btn btn-primary ml-2" (click)="onUpdateProfile()" [ngClass]="{'is-loading': isLoading}">{{lang.la.update}}</button>
            </div>
        </div>
    </div>
</form>

