import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {FileUploader} from 'ng2-file-upload';
import {Constants} from '../../constants';

@Component({
    selector: 'app-imageuploader',
    templateUrl: './imageuploader.component.html',
    styleUrls: ['./imageuploader.component.scss']
})
export class ImageuploaderComponent implements OnInit {

    @Output()
    imageUploaderChange: EventEmitter<FileUploader> = new EventEmitter<FileUploader>();

    @Input()
    isUploadActive: boolean = false;

    @Input()
    imageUploader: FileUploader = new FileUploader({url: Constants.URL});

    @Input()
    multiple: boolean = true;

    constructor(
        private sanitizer: DomSanitizer
    ) {

    }

    ngOnInit() {
        this.imageUploader.onAfterAddingFile = (fileItem) => {

            fileItem.withCredentials = false;
            fileItem.file['imageimage'] = this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(fileItem._file)));
        };
    }

    fileOverBase(event) {
    }

    onAddToQueue() {
        if (!this.multiple && this.imageUploader.queue.length > 1) {
            this.imageUploader.queue.shift();
        }
    }

    onRemoveAgain(pos) {
        this.imageUploader.queue.splice(pos, 1);
    }

    onAdd() {
        this.imageUploaderChange.emit(this.imageUploader);
        this.isUploadActive = false;
    }
}
