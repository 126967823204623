<div class="dropdown" [ngClass]="{'customer-switcher__mobile': mobile}"
     *ngIf="userService.user != undefined &&  userService.user.user_role_id === Constants.USER_ROLE_CONSULTANT_ID && dataService.customersForUsers != undefined && dataService.customersForUsers.length > 1">
    <button class="btn btn-icon btn-dropdown btn-primary-lighter dropdown-toggle" type="button"
            (click)="onOpenSwitcher()">
        <svg style="margin-top: 3px;" fill="#FFFFFF" height="18" viewBox="0 0 24 24" width="18"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M16.5 12c1.38 0 2.49-1.12 2.49-2.5S17.88 7 16.5 7C15.12 7 14 8.12 14 9.5s1.12 2.5 2.5 2.5zM9 11c1.66 0 2.99-1.34 2.99-3S10.66 5 9 5C7.34 5 6 6.34 6 8s1.34 3 3 3zm7.5 3c-1.83 0-5.5.92-5.5 2.75V19h11v-2.25c0-1.83-3.67-2.75-5.5-2.75zM9 13c-2.33 0-7 1.17-7 3.5V19h7v-2.25c0-.85.33-2.34 2.37-3.47C10.5 13.1 9.66 13 9 13z"/>
        </svg>
        <span class="push-m-5-l">
						{{lang.la.customer}}: {{ getCurrentCustomerName() }}
					</span>
    </button>
    <div class="dropdown-menu" [ngClass]="{'show': isUserDropdown}">
        <a class="dropdown-item" *ngFor="let item of dataService.customersForUsers; let pos = index"
           (click)="onCustomersForUserSelected(item.customers)">
            <span *ngIf="!mobile">{{lang.la.customer}}: </span>{{item.customers.customer_name}}
        </a>
    </div>
</div>