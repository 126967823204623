import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TableHeader} from "../../models/tableheader";
import {TableSettings} from "../../models/tablesettings";
import {LangService} from "../../services/lang.service";
import {TableSettingsService} from "../../services/table-settings.service";

@Component({
    selector: 'app-search-table',
    templateUrl: './search-table.component.html',
    styleUrls: ['./search-table.component.scss']
})
export class SearchTableComponent {
    public isDropdownSearch: boolean = false;

    @Input() tableSettings: TableSettings;
    @Output() refreshData = new EventEmitter();

    constructor(private lang: LangService,
                private sessionTableSettings: TableSettingsService) {
    }

    onSearchSelect(pos) {
        this.setSearchColumn(this.tableSettings.tableHeaders[pos]);
        this.isDropdownSearch = false;
    }

    onBlurSearch() {
        setTimeout(() => {
            this.isDropdownSearch = false;
        }, 200);
    }

    onSearchPressed() {
        var newSearchSettings = new TableHeader(this.tableSettings.searchColumn.tablevalue, this.tableSettings.searchColumn.value);

        if (newSearchSettings.tablevalue == "user_firstname") {
            newSearchSettings.tablevalue = "user_name";
        }

        this.setSearchColumn(newSearchSettings);

        this.resetPage();
        this.refreshData.emit(this.tableSettings);
    }

    setSearchColumn(tableHeader: TableHeader) {
        this.tableSettings.searchColumn = tableHeader;
        this.sessionTableSettings.setTableSettings('searchColumn', tableHeader);
    }

    setSearch(searchString: string) {
        this.tableSettings.search = searchString;
        this.sessionTableSettings.setTableSettings('search', searchString);
    }

    onClearSearch() {
        this.setSearch('');
        this.isDropdownSearch = false;

        this.refreshData.emit(this.tableSettings);
    }

    onShowDeactivated() {
        if (this.tableSettings.tableSettingsEquals[0].value == "1") {
            this.tableSettings.tableSettingsEquals[0].value = "0";
        } else {
            this.tableSettings.tableSettingsEquals[0].value = "1";
        }


        this.sessionTableSettings.setTableSettings('showActive', this.tableSettings.tableSettingsEquals[0].value);

        this.resetPage();

        this.refreshData.emit(this.tableSettings);
    }

    resetPage() {
        this.tableSettings.page = 1;
        this.sessionTableSettings.setTableSettings('page', this.tableSettings.page);
    }
}
