<!-- Notification -->
<app-notification></app-notification>

<span *ngIf="version" class="small text-is-grey version-tag" [ngClass]="{'show-version': isSidebarActive && version}">v {{version}}</span>

<nav class="navbar navbar-expand-lg fadeIn" [ngClass]="{'navbar-switch': userService.userOriginal != undefined}">
    <button class="navbar-toggler" type="button" (click)="isSidebarActive = !isSidebarActive">
        <svg fill="#fff" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"/>
        </svg>
    </button>

    <a class="navbar-brand hidden-xs" routerLink="/app/news">
        <img src="./assets/logo.png" alt="Logo" class="navbar-logo">
    </a>

    <div class="collapse navbar-collapse fadeIn" id="navbarSupportedContent">

        <div class="navbar-nav mr-auto">
            <app-customer-switcher></app-customer-switcher>
        </div>

        <span class="navbar-text navbar-user">
			<img *ngIf="userService.user != undefined && userService.user.images != undefined"
                 [src]="utilService.onImageToView(userService.user.images.image_link)" class="profile-image-small"
                 alt="">
            {{userService.user.user_firstname}} {{userService.user.user_lastname}}
		</span>

    </div>

    <div class="dropdown navbar-dropdown">

        <div class="dropdown-menu notificationContainer" [ngClass]="{'show': isDropdownUserActive}"
             [ngStyle]="notificationStyle">
            <h6 class="dropdown-header">Notifikationer</h6>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item circle-container"
               *ngIf="dataService.notifications != undefined && dataService.notifications.length == 0">
				<span>
					Ingen notifikationer på nuværende tidspunkt
				</span>
            </a>
            <a class="dropdown-item circle-container" *ngFor="let item of dataService.notifications; let pos = index"
               (click)="onClickOnNotification(pos)">

				<span *ngIf="item.notification_type == 'tildelt'">
					<ng-container *ngIf="userService.userIsUserRoleId(Constants.USER_ROLE_EMPLOYEE_ID)">Du har fået en ny opgave tildelt</ng-container>
                    <ng-container *ngIf="userService.userIsUserRoleId(Constants.USER_ROLE_CONSULTANT_ID)">En opgave er blevet tildelt en medarbejder</ng-container>
				</span>
                <span *ngIf="item.notification_type == 'udbudt'">
					Du er fået udbudt en ny opgave
				</span>
                <span *ngIf="item.notification_type == 'accepteret'">
					Din afrapporting på en opgave er blevet accepteret
				</span>
                <span *ngIf="item.notification_type == 'ferie oprettet'">
					{{ item.notifiable }} har oprettet ny ferie
				</span>
                <span *ngIf="item.notification_type == 'godkendt'">
					Din afrapporting på en opgave er blevet godkendt
				</span>
                <span *ngIf="item.notification_type == 'edited' && item.notifiable_object == 'User'">
					{{ item.notifiable }} har ændret følgende på sin profil: {{ item.notification_data.join(", ") }}
				</span>
                <span *ngIf="item.notification_read == 0" class="circle"></span>
                <br/>
                <label class="h7 text-is-grey">
                    {{item.notification_creation | mdate: 'DD-MM-YYYY HH:mm' }}
                </label>
            </a>
        </div>

        <a class="navbar-profile push-m-10-l push-m-10-r" (click)="onShowNotifications()">
            <img src="./assets/ic_alert.svg" alt="Notifikations" class="navbar-dropdown-icon">
            <span class="navbar-alert-dot" *ngIf="dataService.notificationsUnread"></span>
        </a>


    </div>
</nav>
<div id="wrapper" [ngClass]="{'toggled': isSidebarActive}">

    <!-- Sidebar -->
    <div id="sidebar-wrapper" *ngIf="!isRedrawing">

        <ul class="sidebar-nav push-p-20-t fadeIn" *ngIf="userService.isUserUpdated">
            <li class="hidden-md hidden-lg">
                <app-customer-switcher mobile="true" (itemSelected)="this.isSidebarActive = false"></app-customer-switcher>
            </li>
            <!-- -->
            <li>
                <a class="sidebar-nav-item" routerLink="news" routerLinkActive="sidebar-a-active"
                   (click)="onCloseDropdown()">
                    <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z"/>
                        <path d="M0 0h24v24H0z" fill="none"/>
                    </svg>
                    <span>
						{{lang.la.dashboard_frontpage}}
					</span>

                </a>
            </li>

            <!-- -->
            <li *ngIf="userService.getShowForUser(USER_RIGHT_TYPES.ASSIGNMENT_CREATE) && onCheckIfCanCreateAssignments()">
                <a class="sidebar-nav-item" routerLink="task/create" routerLinkActive="sidebar-a-active"
                   (click)="onCloseDropdown()">
                    <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm0 4c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H6v-1.4c0-2 4-3.1 6-3.1s6 1.1 6 3.1V19z"/>
                    </svg>
                    <span>
						{{lang.la.dashboard_createassignment}}
					</span>
                </a>
            </li>

            <!-- -->
            <li *ngIf="userService.getShowForUser(USER_RIGHT_TYPES.ASSIGNMENT_LIST)">
                <a class="sidebar-nav-item" routerLink="task/list" routerLinkActive="sidebar-a-active"
                   (click)="onCloseDropdown()">
                    <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"/>
                    </svg>
                    <span>
						{{lang.la.dashboard_assignmentlist}}
					</span>
                </a>
            </li>

            <!-- -->
            <li *ngIf="userService.getShowForUser(USER_RIGHT_TYPES.STORE_LIST)">
                <a class="sidebar-nav-item" routerLink="./store/list" (click)="onStoreDropdown()">
                    <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M20 4H4v2h16V4zm1 10v-2l-1-5H4l-1 5v2h1v6h10v-6h4v6h2v-6h1zm-9 4H6v-4h6v4z"/>
                    </svg>
                    <span>
						{{lang.la.dashboard_stores}}
					</span>
                </a>
                <ul *ngIf="isStoreActive">
                    <li>
                        <a class="sidebar-nav-item" routerLink="./store/list" routerLinkActive="sidebar-a-active"
                           (click)="onSubmenuCloseDropdown()">
                            {{lang.la.dashboard_stores_list}}
                        </a>
                        <a class="sidebar-nav-item" routerLink="./store/create" routerLinkActive="sidebar-a-active"
                           (click)="onSubmenuCloseDropdown()">
                            {{lang.la.dashboard_stores_create}}
                        </a>
                        <a class="sidebar-nav-item" routerLink="./chain/list" routerLinkActive="sidebar-a-active"
                           (click)="onSubmenuCloseDropdown()">
                            {{lang.la.chains}}
                        </a>
                        <a class="sidebar-nav-item" routerLink="./chain/create" routerLinkActive="sidebar-a-active"
                           (click)="onSubmenuCloseDropdown()">
                            {{lang.la.create_chain}}
                        </a>
                    </li>
                </ul>
            </li>

            <!-- -->
            <li *ngIf="userService.getShowForUser(USER_RIGHT_TYPES.USER_LIST)">
                <a class="sidebar-nav-item" routerLink="./user/list/employee" (click)="onUserDropdown()">
                    <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"/>
                    </svg>
                    <span>
						{{lang.la.dashboard_users}}
					</span>
                </a>
                <ul *ngIf="isUserDropdownActive">
                    <li>
                        <a class="sidebar-nav-item" routerLink="./user/list/employee"
                           routerLinkActive="sidebar-a-active" (click)="onSubmenuCloseDropdown()">
                            {{lang.la.employees}}
                        </a>
                        <a class="sidebar-nav-item" routerLink="./user/list/consultant"
                           routerLinkActive="sidebar-a-active" (click)="onSubmenuCloseDropdown()">
                            {{lang.la.salesconsultant}}
                        </a>
                        <a class="sidebar-nav-item" routerLink="./user/list/customer"
                           routerLinkActive="sidebar-a-active" (click)="onSubmenuCloseDropdown()">
                            {{lang.la.customerusers}}
                        </a>
                        <a class="sidebar-nav-item" routerLink="./user/list/teamplayer"
                           routerLinkActive="sidebar-a-active" (click)="onSubmenuCloseDropdown()">
                            {{lang.la.teamplayers}}
                        </a>
                        <a class="sidebar-nav-item" routerLink="./user/create" routerLinkActive="sidebar-a-active"
                           (click)="onSubmenuCloseDropdown()">
                            {{lang.la.create_user}}
                        </a>
                        <a class="sidebar-nav-item" routerLink="./user/send" routerLinkActive="sidebar-a-active"
                           (click)="onSubmenuCloseDropdown()">
                            {{lang.la.send_email_sms}}
                        </a>
                    </li>
                </ul>
            </li>

            <!-- -->
            <li *ngIf="userService.userIsOneOfUserRoleIds([Constants.USER_ROLE_TEAMPLAYER_ID, Constants.USER_ROLE_ADMIN_ID])">
                <a class="sidebar-nav-item" routerLink="./export/list" (click)="onExportDropdown()">
                    <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z"/>
                        <path d="M0 0h24v24H0z" fill="none"/>
                    </svg>
                    <span>
						{{lang.la.afregning}}
					</span>
                </a>
                <ul *ngIf="isExportDropdownActive">
                    <li>
                        <a class="sidebar-nav-item" routerLink="./export/list" routerLinkActive="sidebar-a-active"
                           (click)="onSubmenuCloseDropdown()">
                            {{lang.la.salaryandinvoice}}

                        </a>
                        <a class="sidebar-nav-item" routerLink="./export/create" routerLinkActive="sidebar-a-active"
                           (click)="onSubmenuCloseDropdown()">
                            {{lang.la.createexport}}
                        </a>
                    </li>
                </ul>
            </li>

            <!-- -->
            <li *ngIf="userService.getShowForUser(USER_RIGHT_TYPES.CUSTOMER_LIST)">
                <a class="sidebar-nav-item" routerLink="./customer/list" (click)="onCustomerDropdown()">
                    <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M8 10H5V7H3v3H0v2h3v3h2v-3h3v-2zm10 1c1.66 0 2.99-1.34 2.99-3S19.66 5 18 5c-.32 0-.63.05-.91.14.57.81.9 1.79.9 2.86s-.34 2.04-.9 2.86c.28.09.59.14.91.14zm-5 0c1.66 0 2.99-1.34 2.99-3S14.66 5 13 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm6.62 2.16c.83.73 1.38 1.66 1.38 2.84v2h3v-2c0-1.54-2.37-2.49-4.38-2.84zM13 13c-2 0-6 1-6 3v2h12v-2c0-2-4-3-6-3z"/>
                    </svg>
                    <span>
						{{lang.la.customers}}
					</span>
                </a>
                <ul *ngIf="isCustomerDropdownActive">
                    <li>
                        <a class="sidebar-nav-item" routerLink="./customer/list" routerLinkActive="sidebar-a-active"
                           (click)="onSubmenuCloseDropdown()">
                            {{lang.la.customersoverview}}
                        </a>
                        <a class="sidebar-nav-item" routerLink="./customer/create" routerLinkActive="sidebar-a-active"
                           (click)="onSubmenuCloseDropdown()">
                            {{lang.la.create_customer}}
                        </a>
                    </li>
                </ul>
            </li>

            <!-- -->
            <li *ngIf="userService.getShowForUser(USER_RIGHT_TYPES.CALENDAR)">
                <a class="sidebar-nav-item" routerLink="./calendar" routerLinkActive="sidebar-a-active"
                   (click)="onCloseDropdown()">
                    <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"/>
                    </svg>
                    <span>
						{{lang.la.dashboard_calendar}}
					</span>
                </a>
            </li>

            <!-- -->
            <li>
                <a class="sidebar-nav-item" routerLink="./profile/my" routerLinkActive="sidebar-a-active" (click)="onCloseDropdown()">
                    <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 5v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2H5c-1.11 0-2 .9-2 2zm12 4c0 1.66-1.34 3-3 3s-3-1.34-3-3 1.34-3 3-3 3 1.34 3 3zm-9 8c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1H6v-1z"/>
                        <path d="M0 0h24v24H0z" fill="none"/>
                    </svg>
                    <span>
						{{lang.la.dashboard_profile}}
					</span>
                </a>
            </li>

            <!-- -->
            <!-- <li *ngIf="userService.getShowForUser(USER_RIGHT_TYPES.BUSINESSINTELLIGENCE)">
                <a class="sidebar-nav-item" routerLink="./businessintelligence"  routerLinkActive="sidebar-a-active" (click)="onCloseDropdown()">
                    <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h24v24H0V0z" fill="none"/>
                        <path d="M11 2v20c-5.07-.5-9-4.79-9-10s3.93-9.5 9-10zm2.03 0v8.99H22c-.47-4.74-4.24-8.52-8.97-8.99zm0 11.01V22c4.74-.47 8.5-4.25 8.97-8.99h-8.97z"/>
                    </svg>
                    <span>
                        {{lang.la.dashboard_businessintelligence}}
                    </span>
                </a>
            </li> -->

            <!-- -->
            <li *ngIf="userService.userOriginal != undefined">
                <a class="sidebar-nav-item" (click)="onLogoutAsFake()">
                    <svg fill="#dc3545" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"
                         style="fill: #dc3545 !important">
                        <path d="M0 0h24v24H0V0z" fill="none"/>
                        <path d="M11 9l1.42 1.42L8.83 14H18V4h2v12H8.83l3.59 3.58L11 21l-6-6 6-6z"/>
                    </svg>
                    <span class="text-danger">
						Tilbage til {{userService.userOriginal.user_firstname}}
					</span>
                </a>
            </li>

            <!-- -->
            <li *ngIf="userService.userOriginal == undefined">
                <a class="sidebar-nav-item pb-5" (click)="onLogout()">
                    <svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h24v24H0V0z" fill="none"/>
                        <path d="M11 9l1.42 1.42L8.83 14H18V4h2v12H8.83l3.59 3.58L11 21l-6-6 6-6z"/>
                    </svg>
                    <span>
						{{lang.la.dashboard_logout}}
					</span>
                </a>
            </li>

        </ul>
    </div>
    <!-- /#sidebar-wrapper -->

    <!-- Page Content -->
    <div id="page-content-wrapper" *ngIf="!isRedrawing" (click)="pageContentClick()">
        <div class="container-fluid">
            <div class="container-fluid push-p-30-t">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
    <!-- /#page-content-wrapper -->

</div>
<!-- /#wrapper -->
