<svg width="500px" height="344px" viewBox="0 0 500 344" version="1.1" xmlns="http://www.w3.org/2000/svg"
>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Artboard-2" transform="translate(-27.000000, -19.000000)">
            <g id="graph" transform="translate(27.000000, 19.000000)">
                <path d="M40,311 L490,311" id="line" stroke="#E3E3E3" stroke-linecap="square"></path>
                <path d="M40,271 L490,271" id="line" stroke="#E3E3E3" stroke-linecap="square"></path>
                <path d="M40,231 L490,231" id="line" stroke="#E3E3E3" stroke-linecap="square"></path>
                <path d="M40,191 L490,191" id="line" stroke="#E3E3E3" stroke-linecap="square"></path>
                <path d="M40,151 L490,151" id="line" stroke="#E3E3E3" stroke-linecap="square"></path>
                <path d="M40,111 L490,111" id="line" stroke="#E3E3E3" stroke-linecap="square"></path>
                <path d="M40,73 L490,73" id="line" stroke="#E3E3E3" stroke-linecap="square"></path>

                <text id="side_text" *ngIf="showGraph1" font-family="ArialMT, Arial" font-size="15" font-weight="bold"
                      fill="#000000">
                    <tspan x="36"
                           [attr.y]="(graph.graphs_elements_y[0] - 20)">{{graph.graphs_elements[0] | number}}</tspan>
                </text>

                <rect id="rec_1" (mouseenter)="showGraph1 = true" (mouseleave)="showGraph1 = false" fill="#007AFF"
                      x="39" [attr.y]="graph.graphs_elements_y[0]" width="43"
                      [attr.height]="graph.graphs_elements_height[0]"></rect>
                <rect id="rec_2" fill="#2296F3" x="107" [attr.y]="graph.graphs_elements_y[1]" width="43"
                      [attr.height]="graph.graphs_elements_height[1]"></rect>
                <rect id="rec_3" fill="#007AFF" x="175" [attr.y]="graph.graphs_elements_y[2]" width="43"
                      [attr.height]="graph.graphs_elements_height[2]"></rect>
                <rect id="rec_4" fill="#2296F3" x="243" [attr.y]="graph.graphs_elements_y[3]" width="43"
                      [attr.height]="graph.graphs_elements_height[3]"></rect>
                <rect id="rec_5" fill="#007AFF" x="311" [attr.y]="graph.graphs_elements_y[4]" width="43"
                      [attr.height]="graph.graphs_elements_height[4]"></rect>
                <rect id="rec_6" fill="#2296F3" x="379" [attr.y]="graph.graphs_elements_y[5]" width="43"
                      [attr.height]="graph.graphs_elements_height[5]"></rect>
                <rect id="rec_7" fill="#007AFF" x="447" [attr.y]="graph.graphs_elements_y[6]" width="43"
                      [attr.height]="graph.graphs_elements_height[6]"></rect>

                <text id="side_1" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="0" y="315">{{graph.side_left[0] | number}}</tspan>
                </text>
                <text id="side_3" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="0" y="235">{{graph.side_left[2] | number}}</tspan>
                </text>
                <text id="side_6" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="0" y="115">{{graph.side_left[5] | number}}</tspan>
                </text>
                <text id="side_2" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="0" y="275">{{graph.side_left[1] | number}}</tspan>
                </text>
                <text id="side_4" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="0" y="195">{{graph.side_left[3] | number}}</tspan>
                </text>
                <text id="side_7" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="0" y="75">{{graph.side_left[6] | number}}</tspan>
                </text>
                <text id="side_5" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="0" y="155">{{graph.side_left[5] | number}}</tspan>
                </text>
                <!-- 34 -->
                <text id="1" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="44" y="338">{{graph.graphs_elements_x[0]}}</tspan>
                </text>
                <text id="2" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="114" y="338">{{graph.graphs_elements_x[1]}}</tspan>
                </text>
                <text id="3" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="182" y="338">{{graph.graphs_elements_x[2]}}</tspan>
                </text>
                <text id="4" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="250" y="338">{{graph.graphs_elements_x[3]}}</tspan>
                </text>
                <text id="5" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="318" y="338">{{graph.graphs_elements_x[4]}}</tspan>
                </text>
                <text id="6" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="386" y="338">{{graph.graphs_elements_x[5]}}</tspan>
                </text>
                <text id="7" font-family="ArialMT, Arial" font-size="11" font-weight="normal" fill="#000000">
                    <tspan x="454" y="338">{{graph.graphs_elements_x[6]}}</tspan>
                </text>
            </g>
        </g>
    </g>
</svg>