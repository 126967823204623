import { EventEmitter, OnChanges, OnInit } from '@angular/core';
import { LangService } from '../../services/lang.service';
import { UtilService } from '../../services/util.service';
var DropdownSelectComponent = /** @class */ (function () {
    function DropdownSelectComponent(lang, utilService) {
        this.lang = lang;
        this.utilService = utilService;
        this.arrayList = [];
        this.arrayListName = "name";
        this.arrayListId = "id";
        this.arrayListSelectedId = "";
        this.loadMore = true;
        this.selectedItem = new EventEmitter();
        this.onLoadMore = new EventEmitter();
        this.isLoadingMore = false;
        this.isNoMoreToLoad = false;
        this.isAItemSelected = false;
        this.error = "";
        this.inputDropdown = "";
        this.arrayListForView = [];
        this.isDropdownActive = false;
    }
    DropdownSelectComponent.prototype.ngOnInit = function () {
        if (!this.loadMore)
            this.isNoMoreToLoad = true;
        this.arrayListForView = this.arrayList;
        if (this.arrayListSelectedId != "") {
            for (var i = 0; i < this.arrayList.length; i++) {
                if (this.arrayList[i][this.arrayListId].toString() == this.arrayListSelectedId) {
                    this.inputDropdown = this.utilService.onGetTableContent(this.arrayList[i], this.arrayListName);
                }
            }
        }
        if (this.arrayListSelectedObject != undefined) {
            this.inputDropdown = this.utilService.onGetTableContent(this.arrayListSelectedObject, this.arrayListName);
        }
    };
    DropdownSelectComponent.prototype.ngOnChanges = function () {
        this.arrayListForView = this.arrayList;
        this.inputDropdown = "";
        if (this.arrayListSelectedId != "") {
            for (var i = 0; i < this.arrayList.length; i++) {
                if (this.arrayList[i][this.arrayListId] == this.arrayListSelectedId) {
                    this.inputDropdown = this.utilService.onGetTableContent(this.arrayListForView[i], this.arrayListName);
                }
            }
        }
        // if(this.arrayListSelectedObject != undefined) {
        // 	this.inputDropdown = this.utilService.onGetTableContent(this.arrayListSelectedObject, this.arrayListName);
        // }
    };
    DropdownSelectComponent.prototype.onAddedNewArray = function () {
    };
    DropdownSelectComponent.prototype.onLoadMorePressed = function () {
        this.isLoadingMore = true;
        this.onLoadMore.emit();
    };
    DropdownSelectComponent.prototype.onClearSearch = function () {
        this.inputDropdown = "";
        this.selectedItem.emit("");
    };
    /**
     * Searching through the results
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    DropdownSelectComponent.prototype.onChangeInputDropdown = function () {
        this.error = "";
        this.arrayListForView = [];
        var _loop_1 = function (i) {
            var list_items = this_1.arrayListName.split('&');
            var list_items_values = {};
            var item_found = false;
            for (var a = 0; a < list_items.length; a++) {
                if (this_1.arrayList[i][list_items[a]])
                    list_items_values[list_items[a]] = this_1.arrayList[i][list_items[a]].toLowerCase();
                if (this_1.arrayList[i][list_items[a]] && this_1.arrayList[i][list_items[a]].toLowerCase().match(this_1.inputDropdown.toLowerCase())) {
                    this_1.arrayListForView.push(this_1.arrayList[i]);
                    item_found = true;
                    break;
                }
            }
            //If item is not found, join all columns searchin for, andmath against that
            if (!item_found && list_items.length > 1) {
                var values_combined = Object.keys(list_items_values).map(function (item) {
                    return list_items_values[item];
                });
                if (values_combined.join(' ').toLowerCase().match(this_1.inputDropdown.toLowerCase())) {
                    this_1.arrayListForView.push(this_1.arrayList[i]);
                    item_found = true;
                }
            }
        };
        var this_1 = this;
        for (var i = 0; i < this.arrayList.length; i++) {
            _loop_1(i);
        }
    };
    DropdownSelectComponent.prototype.onItemSelected = function (pos) {
        if (this.arrayListForView.length < pos) {
            return;
        }
        this.error = "";
        this.isAItemSelected = true;
        this.inputDropdown = this.utilService.onGetTableContent(this.arrayListForView[pos], this.arrayListName);
        this.selectedItem.emit(this.arrayListForView[pos][this.arrayListId]);
        this.isDropdownActive = false;
    };
    DropdownSelectComponent.prototype.onFocusOut = function () {
        var _this = this;
        setTimeout(function () {
            if (!_this.isAItemSelected) {
                _this.inputDropdown = "";
            }
            _this.isDropdownActive = false;
        }, 500);
    };
    return DropdownSelectComponent;
}());
export { DropdownSelectComponent };
