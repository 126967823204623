<div class="row mb-3 mb-sm-5">
    <div class="col-sm-3">
        <h1 class="h2">{{lang.la.customer}}</h1>
    </div>
    <div class="col-sm-9 list-action-buttons">
        <button class="btn btn-primary" routerLink="../create">{{lang.la.create}}</button>
        <button class="btn btn-primary" [ngClass]="{'is-loading': csvService.isLoadingExport}"
                [disabled]="csvService.isLoadingExport"
                *ngIf="this.userService.user.user_role_id == Constants.USER_ROLE_TEAMPLAYER_ID || this.userService.user.user_role_id == Constants.USER_ROLE_ADMIN_ID"
                (click)="csvService.exportGeneralCSV(tableSettings, 'export_customers')">{{lang.la.export_to_excel}}</button>
    </div>
</div>

<!-- Loading -->
<app-loading *ngIf="isLoadingView"></app-loading>

<!-- Table -->
<div *ngIf="!isLoadingView">
    <app-table [tableSettings]="tableSettings" [isLoadingTable]="isLoadingTable" [tableData]="tableData"
               [isLoadingTable]="isLoadingTable" (onRowPressed)="onRowPressed($event)"
               (getData)="getData($event)"></app-table>
</div>
