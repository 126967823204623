import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';

import {UtilService} from '../services/util.service';
import {DataService} from '../services/data.service';
import {LangService} from '../services/lang.service';
import {ApiService} from '../services/api.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Consultant} from '../models/consultant';
import {InputString} from '../models/inputstring';
import {InputCheckbox} from '../models/inputcheckbox';
import * as moment from 'moment-timezone';
import {Assignment} from '../models/assignment';
import {FileUploader} from 'ng2-file-upload';
import {Constants, USER_RIGHT_TYPES} from '../constants';
import {UserService} from '../services/user.service';
import {TableSettings} from '../models/tablesettings';
import {NotificationService} from '../shared/notification.service';
import {TableSettingsEquals} from '../models/tablesettingsequals';
import {Location} from '@angular/common';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {User} from '../models/user';
import {AppendixForAssignment} from '../models/appendix-for-assignment';
import {AssignmentExecutions} from '../models/assignment-executions';
import {InputDate} from '../models/inputdate';
import {AssignmentStatus} from '../models/assignment-status';
import {Subject} from 'rxjs';
import {FileUploaderService} from "../services/fileUploaderService";

@Component({
    selector: 'app-assignment-create',
    templateUrl: './assignment-create.component.html',
    styleUrls: ['./assignment-create.component.scss']
})
export class AssignmentCreateComponent implements OnInit, OnDestroy {
    Constants = Constants;
    isLoadingView: boolean = true;
    isLoading: boolean = false;
    isUpdatingView: boolean = false;

    currentState: string;

    //
    USER_RIGHT_TYPES: any = USER_RIGHT_TYPES;

    assignment: Assignment = new Assignment();

    //InputData
    consultants: Consultant[] = [];

    //Inputs
    inputCustomer: InputString = new InputString();
    inputConsultant: InputString = new InputString();
    inputStore: InputString = new InputString();
    inputTime: InputString = new InputString();

    inputDescr: InputString = new InputString();

    inputPosMaterial: InputString = new InputString();
    inputContactPerson: InputString = new InputString();
    inputProducts: InputString = new InputString();
    inputPlaceInStore: InputString = new InputString();

    inputType: InputString = new InputString();
    inputTimeType: InputString = new InputString();

    inputTodoFill: InputCheckbox = new InputCheckbox();
    inputTodoCleaning: InputCheckbox = new InputCheckbox();
    inputTodoShow: InputCheckbox = new InputCheckbox();
    inputTodoDev: InputCheckbox = new InputCheckbox();
    inputTodoOut: InputCheckbox = new InputCheckbox();
    inputTodoDemo: InputCheckbox = new InputCheckbox();

    inputEstimate: InputString = new InputString();

    inputUsers: any[] = [];
    inputUserError: string = '';

    //Frit and fast
    inputDateEnd: InputDate = new InputDate();
    inputDateStart: InputDate = new InputDate();

    //
    inputAssignSupply: InputCheckbox = new InputCheckbox();
    inputAssignOffer: InputCheckbox = new InputCheckbox();
    inputAssignLater: InputCheckbox = new InputCheckbox();

    filesBilag: FileUploader = new FileUploader({});
    filesImages: FileUploader = new FileUploader({});

    // Used for uploading or editing appendixes
    assignmentAppendix: AppendixForAssignment[] = [];


    executionsProgress: number = 1;
    executionsProgressTemp: AssignmentExecutions;
    executionsEditing: boolean = false;

    // Inputs
    inputExpenses: InputString = new InputString();
    inputTimeUsedEnd: InputString = new InputString();
    inputTimeUsedStart: InputString = new InputString();
    inputDriving: InputString = new InputString();
    inputDescription: InputString = new InputString();

    // Assignment execution control
    assignmentExecutionControl: AssignmentExecutions;

    ID: number;

    // information needed to enable and disable form report
    showReportForm: boolean = false;
    showLoaderReportForm: boolean = false;

    executionToPost: any;

    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();

    isNoConsultants: string = '';
    employeeAssignmentStatus: AssignmentStatus;
    canSubmitForm = true;

    @ViewChild('endDate') endDate: ElementRef;

    destroy$: Subject<boolean> = new Subject<boolean>();
    private showAssignmentCreatedNotification: boolean = false;

    constructor(public userService: UserService,
                public apiService: ApiService,
                public route: ActivatedRoute,
                private router: Router,
                public utilService: UtilService,
                public lang: LangService,
                public dataService: DataService,
                public notificationService: NotificationService,
                private location: Location,
                private activatedRoute: ActivatedRoute,
                private fileUploaderService: FileUploaderService) {
    }

    ngOnInit() {
        // this makes reusing the components possible, because if going to another version of the create component (edit, copy, create) directly from the same create component, it only changes the routeParams
        // this means that we should re-init the component when the routeParams changes
        this.activatedRoute.paramMap.subscribe((routeParams: ParamMap) => {
            this.init();
        });
    }

    init() {
        //If agreement not accepted yet
        if ((this.userService.user.user_cooperation_agreements_date == undefined
            || this.userService.user.user_cooperation_agreements_date == '0000-00-00 00:00:00')
            && this.userService.user.user_role_id === this.Constants.USER_ROLE_EMPLOYEE_ID
        ) {
            this.router.navigate(['./app/deal']);
        }

        this.onResetData();

        moment.locale('da-DK');
        this.dpConfig['locale'] = 'da';

        this.dataService.getSalesConsultants();
        this.dataService.getEmployees();

        //Auto closest
        var hh = '';
        var mm = +(moment().format('mm'));
        if (mm >= 45) {
            hh = hh + '45';
        } else if (mm >= 30) {
            hh = hh + '30';
        } else if (mm >= 15) {
            hh = hh + '15';
        } else if (mm >= 0) {
            hh = hh + '00';
        }

        this.inputTime.value = moment().format('HH') + ':' + hh;
        this.ID = this.route.snapshot.params['id'];
        this.setCurrentState();
        if (this.isState('editing')) {
            this.getData();
        }

        this.userFormRules(this.userService.user);
    }

    setCurrentState() {
        if (this.router.url.includes('edit') || this.router.url.includes('copy')) {
            this.currentState = 'editing';
        } else {
            this.currentState = 'creating';
        }
    }

    isState(state) {
        return this.currentState == state;
    }

    getData() {
        this.isLoadingView = true;

        return this.apiService.getAssignment(this.ID).subscribe((data) => {
            if (!data) {
                this.notificationService.setNotification(this.lang.la.error_general, this.notificationService.CONSTANT_TYPE_WARNING);
                return;
            }

            this.assignment = new Assignment(data);
            this.assignment.assignment_timestype_start = this.utilService.fromUTC(this.assignment.assignment_timestype_start).toDate();
            if (this.assignment.assignment_timestype_free_end){
                this.assignment.assignment_timestype_free_end = this.utilService.fromUTC(this.assignment.assignment_timestype_free_end).toDate();
            }


            if (this.userService.user.user_role_id === Constants.USER_ROLE_EMPLOYEE_ID) {
                // if an employee is attempting to copy an assignment, always set the timetype as "fast" and remove the end time
                this.assignment.assignment_timestype_free_end = null;
                this.assignment.assignment_timestype_free = 2;
                this.inputTimeType.value = 'fast';
            } else {
                // when copying (not as employee) adjust the end time accordingly
                if (this.assignment.assignment_timestype_free_end){
                    this.assignment.assignment_timestype_free_end = this.utilService.fromUTC(this.assignment.assignment_timestype_free_end).toDate();
                }
            }

            // @ts-ignore
            if (data.appendices) {
                // @ts-ignore
                this.assignmentAppendix = data.appendices;
            }


            //this.getDataAssignmentAppendixes();
            //this.onGetAssignmentForUsers(this.ID);
            //this.onGetAssignmentTodos(this.ID);

            const d: any[] = this.assignment.todos;
            this.onSetTodos(d);
            this.onSetInputs();

        }, (error) => {
            this.isLoadingView = false;
            this.notificationService.setNotification(this.lang.la.error_general + ' #2', this.notificationService.CONSTANT_TYPE_WARNING);
        });
    }

    onSetInputs() {
        if (this.assignment == undefined) {
            this.notificationService.setNotification(this.lang.la.error_general + ' #1', this.notificationService.CONSTANT_TYPE_WARNING);
            return;
        }

        this.inputCustomer.value = '' + this.assignment.customer_id;
        this.onCustomerChange(this.inputCustomer.value);

        this.inputConsultant.value = '' + this.assignment.user_id;

        this.inputPosMaterial.value = this.assignment.assignment_pos_material.toString();

        this.inputContactPerson.value = this.assignment.assignment_contact_person;

        this.inputStore.value = '' + this.assignment.store_id;

        this.inputTimeType.value = this.assignment.assignment_timestype_free == 1 ? 'frit' : 'fast';

        this.inputDateStart.value = moment(this.assignment.assignment_timestype_start).toDate();

        if (this.inputTimeType.value == 'frit') {
            this.inputDateEnd.value = moment(this.assignment.assignment_timestype_free_end).toDate();
        } else if (this.userService.userIsUserRoleId(Constants.USER_ROLE_EMPLOYEE_ID) && this.router.url.includes('copy')) {
            this.inputDateEnd.value = moment(this.assignment.assignment_timestype_start).add(this.assignment.assignment_time_estimate, 'ms').toDate();
        }

        this.inputDescr.value = this.assignment.assignment_description;

        this.inputType.value = this.assignment.assignment_type;
        this.inputPlaceInStore.value = this.assignment.assignment_place_in_store;

        this.inputEstimate.value = this.utilService.onConvertMiliToHoursAndMili(this.assignment.assignment_time_estimate);

        this.inputAssignLater.value = false;
        this.inputAssignOffer.value = false;
        this.inputAssignSupply.value = false;

        this.inputProducts.value = this.assignment.assignment_product;
        this.inputPlaceInStore.value = this.assignment.assignment_place_in_store;

        if (this.assignment.assignment_status == Constants.ASSIGNMENT_STATUS_GEM) {
            this.inputAssignLater.value = true;
        } else if (this.assignment.assignment_status == Constants.ASSIGNMENT_STATUS_AWAITING_EXECUTION || this.assignment.assignment_status === Constants.ASSIGNMENT_STATUS_ACCEPTED || this.assignment.assignment_status === Constants.ASSIGNMENT_STATUS_AWAITING_ACCEPT) {
            this.inputAssignOffer.value = true;
        } else if (this.assignment.assignment_status == Constants.ASSIGNMENT_STATUS_AWAITING_EMPLOYEE_ACCEPT) {
            this.inputAssignSupply.value = true;
        }
        this.isLoadingView = false;

        this.inputUsers = this.assignment.employees;
    }

    onSetTodos(assignmentTodosForAssignments: any[]) {
        for (let i = 0; i < assignmentTodosForAssignments.length; i++) {
            const element = assignmentTodosForAssignments[i];
            if (element.assignment_todo_id == Constants.ASSIGNMENT_TODO_REFILL) {
                this.inputTodoFill.value = true;
            } else if (element.assignment_todo_id == Constants.ASSIGNMENT_TODO_CLEANING) {
                this.inputTodoCleaning.value = true;
            } else if (element.assignment_todo_id == Constants.ASSIGNMENT_TODO_EXHIBITION) {
                this.inputTodoShow.value = true;
            } else if (element.assignment_todo_id == Constants.ASSIGNMENT_TODO_STOCKORDER) {
                this.inputTodoDev.value = true;
            } else if (element.assignment_todo_id == Constants.ASSIGNMENT_TODO_SETTINGUP) {
                this.inputTodoOut.value = true;
            } else if (element.assignment_todo_id == Constants.ASSIGNMENT_TODO_DEMOSTRATION) {
                this.inputTodoDemo.value = true;
            }
        }
    }

    onCustomerChange(event) {

        this.isUpdatingView = true;
        this.inputCustomer.value = event;

        if (this.inputCustomer.value == undefined || this.inputCustomer.value.length == 0) {
            return;
        }
        if (this.userService.user.user_role_id !== 3) {
            const tscfu: TableSettings = new TableSettings();
            tscfu.tableSettingsEquals.push(new TableSettingsEquals('customer_id', this.inputCustomer.value));
            tscfu.tableSettingsEquals.push(new TableSettingsEquals('users.user_role_id', Constants.USER_ROLE_CONSULTANT_ID));
            tscfu.tableSettingsEquals.push(new TableSettingsEquals('users.user_active', 1));

            const customers = 'customers.customer_id,customers.customer_name';
            const users = 'users.image_id,users.user_id,users.user_firstname,users.user_lastname,';
            tscfu.columns = users + 'customer_for_user_connection_id,customer_id,user_id,' + customers;

            this.inputConsultant.value = '';
            this.consultants = [];


            this.isNoConsultants = '';

            this.apiService.getUserCustomers(tscfu).subscribe((data) => {
                const users = [];
                data.main.forEach(element => {
                    users.push(element.users);
                });
                users.sort((a, b) => (`${a.user_firstname} ${a.user_lastname}` > `${b.user_firstname} ${b.user_lastname}`) ? 1 : (`${a.user_firstname} ${a.user_lastname}` < `${b.user_firstname} ${b.user_lastname}` ? -1 : 0));
                this.consultants = users;

                if (this.consultants.length == 0) {
                    this.isNoConsultants = 'Kunne ikke finde nogen salgskonsulenter';
                }

                setTimeout(() => {
                    this.isUpdatingView = false;
                }, 0);
            }, (error) => {
            });
        } else {
            setTimeout(() => {
                this.isUpdatingView = false;
            }, 0);
        }
    }

    setAssignState(assignState) {
        // clear the assigned users array
        this.inputUsers = [];

        // if marked as assign later, then reset errors
        if (assignState == 'later') {
            this.utilService.resetErrors(this.inputCustomer, this.inputConsultant, this.inputStore, this.inputTime, this.inputEstimate, this.inputContactPerson, this.inputPlaceInStore, this.inputProducts);
            this.inputTodoFill.error = '';
            this.inputUserError = '';
        }

        this.inputAssignLater.value = assignState == 'later';
        this.inputAssignOffer.value = assignState == 'offer';
        this.inputAssignSupply.value = assignState == 'supply';
    }

    getFirstInputUserId(): number {
        return this.inputUsers.length > 0 ? this.inputUsers[0] : 0
    }

    onSelectAssignOffer(user_id) {
        this.inputUsers = [];
        if (user_id) {
            const user = new User();
            user.user_id = user_id;
            this.inputUsers.push(user);
            this.inputUserError = '';
        }
    }

    onCheckForms(): boolean {

        var validForm = true;

        this.inputUserError = '';

        if (this.showReportForm && this.utilService.checkMultipleIfvalid()) {
            validForm = false;
        }

        if (this.inputTimeType.value === 'fast' && this.showReportForm) {
            this.inputEstimate.value = moment(this.inputTimeUsedEnd.value).diff(moment(this.inputTimeUsedStart.value)).toString();
        }
        //Check fill
        if (this.utilService.checkMultipleIfvalid(this.inputCustomer, this.inputConsultant, this.inputStore, this.inputTime, this.inputEstimate)) {
            validForm = false;
        }

        if (this.inputTodoFill.error = this.utilService.checkIfChecked(this.inputTodoFill, this.inputTodoCleaning, this.inputTodoShow, this.inputTodoDev, this.inputTodoOut, this.inputTodoDemo)) {
            validForm = false;
        }
        if ((this.userService.user.user_role_id !== 2 && this.userService.user.user_role_id !== 3) && (this.inputAssignOffer.value || this.inputAssignSupply.value) && this.inputUsers.length == 0) {
            this.inputUserError = 'Vælg en medarbejder';
            validForm = false;
        }

        // validate if the assign field are shown and the assignment_status IS NOT accepted
        if (this.userService.getShowForUser(USER_RIGHT_TYPES.TYPE_ASSIGNMENT_ASSIGN) && this.assignment.assignment_status !== Constants.ASSIGNMENT_STATUS_ACCEPTED) {
            if (this.inputAssignSupply.error = this.utilService.checkIfChecked(this.inputAssignSupply, this.inputAssignLater, this.inputAssignOffer)) {
                validForm = false;
            }
        }

        if (this.showReportForm) {
            if (this.inputDescription.value == "") {
                this.inputDescription.error = 'Kommentaren skal udfyldes';
                validForm = false;
            }
        }
        if(!this.checkTimeConsistency()){
            validForm = false;
        }

        return validForm;
    }

    checkTimeConsistency() {
        if ((this.inputTimeType.value !== 'fast' || this.showReportForm) && moment(this.inputDateEnd.value).diff(moment(this.inputDateStart.value)) <= 0) {
            this.inputDateEnd.error = 'Sluttidspunkt skal være senere end starttidspunkt';
            return false;
        }

        return true;
    }

    onSetExecution(): AssignmentExecutions {
        const ae: AssignmentExecutions = new AssignmentExecutions();

        ae.assignment_id = +this.assignment.assignment_id;
        ae.assignment_execution_drive_distance = this.inputDriving.value;

        ae.assignment_execution_expenses = this.inputExpenses.value;
        ae.assignment_execution_comments = this.inputDescription.value;

        ae.assignment_execution_start_time = this.utilService.toUTC(this.inputDateStart.value).format('YYYY-MM-DD HH:mm:00');
        ae.assignment_execution_end_time = this.utilService.toUTC(this.inputDateEnd.value).format('YYYY-MM-DD HH:mm:00');

        ae.assignment_execution_time_spend = moment.duration(moment(this.inputDateEnd.value).diff(moment(this.inputDateStart.value))).asMilliseconds();

        return ae;
    }

    onSetAssigment(): Assignment | boolean {
        if (this.inputAssignLater.value !== true && !this.onCheckForms()) {
            this.notificationService.setNotification(this.lang.la.error_form_invalid, this.notificationService.CONSTANT_TYPE_WARNING);

            return false;
        }

        const as: Assignment = new Assignment();

        // User ids
        if (this.inputUsers !== undefined && this.inputUsers.length > 0) {
            as.user_ids = [];
            for (let i = 0; i < this.inputUsers.length; i++) {
                as.user_ids.push(+this.inputUsers[i].user_id);
            }
        }

        if (this.isState('editing') && !this.router.url.includes('copy')) {
            as.assignment_id = this.ID;
        }

        if (this.inputCustomer.value) {
            as.customer_id = +this.inputCustomer.value;
        }
        if (this.inputStore.value) {
            as.store_id = +this.inputStore.value;
        }
        if (this.inputConsultant.value) {
            as.user_id = +this.inputConsultant.value;
        }
        if (this.inputDescr.value) {
            as.assignment_description = this.inputDescr.value;
        } else if (this.inputDescription.value) {
            as.assignment_description = this.inputDescription.value;
        }

        if (this.showReportForm) {
            // if the user completes a report when creating an assignment it needs to calculate the estimate time based on the difference between the end and the start of the assignment
            as.assignment_time_estimate = moment.duration(moment(this.inputDateEnd.value).diff(moment(this.inputDateStart.value))).asMilliseconds().toString();
        } else if (this.inputEstimate.value) {
            as.assignment_time_estimate = '' + this.utilService.convertEstimateToMili(this.inputEstimate.value);
        }

        as.assignment_product = this.inputProducts.value;

        as.assignment_place_in_store = this.inputPlaceInStore.value;

        as.assignment_contact_person = this.inputContactPerson.value;


        as.assignment_type = this.inputType.value;

        if (this.inputTimeType.value == 'frit') {
            as.assignment_timestype_free = 1;
            as.assignment_timestype_start = this.utilService.toUTC(this.inputDateStart.value).format('YYYY-MM-DD HH:mm:00');
            as.assignment_timestype_free_end = this.utilService.toUTC(this.inputDateEnd.value).format('YYYY-MM-DD HH:mm:00');

        } else {
            as.assignment_timestype_free = 2;
            as.assignment_timestype_start = this.utilService.toUTC(this.inputDateStart.value).format('YYYY-MM-DD HH:mm:00');
        }

        //Todos
        as.assignment_todos = [];

        if (this.inputTodoFill.value) {
            as.assignment_todos.push(Constants.ASSIGNMENT_TODO_REFILL);
        }
        if (this.inputTodoCleaning.value) {
            as.assignment_todos.push(Constants.ASSIGNMENT_TODO_CLEANING);
        }
        if (this.inputTodoShow.value) {
            as.assignment_todos.push(Constants.ASSIGNMENT_TODO_EXHIBITION);
        }
        if (this.inputTodoDev.value) {
            as.assignment_todos.push(Constants.ASSIGNMENT_TODO_STOCKORDER);
        }
        if (this.inputTodoOut.value) {
            as.assignment_todos.push(Constants.ASSIGNMENT_TODO_SETTINGUP);
        }
        if (this.inputTodoDemo.value) {
            as.assignment_todos.push(Constants.ASSIGNMENT_TODO_DEMOSTRATION);
        }

        if (this.userService.user.user_role_id !== Constants.USER_ROLE_EMPLOYEE_ID && this.userService.user.user_role_id !== Constants.USER_ROLE_CONSULTANT_ID) {
            if (!this.router.url.includes('copy') && (this.assignment.assignment_status === Constants.ASSIGNMENT_STATUS_ACCEPTED || this.assignment.assignment_status === Constants.ASSIGNMENT_STATUS_AWAITING_ACCEPT)) {
                // if the status is godkendt or awaiting accept, an execution has been made - we should not change the status
                // intentionally left empty

            } else if (this.inputAssignSupply.value == true) {
                as.assignment_status = Constants.ASSIGNMENT_STATUS_AWAITING_EMPLOYEE_ACCEPT;
                // as.user_ids_assigned = 0;
            } else if (this.inputAssignOffer.value == true) {
                as.assignment_status = Constants.ASSIGNMENT_STATUS_AWAITING_EXECUTION;
                // as.user_ids_assigned = 1;
            } else if (this.inputAssignLater.value == true) {
                as.assignment_status = Constants.ASSIGNMENT_STATUS_GEM;
            }
        } else if (this.userService.user.user_role_id === Constants.USER_ROLE_EMPLOYEE_ID) {
            as.assignment_status = this.employeeAssignmentStatus;
        } else if (this.userService.user.user_role_id === this.Constants.USER_ROLE_CONSULTANT_ID) {
            as.assignment_status = AssignmentStatus.ASSIGNMENT_STATUTS_SALES_CONSULTANT;
        }

        if (this.assignment && this.assignmentAppendix.length > 0) {
            as.appendix_ids = [];
            for (const appendix of this.assignmentAppendix) {
                as.appendix_ids.push(appendix.appendix_for_assignment_id);
            }
        }

        as.assignment_pos_material = +this.inputPosMaterial.value;

        if (this.isState('creating') && this.userService.user.user_role_id === Constants.USER_ROLE_CONSULTANT_ID) {
            as.customer_id = null;
        }

        // if copying as a sales consultant, dont assign / offer to the same users
        if(this.isState('editing') && this.router.url.includes('copy') && this.userService.userIsUserRoleId(Constants.USER_ROLE_CONSULTANT_ID)){
            as.user_ids = [];
        }

        return as;
    }

    onCreateAssignment() {
        const dataPayload = this.onSetAssigment();
        if (!dataPayload || typeof dataPayload === 'boolean') return;

        this.isLoading = true;
        this.apiService.postAssignment(dataPayload).subscribe((data: any) => {
            this.assignment = data.main;
            this.showAssignmentCreatedNotification = true;

            this.onPostExecution();

        }, (error) => {
            this.isLoading = false;
            this.notificationService.setNotification(error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
        });
    }


    onEditAssignment() {
        const dataPayload = this.onSetAssigment();
        if (!dataPayload || typeof dataPayload === 'boolean') return;

        this.isLoading = true;
        this.apiService.patchAssignment(dataPayload).subscribe((data) => {
            this.assignment = data['main'];
            this.uploadFiles();

        }, (error) => {
            this.isLoading = false;
            this.notificationService.setNotification(error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
        }, () => {

        });
    }

    onResetData() {
        this.isLoadingView = true;

        this.inputCustomer = new InputString();

        this.inputConsultant = new InputString();

        this.inputStore = new InputString();

        this.inputTimeType.value = 'frit';

        // set the minutes
        const numberOfMinutesToAdd = (Math.floor(moment().minutes() / 15) + 1) * 15;

        this.inputDateStart.value = moment().set({minutes: numberOfMinutesToAdd}).toDate();
        this.inputDateEnd.value = moment().set({minutes: numberOfMinutesToAdd + 15}).toDate();

        this.inputDescr.value = '';

        this.inputType.value = '';

        this.inputEstimate.value = '';
        this.inputPlaceInStore.value = '';

        this.inputAssignLater.value = false;
        this.inputAssignOffer.value = true;
        this.inputAssignSupply.value = false;

        this.inputTodoFill.value = false;
        this.inputTodoCleaning.value = false;
        this.inputTodoShow.value = false;
        this.inputTodoDev.value = false;
        this.inputTodoOut.value = false;
        this.inputTodoDemo.value = false;
        this.inputUsers = [];

        this.inputContactPerson.value = '';
        this.inputProducts.value = '';
        this.inputPlaceInStore.value = '';

        this.inputTimeType.value = 'frit';
        this.inputType.value = 'mercer';

        this.inputPosMaterial.value = '0';

        this.inputCustomer.value = '';
        this.inputConsultant.value = '';
        this.inputStore.value = '';

        this.inputDriving.value = '0';

        this.inputExpenses.value = '';
        this.inputDescription.value = '';

        // Clean upload
        if (this.filesBilag !== undefined && this.filesBilag.queue !== undefined) {
            this.filesBilag.queue = [];
        }
        // Clean upload
        if (this.filesImages !== undefined && this.filesImages.queue !== undefined) {
            this.filesImages.queue = [];
        }

        setTimeout(() => {
            this.isLoadingView = false;
            this.isLoading = false;
        }, 1);
    }


    onGoBack() {
        this.location.back();
    }

    onPostExecution() {
        if (this.showReportForm) {
            this.executionToPost = this.onSetExecution();
            if (this.executionToPost !== undefined) {
                this.apiService.postAssignmentExecutions(this.executionToPost).subscribe((data: any) => {
                    this.executionsProgressTemp = data['data'];
                    this.executionsProgress = 2;

                    this.assignmentExecutionControl = data['data'];

                    this.uploadFiles();
                });
            }
        } else {
            // if there is no report form try to post an appendix
            this.uploadFiles();
        }
    }


    uploadFiles() {
        Promise.all([
            this.onPostAppendix().catch((error) => {
                this.notificationService.setNotification("Der skete en fejl med upload af bilag. Tjek venligst at alle billag er blevet gemt korrekt.", this.notificationService.CONSTANT_TYPE_WARNING);
                this.isLoading = false;
                throw error;
            }),
            this.onPostImages().catch((error) => {
                this.showUploadErrorNotification();
                throw error;
            })
        ]).then((response) => {
            this.onCreateOrEditDone();
        });
    }

    onPostAppendix() {
        const options = {
            type: 'appendix',
            module: this.showReportForm ? 'executions' : 'assignments',
            id: this.showReportForm ? this.assignmentExecutionControl.assignment_execution_id : this.assignment.assignment_id
        };
        return this.fileUploaderService.uploadAllInQueue(this.filesBilag, options);
    }

    onPostImages() {
        const options = {
            type: 'image',
            module: 'executions',
            id: this.executionsProgressTemp ? this.executionsProgressTemp.assignment_execution_id : null
        };
        return this.fileUploaderService.uploadAllInQueue(this.filesImages, options);
    }

    onCreateOrEditDone() {
        if (this.isState('creating') || this.router.url.includes('copy')) {
            this.onResetData();
            window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
            this.userFormRules(this.userService.user);
        } else {

            if (!this.notificationService.notificationState) {
                this.notificationService.setNotification(this.lang.la.success_updated, this.notificationService.CONSTANT_TYPE_NORMAL);
                this.router.navigate(['../app/task/list/']);
            } else {
                // if the notificationService is showing a notification (most likely an error) do not renavigate
            }
        }
    }

    userFormRules(user: User) {
        switch (user.user_role_id) {
            case 1 :
            case 5 : {
                break;
            }

            case Constants.USER_ROLE_EMPLOYEE_ID : {
                this.inputTimeType.value = 'fast';
                this.inputTimeType.disabled = true;

                this.inputAssignOffer.value = false;
                this.inputAssignLater.value = false;
                this.inputAssignSupply.value = true;

                this.employeeAssignmentStatus = AssignmentStatus.ASSIGNMENT_STATUS_AWAITING_EXECUTION;

                this.inputUsers.push(this.userService.user);

                break;
            }
            case 3 : {
                const currentUser = this.userService.user;
                this.consultants[0] = new Consultant(currentUser);
                this.inputConsultant.value = this.userService.getUserId().toString();
                this.inputConsultant.disabled = true;

                this.dataService.getCustomersForUsersCB(() => {
                    if (this.userService.user.customer_id) {
                        this.inputCustomer.value = "" + this.userService.user.customer_id;
                        this.inputCustomer.disabled = true;
                        this.onCustomerChange(this.inputCustomer.value);
                    }
                });
                break;
            }
            default : {
                break;
            }
        }
    }

    onRemoveAppendixFromExecutions(pos) {
        if (this.isState('copying')) {
            this.assignmentAppendix.splice(pos, 1);
        } else {
            const el: AppendixForAssignment = this.assignmentAppendix[pos];
            this.assignmentAppendix[pos].isLoading = true;
            el.appendix_active = 0;
            this.apiService.patchAssignmentAppendixes(el).subscribe((data) => {
                this.assignmentAppendix[pos].isLoading = false;
                this.assignmentAppendix.splice(pos, 1);
            }, (error) => {
                this.assignmentAppendix[pos].isLoading = false;
            });
        }

    }

    roundTimeValue(timestamp, interval = 15) {

        const minutes = moment(timestamp).minutes();
        let minutes_to_add = 0;

        if (minutes % interval !== 0) {
            minutes_to_add += interval - (minutes % interval);
        }
        return moment(timestamp).add(minutes_to_add, 'minutes').set('seconds', 0).format();
    }

    onDateChange(date) {
        if (this.showReportForm) {
            let tempDate = this.inputDateEnd.value;
            tempDate.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
            this.inputDateEnd.value = tempDate;
        }
    }

    onTimeChange(date, input) {
        this.inputDateEnd.error = '';

        const interval = 15;

        if (input === 'start' && date.getMinutes() % interval !== 0) {
            this.inputDateStart.value = new Date(this.roundTimeValue(date, interval));

        } else if (input === 'end' && date.getMinutes() % interval !== 0) {
            this.inputDateEnd.value = new Date(this.roundTimeValue(date, interval));
        }

        // if the user is an employee and the date is smaller < now() enable execution
        if (this.userService.user.user_role_id === Constants.USER_ROLE_EMPLOYEE_ID && date) {
            if (moment(this.inputDateStart.value) < moment()) {
                this.showReportForm = true;
            } else {
                this.inputDateEnd.value = this.inputDateStart.value;
                this.showReportForm = false;
            }
        }

        // always ensure that the end date is the same as the start date
        this.onDateChange(this.inputDateStart.value);
    }


    showUploadErrorNotification() {
        this.notificationService.setImageUploadErrorNotification();
        this.isLoading = false;
    }

    onInputDriving(drivingDistance: number) {
        // return the next integer number if it has decimals
        this.inputDriving.value = '' + Math.ceil(drivingDistance);
        if (+this.inputDriving.value < 0) {
            this.inputDriving.value = '0';
        }
    }

    onAssignmentChangeType(event) {
        if (this.inputTimeType.value === 'frit' && this.showReportForm) {
            this.showReportForm = false;
        }
    }

    onBeforeAppendixUpload(event) {
        this.filesBilag = event;
        this.canSubmitForm = this.filesBilag.queue.filter((image: any) => image.error).length <= 0;
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}