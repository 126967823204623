import {Component, OnInit} from '@angular/core';
import {UtilService} from '../services/util.service';
import {DataService} from '../services/data.service';
import {ApiService} from '../services/api.service';
import {LangService} from '../services/lang.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '../models/store';
import {TableSettings} from '../models/tablesettings';

@Component({
    selector: 'app-store',
    templateUrl: './store.component.html',
    styleUrls: ['./store.component.scss']
})
export class StoreComponent implements OnInit {

    isLoadingView: boolean = false;

    storeId: number = -1;
    store: Store;

    constructor(public apiService: ApiService, public route: ActivatedRoute, private router: Router, public utilService: UtilService, public lang: LangService, public dataService: DataService) {
    }

    ngOnInit() {
        this.storeId = this.route.snapshot.params['id'];
        this.getData();
    }

    getData() {
        this.isLoadingView = true;

        var ts: TableSettings = new TableSettings();
        ts.columns = "store_name,store_phone,store_address,store_city,store_postal_code,chain_id,chains.chain_name";

        this.apiService.getStore(ts, this.storeId).subscribe((data) => {
            if (data.main[0] != undefined) {
                this.store = data.main[0];
            }
            this.isLoadingView = false;
        }, (error) => {
            this.isLoadingView = false;
        });
    }

}
