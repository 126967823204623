<div class="row">
    <div class="col-6">
        <h1 class="push-m-50-b h2">{{lang.la.news_title}} </h1>
    </div>
    <div class="col-6 has-text-right">
        <button class="btn btn-primary" *ngIf="userService.getShowForUser(USER_RIGHT_TYPES.TYPE_NEWS_CREATE)"
                routerLink="../news/create">{{lang.la.create_news}}</button>
    </div>
</div>

<!-- Loading -->
<app-loading *ngIf="isLoadingView"></app-loading>

<div class="row " *ngIf="newsdata.length == 0 && !isLoadingView">
    <div class="col-sm-12 has-text-centered">
        <svg fill="#ccc" class="fadeIn delay-02" height="115" viewBox="0 0 24 24" width="115"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
        </svg>
        <br/>
        <br/>
        <p class="h3 fadeIn delay-04">
            {{lang.la.news_read_all}}
        </p>
    </div>
</div>

<div class="card card-top is-transparent" *ngFor="let news of newsdata; let pos = index">

    <div class="card-body news-image-body" *ngIf="news.images.length > 0">
        <div class="row">
            <div class="col" *ngFor="let newsImage of news.images">
                <a href="{{utilService.onImageToView(newsImage.image_link)}}" target="_blank">
                    <img #tempImage (load)="rotateImage(tempImage)"
                         [src]="utilService.onImageToView(newsImage.image_link)" alt="" class="news-image">
                </a>
            </div>
        </div>
    </div>
    <div class="card-body push-p-5-b push-m-30-t position-relative">
        <div class="custom-control custom-checkbox news-check" (click)="onSeenClicked(pos)">
            <input type="checkbox" class="custom-control-input" [(ngModel)]="news.news_for_users_read">
            <label class="custom-control-label"> {{lang.la.news_press_read}}</label>
        </div>

        <h4 class="text-is-bold">
            {{news.news_title}}
        </h4>


    </div>
    <div class="card-body push-p-0-b push-p-5-t">
        <p class="h6 push-p-0 push-m-0 text-is-grey">
            <!-- {{lang.la.created_at}} <span class="text-is-grey">{{news.news_creation | date : 'dd/MM/yy HH:mm'}}</span> -->
            {{lang.la.created_at}} <span
                class="text-is-grey">{{news.news_creation | mdate : 'DD/MM/YYYY HH:mm:ss'}}</span>

        </p>
    </div>
    <div class="card-body">
        <p class="news-description">
            {{news.news_description}}
        </p>
    </div>
    <div class="card-body row" *ngIf="userService.getShowForUser(USER_RIGHT_TYPES.TYPE_NEWS_CREATE)">
        <div class="col-sm-12 has-text-right">
            <span class="badge badge-secondary push-m-5-r" *ngIf="news.news_for_all">Alle</span>
            <span class="badge badge-secondary push-m-5-r" *ngIf="news.news_for_employees">Medarbejdere</span>
            <span class="badge badge-secondary push-m-5-r" *ngIf="news.news_for_customer_users">Kundebrugere</span>
            <span class="badge badge-secondary push-m-5-r"
                  *ngIf="news.news_for_sales_consultants">Salgskonsulenter</span>
            <a [routerLink]="['edit/', news.news_id]" class="push-m-20-l btn btn-outline-success btn-sm">Rediger</a>
        </div>
    </div>
</div>

<div class="row push-m-50-t" *ngIf="!isLoadingView && newsdata != undefined && newsdata.length > 8 && !isNoMoreToLoad"
     (click)="onLoadMore()">
    <div class="col-sm-12">
        <button class="btn is-fullwidth" [ngClass]="{'is-loading': isLoading}">
            {{lang.la.load_more}}
        </button>
    </div>
</div>


<div class="row push-m-50-t" *ngIf="newsdataArchived != undefined && newsdataArchived.length != 0 && !isLoadingView">
    <div class="col-sm-12">
        <hr>
    </div>
</div>

<div class="row push-m-50-b push-m-50-t"
     *ngIf="newsdataArchived != undefined && newsdataArchived.length != 0 && !isLoadingView">
    <div class="col-sm-12">
        <h1 class="h2">{{lang.la.news_archived_title}}</h1>
    </div>
</div>

<div class="card card-top is-transparent" *ngFor="let news of newsdataArchived; let pos = index">
    <div class="card-body news-image-body">
        <div class="row">
            <div class="col" *ngFor="let newsImage of news.images">
                <a href="{{utilService.onImageToView(newsImage.image_link)}}" target="_blank">
                    <img #tempImage (load)="rotateImage(tempImage)"
                         [src]="utilService.onImageToView(newsImage.image_link)" alt="" class="news-image">
                </a>
            </div>
        </div>
    </div>
    <div class="card-body push-p-5-b push-m-30-t position-relative">
        <div class="custom-control custom-checkbox news-check" (click)="onUnseenClicked(pos)">
            <input type="checkbox" class="custom-control-input" [(ngModel)]="news.news_for_users_read">
            <label class="custom-control-label"> {{lang.la.news_press_unread}}</label>
        </div>


        <h4 class="text-is-bold">
            {{news.news_title}}
        </h4>


    </div>
    <div class="card-body push-p-0-b push-p-5-t">
        <p class="h6 push-p-0 push-m-0 text-is-grey">
            {{lang.la.created_at}} <span
                class="text-is-grey">{{news.news_creation | mdate : 'DD/MM/YYYY HH:mm:ss'}}</span>
        </p>
    </div>
    <div class="card-body">
        <p class="news-description">
            {{news.news_description}}
        </p>
    </div>
    <div class="card-body row" *ngIf="userService.getShowForUser(USER_RIGHT_TYPES.TYPE_NEWS_CREATE)">
        <div class="col-sm-12 has-text-right">
            <span class="badge badge-secondary push-m-5-r" *ngIf="news.news_for_all">Alle</span>
            <span class="badge badge-secondary push-m-5-r" *ngIf="news.news_for_employees">Medarbejdere</span>
            <span class="badge badge-secondary push-m-5-r" *ngIf="news.news_for_customer_users">Kundebrugere</span>
            <span class="badge badge-secondary push-m-5-r"
                  *ngIf="news.news_for_sales_consultants">Salgskonsulenter</span>
            <a [routerLink]="['edit/', news.news_id]" class="push-m-20-l btn btn-outline-success btn-sm">Rediger</a>
        </div>
    </div>
</div>

<div class="row push-m-50-t"
     *ngIf="!isLoadingView && newsdataArchived != undefined && newsdataArchived.length > 8 && !isNoMoreToLoadArchived"
     (click)="onLoadMoreArchived()">
    <div class="col-sm-12">
        <button class="btn is-fullwidth" [ngClass]="{'is-loading': isLoadingArchived}">
            {{lang.la.load_more}}
        </button>
    </div>
</div>