<div class="container-fluid has-text-centered is-fullheightvh">
    <div class="row is-fullheightvh">
        <div class="col-sm-12 col-xs-12 bg-image col-md-6 is-fullheightvh has-centered-everything">

            <div class="max-width-400" *ngIf="!isSuccessful && !failed">
                <img src="./assets/logo_dark.png" alt="" class="push-m-50-b max-width-100">
                <div class="is-fullwidth has-text-left">

                    <form (ngSubmit)="onChangePassword()">

                        <div class="form-group">
                            <label for="inputPw1">{{lang.la.password}}</label>
                            <input type="password" placeholder="{{lang.la.password}}" class="form-control" required
                                   [(ngModel)]="inputPw1.value"
                                   name="inputPw1" [ngClass]="{'is-invalid': inputPw1.error}"/>
                            <div class="invalid-feedback d-inline" *ngIf="inputPw1.error">
                                {{inputPw1.error}}
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="inputPw2">{{lang.la.password_again}}</label>
                            <input type="password" placeholder="{{lang.la.password}}" class="form-control" required
                                   [(ngModel)]="inputPw2.value"
                                   name="inputPw2" [ngClass]="{'is-invalid': inputPw2.error}"/>
                            <div class="invalid-feedback d-inline" *ngIf="inputPw2.error">
                                {{inputPw2.error}}
                            </div>
                        </div>

                        <button class="btn btn-primary push-m-10-t is-fullwidth"
                                [ngClass]="{'is-loading': isLoading}">{{lang.la.change_password}}</button>

                        <div class="form-group push-m-30-t text-center">
                            <a class="link is-grey" routerLink="../login">{{lang.la.go_to_login}}</a>
                        </div>
                    </form>

                </div>
            </div>

            <div class="max-width-400" *ngIf="isSuccessful || failed">
                <img src="./assets/logo_dark.png" alt="" class="push-m-50-b max-width-100">
                <div class="is-fullwidth has-text-left">
                    <p *ngIf="isSuccessful">
                        {{ lang.la.change_password_success }}
                    </p>
                    <p *ngIf="failed">
                        {{ lang.la.change_password_fail }}
                    </p>

                    <button class="btn btn-primary push-m-10-t" routerLink="../login"
                            [ngClass]="{'is-loading': isLoading}">{{lang.la.go_to_login}}</button>
                </div>
            </div>

        </div>
        <div class="col-sm-6  is-fullheightvh hidden-sm hidden-xs">

        </div>
    </div>
</div>
