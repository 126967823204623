<div
        class="alert notification"
        [ngClass]="{'alert-success':notificationService.notificationType == 0 || notificationService.notificationType == 2, 'alert-danger':notificationService.notificationType == 1}"
        (click)="onClose()"
        *ngIf="notificationService.notificationState && !notificationService.showCreateNotificationView">

    <svg *ngIf="notificationService.notificationType == 2" class="push-m-30-b push-m-30-t notification-image"
         width="100px" height="100px" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg"
    >
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Artboard" transform="translate(-37.000000, -52.000000)">
                <g id="ic_done_white_36px" transform="translate(37.000000, 52.000000)">
                    <polygon id="Shape" points="0 0 100 0 100 100 0 100"></polygon>
                    <circle id="Oval" stroke="#155724" stroke-width="5" cx="50" cy="50" r="47.5"></circle>
                    <polygon id="Shape" fill="#155724" fill-rule="nonzero"
                             points="40.3798492 61.3051981 28.0949622 49.2974781 24 53.3000515 40.3798492 69.3103448 75.4795259 35.0025734 71.3845636 31"></polygon>
                </g>
            </g>
        </g>
    </svg>

    <div [innerHTML]="notificationService.notificationText"></div>

</div>
<div class="row push-m-0" (click)="onClose()">
    <div class="has-centered-everything col-sm-12">
        <div class="alert notification alert-success alert-is-floating"
             *ngIf="notificationService.showCreateNotificationView">
            <span>{{notificationService.createNotification.model}} #{{notificationService.createNotification.id}} blev oprettet.</span>

            <a *ngIf="notificationService.createNotification.showCopy" class="btn btn-white"
               [routerLink]="['/app/task/copy/',notificationService.createNotification.id]">Kopier opgaven</a>

            <a class="btn btn-white"
               [routerLink]="[notificationService.createNotification.route, notificationService.createNotification.id]">Gå
                til siden</a>
        </div>
    </div>
</div>