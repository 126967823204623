import {Component, OnInit} from '@angular/core';
import {NotificationService} from '../notification.service';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html'
})
export class NotificationComponent implements OnInit {

    constructor(public notificationService: NotificationService) {
    }

    ngOnInit() {
    }

    onClose() {
        this.notificationService.removeNotification()
    }

}
