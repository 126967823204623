import {Image} from "./image";

export class AppendixForAssignmentExecutions {

    public primary_key: string = "appendix_for_assignment_execution_id";

    public appendix_for_assignment_execution_id: number;
    public assignment_execution_id: string;
    public appendix_amount: string;
    public appendix_link: string;
    public isLoading: boolean = false;
    public appendix_active: number = 1;

    public images: Image;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }

    public getPostObject() {
        var obj = this;
        delete obj.primary_key;
        delete obj.images;
        delete obj.appendix_link;
        delete obj.isLoading;
        return JSON.stringify(obj);
    }
}