export class Customer {

    public primary_key: string = "customer_id";

    public customer_id: number;
    public customer_name: string;
    public customer_contact_name: string;
    public customer_contact_position: string;
    public customer_email: string;
    public customer_phone: string;
    public customer_address: string;
    public customer_postal_code: string;
    public customer_city: string;
    public customer_active: number;

    public customers: Customer;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }

    //
    public getObject() {
        return {
            customer_id: this.customer_id,
        }
    }

    public getPostJson() {
        return JSON.stringify(this);
    }

    public getPatchJson() {
        var object = this;
        delete object.primary_key;
        return JSON.stringify(this);
    }
}