<!-- Modal -->
<div class="modal fade modal-show" tabindex="-1" role="dialog" [ngClass]="{'modal-show': isDeleteModalActive}">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title">{{lang.la.modal_deactivate_title}}</h6>
                <button type="button" class="close" (click)="isDeleteModalActive = !isDeleteModalActive"
                        aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                {{lang.la.modal_deactivate_descr}}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" [ngClass]="{'is-loading': isLoading}"
                        (click)="isDeleteModalActive = !isDeleteModalActive">{{lang.la.cancel}}</button>
                <button type="button" class="btn btn-danger" (click)="onChangeActive(0)"
                        [ngClass]="{'is-loading': isLoading}">{{lang.la.inactive}}</button>
            </div>
        </div>
    </div>
</div>


<div class="row">
    <div class="col-sm-8">
        <h1 class="push-m-50-b h2" *ngIf="!isEditing">{{lang.la.create_customer}}</h1>
        <h1 class="push-m-50-b h2" *ngIf="isEditing">{{lang.la.edit_customer}}</h1>

    </div>
    <div class="col-sm-4 has-text-right text-right" *ngIf="isEditing && isEditingCustomer != undefined">
        <button *ngIf="isEditingCustomer.customer_active == 1" type="button" class="btn btn-danger"
                (click)="isDeleteModalActive = !isDeleteModalActive"
                [ngClass]="{'is-loading': isLoading}">{{lang.la.inactive}}</button>
        <button *ngIf="isEditingCustomer.customer_active == 0" type="button" class="btn btn-primary"
                (click)="onChangeActive(1)" [ngClass]="{'is-loading': isLoading}">{{lang.la.active}}</button>
    </div>

</div>

<div class="card card-top  card-loading" *ngIf="isLoadingView">
    <div class="card-body">
        <div class="loader"></div>
    </div>
</div>
<!-- Table -->
<div class="card card-top" *ngIf="!isLoadingView">
    <div class="card-body row">

        <div class="col-sm-6">

            <div class="form-group">
                <label>* {{lang.la.name}}</label>
                <div class="input-group">
                    <input type="text" class="form-control" tabindex="1"
                           (ngModelChange)="inputName.error = ''" [(ngModel)]="inputName.value"
                           name="inputName.value" [ngClass]="{'is-invalid': inputName.error}">
                </div>
                <div class="invalid-feedback d-inline" *ngIf="inputName.error">
                    {{inputName.error}}
                </div>
            </div>
            <div class="form-group">
                <label>* {{lang.la.phoneno}}</label>
                <div class="input-group">
                    <input type="number" class="form-control" tabindex="3"
                           (ngModelChange)="inputPhoneNo.error = ''" [(ngModel)]="inputPhoneNo.value"
                           name="inputPhoneNo.value" [ngClass]="{'is-invalid': inputPhoneNo.error}">
                </div>
                <div class="invalid-feedback d-inline" *ngIf="inputPhoneNo.error">
                    {{inputPhoneNo.error}}
                </div>
            </div>
        </div>

        <div class="col-sm-6">
            <div class="form-group">
                <label>* {{lang.la.email}}</label>
                <div class="input-group">
                    <input type="text" class="form-control" tabindex="2"
                           (ngModelChange)="inputEmail.error = ''" [(ngModel)]="inputEmail.value"
                           name="inputEmail.value" [ngClass]="{'is-invalid': inputEmail.error}">
                </div>
                <div class="invalid-feedback d-inline" *ngIf="inputEmail.error">
                    {{inputEmail.error}}
                </div>
            </div>
        </div>
    </div>

    <div class="card-divider"></div>

    <div class="card-body row">
        <div class="col-sm-6">
            <div class="form-group">
                <label>* {{lang.la.street}}</label>
                <div class="input-group">
                    <input type="text" class="form-control" tabindex="4"
                           (ngModelChange)="inputAddress.error = ''" [(ngModel)]="inputAddress.value"
                           name="inputAddress.value" [ngClass]="{'is-invalid': inputAddress.error}">
                </div>
                <div class="invalid-feedback d-inline" *ngIf="inputAddress.error">
                    {{inputAddress.error}}
                </div>
            </div>
            <div class="form-group">
                <label>* {{lang.la.city}}</label>
                <div class="input-group">
                    <input type="text" class="form-control" tabindex="6"
                           (ngModelChange)="inputCity.error = ''" [(ngModel)]="inputCity.value"
                           name="inputCity.value" [ngClass]="{'is-invalid': inputCity.error}">
                </div>
                <div class="invalid-feedback d-inline" *ngIf="inputCity.error">
                    {{inputCity.error}}
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label>* {{lang.la.postalcode}}</label>
                <div class="input-group">
                    <input type="number" class="form-control" tabindex="5"
                           (ngModelChange)="inputPostalCode.error = ''" [(ngModel)]="inputPostalCode.value"
                           name="inputPostalCode.value" [ngClass]="{'is-invalid': inputPostalCode.error}">
                </div>
                <div class="invalid-feedback d-inline" *ngIf="inputPostalCode.error">
                    {{inputPostalCode.error}}
                </div>
            </div>
        </div>
    </div>

    <div class="card-divider"></div>

    <div class="card-body row">
        <div class="col-sm-6">
            <div class="form-group">
                <label>{{lang.la.contactperson}}</label>
                <div class="input-group">
                    <input type="text" class="form-control" tabindex="7"
                           (ngModelChange)="inputContactPerson.error = ''" [(ngModel)]="inputContactPerson.value"
                           name="inputContactPerson.value" [ngClass]="{'is-invalid': inputContactPerson.error}">
                </div>
                <div class="invalid-feedback d-inline" *ngIf="inputContactPerson.error">
                    {{inputContactPerson.error}}
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label>{{lang.la.contactperson_position}}</label>
                <div class="input-group">
                    <input type="text" class="form-control" tabindex="8"
                           (ngModelChange)="inputContactPersonPosition.error = ''"
                           [(ngModel)]="inputContactPersonPosition.value"
                           name="inputContactPersonPosition.value"
                           [ngClass]="{'is-invalid': inputContactPersonPosition.error}">
                </div>
                <div class="invalid-feedback d-inline" *ngIf="inputContactPersonPosition.error">
                    {{inputContactPersonPosition.error}}
                </div>
            </div>
        </div>
        <div class="col-sm-12 has-text-right">
            <button class="btn btn-secondary ml-2" [ngClass]="{'is-loading': isLoading}"
                    (click)="onGoBack()">{{lang.la.cancel}}</button>
            <button class="btn btn-primary ml-2" [ngClass]="{'is-loading': isLoading}" (click)="onCreateCustomer()"
                    *ngIf="!isEditing">{{lang.la.create}}</button>
            <button class="btn btn-primary ml-2" [ngClass]="{'is-loading': isLoading}" (click)="onEditCustomer()"
                    *ngIf="isEditing">{{lang.la.save_customer}}</button>
        </div>
    </div>
</div>
