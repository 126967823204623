import {Component, OnInit} from '@angular/core';
import {UtilService} from '../services/util.service';
import {DataService} from '../services/data.service';
import {ApiService} from '../services/api.service';
import {LangService} from '../services/lang.service';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../models/user';
import {TableSettings} from '../models/tablesettings';
import {TableHeader} from '../models/tableheader';
import {SendSMSOREmail} from '../models/send-message';
import {NotificationService} from '../shared/notification.service';
import {TableSettingsContains} from '../models/tablesettingscontains';
import {Constants} from '../constants';
import {TableSettingsEquals} from '../models/tablesettingsequals';
import {TableSettingsService} from "../services/table-settings.service";

@Component({
    selector: 'app-user-send',
    templateUrl: './user-send.component.html',
    styleUrls: ['./user-send.component.scss']
})
export class UserSendComponent implements OnInit {

    usersAll: User[] = []; //Used in view
    users: User[] = []; //Used in view
    usersSelected: number[] = []; //Selected users to figure out pagination

    page: number = 0;

    isDropdownSearch: boolean = false;

    isLoading: boolean = false;
    isLoadingView: boolean = false;

    isSendingLoading: boolean = false;
    isSendModalActive: boolean = false;

    sendType: number = 0;
    CONSTANT_SEND_TYPE_SMS = 0;
    CONSTANT_SEND_TYPE_EMAIL = 1;

    usersSelectedCount: number = 0;

    inputSearchString: string = "";
    inputSubject: string = "";
    inputMessage: string = "";
    inputMessageMax: number = 459;

    tableSettings: TableSettings;

    selectMode: string = '';

    actionType: string;
    numberOfMessages: number;

    constructor(
        public apiService: ApiService,
        public route: ActivatedRoute,
        private router: Router,
        public utilService: UtilService,
        public lang: LangService,
        public dataService: DataService,
        public notificationService: NotificationService,
        private  sessionTableSettings: TableSettingsService
    ) {
    }

    ngOnInit() {
        this.actionType = 'sms';
        this.numberOfMessages = 1;

        this.tableSettings = new TableSettings();
        this.updateTableSettingsFromSession();

        // this.tableSettings.tableHeaders.push(new TableHeader("user_type_demo", "Demo"));
        // this.tableSettings.tableHeaders.push(new TableHeader("user_type_mercer", "Mercer"));
        this.tableSettings.tableHeaders.push(new TableHeader("user_name", "Navn"));
        this.tableSettings.tableHeaders.push(new TableHeader("user_email", "Email"));
        this.tableSettings.tableHeaders.push(new TableHeader("user_phone", "Tlf.nr."));
        this.tableSettings.tableHeaders.push(new TableHeader("user_address", "Adresse"));
        this.tableSettings.tableHeaders.push(new TableHeader("user_postal_code", "Postnr."));
        this.tableSettings.tableHeaders.push(new TableHeader("user_city", "By"));
        this.tableSettings.tableHeaders.push(new TableHeader("customers", "Kunde"));
        this.tableSettings.searchColumn = this.tableSettings.tableHeaders[0];
        this.tableSettings.columns = "user_firstname,user_lastname,user_email,user_phone,user_address,user_postal_code,user_city,user_type_demo,user_type_mercer,user_contact_on_email,user_contact_on_sms";
        this.tableSettings.sortBy = new TableHeader("user_name", "Navn");
        this.tableSettings.sortBy.asc = false;
        this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals('user_role_id', Constants.USER_ROLE_EMPLOYEE_ID));
        this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals('user_active', 1));
        this.tableSettings.results = 500;

        // Add search columns
        //new TableHeader("user_name", "Navn")
        this.tableSettings.searchColumns.push(new TableHeader("user_name", "Navn"));
        this.tableSettings.searchColumns.push(new TableHeader("user_name", "Navn"));
        this.tableSettings.searchColumns.push(new TableHeader("user_email", "Email"));
        this.tableSettings.searchColumns.push(new TableHeader("user_phone", "Tlfnr."));
        this.tableSettings.searchColumns.push(new TableHeader("user_address", "Adresse"));
        this.tableSettings.searchColumns.push(new TableHeader("user_postal_code", "Postnr."));
        this.tableSettings.searchColumns.push(new TableHeader("user_city", "By"));
        this.tableSettings.searchColumns.push(new TableHeader("customers", "Kunde"));

        this.getData();
    }

    updateTableSettingsFromSession() {
        const sessionSearch = this.sessionTableSettings.getTableSettings();
        if (!sessionSearch) {
            return;
        }

        if (sessionSearch['search']) {
            this.tableSettings.search = sessionSearch['search'];
        }
        if (sessionSearch['searchColumn']) {
            this.tableSettings.searchColumn = sessionSearch['searchColumn'];
        }
        if (sessionSearch['sortBy']) {
            this.tableSettings.sortBy = sessionSearch['sortBy'];
        }
        if (sessionSearch['page']) {
            this.tableSettings.page = sessionSearch['page'];
        }
        if (sessionSearch['showActive']) {
            this.tableSettings.tableSettingsEquals[0].value = sessionSearch['showActive'];
        }
    }

    getData() {
        this.isLoadingView = true;
        this.apiService.getUsers(this.tableSettings).subscribe((data) => {
            this.isLoadingView = false;
            this.users = data.main;
            this.usersAll = data.main;

            this.tableSettings.tableOffset = data.tableOffset;

            // Show customers as a string
            for (let user in this.users) {
                this.users[user].customer_list = this.users[user].customers.map(customer => customer.customer_name);
            }


        }, (error) => {
            this.isLoadingView = false;
        });
    }

    onSelectAllOnPage() {
        if(this.users.length == this.usersSelected.length){
            //if everyone is selected already => deselect
            this.usersSelected = [];
        } else {
            // add everyone
            this.usersSelected = this.users.map((user) => {
                if(this.canBeContactedByThisType(user)){
                    return user.user_id;
                }
            });
        }
    }

    isAllOfTypeSelected(type){
        // run through all users and check if they have already been selected
        let allOfTypeIsSelected = true;
        this.users.forEach((user) => {
            if (user[type] && !this.userIsSelected(user) && this.canBeContactedByThisType(user)) {
                allOfTypeIsSelected = false;
            }
        });

        return allOfTypeIsSelected;
    }

    onSelectAllByType(type): void {
        if(this.isAllOfTypeSelected(type)){
            // deselecting
            this.selectMode = 'remove';
        } else {
            this.selectMode = type;
        }

        this.users.forEach((user) => {
            if(user[type]){
                if(this.selectMode == type){
                    // can the user be contacted in this communication form? (email, phone)
                    if (this.canBeContactedByThisType(user)){

                        // do not allow duplicates in the usersSelected array
                        if(this.usersSelected.indexOf(user.user_id) == -1) {
                            this.usersSelected.push(user.user_id);
                        }
                    }

                } else if (this.selectMode == 'remove'){
                    // remove the user
                    if(this.usersSelected.indexOf(user.user_id) >= 0){
                        this.usersSelected.splice(this.usersSelected.indexOf(user.user_id), 1);
                    }
                }
            }
        });

    }

    onSelectForSend(selectedUser: User, allowRemoval: boolean = true): void {
        if (!this.canBeContactedByThisType(selectedUser)) return;

        this.selectMode = '';

        let index = this.usersSelected.indexOf(selectedUser.user_id);
        if(index >= 0){

            if(allowRemoval){
                // remove from the array
                this.usersSelected.splice(index, 1);
            }
        } else {
            // add to the array
            this.usersSelected.push(selectedUser.user_id);
        }
    }

    userIsSelected(user){
        return this.usersSelected.indexOf(user.user_id) >= 0;
    }

    onSortTable(pos) {
        if (this.tableSettings.tableHeaders[pos].tablevalue == "#") {
            return;
        }

        this.tableSettings.page = 1;

        this.tableSettings.tableHeaders[pos].asc = !this.tableSettings.tableHeaders[pos].asc;
        this.tableSettings.sortBy = this.tableSettings.tableHeaders[pos];

        this.getData();
    }

    onSendEmail() {
        this.sendType = this.CONSTANT_SEND_TYPE_EMAIL;
        this.isSendModalActive = true;
    }

    onSendSMS() {
        this.sendType = this.CONSTANT_SEND_TYPE_SMS;
        this.isSendModalActive = true;
    }

    onSend() {
        this.isSendingLoading = true;

        var sendSMSOREmail: SendSMSOREmail = new SendSMSOREmail();
        sendSMSOREmail.message = this.inputMessage;
        sendSMSOREmail.users = this.usersSelected.slice(0);

        if (this.sendType == this.CONSTANT_SEND_TYPE_SMS) {
            sendSMSOREmail.send_sms = 1;
        } else if (this.sendType == this.CONSTANT_SEND_TYPE_EMAIL) {
            sendSMSOREmail.send_email = 1;
            sendSMSOREmail.subject = this.inputSubject;
        }

        this.apiService.postSendEmailOrSms(sendSMSOREmail).subscribe((data) => {
            this.isSendingLoading = false;
            this.isSendModalActive = false;
            this.isSendModalActive = false;
            this.notificationService.setNotification("Din besked er blevet sendt", this.notificationService.CONSTANT_TYPE_NORMAL);
            this.inputMessage = '';
            this.inputSubject = '';
            this.usersSelected = [];
        }, (error) => {
            this.notificationService.setNotification(error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
            this.isSendingLoading = false;
        });
    }

    countNumberOfMessages(event) {
        if (this.actionType === 'sms') {
            event.length < 153 ? this.numberOfMessages = 1 : this.numberOfMessages = Math.ceil(event.length / 153);
        }
    }

    canBeContactedByThisType(user) {
        let valid = true;

        if (this.actionType == 'sms') {
            valid = user.user_contact_on_sms == 1;
        }
        if (this.actionType == 'email') {
            valid = user.user_contact_on_email == 1;
        }

        if (!!(this.utilService.checkPhoneNumber(user.user_phone))) {
            valid = false;
        }

        return valid;
    }
}
