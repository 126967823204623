import {Component, OnInit} from '@angular/core';
import {LangService} from '../services/lang.service';
import {USER_RIGHT_TYPES} from '../constants';
import {UserService} from '../services/user.service';
import {ApiService} from '../services/api.service';
import {TableSettings} from '../models/tablesettings';
import {NewsForUsersMM} from '../models/news-for-users-mm';
import {UtilService} from '../services/util.service';
import {DataService} from '../services/data.service';
import {NotificationService} from '../shared/notification.service';
import * as EXIF from 'exif-js';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

    USER_RIGHT_TYPES: any = USER_RIGHT_TYPES;

    isLoading: boolean = false;
    isLoadingArchived: boolean = false;
    isLoadingView: boolean = false;
    isNoMoreToLoad: boolean = false;
    isNoMoreToLoadArchived: boolean = false;

    newsdata: NewsForUsersMM[] = [];
    newsdataArchived: NewsForUsersMM[] = [];

    tableSettings: TableSettings;
    tableSettingsArchived: TableSettings;

    page: number = 1;
    pageArchived: number = 1;

    constructor(
        public lang: LangService,
        public apiService: ApiService,
        public userService: UserService,
        public utilService: UtilService,
        public dataService: DataService,
        private notificationService: NotificationService
    ) {
    }

    ngOnInit() {
        this.tableSettings = new TableSettings();
        this.tableSettingsArchived = new TableSettings();

        if(!this.userService.user) return;

        this.isLoadingView = true;

        this.getData();
        this.getNewsArchived();
    }

    getData() {
        this.tableSettings.isShowingDeactivated = true;

        this.isLoading = true;
        this.apiService.getNews(this.page, 0).subscribe((data) => {

            this.newsdata = this.newsdata.concat(data['data'].main);

            if (data['data'].main.length == 0) {
                this.isNoMoreToLoad = false;
            }
            this.dataService.newsUnread = this.newsdata.length;
            this.tableSettings.tableOffset = data.tableOffset;
            this.isLoadingView = false;
            this.isLoading = false;
        }, (error) => {
            this.utilService.onRetryApi("getCustomers", () => {
                this.getData();
            }, () => {
                this.isLoadingView = false;
                this.isLoading = false;
                this.notificationService.setNotification(this.lang.la.error_general_message + " " + error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
            });

        })
    }


    getNewsArchived() {

        this.apiService.getNews(this.pageArchived, 1).subscribe((data) => {
            this.newsdataArchived = this.newsdataArchived.concat(data['data'].main);
            if (data['data'].main.length == 0) {
                this.isNoMoreToLoadArchived = false;
            }
            this.tableSettingsArchived.tableOffset = data.tableOffset;
            this.isLoadingArchived = false;
        }, (error) => {
            this.isLoadingArchived = false;
        })
    }

    onLoadMore() {
        this.page++;

        this.getData();
    }

    onLoadMoreArchived() {
        this.pageArchived++;

        this.getNewsArchived();
    }

    onSeenClicked(pos) {

        var nfu: NewsForUsersMM = new NewsForUsersMM();
        nfu.news_for_user_connection_id = this.newsdata[pos].news_for_user_connection_id;
        nfu.news_for_users_read = this.newsdata[pos].news_for_users_read == 0 ? 1 : 0;

        this.apiService.patchNewsForUsers(nfu).subscribe((data) => {
            this.dataService.newsUnread--;
            this.newsdataArchived = [];
            this.getNewsArchived();
            this.newsdata.splice(pos, 1);

        }, (error) => {
        });
    }

    onUnseenClicked(pos) {

        var nfu: NewsForUsersMM = new NewsForUsersMM();
        nfu.news_for_user_connection_id = this.newsdataArchived[pos].news_for_user_connection_id;
        nfu.news_for_users_read = this.newsdataArchived[pos].news_for_users_read == 0 ? 1 : 0;

        this.apiService.patchNewsForUsers(nfu).subscribe((data) => {
            this.newsdataArchived.splice(pos, 1);
            this.newsdata = [];
            this.getData();
        }, (error) => {
        });
    }

    rotateImage(img_tag) {

        EXIF.getData(img_tag, function () {
            const tags = EXIF.getAllTags(this);
            if (tags.Orientation) {
                switch (tags.Orientation) {
                    case 6:
                        img_tag.style.transform = 'rotate(90deg)';
                        break;
                    case 8:
                        img_tag.style.transform = 'rotate(170deg)';
                        break;
                    case 3:
                        img_tag.style.transform = 'rotate(180deg)';
                        break;

                }
            }
        });

    }
}
