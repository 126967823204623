<h1 class="push-m-50-b h2" *ngIf="!isEditing">{{lang.la.create_store}}</h1>
<h1 class="push-m-50-b h2" *ngIf="isEditing">{{lang.la.edit_store}}</h1>

<!-- Loading -->
<app-loading *ngIf="isLoadingView"></app-loading>

<div class="card card-top" *ngIf="!isLoadingView">
    <form>

        <div class="card-body row">
            <div class="col-sm-6">

                <div class="form-group">
                    <label for="type">* {{lang.la.name}}</label>
                    <div class="input-group">
                        <input type="text" class="form-control" tabindex="1"
                               (ngModelChange)="inputName.error = ''" [(ngModel)]="inputName.value"
                               name="inputName.value" [ngClass]="{'is-invalid': inputName.error}">

                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputName.error">
                        {{inputName.error}}
                    </div>
                </div>

            </div>

            <div class="col-sm-6">
                <div class="form-group">
                    <label for="type">{{lang.la.phoneno}}</label>
                    <div class="input-group">
                        <input type="number" class="form-control" tabindex="2"
                               (ngModelChange)="inputPhoneNo.error = ''" [(ngModel)]="inputPhoneNo.value"
                               name="inputPhoneNo.value" [ngClass]="{'is-invalid': inputPhoneNo.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputPhoneNo.error">
                        {{inputPhoneNo.error}}
                    </div>
                </div>
            </div>

            <div class="col-sm-6">
                <div class="form-group">
                    <label for="type">* {{lang.la.chain}}</label>

                    <app-dropdown-select-type
                            arrayType="3"
                            [arrayListSelectedId]="inputChain.value"
                            (selectedItem)="inputChain.value = $event"></app-dropdown-select-type>

                    <div class="invalid-feedback d-inline" *ngIf="inputChain.error">
                        {{inputChain.error}}
                    </div>
                </div>
            </div>
        </div>

        <div class="card-divider"></div>

        <div class="card-body row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="type">* {{lang.la.street}}</label>
                    <div class="input-group">
                        <input type="text" class="form-control" tabindex="4"
                               (ngModelChange)="inputAddress.error = ''" [(ngModel)]="inputAddress.value"
                               name="inputAddress.value" [ngClass]="{'is-invalid': inputAddress.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputAddress.error">
                        {{inputAddress.error}}
                    </div>
                </div>
                <div class="form-group">
                    <label for="type">* {{lang.la.city}}</label>
                    <div class="input-group">
                        <input type="text" class="form-control" tabindex="6"
                               (ngModelChange)="inputCity.error = ''" [(ngModel)]="inputCity.value"
                               name="inputCity.value" [ngClass]="{'is-invalid': inputCity.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputCity.error">
                        {{inputCity.error}}
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="type">* {{lang.la.postalcode}}</label>
                    <div class="input-group">
                        <input type="number" class="form-control" tabindex="5"
                               (ngModelChange)="inputPostalcode.error = ''" [(ngModel)]="inputPostalcode.value"
                               name="inputPostalcode.value" [ngClass]="{'is-invalid': inputPostalcode.error}">
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputPostalcode.error">
                        {{inputPostalcode.error}}
                    </div>
                </div>
            </div>
            <div class="col-sm-12 has-text-right">
                <button class="btn btn-secondary ml-2" [ngClass]="{'is-loading': isLoading}"
                        (click)="onGoBack()">{{lang.la.cancel}}</button>
                <button class="btn btn-primary ml-2" [ngClass]="{'is-loading': isLoading}" (click)="onCreateStore()"
                        *ngIf="!isEditing">{{lang.la.create}}</button>
                <button class="btn btn-primary ml-2" [ngClass]="{'is-loading': isLoading}" (click)="onEditStore()"
                        *ngIf="isEditing">{{lang.la.save}}</button>
            </div>
        </div>
    </form>

</div>
