<app-imageuploader (imageUploaderChange)="filesImages = $event" [imageUploader]="filesImages"
                   [(isUploadActive)]="isImageUploadActive"></app-imageuploader>

<!-- Modal -->
<div class="modal fade modal-show" tabindex="-1" role="dialog" [ngClass]="{'modal-show': isDeleteModalActive}">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title">{{lang.la.modal_delete_title}}</h6>
                <button type="button" class="close" (click)="isDeleteModalActive = !isDeleteModalActive"
                        aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                {{lang.la.modal_delete_descr}}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" [ngClass]="{'is-loading': isLoading}"
                        (click)="isDeleteModalActive = !isDeleteModalActive">{{lang.la.cancel}}</button>
                <button type="button" class="btn btn-danger" (click)="onDeleteNews()"
                        [ngClass]="{'is-loading': isLoading}">{{lang.la.delete}}</button>
            </div>
        </div>
    </div>
</div>
<!-- /Modal -->

<h1 class="push-m-50-b h2" *ngIf="!isEditing">{{lang.la.create_news}}</h1>
<h1 class="push-m-50-b h2" *ngIf="isEditing">{{lang.la.save}}</h1>

<!-- Loading -->
<app-loading *ngIf="isLoadingView"></app-loading>

<!-- Table -->
<div class="card card-top" *ngIf="!isLoadingView">
    <div class="card-body row">
        <div class="col-sm-12">
            <div class="form-group">
                <label for="type">{{lang.la.headline}}</label>
                <div class="input-group">
                    <input type="text" class="form-control"
                           (ngModelChange)="inputHeadline.error = ''" [(ngModel)]="inputHeadline.value"
                           name="inputHeadline.value" [ngClass]="{'is-invalid': inputHeadline.error}">
                </div>
                <div class="invalid-feedback d-inline" *ngIf="inputHeadline.error">
                    {{inputHeadline.error}}
                </div>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="form-group">
                <label for="news">{{lang.la.your_news}}</label>
                <textarea class="form-control" id="news" rows="20"
                          (ngModelChange)="inputNewsText.error = ''" [(ngModel)]="inputNewsText.value"
                          name="inputNewsText.value" [ngClass]="{'is-invalid': inputNewsText.error}"></textarea>
                <div class="invalid-feedback d-inline" *ngIf="inputNewsText.error">
                    {{inputNewsText.error}}
                </div>
            </div>
        </div>

        <!-- Choose what users should be shown the news -->
        <div class="card-body row">
            <div class="col-sm-12">
                <label>* Administratorer kan se alle nyheder</label>
            </div>
            <div class="col-sm-6">

                <div class="form-group input-group" (click)="onAllCheckbox()">
                    <label type="text" for="check1" class="form-control"
                           [ngClass]="">Alle</label>
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <input type="checkbox" aria-label="alle" id="check1" [(ngModel)]="inputAllCheckbox.value"
                                   name="inputAllCheckbox.value" disabled>
                        </div>
                    </div>
                </div>
                <div class="form-group input-group">
                    <label type="text" for="check2" class="form-control"
                           [ngClass]="">Kundebrugere</label>
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <input type="checkbox" aria-label="kundebruger" id="check2"
                                   [(ngModel)]="inputCustomerUserCheckbox.value"
                                   name="inputCustomerUserCheckbox.value"
                                   (ngModelChange)="onCheckBoxChange(inputCustomerUserCheckbox)">
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-sm-6">

                <div class="form-group input-group">
                    <label type="text" for="check4" class="form-control"
                           [ngClass]="">Salgskonsulenter</label>
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <input type="checkbox" aria-label="salgskonsulent" id="check4"
                                   [(ngModel)]="inputSalesConsultantCheckbox.value"
                                   name="inputSalesConsultantCheckbox.value"
                                   (ngModelChange)="onCheckBoxChange(inputSalesConsultantCheckbox)">
                        </div>
                    </div>
                </div>
                <div class="form-group input-group">
                    <label type="text" for="check5" class="form-control"
                           [ngClass]="">Medarbejdere</label>
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <input type="checkbox" aria-label="medarbejder" id="check5"
                                   [(ngModel)]="inputEmployeeCheckbox.value"
                                   name="inputEmployeeCheckbox.value"
                                   (ngModelChange)="onCheckBoxChange(inputEmployeeCheckbox)">
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <div class="col-sm-12 push-m-20-b">
            <div class="form-group">

                <label>{{lang.la.image}}</label>

                <app-inputupload (imageUploaderChange)="filesImages = $event" [imageUploader]="filesImages"
                                 [checkExisting]="false"></app-inputupload>
                <!-- If Editing -->
                <div class="row">
                    <div class="col-sm-12 push-m-20-t" *ngIf="isEditing">

                        <label>{{lang.la.formerly_added_images}}</label>

                        <!-- Loop -->
                        <div *ngFor="let item of images; let pos = index" class="col-sm-12 push-m-10-b push-m-10-t">
                            <div class="row">
                                <div class="col-sm-11">
									<span class="float-left">
										<img [src]="utilService.onImageToView(item.images.image_link)" alt=""
                                             class="fileuploader-image"/>
									</span>
                                    <span class="float-left push-p-10-l">
										<span class="h6">
											{{ item.image_id }}
										</span><br/>
									</span>
                                </div>
                                <div class="col-sm-1">
									<span *ngIf="item.isLoading">
										<div class="loader-small"></div>
									</span>

                                    <a (click)="onRemoveImage(pos)" *ngIf="!item.isLoading">
                                        <svg fill="#ff0000" height="16" viewBox="0 0 24 24" width="16"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                    d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                                            <path d="M0 0h24v24H0z" fill="none"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>

                        </div>
                        <!-- /Loop -->

                    </div>
                </div>
                <!-- /If Editing -->

            </div>
        </div>

        <div class="col-sm-12 has-text-right">
            <button class="btn btn-outline-danger mr-3" [ngClass]="{'is-loading': isLoading}"
                    (click)="isDeleteModalActive = true" *ngIf="isEditing">{{lang.la.delete}}</button>
            <button class="btn btn-primary mr-3" [ngClass]="{'is-loading': isLoading}" (click)="onCreateNews()"
                    *ngIf="!isEditing">{{lang.la.create}}</button>
            <button class="btn btn-primary" [ngClass]="{'is-loading': isLoading}" (click)="onEditNews()"
                    *ngIf="isEditing">{{lang.la.save}}</button>
        </div>
    </div>
</div>
