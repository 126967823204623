<!-- Modal -->
<div class="modal  modal-show" tabindex="-1" role="dialog" [ngClass]="{'modal-show': isUploadActive}">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Upload billeder</h5>
                <button type="button" class="close" (click)="isUploadActive = !isUploadActive" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <div class="row">

                    <div class="col-sm-12">
                        <label ng2FileDrop (fileOver)="fileOverBase($event)" [uploader]="imageUploader"
                               class="well my-drop-zone fileuploader fileContainer">

                            <svg class="push-m-30-t" width="75px" height="91px" viewBox="0 0 75 91" version="1.1"
                                 xmlns="http://www.w3.org/2000/svg">
                                <defs></defs>
                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g id="ic_file_upload_black_24px" transform="translate(-27.000000, -16.000000)">
                                        <polygon id="Shape" points="0 0 129 0 129 129 0 129"></polygon>
                                        <path d="M48.4285714,85.5882353 L80.5714286,85.5882353 L80.5714286,53.4705882 L102,53.4705882 L64.5,16 L27,53.4705882 L48.4285714,53.4705882 L48.4285714,85.5882353 Z M27,96.2941176 L102,96.2941176 L102,107 L27,107 L27,96.2941176 Z"
                                              id="Shape" fill="#efefef" fill-rule="nonzero"></path>
                                    </g>
                                </g>
                            </svg>
                            <br/><br/>

                            Hiv filer over eller tryk her
                            <input type="file" ng2FileSelect [multiple]="multiple" [uploader]="imageUploader"
                                   (change)="onAddToQueue()" accept="image/png,image/jpeg"/>
                            <a class="uploader__button">Upload</a>
                        </label>
                    </div>

                </div>
            </div>
            <div class="modal-body border-top"
                 *ngIf="imageUploader != undefined && imageUploader.queue != undefined && imageUploader.queue .length != 0">
                <div class="row">
                    <div class="col-sm-12 push-p-10-b">
                        <h2 class="h5">Filer klar til upload</h2>
                    </div>
                    <div class="col-sm-12">
                        <div *ngFor="let item of imageUploader.queue; let pos = index" class="push-p-10-b row">
                            <div class=" col-10">
                                <img [src]="item.file.imageimage" style="width: 20px; height: 20px;">
                                {{ item?.file?.name }} ({{ item?.file?.size / 1024 / 1024 | number:'.2' }} MB)
                            </div>
                            <div class="col-2 text-right">
                                <a class="text-grey" (click)="onRemoveAgain(pos)">
                                    <svg fill="#ff0000" height="16" viewBox="0 0 24 24" width="16"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                                        <path d="M0 0h24v24H0z" fill="none"/>
                                    </svg>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-grey" (click)="isUploadActive = !isUploadActive">Fortryd</button>
                <button type="button" class="btn btn-primary" (click)="onAdd()">Tilføj</button>
            </div>
        </div>
    </div>
</div>
