import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {LangService} from '../../services/lang.service';

@Component({
    selector: 'app-dropdown-select-multiple',
    templateUrl: './dropdown-select-multiple.component.html'
})
export class DropdownSelectMultipleComponent implements OnInit, OnChanges {

    @Input() arrayList: any[] = [];
    @Input() arrayListName: string = "name";
    @Input() arrayListId: string = "id";
    @Output() selectedItem: EventEmitter<any[]> = new EventEmitter<any[]>();

    @Input()
    arrayListSelected: any[] = [];

    inputDropdown: string = "";
    arrayListForView: any[] = [];

    isDropdownActive: boolean = false;
    isUnselecting: boolean = false;

    constructor(public lang: LangService) {
    }

    ngOnInit() {
        this.arrayListForView = this.arrayList;
        if (this.arrayListSelected == undefined) {
            this.arrayListSelected = [];
        }
    }

    ngOnChanges() {
        this.arrayListForView = this.arrayList;
        if (this.arrayListSelected == undefined) {
            this.arrayListSelected = [];
        }
    }

    onOpenDropdown() {
        if (!this.isUnselecting) {
            this.isDropdownActive = !this.isDropdownActive
        }
    }

    onChangeInputDropdown() {
        this.arrayListForView = [];
        for (let i = 0; i < this.arrayList.length; i++) {
            if ((this.arrayList[i][this.arrayListName]).toLowerCase().match(this.inputDropdown.toLowerCase())) {
                this.arrayListForView.push(this.arrayList[i]);
            }
        }
    }

    onItemSelected(pos) {
        if (this.arrayListForView.length < pos) {
            return;
        }
        this.inputDropdown = "";
        this.arrayListSelected.push(this.arrayListForView[pos]);

        this.selectedItem.emit(this.arrayListSelected);

        this.arrayListForView.splice(pos, 1);
    }

    onItemUnselected(pos) {
        this.arrayList.push(this.arrayListSelected[pos]);

        this.selectedItem.emit(this.arrayListSelected);

        this.arrayListSelected.splice(pos, 1);
        this.isUnselecting = true;
        setTimeout(() => {
            this.isUnselecting = false;
        }, 200);
    }
}
