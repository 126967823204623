import {Component, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {UtilService} from '../services/util.service';
import {DataService} from '../services/data.service';
import {LangService} from '../services/lang.service';
import {UserService} from '../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FileUploader} from 'ng2-file-upload';
import {User} from '../models/user';
import {InputString} from '../models/inputstring';
import {InputCheckbox} from '../models/inputcheckbox';
import {Constants} from '../constants';
import {ApiService} from '../services/api.service';
import {NotificationService} from '../shared/notification.service';
import {TableSettings} from '../models/tablesettings';
import {Image} from '../models/image';
import * as moment from 'moment';
import {BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {Vacation} from '../models/vacation';
import {TableSettingsEquals} from '../models/tablesettingsequals';
import {Location} from "@angular/common";
import {TableHeader} from "../models/tableheader";
import {FileUploaderService} from "../services/fileUploaderService";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit {

    Constants = Constants;
    //View
    isLoading: boolean = false;
    isVacationActive: boolean = false;
    isLoadingVacation: boolean = false;

    //
    vacations: Vacation[] = [];
    inputVacationRange: Date[] = [];

    //Inputs
    inputFirstName: InputString = new InputString();
    inputLastName: InputString = new InputString();
    inputImage: InputString = new InputString();
    inputPhoneNo: InputString = new InputString();
    inputEmail: InputString = new InputString();
    inputPersonalNumber: InputString = new InputString();
    inputPostalCode: InputString = new InputString();
    inputAddress: InputString = new InputString();
    inputCity: InputString = new InputString();
    inputRegNo: InputString = new InputString();
    inputAccountNo: InputString = new InputString();

    inputContactEmail: InputCheckbox = new InputCheckbox();
    inputContactSMS: InputCheckbox = new InputCheckbox();

    inputCollabAccepted: InputString = new InputString();
    inputCollabText: InputString = new InputString();

    //
    inputBreastSize: InputString = new InputString();
    inputDelieveryShop: InputCheckbox = new InputCheckbox();

    //
    isInvalidImageExist: boolean = false;

    //
    isImageUploadActive: boolean = false;
    filesImages: FileUploader = new FileUploader({});

    user: User;
    dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
    inputPassword1: InputString = new InputString();
    inputPassword2: InputString = new InputString();

    constructor(
        public route: ActivatedRoute,
        private router: Router,
        public userService: UserService,
        public utilService: UtilService,
        public lang: LangService,
        public dataService: DataService,
        private sanitizer: DomSanitizer,
        private apiService: ApiService,
        private notificationService: NotificationService,
        private location: Location,
        private fileUploaderService: FileUploaderService
    ) {

    }

    ngOnInit() {
        moment.locale('da-DK');

        this.dpConfig = this.utilService.getDatePickerConfig();
        var ts: TableSettings = new TableSettings();
        ts.columns = 'user_id,user_email,user_firstname,user_lastname,user_role_id,user_phone,user_cpr,user_address,'
            + 'user_postal_code,user_bank_reg,user_bank_account,user_danloen_id,user_notes,'
            + 'user_contact_on_email,user_contact_on_sms,user_contact_on_app,user_creation,user_updated,'
            + 'user_login_attempts,user_blocked,user_active,user_city,user_breastsize,' + Constants.PARAMETER_IMAGES_ONLY_ACTIVE;

        this.apiService.getUser(ts, this.userService.getUserId()).subscribe((data) => {
            if (data.main.length == 0) {
                return;
            }

            // this.userService.user.setUserDetails(data.main[0]);
            this.userService.setUser(new User(data.main[0]));
            if (data.main[0]['images'] != undefined) {
                this.userService.setUser(data.main[0]['images']);
            }

            this.onGetVacation();
            this.onAddInformations();

        }, (error) => {
        });
    }

    onAddInformations() {
        this.user = this.userService.getUser();
        this.inputFirstName.value = this.user.user_firstname;
        this.inputLastName.value = this.user.user_lastname;
        this.inputPhoneNo.value = this.user.user_phone;
        this.inputEmail.value = this.user.user_email;
        this.inputPersonalNumber.value = this.user.user_cpr && this.user.user_cpr != '' ? this.user.user_cpr : '0';
        this.inputPostalCode.value = this.user.user_postal_code;
        this.inputAddress.value = this.user.user_address;
        this.inputCity.value = this.user.user_city;
        this.inputRegNo.value = this.user.user_bank_reg;
        this.inputDelieveryShop.value = this.user.user_home_delivery == '1' ? true : false;
        this.inputAccountNo.value = this.user.user_bank_account;
        this.inputContactEmail.value = this.user.user_contact_on_email == '1' ? true : false;
        this.inputContactSMS.value = this.user.user_contact_on_sms == '1' ? true : false;
        this.inputBreastSize.value = this.user.user_breastsize;
        this.inputCollabAccepted.value = this.user.user_cooperation_agreements_date;
        this.inputCollabText.value = this.user.user_cooperation_agreements;
    }

    onPrint() {

        const childWindow = window.open('', 'childWindow', 'location=yes, menubar=yes, toolbar=yes');
        childWindow.document.open();
        childWindow.document.write('<html><head></head><body>');
        childWindow.document.write('Samarbejdsaftale godkendt: ' + document.getElementById('samarbejdsaftalerdate')['value'].replace(/\n/gi, '<br>'));
        childWindow.document.write('<br/><br/>');
        childWindow.document.write(document.getElementById('samarbejdsaftaler')['value'].replace(/\n/gi, '<br>'));
        childWindow.document.write('</body></html>');
        childWindow.print();
        childWindow.document.close();
        childWindow.close();
    }

    onAcceptCooperationDeal() {
        var u: User = new User();

        u.user_id = this.userService.getUserId();
        u.user_cooperation_agreements_date = moment().format('YYYY-MM-DD HH:mm:00');

        this.apiService.patchUser(u).subscribe((data) => {
            this.notificationService.setNotification(this.lang.la.success_cooperation_deal, this.notificationService.CONSTANT_TYPE_NORMAL);
            this.userService.setUser(new User(data['data']));
        }, (error) => {
            this.isLoading = false;
            this.notificationService.setNotification(this.lang.la.error_general, this.notificationService.CONSTANT_TYPE_WARNING);
        });
    }


    onCheckForms() {
        let validForm = true;

        if ((this.inputFirstName.error = this.utilService.checkFilled(this.inputFirstName.value))) validForm = false;
        if ((this.inputLastName.error = this.utilService.checkFilled(this.inputLastName.value))) validForm = false;
        if ((this.inputPhoneNo.error = this.utilService.checkFilled(this.inputPhoneNo.value))) validForm = false;
        if ((this.inputEmail.error = this.utilService.checkFilled(this.inputEmail.value))) validForm = false;

        if ((this.inputEmail.error = this.utilService.checkMail(this.inputEmail.value))) validForm = false;
        if ((this.inputPhoneNo.error = this.utilService.checkNumber(this.inputPhoneNo.value))) validForm = false;

        // employee specific validation
        if (this.user.user_role_id === Constants.USER_ROLE_EMPLOYEE_ID) {
            if ((this.inputPhoneNo.error = this.utilService.checkFilled(this.inputPhoneNo.value))) validForm = false;
            if ((this.inputEmail.error = this.utilService.checkFilled(this.inputEmail.value))) validForm = false;
            if ((this.inputPersonalNumber.error = this.utilService.checkFilled(this.inputPersonalNumber.value))) validForm = false;
            if ((this.inputPostalCode.error = this.utilService.checkFilled(this.inputPostalCode.value))) validForm = false;
            if ((this.inputAddress.error = this.utilService.checkFilled(this.inputAddress.value))) validForm = false;
            if ((this.inputCity.error = this.utilService.checkFilled(this.inputCity.value))) validForm = false;
            if ((this.inputRegNo.error = this.utilService.checkNumber(this.inputRegNo.value))) validForm = false;
            if ((this.inputRegNo.error = this.utilService.checkLength(this.inputRegNo.value, 4))) validForm = false;
            if ((this.inputAccountNo.error = this.utilService.checkNumber(this.inputAccountNo.value))) validForm = false;
            if ((this.inputRegNo.error = this.utilService.checkLength(this.inputRegNo.value, 10))) validForm = false;
        }

        if (this.inputPassword1.value.length != 0) {
            if (this.inputPassword1.value != this.inputPassword2.value) {
                this.inputPassword1.error = "Adgangskoder er ikke ens";
                validForm = false;
            } else if (!this.utilService.checkPassword(this.inputPassword1)) {
                validForm = false;
            }
        }

        return validForm;
    }

    onUpdateProfile() {
        this.isLoading = true;
        if (!this.onCheckForms()) {
            this.notificationService.setNotification(this.lang.la.error_form_invalid, this.notificationService.CONSTANT_TYPE_WARNING);
            this.isLoading = false;
            return;
        }

        var u: User = new User();

        u.user_id = this.userService.getUserId();
        u.user_cooperation_agreements = this.inputCollabText.value;
        u.user_cooperation_agreements_date = this.inputCollabAccepted.value;

        u.user_firstname = this.inputFirstName.value;
        u.user_lastname = this.inputLastName.value;
        u.user_phone = this.inputPhoneNo.value;
        u.user_email = this.inputEmail.value;
        u.user_cpr = this.inputPersonalNumber.value;
        u.user_home_delivery = this.inputDelieveryShop.value ? '1' : '0';
        u.user_address = this.inputAddress.value;
        u.user_postal_code = this.inputPostalCode.value;
        u.user_city = this.inputCity.value;
        u.user_bank_reg = this.inputRegNo.value;
        u.user_bank_account = this.inputAccountNo.value;
        u.user_contact_on_email = this.inputContactEmail.value ? '1' : '0';
        u.user_contact_on_sms = this.inputContactSMS.value ? '1' : '0';
        u.user_breastsize = this.inputBreastSize.value;

        if (this.inputPassword1.value != "") {
            u.user_password = this.inputPassword1.value;
        }


        this.apiService.patchUser(u).subscribe((data) => {

            window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
            data['data'].user_token = this.userService.user.user_token;
            this.userService.setUser(new User(data['data']));
            this.onUploadImage(this.userService.user, false)
                .then((response) => {
                    this.notificationService.setNotification(this.lang.la.success_updated, this.notificationService.CONSTANT_TYPE_NORMAL);
                });
        }, (error) => {
            this.isLoading = false;
            this.notificationService.setNotification(this.lang.la.error_general, this.notificationService.CONSTANT_TYPE_WARNING);
        });
    }

    onUploadImage(c: User, isItPostCall: boolean) {
        const options = {
            type: 'image',
            module: 'users',
            id: c.user_id
        };
        return this.fileUploaderService.uploadAllInQueue(this.filesImages, options)
            .then((responseArr) => {
                const response = responseArr.length > 0 ? responseArr[0] : false;
                this.isLoading = false;

                if (response) {
                    this.user.images = new Image(response.data.image);
                    this.user.images.image_link = response.data.image_link.substring(0, response.data.image_link.indexOf('.jpg'));
                }

                this.userService.setUser(this.user);
                // empty the fileImages array after uploading the picture
                this.filesImages.queue.length = 0;
                this.onAddInformations();
            }).catch((error) => {
                this.notificationService.setImageUploadErrorNotification('Dine oplysninger er gemt, men billedet kunne ikke uploades.');
                this.isLoading = false;
                throw error;
            });
    }

    //Vacation
    onGetVacation() {
        var ts: TableSettings = new TableSettings();
        ts.tableSettingsEquals.push(new TableSettingsEquals('vacation_active', '1'));
        ts.sortBy = new TableHeader("vacation_start", "vacation_start");
        this.apiService.getVacations(ts).subscribe((data) => {
            this.vacations = data.main;
        }, (error) => {
        });
    }

    onAddVacation() {
        this.isLoadingVacation = true;
        var v: Vacation = new Vacation();
        v.user_id = this.userService.getUserId();
        v.vacation_start = moment(this.inputVacationRange[0]).format('YYYY-MM-DD HH:mm:ss');
        v.vacation_end = moment(this.inputVacationRange[1]).format('YYYY-MM-DD HH:mm:ss');
        this.apiService.postVacation(v).subscribe((data) => {
            this.onGetVacation();
            this.isLoadingVacation = false;
            this.isVacationActive = false;
        }, (error) => {
            this.isLoadingVacation = false;
            this.notificationService.setNotification(error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
        });
    }

    onDeleteVacation(pos) {
        this.isLoadingVacation = true;
        this.vacations[pos].vacation_active = 0;
        this.apiService.patchVacation(this.vacations[pos]).subscribe((data) => {
            this.onGetVacation();
            this.isLoadingVacation = false;
            this.isVacationActive = false;
            this.notificationService.setNotification('Ferie slettet.', this.notificationService.CONSTANT_TYPE_NORMAL);

        }, (error) => {
            this.isLoadingVacation = false;
            this.notificationService.setNotification(this.lang.la.error_general_message, this.notificationService.CONSTANT_TYPE_WARNING);
        });
    }

    onGoBack() {
        this.location.back();
    }
}
