export class Export {

    public primary_key: string = "export_id";

    public export_id: number;
    public customer_id: string;
    public user_id: string;
    public export_start: string;
    public export_end: string;
    public export_assignment_type: string;
    public export_status: string;

    public export_include_previously_exported_assignments: boolean = false;
    public export_mark_assignments_as_exported: boolean = false;

    constructor(values: Object = {}) {
        Object.assign(this, values);
    }

    getPostJson() {
        var obj = this;
        delete obj.export_id;
        return JSON.stringify(obj);
    }
}
