export class Notification {
    public primary_key: string = "notification_id";

    notifiable_id: number;
    notifiable_object: string;
    notification_creation: string;
    notifiable: string;
    notification_id: number;
    notification_read: number;
    notification_type: string;
    notification_data: string[];
    notification_updated: string;
    user_id: number;
}

