import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserService} from "../services/user.service";
import {Constants} from "../constants";
import {LangService} from "../services/lang.service";
import {DataService} from "../services/data.service";
import {NotificationService} from "../shared/notification.service";
import {UtilService} from "../services/util.service";
import {Router} from "@angular/router";
import {ApiService} from "../services/api.service";

@Component({
    selector: 'app-customer-switcher',
    templateUrl: './customer-switcher.component.html',
    styleUrls: ['./customer-switcher.component.scss']
})
export class CustomerSwitcherComponent implements OnInit {

    Constants: any = Constants;
    isUserDropdown: boolean = false;
    isRedrawing: boolean = false;

    @Input() mobile: boolean = false;
    @Output() itemSelected = new EventEmitter();

    constructor(
        public userService: UserService,
        public apiService: ApiService,
        public lang: LangService,
        public dataService: DataService,
        public notificationService: NotificationService,
        public utilService: UtilService,
        public router: Router,
    ) {
    }

    ngOnInit() {

    }

    onOpenSwitcher() {
        this.isUserDropdown = !this.isUserDropdown;
    }

    getCurrentCustomerName() {
        let customer;
        for (let i = 0; i < this.dataService.customersForUsers.length; i++) {
            if (this.dataService.customersForUsers[i].customer_id == this.userService.user.customer_id) {
                customer = this.dataService.customersForUsers[i].customers;
            }
        }

        return customer ? customer.customer_name : '';
    }

    onCustomersForUserSelected(customer) {
        if (this.dataService.customersForUsers.length == 0) {
            this.notificationService.setNotification("Der skete en fejl. Prøv at 'refreshe' siden igen.", this.notificationService.CONSTANT_TYPE_WARNING);
            return;
        }

        this.isUserDropdown = false;

        this.apiService.getUserCustomersSelect(customer.customer_id).subscribe((data) => {
            this.userService.setCustomerIDForUser(data.data.customer_id);
            this.userService.user.customer_id = data.data.customer_id;

            this.isRedrawing = true;
            setTimeout(() => {
                this.isRedrawing = false;

                this.notificationService.setNotification("Du har nu skiftet kunde til " + customer.customer_name, this.notificationService.CONSTANT_TYPE_NORMAL);
            }, 100);

            this.router.navigate(['app']);

            this.itemSelected.emit(true);

        }, (error) => {
            this.utilService.onRetryApi("onCustomersForUserSelected", () => {
                this.onCustomersForUserSelected(customer);
            }, () => {
                this.notificationService.setNotification(this.lang.la.error_general_message + " " + error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
            });
        });


        this.apiService.patchUserSettings(this.userService.userSettings).subscribe((data) => {
        }, (error) => {
        })
    }
}
