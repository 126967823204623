import {Component, OnInit} from '@angular/core';
import {UtilService} from '../services/util.service';
import {DataService} from '../services/data.service';
import {LangService} from '../services/lang.service';
import {ApiService} from '../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FileUploader} from 'ng2-file-upload';
import {InputString} from '../models/inputstring';
import {Constants} from '../constants';
import {Chain} from '../models/chain';
import {NotificationService} from '../shared/notification.service';
import {TableSettings} from '../models/tablesettings';
import {Image} from '../models/image';
import {Location} from "@angular/common";
import {FileUploaderService} from "../services/fileUploaderService";

@Component({
    selector: 'app-chain-create',
    templateUrl: './chain-create.component.html',
    styleUrls: ['./chain-create.component.scss']
})
export class ChainCreateComponent implements OnInit {

    isLoading: boolean = false;
    isLoadingView: boolean = false;

    //Inputs
    inputName: InputString = new InputString();
    inputImage: Image;

    //Editing
    isEditingId: number = 0;
    isEditing: boolean = false;

    //
    isImageUploadActive: boolean = false;
    filesImages: FileUploader = new FileUploader({});

    constructor(
        public apiService: ApiService,
        public notificationService: NotificationService,
        public route: ActivatedRoute,
        private router: Router,
        public utilService: UtilService,
        public lang: LangService,
        public dataService: DataService,
        private location: Location,
        private fileUploaderService: FileUploaderService
    ) {
    }

    ngOnInit() {
        this.onCheckIfEditing();
    }

    onCheckIfEditing() {
        this.isEditingId = this.route.snapshot.params['id'];
        if (this.isEditingId != undefined) {
            this.isEditing = true;

            this.isLoadingView = true;

            var ts: TableSettings = new TableSettings();
            ts.columns = "chain_id,chain_name," + Constants.PARAMETER_IMAGES_ONLY_ACTIVE;

            this.apiService.getChain(ts, this.isEditingId).subscribe((data) => {
                if (data.main[0] != undefined) {
                    this.onSetInputs(data.main[0]);
                }
                this.isLoadingView = false;
            }, (error) => {
                this.isLoadingView = false;
            });
        }
    }

    onSetInputs(c: Chain) {
        this.inputName.value = c.chain_name;
        this.inputImage = c.images;
    }

    resetInputs() {
        this.onSetInputs(new Chain());
        this.inputImage = undefined;
        this.filesImages = new FileUploader({});
        window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
    }

    onCheckForms() {
        return this.utilService.checkMultipleIfvalid(this.inputName);
    }

    onCreate() {
        if (this.onCheckForms()) {
            this.notificationService.setNotification(this.lang.la.error_form_invalid, this.notificationService.CONSTANT_TYPE_WARNING);

            return;
        }

        this.isLoading = true;

        var c: Chain = new Chain();
        c.chain_name = this.inputName.value;

        this.apiService.postChain(c).subscribe((data) => {

            this.onUploadImage(data['data'], true);
        }, (error) => {
            this.notificationService.setNotification(error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
            this.isLoading = false;
        });
    }

    onEdit() {
        if (this.onCheckForms()) {
            this.notificationService.setNotification(this.lang.la.error_form_invalid, this.notificationService.CONSTANT_TYPE_WARNING);

            return;
        }

        this.isLoading = true;

        var c: Chain = new Chain();
        c.chain_id = this.isEditingId;
        c.chain_name = this.inputName.value;

        this.apiService.patchChain(c).subscribe((data) => {
            this.onUploadImage(data['data'], false);
        }, (error) => {
            this.notificationService.setNotification(error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
            this.isLoading = false;
        });

    }

    onUploadImage(c: Chain, isItPostCall: boolean) {
        const options = {
            type: 'image',
            module: 'chains',
            id: c.chain_id
        };
        return this.fileUploaderService.uploadAllInQueue(this.filesImages, options)
            .then(() => {
                if (isItPostCall) {
                    this.notificationService.showCreateNotification('Kæde', c.chain_id, '/app/chain/show', 10000);
                    this.resetInputs();
                } else {
                    this.notificationService.setNotification(this.lang.la.success_updated, this.notificationService.CONSTANT_TYPE_NORMAL);
                    this.router.navigate(['app/chain/list/']);
                }

                this.isLoading = false;
            })
            .catch((error) => {
                this.notificationService.setNotification(error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
                this.isLoading = false;
            });
    }

    onRemoveImage() {
        var el: Image = this.inputImage;

        el.image_active = 0;
        this.apiService.patchImage(el).subscribe((data) => {
            this.notificationService.setNotification(this.lang.la.success_deleted, this.notificationService.CONSTANT_TYPE_NORMAL);
            this.inputImage = undefined;
            this.onCheckIfEditing();
        }, (error) => {
        });
    }

    onGoBack() {
        this.location.back();
    }
}
