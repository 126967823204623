<div class="dropdown dropdown-select">
    <!-- Input -->
    <div class="btn btn-outline-secondary is-fullwidth" (click)="onOpenDropdown()">
        <div *ngIf="arrayListSelected == undefined || arrayListSelected.length == 0">
            {{lang.la.choose}}
        </div>
        <div *ngFor="let item of arrayListSelected; let pos = index" class="badge badge-secondary push-m-5-r"
             (click)="onItemUnselected(pos)">
            {{utilService.onGetTableContent(item, arrayListName)}}
        </div>
    </div>

    <!-- Dropdown -->
    <div class="dropdown-menu push-p-0-t" [ngClass]="{'show': isDropdownActive}">
        <!-- Input -->
        <input type="text" class="form-control dropdown-toggle" placeholder="{{lang.la.search}}"
               *ngIf="isDropdownActive"
               [(ngModel)]="inputDropdown"
               (ngModelChange)="onChangeInputDropdown()"
               (keydown.tab)="onItemSelected(0)">

        <a class="dropdown-item" *ngFor="let item of arrayListForView; let pos = index" (click)="onItemSelected(pos)">
            {{utilService.onGetTableContent(item, arrayListName)}}
        </a>

        <a class="dropdown-item dropdown-item-btn" *ngIf="!isLoadingMore && arrayList.length > 20 && !isNoMoreToLoad"
           (click)="onLoadMorePressed()">Hent flere</a>
        <a class="dropdown-item" *ngIf="!isLoadingMore && arrayList.length == 0">Kan ikke finde nogen</a>
        <a class="dropdown-item" *ngIf="isLoadingMore">
            <div class="loader"></div>
        </a>

    </div>

    <img src="./assets/dropdown.svg" alt="" class="dropdown-select-arrow" (click)="onOpenDropdown()">

    <div class="invalid-feedback d-inline" *ngIf="error">
        {{error}}
    </div>
</div>
<div class="dropdown-back-click" *ngIf="isDropdownActive" (click)="isDropdownActive = false"></div>