import {Component, OnInit} from '@angular/core';
import {UtilService} from '../services/util.service';
import {DataService} from '../services/data.service';
import {ApiService} from '../services/api.service';
import {LangService} from '../services/lang.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Customer} from '../models/customer';
import {TableSettings} from '../models/tablesettings';
import {TableHeader} from '../models/tableheader';
import {TableSettingsEquals} from '../models/tablesettingsequals';
import {NotificationService} from '../shared/notification.service';
import {CSVService} from '../services/csv.service';
import {UserService} from '../services/user.service';
import {Constants} from '../constants';

@Component({
    selector: 'app-customer-list',
    templateUrl: './customer-list.component.html',
    styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit {

    Constants: any = Constants;

    isLoadingView: boolean = false;

    //Table
    isLoadingTable: boolean = true;
    tableData: Customer[] = [];
    tableHeaders: TableHeader[];
    tableSettings: TableSettings;

    constructor(
        public apiService: ApiService,
        public route: ActivatedRoute,
        private router: Router,
        public utilService: UtilService,
        public lang: LangService,
        public dataService: DataService,
        public userService: UserService,
        public notificationService: NotificationService,
        public csvService: CSVService
    ) {
    }

    ngOnInit() {
        this.tableHeaders = [
            new TableHeader("customer_name", this.lang.la.name),
            new TableHeader("customer_contact_name", this.lang.la.contactperson),
            new TableHeader("customer_contact_position", this.lang.la.contactperson_position),
            new TableHeader("customer_email", this.lang.la.email),
            new TableHeader("customer_phone", this.lang.la.phoneno),
            new TableHeader("customer_address", this.lang.la.address),
            new TableHeader("customer_postal_code", this.lang.la.postalcode),
            new TableHeader("customer_city", this.lang.la.city)
        ];

        // Set all headers visible
        this.tableHeaders.forEach(function (header) {
            header.checked = true;
        });

        this.tableSettings = new TableSettings();
        this.tableSettings.sortBy = this.tableHeaders[0];
        this.tableSettings.tableHeaders = this.tableHeaders;
        this.tableSettings.searchColumn = this.tableHeaders[0];
        this.tableSettings.tableHeaders[0].asc = false;
        this.tableSettings.tableSettingsEquals.push(new TableSettingsEquals("customer_active", "1"));
        this.tableSettings.isShowingDeactivated = true;

        this.isLoadingView = false;
    }

    getData(tableSettingsForData: TableSettings) {
        this.tableSettings = tableSettingsForData;

        this.isLoadingTable = true;

        this.apiService.getCustomers(tableSettingsForData).subscribe((data) => {
            this.isLoadingView = false;
            this.isLoadingTable = false;
            this.tableData = data.main;
            this.tableSettings.tableOffset = data.tableOffset;

        }, (error) => {
            this.utilService.onRetryApi("getCustomers", () => {
                this.getData(this.tableSettings);
            }, () => {
                this.isLoadingView = false;
                this.isLoadingTable = false;
                this.notificationService.setNotification(this.lang.la.error_general_message + " " + error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
            });
        });
    }

    onRowPressed(event) {
        this.router.navigate(['app/customer/show/', this.tableData[event.pos].customer_id]);
    }

}
