<!-- Modal -->
<div class="modal fade modal-show" tabindex="-1" role="dialog" [ngClass]="{'modal-show': isDeleteModalActive}">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title">{{lang.la.modal_delete_title}}</h6>
                <button type="button" class="close" (click)="isDeleteModalActive = !isDeleteModalActive"
                        aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                {{lang.la.modal_delete_descr}}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" [ngClass]="{'is-loading': isLoading}"
                        (click)="isDeleteModalActive = !isDeleteModalActive">{{lang.la.cancel}}</button>
                <button type="button" class="btn btn-danger" (click)="onDelete()"
                        [ngClass]="{'is-loading': isLoading}">{{lang.la.delete}}</button>
            </div>
        </div>
    </div>
</div>

<div class="row push-m-50-b" *ngIf="!isLoadingView">
    <div class="col-5">
        <h2 class="h2">{{lang.la.assignment}} #{{assignment.assignment_id}}</h2>

        <h5 class="h5">
        Status:
        <ng-container [ngSwitch]="assignment.assignment_status">
            <span class="badge badge-warning" *ngSwitchCase="'i-udbud-fra-kunde'">Oprettet af kunde - afventer accept</span>
            <span class="badge badge-warning" *ngSwitchCase="'adhoc'">Oprettet - afventer endelig info</span>
            <span class="badge badge-warning" *ngSwitchCase="'afventer-medarbejder-accept'">I udbud - afventer medarbejder</span>
            <span class="badge badge-warning" *ngSwitchCase="'afventer-medarbejder-udfyldelse'">Afventer afrapportering</span>
            <span class="badge badge-warning" *ngSwitchCase="'afventer-godkendelse'">Afventer godkendelse</span>
            <span class="badge badge-success" *ngSwitchCase="'godkendt'">Godkendt</span>
        </ng-container>
        </h5>
    </div>
    <div class="col-7 has-text-right">
        <button class="btn btn-outline-danger ml-2 mb-2" (click)="isDeleteModalActive = !isDeleteModalActive"
                *ngIf="userService.getShowForUser(USER_RIGHT_TYPES.TYPE_ASSIGMENT_DELETE) && (!assignment.execution || (assignment.execution.invoice_been_run == 0 && assignment.execution.salary_been_given == 0))">{{lang.la.delete}}</button>
        <button class="btn btn-outline-success ml-2 mb-2"
                [routerLink]="['../../copy/',assignment.assignment_id]"
                *ngIf="userService.getShowForUser(USER_RIGHT_TYPES.TYPE_ASSIGMENT_COPY)">
        <span class="is-fullwidth"
              [tooltip]="tolTemplate"
              [placement]="'bottom'">{{lang.la.copy}}</span>
        </button>
        <button class="btn btn-outline-secondary ml-2 mb-2" (click)="onEditExecutions()"
                *ngIf="showEditExecutionButton()">
            Rediger afrapportering
        </button>
        <button [routerLink]="['../../edit/', assignment.assignment_id]" class="btn btn-outline-secondary ml-2 mb-2"
                *ngIf="showEditButton()">{{lang.la.edit}}</button>
    </div>
</div>

<app-loading *ngIf="isLoadingView && !errorLoadingAssignment"></app-loading>

<div class="row" *ngIf="showEditWarning.visible">
    <div class="col-sm-12">
        <div class="edit-notification">Opgaven bliver lukket for redigering om
            {{showEditWarning.timeAvailable > 0 ? (showEditWarning.timeAvailable + (showEditWarning.timeAvailable > 1 ? ' minutter' : ' minut')) : 'mindre end et minut'}} </div>
    </div>
</div>

<div class="card card-top" *ngIf="checkIfUserCanSeeAssignment()">

    <div class="card-body row">
        <div class="col-sm-8 push-p-20-b">
            <h3 class="float-left">
                {{lang.la.report}}
            </h3>
        </div>

        <div class="col-sm-6">
            <div class="form-group">
                <label class="text-is-grey">{{lang.la.employee}}</label>
                <p class="h6">{{assignment.execution.user.user_firstname}} {{assignment.execution.user.user_lastname}}</p>
                <p class="h6">{{lang.la.email}}: <a
                        href="mailto:{{assignment.execution.user.user_email}}">{{assignment.execution.user.user_email}}</a>
                </p>
                <p class="h6">{{lang.la.phoneno}}: <a
                        href="tel:{{assignment.execution.user.user_phone}}">{{assignment.execution.user.user_phone}}</a>
                </p>

            </div>
        </div>
    </div>

    <div class="card-divider"></div>

    <div class="card-body row">
        <div class="col-sm-6">
            <div class="form-group">
                <label class="text-is-grey"> {{lang.la.time_start}}</label>
                <p class="h6">{{assignment.execution.assignment_execution_start_time | mdate: 'dddd	DD. MMM YYYY HH:mm' }} </p>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="text-is-grey"> {{lang.la.duration}}</label>
                <p class="h6">{{utilService.onConvertMiliToHoursAndMili(assignment.execution.assignment_execution_time_spend)}}
                    {{ assignment.execution.assignment_execution_time_spend > 6300000 ? 'timer' : 'time' }}</p>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="text-is-grey">{{lang.la.driving}}</label>
                <p class="h6">{{assignment.execution.assignment_execution_drive_distance}} km</p>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="text-is-grey"> {{lang.la.udlaeg}}</label>
                <p class="h6">{{assignment.execution.assignment_execution_expenses | currency: 'DKK' |formatedcurrency}}</p>
            </div>
        </div>
        <div class="col-sm-6" *ngIf="assignment.execution.salary_given_at">
            <div class="form-group">
                <label class="text-is-grey"> {{lang.la.salary_give_at}}</label>
                <p class="h6">{{assignment.execution.salary_given_at | mdate: 'dddd	DD. MMM YYYY HH:mm'}}</p>
            </div>
        </div>
        <div class="col-sm-6" *ngIf="assignment.execution.invoice_given_at">
            <div class="form-group">
                <label class="text-is-grey"> {{lang.la.invoice_give_at}}</label>
                <p class="h6">{{assignment.execution.invoice_given_at |  mdate: 'dddd	DD. MMM YYYY HH:mm'}}</p>
            </div>
        </div>


        <div class="col-sm-12">
            <div class="form-group">
                <label class="text-is-grey"> {{lang.la.comment}}</label>
                <p class="h6">{{assignment.execution.assignment_execution_comments}}</p>
            </div>
        </div>
    </div>

    <div class="card-divider"></div>

    <div class="card-body row">

        <div class="col-sm-6">
            <label class="text-is-grey">{{lang.la.images}}</label>
            <div class="card">
                <div class="card-body">
                    <a *ngFor="let item of assignmentExecutionImages"
                       [href]="utilService.onImageToView(item.images.image_link)" target="_blank">
                        <img #tempImage (load)="rotateImage(tempImage)"
                             [src]="utilService.onImageToViewThumbnail(item.images.image_link)" alt=""
                             class="assignment-image"/>
                    </a>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <label class="text-is-grey">{{lang.la.expenditures}}</label>
            <div class="card">
                <div class="card-body">
                    <a *ngFor="let item of assignmentExecutionAppendix"
                       [href]="utilService.onAppendixToDownload(item.appendix_link)" target="_blank"
                       class="assignment-image-bilag">
                        <img #tempImage (load)="rotateImage(tempImage)"
                             [src]="utilService.onAppendixToView(item.appendix_link)" alt="" class="assignment-image"/>
                        <span *ngIf="item.appendix_amount > 0">
							{{item.appendix_amount | currency: 'DKK' | formatedcurrency}}
						</span>
                    </a>

                </div>
            </div>
        </div>
    </div>

    <div class="card-divider" *ngIf="assignment.execution && !assignment.execution.assignment_execution_approved"></div>

    <div class="card-body row"
         *ngIf="userService.getShowForUser(USER_RIGHT_TYPES.ASSIGNMENT_ACCEPT) && assignment.execution && assignment.execution.assignment_execution_approved == 0">
        <div class="col-sm-12 has-text-right">
            <button type="button" class="btn btn-success" (click)="onExecutionsAccept()"
                    [ngClass]="{'is-loading': executionsIsLoadingApproved}">{{lang.la.approve}}</button>
        </div>
    </div>
</div>

<!-- Assignemnt -->
<div class="card card-top" *ngIf="!isLoadingView">
    <div class="card-body row">
        <div class="col-sm-2 has-text-centered"
             *ngIf="assignmentChain != undefined && assignmentChain.images != undefined && assignmentChain.images.image_link != undefined">
            <img #tempImage (load)="rotateImage(tempImage)"
                 [src]="utilService.onImageToView(assignmentChain.images.image_link)" alt="Opgave billede"
                 class="assignment-image"/>
        </div>
        <!-- Section 1 -->
        <div class="col-sm-4">
            <div class="form-group">
                <label class="text-is-grey">{{lang.la.as_c_card_customer}}</label>
                <p class="h6">{{assignment.customer ? assignment.customer.customer_name : 'Ingen kunde valgt endnu'}}</p>
            </div>
            <div class="form-group">
                <label class="text-is-grey">Type</label>
                <p class="h6"> {{assignment.assignment_type | titlecase}} </p>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-group">
                <label class="text-is-grey">{{lang.la.as_c_card_salesconsultant}} </label>
                <ng-container *ngIf="assignment.sales_consultant">
                    <p class="h6">{{assignment.sales_consultant.user_firstname}} {{assignment.sales_consultant.user_lastname}}</p>
                    <p class="h6">{{lang.la.email}}: <a
                            href="mailto:{{assignment.sales_consultant.user_email}}">{{assignment.sales_consultant.user_email}}</a>
                    </p>
                    <p class="h6">{{lang.la.phoneno}}: <a
                            href="tel:{{assignment.sales_consultant.user_phone}}">{{assignment.sales_consultant.user_phone}}</a>
                    </p>
                </ng-container>

                <p *ngIf="!assignment.sales_consultant" class="h6">Ingen salgskonsulent valgt endnu</p>

            </div>
        </div>
    </div>

    <div class="card-divider"></div>

    <!-- Kunde -->
    <div class="card-body row">
        <div class="col-sm-4">
            <div class="form-group">
                <label class="text-is-grey">{{lang.la.as_c_card_store}}</label>
                <ng-container *ngIf="assignment.store">
                    <p class="h6">{{assignment.store.store_name}}</p>
                    <p class="h6">{{assignment.store.store_address}}</p>
                    <p class="h6">{{assignment.store.store_postal_code}} {{assignment.store.store_city}}</p>
                    <p class="h6">{{lang.la.phoneno}}: <a
                            href="tel:{{assignment.store.store_phone}}">{{assignment.store.store_phone}}</a></p>
                </ng-container>
                <p *ngIf="!assignment.store" class="h6">Ingen butik valgt endnu</p>
            </div>
        </div>
        <div class="col-sm-8" *ngIf="assignment.store">
            <iframe
                    width="650"
                    height="155"
                    frameborder="0"
                    style="border:0;width: 100%; max-width: 100%;"
                    [src]="onAddressUrl()"
                    allowfullscreen></iframe>
        </div>
    </div>

    <!--<div class="card-divider"></div>-->
    <!---->
    <!--&lt;!&ndash; Section 2 &ndash;&gt;-->
    <!--<div class="card-body row">-->
    <!--<div class="col-sm-6">-->
    <!--<div class="form-group">-->
    <!--<label class="text-is-grey">{{lang.la.as_c_card_type}}</label>-->
    <!--<p class="h6">{{utilService.onUppercase(assignment.assignment_type)}}</p>-->
    <!--</div>-->
    <!--</div>-->
    <!--<div class="col-sm-6">-->
    <!--</div>-->
    <!--</div>-->
    <!---->
    <!--<div class="card-divider"></div>-->

    <!--&lt;!&ndash; Free Time &ndash;&gt;-->
    <!--<div class="card-body row">-->


    <!--&lt;!&ndash; &ndash;&gt;-->

    <!--</div>-->


    <div class="card-divider"></div>

    <!-- Section  -->
    <div class="card-body row">
        <!--Free Time-->
        <div [ngClass]="{'col-sm-8': !onShouldShowCompleteDate(), 'col-lg-4 col-md-6 col-sm-12': onShouldShowCompleteDate()}"
             *ngIf="assignment.assignment_timestype_free == 1">
            <div class="form-group">
                <label class="text-is-grey">Skal udføres i perioden</label>
                <p class="h6" *ngIf="assignment.assignment_timestype_start != '0000-00-00 00:00:00'">
                    {{utilService.onUppercase(assignment.assignment_timestype_start | mdate: 'dddd	DD. MMM YYYY HH:mm')}}
                    -
                    <span *ngIf="onShouldShowCompleteDate()">
						{{utilService.onUppercase(assignment.assignment_timestype_free_end | mdate: 'HH:mm')}}
					</span>
                    <span *ngIf="!onShouldShowCompleteDate()">
						{{utilService.onUppercase(assignment.assignment_timestype_free_end | mdate: 'dddd	DD. MMM YYYY HH:mm')}}
					</span>

                </p>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6" *ngIf="assignment.assignment_timestype_free == 2">
            <div class="form-group">
                <label class="text-is-grey">Skal udføres på dette tidspunkt</label>
                <p class="h6" *ngIf="assignment.assignment_timestype_start != '0000-00-00 00:00:00'">
                    {{utilService.onUppercase(assignment.assignment_timestype_start | mdate: 'dddd	DD. MMM YYYY HH:mm')}}
                </p>
            </div>
        </div>


        <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="form-group">
                <label class="text-is-grey">{{lang.la.as_c_card_estimate}}</label>
                <p class="h6">
                    {{utilService.onConvertMiliToHoursAndMili(assignment.assignment_time_estimate)}} {{ assignment.assignment_time_estimate >= 6300000 ? 'timer' : 'time' }}
                </p>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="form-group">
                <label class="text-is-grey">{{lang.la.pos_material}}</label>
                <p class="h6" *ngIf="assignment.assignment_pos_material == 1">{{lang.la.yes}}</p>
                <p class="h6" *ngIf="assignment.assignment_pos_material == 0">{{lang.la.no}}</p>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="form-group">
                <label class="text-is-grey">{{lang.la.goods}}</label>
                <p class="h6">{{assignment.assignment_product}}</p>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="form-group">
                <label class="text-is-grey">{{lang.la.contact_person}}</label>
                <p class="h6">{{assignment.assignment_contact_person}}</p>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="form-group">
                <label class="text-is-grey">{{lang.la.place_in_store}}</label>
                <p class="h6">{{assignment.assignment_place_in_store}}</p>
            </div>
        </div>
    </div>

    <div class="card-divider"></div>

    <!-- Todos  -->
    <div class="card-body row">
        <div class="col-lg-6">
            <div class="form-group"
                 *ngIf="userService.user.user_role_id === Constants.USER_ROLE_ADMIN_ID || userService.user.user_role_id === Constants.USER_ROLE_TEAMPLAYER_ID ">
                <label class="text-is-grey">{{lang.la.selected_users_for_assignment}}</label>
                <div class="row">
                    <div class="col-12" *ngIf="!isLoadingUsers">
                        <a class="float-left push-m-15-r" *ngFor="let item of assignmentUser; let pos = index"
                           [routerLink]="['../../../user/show/', item.user_id]">
                            {{item.users.user_firstname}} {{item.users.user_lastname}}
                        </a>
                    </div>
                </div>
                <ng-container *ngIf="isLoadingUsers">
                    <div class="loader-small"></div>
                </ng-container>
            </div>
            <!-- Tildelt user -->
            <div class="form-group" *ngIf="isSuppliedUser != undefined">
                <label class="text-is-grey">{{lang.la.assigned_users_picked}}</label>
                <div class="row">
                    <div class="col-12" *ngIf="!isLoadingUsers && isSuppliedUser != undefined">
                        <a class="push-m-15-r text-success"
                           [routerLink]="['../../../user/show/', isSuppliedUser.users.user_id]">
                            {{isSuppliedUser.users.user_firstname}} {{isSuppliedUser.users.user_lastname}}
                        </a><br />
                        <p class="h6">{{lang.la.phoneno}}: <a href="tel:{{isSuppliedUser.users.user_phone}}">{{isSuppliedUser.users.user_phone}}</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="form-group">
                <label class="text-is-grey">{{lang.la.as_c_card_todo}}</label>
                <h5 class="h5" *ngIf="assignment.todos !==undefined && assignment.todos.length > 0; else noTodos">
				<span *ngFor="let item of assignment.todos" class="badge badge-secondary push-m-20-r push-m-10-b">
					{{Constants.TODO_NAME[item.assignment_todo_id]}}
				</span>
                </h5>
            </div>

        </div>
        <ng-template #noTodos><p class="h6">{{lang.la.information_not_available}}</p></ng-template>
    </div>


    <div class="card-divider"></div>

    <!-- Appendix  -->
    <div class="card-body row">
        <div class="col-sm-12">
            <label class="text-is-grey">{{lang.la.expenditures}}</label>
            <div class="card">
                <div class="card-body">
                    <a *ngFor="let item of assignmentAppendix"
                       [href]="utilService.onAppendixToDownload(item.appendix_link)" title="{{item.appendix_name}}"
                       target="_blank" class="assignment-image-bilag">
                        <img #testImage [src]="utilService.onAppendixToView(item.appendix_link)" alt=""
                             (load)="rotateImage(testImage)" class="assignment-image"/>
                        <p class="appendix-text">{{item.appendix_name}}</p>
                    </a>
                </div>
            </div>
        </div>
    </div>


    <!-- Descrition -->
    <div class="card-divider"></div>
    <div class="card-body row">
        <div class="col-sm-12">
            <label class="text-is-grey">{{lang.la.as_c_card_descr}}</label>
        </div>
        <div class="col-sm-12">
            <p class="h6" [innerHTML]="assignment.assignment_description | linkify">

            </p>
        </div>
    </div>

    <ng-container *ngIf="checkIfUserCanChangeEmployeesOfAssignment()">
        <div class="card-divider"></div>
        <div class="card-body row">
            <div class="col-sm-12 mb-1">
                <label class="text-is-grey">Tilføj medarbejdere / Fjern medarbejdere</label>
            </div>
            <div class="col-sm-6">
                <div class="form-group input-group">
                    <label type="text" for="radio40" class="form-control"
                           [ngClass]="{'is-invalid': inputAssignSupply.error}">
                        {{lang.la.supply}} <br/>
                        <!--<span class="text-is-grey h7">{{lang.la.supply_descr}}</span>-->
                    </label>
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <input type="radio" aria-label="Enkeltstående" id="radio40"
                                   (ngModelChange)="inputAssignSupply.error = ''" [(ngModel)]="inputAssignSupply.value"
                                   name="inputAssignSupply.value" [value]="true" (click)="onAssignPressed(0)">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group input-group">
                    <label type="text" for="radio41" class="form-control"
                           [ngClass]="{'is-invalid': inputAssignOffer.error}">
                        {{lang.la.assign_to}} <br/>
                        <!--<span class="text-is-grey h7">{{lang.la.assign_to_descr}}</span>-->
                    </label>
                    <div class="input-group-append">
                        <div class="input-group-text">
                            <input type="radio" aria-label="Enkeltstående" id="radio41"
                                   (ngModelChange)="inputAssignOffer.error = ''" [(ngModel)]="inputAssignOffer.value"
                                   name="inputAssignOffer.value" [value]="true" (click)="onAssignPressed(1)">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12" *ngIf="inputAssignOffer.value || inputAssignSupply.value ">
                <label>*Vælg medarbejdere</label>
                <div class="invalid-feedback d-inline" *ngIf="inputAssignSupply.error">
                    {{inputAssignSupply.error}}
                </div>
            </div>

            <!-- Select multiple for 'udbyd' -->
            <div class="col-sm-12" *ngIf="inputAssignSupply.value">
                <app-dropdown-select-type-multiple
                        [arrayType]="1"
                        (selectedItem)="inputUsers = $event"
                        [(arrayListSelected)]="inputUsers"></app-dropdown-select-type-multiple>
            </div>
        </div>


        <!-- Only select one for 'tildel' -->
        <div class="col-sm-12" *ngIf="inputAssignOffer.value ">
            <app-dropdown-select-type
                    [arrayType]="1"
                    [arrayListSelectedId]="onCheckCopyToId()"
                    (selectedItem)="onSelectAssignOffer($event)"></app-dropdown-select-type>
        </div>


        <div class="col-sm-12 mt-2 mb-2 has-text-right">
            <button class="btn btn-success" (click)="onEditAssignmentUsers()" [ngClass]="{'is-loading': isLoading}">
                {{lang.la.save_assignment}}
            </button>
        </div>
    </ng-container>

</div>

<!-- Acceptere opgaven -->
<div class="card push-m-50-b push-m-50-t fadeIn"
     *ngIf="showAcceptAssignment()">
    <div class="card-body row">
        <div class="col-sm-12 push-p-50 text-center">
            <p class="h5 push-m-20-b">
                {{lang.la.accept_assignment_descr}}
            </p>
            <button class="btn btn-success" (click)="onAcceptAssignment()" [ngClass]="{'is-loading': isLoading}">
                {{lang.la.accept_assignment}}
            </button>
        </div>
    </div>
</div>


<!-- Opgaven gemt -->
<div class="card push-m-50-b push-m-50-t fadeIn"
     *ngIf="!isLoadingView && assignment.assignment_status == Constants.ASSIGNMENT_STATUS_GEM">
    <div class="card-body row">
        <div class="col-sm-12 push-p-50 text-center">
            <p class="h5 push-m-20-b">
                {{lang.la.assignment_saved}}
            </p>
            <button [routerLink]="['../../edit/', assignment.assignment_id]" class="btn btn-outline-secondary">

                {{lang.la.edit_assignment}}
            </button>
        </div>
    </div>
</div>

<!-- Reporting create and edit -->
<div class="card push-m-50-b push-m-50-t fadeIn" id="reportingElement" *ngIf="showCreateExecution()">
    <div class="card-body row">
        <div class="col-sm-10 push-p-20-b">
            <h1 class=" h3" *ngIf="!executionsEditing">{{lang.la.report}}</h1>
            <h1 class=" h3"
                *ngIf="executionsEditing">{{lang.la.edit_report_from}} {{utilService.onUppercase(assignment.execution.assignment_execution_creation | mdate: 'dddd	DD. MMM YYYY HH:mm')}}</h1>
        </div>

        <div class="col-sm-2 push-p-5-t">
        </div>

        <div class="col-sm-12">
            <div class="row">

                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Tidspunkt</label>
                        <div class="input-group mb-2" #dp="bsDatepicker"
                             bsDatepicker [bsConfig]="dpConfig" [(bsValue)]="inputDateStart.value"
                             (bsValueChange)="changeEndDate($event)">
                            <input type="text"
                                   class="form-control"
                                   [value]="inputDateStart.value | mdate: 'DD/MM/YYYY'"
                                   disabled
                            />
                        </div>
                    </div>
                </div>

                <div class="col-sm-6">
                    <div class="form-group timepicker-alignment">
                        <timepicker [(ngModel)]="inputDateStart.value" [minuteStep]="15" [showMeridian]="false"
                                    (ngModelChange)="onExecutionTimeChange('start')" name="starttime"></timepicker>
                        -
                        <timepicker [(ngModel)]="inputDateEnd.value" [minuteStep]="15" [showMeridian]="false"
                                    (ngModelChange)="onExecutionTimeChange('end')" name="endtime"></timepicker>
                    </div>
                    <div class="invalid-feedback d-inline" *ngIf="inputDateEnd.error">
                        {{inputDateEnd.error}}
                    </div>
                </div>
                <!--<div class="col-sm-3">-->
                <!--<div class="form-group">-->
                <!---->
                <!--</div>-->
                <!--</div>-->


                <div class="col-sm-6">
                    <div class="form-group">
                        <label>{{lang.la.driving}}</label>
                        <div class="input-group">
                            <input type="number" class="form-control" [(ngModel)]="inputDriving.value"
                                   (change)="onInputDriving($event.target.value)">
                            <div class="input-group-append">
                                <span class="input-group-text">km</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!--<div class="col-sm-6">-->
                <!--<div class="form-group">-->
                <!--<label>{{lang.la.udlaeg}}</label>-->
                <!--<div class="input-group">-->
                <!--<input type="number" class="form-control" [(ngModel)]="inputExpenses.value"  >-->
                <!--<div class="input-group-append">-->
                <!--<span class="input-group-text" >DKK</span>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->

                <div class="col-sm-12 push-m-20-t">
                    <div class="form-group">
                        <label for="exampleFormControlTextarea1">* {{lang.la.comment}} om afrapportering <span
                                class="text-is-grey small">(Hvad har du lavet?)</span></label>
                        <textarea class="form-control" [(ngModel)]="inputDescription.value"
                                  id="exampleFormControlTextarea1" name="inputDescription.value"
                                  rows="3"
                                  [ngClass]="{'is-invalid': inputDescription.error}"></textarea>
                        <div class="invalid-feedback d-inline" *ngIf="inputDescription.error">
                            {{inputDescription.error}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-6">

                    <label>{{lang.la.images}}</label>
                    <app-inputupload (imageUploaderChange)="filesImages = $event"
                                     accept="image/png,image/gif,image/jpeg"
                                     [imageUploader]="filesImages"></app-inputupload>
                    <!-- If Editing -->
                    <div class="row">
                        <div class="col-sm-12" *ngIf="executionsEditing">

                            <label class="push-m-20-t">{{lang.la.formerly_added_images}}</label>

                            <!-- Loop -->
                            <div *ngFor="let item of assignmentExecutionImages; let pos = index"
                                 class="col-sm-12 push-m-10-b push-m-10-t">
                                <div class="row">
                                    <div class="col-sm-11">
									<span class="float-left">
										<img #tempImage (load)="rotateImage(tempImage)"
                                             [src]="utilService.onImageToView(item.images.image_link)" alt=""
                                             class="fileuploader-image"/>
									</span>
                                        <span class="float-left push-p-10-l">
										<span class="h6">
											{{ item.image_id }}
										</span><br/>
									</span>
                                    </div>
                                    <div class="col-sm-1">
									<span *ngIf="item.isLoading">
										<div class="loader-small"></div>
									</span>

                                        <a (click)="onRemoveImageFromExecutions(pos)" *ngIf="!item.isLoading">
                                            <svg fill="#ff0000" height="16" viewBox="0 0 24 24" width="16"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                                                <path d="M0 0h24v24H0z" fill="none"/>
                                            </svg>
                                        </a>
                                    </div>
                                </div>

                            </div>
                            <!-- /Loop -->

                        </div>
                    </div>
                    <!-- /If Editing -->

                </div>

                <div class="col-md-6">
                    <label>{{lang.la.expenditures}}</label>
                    <app-inputappendix (imageUploaderChange)="onBeforeAppendixUpload($event)"
                                       (totalAppendixValue)="inputExpenses.value = $event"
                                       [hasExpense]="true"
                                       [queueIsValid]="canSubmitForm" (validateQueueChange)="canSubmitForm = $event"
                                       [imageUploader]="filesBilag"></app-inputappendix>
                    <!-- If Editing -->
                    <div class="col-sm-12" *ngIf="executionsEditing">
                        <label class="push-m-20-t">{{lang.la.formerly_added_appendix}}</label>

                        <!-- Loop -->
                        <div *ngFor="let item of assignmentExecutionAppendix; let pos = index"
                             class="col-sm-12 push-m-10-b push-m-10-t">
                            <div class="row">
                                <div class="col-sm-11">
								<span class="float-left">
									<img #tempImage (load)="rotateImage(tempImage)"
                                         [src]="utilService.onAppendixToView(item.appendix_link)" alt=""
                                         class="fileuploader-image"/>
								</span>
                                    <span class="float-left push-p-10-l">
									<span class="h6">
										{{ item.appendix_link }}
									</span><br/>
									<span class="h7 text-is-grey">
										{{item.appendix_amount | currency: 'DKK' | formatedcurrency}}
									</span>

								</span>
                                </div>
                                <div class="col-sm-1">
								<span *ngIf="item.isLoading">
									<div class="loader-small"></div>
								</span>

                                    <a (click)="onRemoveAppendixFromExecutions(pos)" *ngIf="!item.isLoading">
                                        <svg fill="#ff0000" height="16" viewBox="0 0 24 24" width="16"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                                            <path d="M0 0h24v24H0z" fill="none"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>

                        </div>
                        <!-- /Loop -->
                    </div>

                </div>
            </div>
        </div>

        <div class="col-sm-12 has-text-right push-m-20-t">
            <button class="btn btn-success"
                    [disabled]="!canSubmitForm"
                    (click)="onPostExecutions()" [ngClass]="{'is-loading': isLoading}">
				   <span class="is-fullwidth"
                         [ngClass]="{'disabled-cursor' : !canSubmitForm}"
                         [tooltip]="template"
                         [isDisabled]="canSubmitForm"
                         [placement]="'bottom'">{{lang.la.create_report_now}}</span>
            </button>
        </div>
    </div>
</div>

<ng-template #template>En fejl opstod ved tilføjelse af bilag</ng-template>

<div class="row push-m-50-b push-m-50-t"
     *ngIf="userService.getShowForUser(USER_RIGHT_TYPES.TYPE_ASSIGMENT_EDIT) && !isLoadingView && assignmentHistoric.length != 0">
    <div class="col-sm-6">
        <h1 class="h2">{{lang.la.historic}}</h1>
    </div>
    <div class="col-sm-6 has-text-right">
    </div>
</div>

<div class="row"
     *ngIf="userService.getShowForUser(USER_RIGHT_TYPES.TYPE_ASSIGMENT_EDIT) && !isLoadingView && assignmentHistoric.length != 0">
    <div class="col-sm-12">
        <div class="row" *ngFor="let history of assignmentHistoric; let pos = index">
            <div class="col-sm-2 d-none d-sm-block" [ngClass]="{'assignment-his-line': pos != assignmentHistoric.length-1}">
                <img #tempImage (load)="rotateImage(tempImage)"
                     *ngIf="history.users != undefined && history.users.images != undefined"
                     [src]="utilService.onImageToViewThumbnail(history.users.images.image_link)"
                     class="assignment-his-number"/>
                <img #tempImage (load)="rotateImage(tempImage)"
                     *ngIf="history.users == undefined || history.users.images == undefined"
                     src="./assets/placeholder_profile.png" class="assignment-his-number"/>
            </div>
            <div class="col-12 col-sm-10 push-p-20-b">
                <div class="card history-log">
                    <div class="card-body row">
                        <div class="col-12">
                            <p class="push-m-10-b">
                                <span class="col-9 pt-2 pb-2 d-inline-block">
                                    <a *ngIf="history.users.user_id"
                                       [routerLink]="['../../../user/show/', history.users.user_id]">
                                        {{history.users.user_firstname}} {{history.users.user_lastname}}
                                    </a>
                                    <span [innerHtml]="utilService.onDisplayLogText(history,null)"></span>
                                </span>

                                <a class="log__date h7">{{addTimeZoning(history.user_log_updated)}}</a>
                            </p>
                            <ng-container *ngIf="history.user_log_action === 'edited'">
                                <div class="card-divider"></div>
                                <div class="row log__header">
                                    <div class="col-sm-4">Felt</div>
                                    <div class="col-sm-4">Tidligere status</div>
                                    <div class="col-sm-4">Ny status</div>
                                </div>
                                <ng-container>
                                    <div class="row log__text" *ngFor="let field of history.user_log_text">
                                        <div class="col-sm-4">{{field.fieldName}}</div>
                                        <div class="col-sm-4">{{field.from}}</div>
                                        <div class="col-sm-4">{{field.to}}</div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
