import { Routes } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeDa from '@angular/common/locales/da';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { daLocale } from 'ngx-bootstrap/locale';
// Import Bugsnag and the Angular integration
import bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NewsComponent } from './news/news.component';
import { NewsCreateComponent } from './news-create/news-create.component';
import { ProfileComponent } from './profile/profile.component';
import { AssignmentCreateComponent } from './assignment-create/assignment-create.component';
import { AssignmentComponent } from './assignment/assignment.component';
import { AssignmentListComponent } from './assignment-list/assignment-list.component';
import { StoreComponent } from './store/store.component';
import { StoreListComponent } from './store-list/store-list.component';
import { StoreCreateComponent } from './store-create/store-create.component';
import { StoreImportComponent } from './store-import/store-import.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserSendComponent } from './user-send/user-send.component';
import { UserCreateComponent } from './user-create/user-create.component';
import { UserComponent } from './user/user.component';
import { CustomerComponent } from './customer/customer.component';
import { CustomerListComponent } from './customer-list/customer-list.component';
import { CustomerCreateComponent } from './customer-create/customer-create.component';
import { ExportListComponent } from './export-list/export-list.component';
import { ExportCreateComponent } from './export-create/export-create.component';
import { ExportComponent } from './export/export.component';
import { MyCalendarComponent } from './calendar/calendar.component';
import { BusinessintelligenceComponent } from './businessintelligence/businessintelligence.component';
import { ChainComponent } from './chain/chain.component';
import { ChainListComponent } from './chain-list/chain-list.component';
import { ChainCreateComponent } from './chain-create/chain-create.component';
import { LostpasswordComponent } from './lostpassword/lostpassword.component';
import { NewpasswordComponent } from './newpassword/newpassword.component';
import { DealcheckerComponent } from './dealchecker/dealchecker.component';
import { AssignmentImportComponent } from './assignment-import/assignment-import.component';
import { UserRoleGuard } from './guards/user-role.guard';
import { Constants } from './constants';
// @ts-ignore
import { version } from '../../package.json';
import * as process from 'process';
registerLocaleData(localeDa, 'da');
defineLocale('da', daLocale);
// configure Bugsnag asap
var bugsnagClient = bugsnag({
    apiKey: 'cebf8cd02e24473285f11b7797f17de1',
    appVersion: version,
    notifyReleaseStages: ['production', 'staging'],
    releaseStage: process.env.NODE_ENV
});
// create a factory which will return the bugsnag error handler
export function errorHandlerFactory() {
    return new BugsnagErrorHandler(bugsnagClient);
}
var ɵ0 = { version: version }, ɵ1 = { roles: [Constants.USER_ROLE_ADMIN_ID, Constants.USER_ROLE_TEAMPLAYER_ID] }, ɵ2 = { roles: [Constants.USER_ROLE_ADMIN_ID, Constants.USER_ROLE_TEAMPLAYER_ID] }, ɵ3 = { roles: [Constants.USER_ROLE_ADMIN_ID, Constants.USER_ROLE_TEAMPLAYER_ID] }, ɵ4 = { roles: [Constants.USER_ROLE_ADMIN_ID, Constants.USER_ROLE_TEAMPLAYER_ID] };
var appRoutes = [
    { path: '', component: LoginComponent },
    { path: 'login', component: LoginComponent },
    { path: 'lostpassword', component: LostpasswordComponent },
    { path: 'newpassword', component: NewpasswordComponent },
    {
        path: 'app', data: ɵ0, component: DashboardComponent, children: [
            { path: '', redirectTo: 'news', pathMatch: 'full' },
            { path: 'news', component: NewsComponent },
            { path: 'news/create', component: NewsCreateComponent },
            { path: 'news/edit/:id', component: NewsCreateComponent },
            { path: 'task/create', component: AssignmentCreateComponent },
            { path: 'task/import', component: AssignmentImportComponent },
            { path: 'task/copy/:id', component: AssignmentCreateComponent },
            { path: 'task/edit/:id', component: AssignmentCreateComponent },
            { path: 'task/show/:id', component: AssignmentComponent },
            { path: 'task/list', component: AssignmentListComponent },
            { path: 'profile/my', component: ProfileComponent },
            { path: 'store/list', component: StoreListComponent },
            { path: 'store/import', component: StoreImportComponent },
            {
                path: 'store/create',
                component: StoreCreateComponent,
                canActivate: [UserRoleGuard],
                data: ɵ1
            },
            { path: 'store/edit/:id', component: StoreCreateComponent },
            { path: 'store/show/:id', component: StoreComponent },
            { path: 'user/list', component: UserListComponent },
            { path: 'user/list/:type', component: UserListComponent },
            { path: 'user/send', component: UserSendComponent },
            {
                path: 'user/create',
                component: UserCreateComponent,
                canActivate: [UserRoleGuard],
                data: ɵ2
            },
            { path: 'user/edit/:id', component: UserCreateComponent },
            {
                path: 'user/show/:id',
                component: UserComponent,
                canActivate: [UserRoleGuard],
                data: ɵ3
            },
            { path: 'customer/list', component: CustomerListComponent },
            { path: 'customer/show/:id', component: CustomerComponent },
            {
                path: 'customer/create', component: CustomerCreateComponent,
                data: ɵ4
            },
            { path: 'customer/edit/:id', component: CustomerCreateComponent },
            { path: 'export/show/:id', component: ExportComponent },
            { path: 'export/list', component: ExportListComponent },
            { path: 'export/create', component: ExportCreateComponent },
            { path: 'calendar', component: MyCalendarComponent },
            { path: 'chain/list', component: ChainListComponent },
            { path: 'chain/create', component: ChainCreateComponent },
            { path: 'chain/edit/:id', component: ChainCreateComponent },
            { path: 'chain/show/:id', component: ChainComponent },
            { path: 'businessintelligence', component: BusinessintelligenceComponent },
            { path: 'deal', component: DealcheckerComponent },
        ],
    },
    { path: '**', redirectTo: 'app/news' }
];
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
