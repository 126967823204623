import {RelatedObjects} from "./related-objects";
import {TableOffset} from "./tableoffset";

export class TableData {
    public main: any[];
    public tableOffset: TableOffset;
    public related_objects: RelatedObjects;

    constructor() {
    }
}