import {TableSettingsContains} from './../models/tablesettingscontains';
import {Component, OnInit} from '@angular/core';
import {UtilService} from '../services/util.service';
import {DataService} from '../services/data.service';
import {LangService} from '../services/lang.service';
import {ApiService} from '../services/api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Export} from '../models/export';
import {InputString} from '../models/inputstring';
import {InputCheckbox} from '../models/inputcheckbox';
import * as moment from 'moment';
import {TableSettings} from '../models/tablesettings';
import {ExportListItem} from '../models/export-list-item';
import {NotificationService} from '../shared/notification.service';
import {CSVService} from '../services/csv.service';
import {TableHeader} from '../models/tableheader';
import {BsDatepickerConfig} from 'ngx-bootstrap';
import {Location} from "@angular/common";

@Component({
    selector: 'app-export-create',
    templateUrl: './export-create.component.html',
    styleUrls: ['./export-create.component.scss']
})
export class ExportCreateComponent implements OnInit {

    isLoading: boolean = false;

    customerAndEmployee: ExportListItem[] = [];

    isDropdownActive: boolean = false;
    inputDropdown: string = "";
    inputDropdownSelected: number;

    //Inputs
    inputCustomer: InputString = new InputString();
    inputPeriod: any;
    inputPeriodError: InputString = new InputString();

    inputStatus: InputString = new InputString();
    inputType: InputString = new InputString();
    inputCustomerOrEmployeeSelected: ExportListItem;
    inputCustomerOrEmployeeSelectedError: InputString = new InputString();
    timeoutId: any;
    public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();


    inputMarkAsExported: InputCheckbox = new InputCheckbox();
    inputIncludePreviouslyExported: InputCheckbox = new InputCheckbox();

    constructor(
        public apiService: ApiService,
        public route: ActivatedRoute,
        private router: Router,
        private csvService: CSVService,
        public utilService: UtilService,
        public lang: LangService,
        public dataService: DataService,
        public notificationService: NotificationService,
        private location: Location,
    ) {
    }

    ngOnInit() {
        this.dpConfig = this.utilService.getDatePickerConfig();

        //Presat
        this.inputCustomer.value = "0";
        this.inputStatus.value = "godkendt";
        this.inputType.value = "samtlige";

        this.onSearchChange('');
    }

    onGetCustomer() {
        // clear the list of customers and employees before every update

        let tableSettings = new TableSettings();
        tableSettings.results = 500;
        if (this.inputDropdown) {
            tableSettings.tableSettingsContains.push(new TableSettingsContains('customer_name', this.inputDropdown));
        }
        tableSettings.tableSettingsContains.push(new TableSettingsContains('customer_active', 1));
        tableSettings.sortBy = new TableHeader('customer_name', 'customer_name');
        tableSettings.sortBy.asc = !tableSettings.sortBy.asc;

        return this.apiService.getCustomers(tableSettings).toPromise()
            .then((data) => {

                for (let i = 0; i < data.main.length; i++) {
                    const element = data.main[i];

                    var exi = new ExportListItem();
                    exi.name = element.customer_name;
                    exi.customer_id = element.customer_id;
                    exi.type = "Kunde";

                    this.customerAndEmployee.push(exi);

                }

            }, (error) => {
            });
    }

    onGetEmployee() {

        let tableSettings = new TableSettings();
        tableSettings.results = 500;

        if (this.inputDropdown) {
            tableSettings.tableSettingsContains.push(new TableSettingsContains('user_name', this.inputDropdown));
        }

        tableSettings.tableSettingsContains.push(new TableSettingsContains('user_role_id', 2));
        tableSettings.tableSettingsContains.push(new TableSettingsContains('user_active', 1));

        tableSettings.sortBy = new TableHeader('user_name', 'user_name');
        tableSettings.sortBy.asc = !tableSettings.sortBy.asc;

        return this.apiService.getUsers(tableSettings).toPromise().then((data) => {

            // If search matches alle medarbejdere, include in search
            if (!this.inputDropdown || ("alle medarbejdere".includes(this.inputDropdown.toLowerCase()) && this.inputDropdown.length > 0)) {
                var exi = new ExportListItem();
                exi.name = "Alle Medarbejdere";
                exi.user_id = -1;
                exi.type = "Medarbejder";
                this.customerAndEmployee.push(exi);
            }

            for (let i = 0; i < data.main.length; i++) {
                const element = data.main[i];

                var exi = new ExportListItem();
                exi.name = element.user_firstname + " " + element.user_lastname;
                exi.user_id = element.user_id;
                exi.type = "Medarbejder";

                this.customerAndEmployee.push(exi);
            }

        }, (error) => {

        });
    }

    onGoBack() {
        this.location.back();
    }

    onItemSelected(pos) {
        this.inputDropdownSelected = pos;

        this.inputCustomerOrEmployeeSelected = this.customerAndEmployee[pos];
        this.inputDropdown = this.customerAndEmployee[pos].type + ": " + this.customerAndEmployee[pos].name;
        this.isDropdownActive = false;
        this.inputCustomerOrEmployeeSelectedError.error = "";
    }

    onSearchChange(element) {
        if (this.timeoutId) window.clearTimeout(this.timeoutId);
        this.timeoutId = window.setTimeout(() => {
            this.customerAndEmployee = [];
            Promise.all([
                this.onGetCustomer(),
                this.onGetEmployee()
            ]).then((response) => {
                this.customerAndEmployee.sort((a, b) => ("" + (a.type + a.name) > "" + (b.type + b.name)) ? 1 : -1)
            });

        }, 500)
    }


    onFocusOut() {

    }

    onClearSearch() {
        this.inputDropdown = "";
        this.inputDropdownSelected = -1;
        this.inputCustomerOrEmployeeSelected = undefined;
    }

    onCheckForms(): boolean {
        var validForm = true;

        if (this.inputCustomerOrEmployeeSelected == undefined) {
            this.inputCustomerOrEmployeeSelectedError.error = "Vælg medarbejder eller kunde";
            validForm = false;
        }
        if (this.inputPeriod == undefined) {
            this.inputPeriodError.error = "Vælg periode";
            validForm = false;
        }
        if (this.inputStatus.error = this.utilService.checkFilled(this.inputStatus.value)) {
            validForm = false;
        }
        if (this.inputType.error = this.utilService.checkFilled(this.inputType.value)) {
            validForm = false;
        }
        return validForm;
    }


    onCreateExport() {
        if (!this.onCheckForms()) {
            return;
        }

        if (this.inputCustomerOrEmployeeSelected == undefined) {
            return;
        }
        if (this.inputPeriod == undefined) {
            return;
        }

        this.isLoading = true;

        var data = {
            customer_id: null,
            user_id: null,
            export_mark_assignments_as_exported: this.inputMarkAsExported.value,
            export_include_previously_exported_assignments: this.inputIncludePreviouslyExported.value,
            export_start: moment(this.inputPeriod[0]).format('YYYY-MM-DD 00:00:00'),
            export_end: moment(this.inputPeriod[1]).format('YYYY-MM-DD 23:59:59'),
            export_assignment_status: this.inputStatus.value,
            export_assignment_type: this.inputType.value
        };


        if (this.inputCustomerOrEmployeeSelected.type == "Medarbejder") {
            data.user_id = this.inputCustomerOrEmployeeSelected.user_id;
        } else {
            data.customer_id = this.inputCustomerOrEmployeeSelected.customer_id;
        }

        var export_object: Export = new Export(data);

        this.apiService.postExport(export_object).subscribe((data) => {
            this.isLoading = false;

            var exports: Export = new Export(data['data']);
            this.csvService.exportCSVdata = exports;

            this.notificationService.setNotification("Succesfuldt oprettet", this.notificationService.CONSTANT_TYPE_NORMAL);
            this.router.navigate(['app/export/show/' + data['data']['export_id']]);
        }, (error) => {
            this.notificationService.setNotification(error.error.message, this.notificationService.CONSTANT_TYPE_WARNING);
            this.isLoading = false;
        });
    }

}
