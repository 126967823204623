import * as moment from 'moment-timezone';
import { Constants } from '../constants';
import { Retry } from '../models/retry';
import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
var UtilService = /** @class */ (function () {
    function UtilService() {
        this.timeoption_quarter = ["00:00", "00:15", "00:30", "00:45", "01:00", "01:15", "01:30", "01:45", "02:00", "02:15", "02:30", "02:45", "03:00", "03:15", "03:30", "03:45", "04:00", "04:15", "04:30", "04:45", "05:00", "05:15", "05:30", "05:45", "06:00", "06:15", "06:30", "06:45", "07:00", "07:15", "07:30", "07:45", "08:00", "08:15", "08:30", "08:45", "09:00", "09:15", "09:30", "09:45", "10:00", "10:15", "10:30", "10:45", "11:00", "11:15", "11:30", "11:45", "12:00", "12:15", "12:30", "12:45", "13:00", "13:15", "13:30", "13:45", "14:00", "14:15", "14:30", "14:45", "15:00", "15:15", "15:30", "15:45", "16:00", "16:15", "16:30", "16:45", "17:00", "17:15", "17:30", "17:45", "18:00", "18:15", "18:30", "18:45", "19:00", "19:15", "19:30", "19:45", "20:00", "20:15", "20:30", "20:45", "21:00", "21:15", "21:30", "21:45", "22:00", "22:15", "22:30", "22:45", "23:00", "23:15", "23:30", "23:45"];
        this.timeoption_quarter_12 = ["00:00", "00:15", "00:30", "00:45", "01:00", "01:15", "01:30", "01:45", "02:00", "02:15", "02:30", "02:45", "03:00", "03:15", "03:30", "03:45", "04:00", "04:15", "04:30", "04:45", "05:00", "05:15", "05:30", "05:45", "06:00", "06:15", "06:30", "06:45", "07:00", "07:15", "07:30", "07:45", "08:00", "08:15", "08:30", "08:45", "09:00", "09:15", "09:30", "09:45", "10:00", "10:15", "10:30", "10:45", "11:00", "11:15", "11:30", "11:45", "12:00"];
        this.retry = {};
    }
    UtilService.prototype.sortData = function (table, sorting_table, asc) {
        var compare = function (a, b) {
            a = typeof a === "string" ? a.toLowerCase() : a;
            b = typeof b === "string" ? b.toLowerCase() : b;
            if (asc) {
                if (a[sorting_table] < b[sorting_table]) {
                    return -1;
                }
                else if (a[sorting_table] > b[sorting_table]) {
                    return 1;
                }
            }
            else {
                if (a[sorting_table] < b[sorting_table]) {
                    return 1;
                }
                else if (a[sorting_table] > b[sorting_table]) {
                    return -1;
                }
            }
            return 0;
        };
        return table.sort(compare);
    };
    UtilService.prototype.onFormatToEstimate = function (ms) {
        var minutes = ms / 60 / 1000;
        return minutes.toString();
    };
    /**
     * View utils
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    UtilService.prototype.onUppercase = function (str) {
        if (str != undefined && str.length > 1) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }
        else {
            return str;
        }
    };
    UtilService.prototype.onDisplayLogTextPressed = function (router, log) {
        if (log.user_log_table == "assignments") {
            router.navigate(['./app/task/show/', log.user_log_table_id]);
        }
        else {
            router.navigate(['./app/user/show/', log.user_log_table_id]);
        }
    };
    UtilService.prototype.onDisplayLogText = function (log, u) {
        var text = '';
        switch (log.user_log_action) {
            case 'edited':
                if (log.user_log_table == "assignments") {
                    text = "redigerede  i opgave <a>#" + log.user_log_table_id + "</a>";
                }
                else {
                    text = "redigerede  hos <a>" + (u.user_firstname + '' + u.user_lastname) + "</a>";
                }
                break;
            case 'accepted':
                text = 'accepterede opgaven';
                break;
            case 'salary_been_given':
                text = "har <a href=\"/app/export/show/" + log.parsed_user_log_text.export_id + "\">k\u00F8rt l\u00F8n</a> for opgaven";
                break;
            case 'invoice_been_run':
                text = "har <a href=\"/app/export/show/" + log.parsed_user_log_text.export_id + "\">faktureret</a> opgaven";
                break;
            default:
                if (log.user_log_table == "assignments") {
                    text = "oprettede opgave <a>#" + log.user_log_table_id + "</a>";
                }
                else {
                    text = "oprettede <a>" + (u.user_firstname + '' + u.user_lastname) + "</a>";
                }
                break;
        }
        return text;
    };
    UtilService.prototype.onImageToView = function (image_name) {
        return Constants.IMAGE_URL + image_name + ".jpg";
    };
    UtilService.prototype.onImageToViewThumbnail = function (image_name) {
        return Constants.IMAGE_URL + image_name + "_thumb.jpg";
    };
    UtilService.prototype.onAppendixToView = function (image_name) {
        image_name = image_name.toLowerCase();
        var imageType = image_name ? image_name.substring(image_name.lastIndexOf('.'), image_name.length).replace('.', '') : 'others';
        imageType = imageType.toLowerCase();
        var imageFormats = ['png', 'jpeg', 'jpg', 'gif'];
        var wordFormats = ['doc', 'dot', 'wbk', 'docx', 'dotx', 'dotm', 'docb'];
        var excelFormats = ['xls', 'xlt', 'xlm', 'xlsx', 'xlsm', 'xltx', 'xltm', 'xlsb', 'xla', 'xlam', 'xll', 'xlw'];
        var powerPointFormats = ['ppt', 'pot', 'pps', 'pptx', 'pptm', 'potx', 'potm', 'ppam', 'ppsx', 'ppsm', 'sldx', 'sldm'];
        var pdfFormat = ['pdf'];
        var csvFormat = ['csv'];
        if (imageFormats.includes(imageType)) {
            return Constants.APPENDIX_URL + image_name;
        }
        else if (wordFormats.includes(imageType)) {
            return '../assets/placeholder_doc.svg';
        }
        else if (excelFormats.includes(imageType)) {
            return '../assets/placeholder_xls.svg';
        }
        else if (powerPointFormats.includes(imageType)) {
            return '../assets/placeholder_ppt.svg';
        }
        else if (pdfFormat.includes(imageType)) {
            return '../assets/placeholder_pdf.svg';
        }
        else if (csvFormat.includes(imageType)) {
            return '../assets/placeholder_csv.svg';
        }
        else {
            return '../assets/placeholder.svg';
        }
    };
    UtilService.prototype.onAppendixToDownload = function (image_name) {
        return Constants.APPENDIX_URL + image_name;
    };
    UtilService.prototype.onConvertMiliToHoursAndMili = function (duration) {
        var durationNumber = parseInt(duration);
        if (durationNumber < 1000) {
            return "00:00";
        }
        var m = (durationNumber / 1000 / 60) % 60;
        var h = ((durationNumber - m) / 1000 / 60 / 60);
        // @ts-ignore
        var minutes = parseInt(m).toString(10);
        // @ts-ignore
        var hours = parseInt(h).toString(10);
        var hoursString = (hours < 10) ? "0" + hours : hours;
        var minutesString = (minutes < 10) ? "0" + minutes : minutes;
        return hoursString + ":" + minutesString;
    };
    UtilService.prototype.onConvertHHmmFormatToMili = function (format) {
        var hhmmformat = format.split(":");
        var hh = Math.floor(+hhmmformat[0] * 3600000);
        var mm = Math.floor(+hhmmformat[1] * 60000);
        return (hh + mm);
    };
    UtilService.prototype.onConvertMiliToFormat = function (duration) {
        var durationNumber = parseInt(duration);
        if (durationNumber < 1000) {
            return "00:00:00";
        }
        var seconds = parseInt("" + (durationNumber / 1000) % 60);
        var minutes = parseInt("" + (durationNumber / (1000 * 60)) % 60);
        var hours = parseInt("" + (durationNumber / (1000 * 60 * 60)) % 24);
        var hoursString = (hours < 10) ? "0" + hours : hours;
        var minutesString = (minutes < 10) ? "0" + minutes : minutes;
        var secondsString = (seconds < 10) ? "0" + seconds : seconds;
        return hoursString + ":" + minutesString + ":" + secondsString;
    };
    UtilService.prototype.onConvertDatesToDurationsMiliseconds = function (datestart, dateend) {
        var ms = moment(dateend).diff(moment(datestart));
        return +moment.duration(ms);
    };
    UtilService.prototype.convertDatesToFormat = function (datestart, dateend) {
        return this.onConvertMiliToFormat("" + this.onConvertDatesToDurationsMiliseconds(datestart, dateend));
    };
    UtilService.prototype.convertMiliToHours = function (mili) {
        var hours = +mili / 1000 / 60 / 60;
        return hours;
    };
    UtilService.prototype.convertEstimateToMili = function (mili) {
        var miliSplit = mili.split(":");
        var miliReturn = ((+miliSplit[0] * 60 * 60) + (+miliSplit[1] * 60)) * 1000;
        return miliReturn;
    };
    UtilService.prototype.onRetryApi = function (identifier, callback, error) {
        if (this.retry[identifier] == undefined) {
            this.retry[identifier] = new Retry(identifier);
        }
        else {
            this.retry[identifier].retry_number = this.retry[identifier].retry_number + 1;
        }
        if (this.retry[identifier].retry_number == 3) {
            delete this.retry[identifier];
            error();
            return;
        }
        else {
            setTimeout(function () {
                callback();
            }, 1000);
        }
    };
    UtilService.prototype.onGetTableContentDot = function (item, tablevalue) {
        if (tablevalue.indexOf(".") !== -1) {
            var splitTableValue = tablevalue.split(".");
            if (item[splitTableValue[0]] == undefined) {
                return "";
            }
            if (Array.isArray(item[splitTableValue[0]])) {
                var returnString = "";
                for (var i = 0; i < item[splitTableValue[0]].length; i++) {
                    var element = item[splitTableValue[0]][i][splitTableValue[1]];
                    returnString = returnString + "\n" + element;
                }
                return returnString;
            }
            if (item[splitTableValue[0]][splitTableValue[1]] == undefined) {
                return "";
            }
            return item[splitTableValue[0]][splitTableValue[1]];
        }
        return item[tablevalue];
    };
    UtilService.prototype.onGetTableContent = function (item, tablevalue, dropdown) {
        if (dropdown === void 0) { dropdown = false; }
        if (tablevalue.indexOf(".") !== -1 && tablevalue.indexOf("&") === -1) {
            return this.onGetTableContentDot(item, tablevalue);
        }
        if (tablevalue.indexOf("&") !== -1) {
            var splitTableValue = tablevalue.split("&");
            var firstValue = "";
            var lastValue = "";
            if (splitTableValue[0].indexOf(".") !== -1) {
                firstValue = this.onGetTableContentDot(item, splitTableValue[0]);
            }
            else {
                firstValue = item[splitTableValue[0]];
            }
            if (splitTableValue[1].indexOf(".") !== -1) {
                lastValue = this.onGetTableContentDot(item, splitTableValue[1]);
            }
            else {
                lastValue = item[splitTableValue[1]];
            }
            return firstValue + " " + lastValue;
        }
        if (tablevalue === 'store_name' && dropdown) {
            return item[tablevalue] + " (" + item['store_address'] + ")";
        }
        if (tablevalue === 'customers' && item['customers'].length) {
            return item['customers'].map(function (customer) { return customer.customer_name; }).join(', ');
        }
        if (tablevalue === 'user_firstname') {
            return item['user_firstname'] + ' ' + item['user_lastname'];
        }
        return item[tablevalue];
    };
    /**
     * Validations
     * Author: Tommy Jepsen - tommy@tonsstudio.com
     **/
    UtilService.prototype.checkFilled = function (control) {
        if (control == undefined || control.length == 0) {
            return "Skal udfyldes";
        }
        else {
            return "";
        }
    };
    UtilService.prototype.checkIfChecked = function () {
        var boxes = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            boxes[_i] = arguments[_i];
        }
        var anyCheckd = false;
        for (var i = 0; i < boxes.length; i++) {
            if (boxes[i].value) {
                anyCheckd = true;
            }
        }
        if (anyCheckd) {
            return "";
        }
        else {
            return "Vælg mindst én opgave";
        }
    };
    UtilService.prototype.checkMultipleIfChecked = function () {
        var inputCheckboxs = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            inputCheckboxs[_i] = arguments[_i];
        }
        var anyCheckd = false;
        for (var i = 0; i < inputCheckboxs.length; i++) {
            if (inputCheckboxs[i].error = this.checkIfChecked(inputCheckboxs[i])) {
                anyCheckd = false;
            }
            else {
                anyCheckd = true;
            }
        }
        return anyCheckd;
    };
    UtilService.prototype.checkMultipleIfvalid = function () {
        var inputStrings = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            inputStrings[_i] = arguments[_i];
        }
        var anyCheckd = false;
        for (var i = 0; i < inputStrings.length; i++) {
            if (inputStrings[i].error = this.checkFilled(inputStrings[i].value)) {
                anyCheckd = true;
            }
        }
        return anyCheckd;
    };
    UtilService.prototype.resetErrors = function () {
        var inputStrings = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            inputStrings[_i] = arguments[_i];
        }
        for (var i = 0; i < inputStrings.length; i++) {
            inputStrings[i].error = "";
        }
    };
    UtilService.prototype.checkNumber = function (control) {
        var pattern = /^\d+$/;
        if (control == undefined || !pattern.test(control)) {
            return "Skal være et nummer";
        }
        else {
            return "";
        }
    };
    UtilService.prototype.checkLength = function (control, nolength) {
        if (control == undefined || control.length > nolength) {
            return "Må ikke være længere end " + nolength;
        }
        else {
            return "";
        }
    };
    UtilService.prototype.checkMail = function (control) {
        var emailFilter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/;
        if (!emailFilter.test(control)) {
            return "Ikke gyldig email";
        }
        return "";
    };
    UtilService.prototype.checkPhoneNumber = function (control) {
        if (control == undefined || control.length < 8) {
            return "Ikke gyldigt telefonnummer";
        }
        return "";
    };
    UtilService.prototype.checkPassword = function (input) {
        if (input.value.length < 8) {
            input.error = "Mindst 8";
        }
        if (input.value.length > 40) {
            input.error = "For stor";
        }
        var pwFilter = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/;
        if (!pwFilter.test(input.value)) {
            input.error = "Adgangskoden skal være mindst 8 tegn, indeholde mindst et stort og et småt bogstav og mindst et tal";
        }
        return input.error === '';
    };
    UtilService.prototype.implodeTitle = function (array, key) {
        var _this = this;
        if (typeof array == 'string')
            return array;
        return array
            .map(function (item) {
            return ' ' + _this.onUppercase(item[key]).trim();
        })
            .toString()
            .trim();
    };
    UtilService.prototype.chunkArray = function (array, size) {
        var chunks = [];
        for (var i = 0; i < Math.ceil(array.length / size); i++) {
            chunks.push(array.slice(size * i, size * i + size));
        }
        return chunks;
    };
    UtilService.prototype.fromUTC = function (date) {
        return moment.tz(date, 'UTC').tz('Europe/Copenhagen');
    };
    UtilService.prototype.toUTC = function (date) {
        return moment.tz(date, 'Europe/Copenhagen').tz('UTC');
    };
    UtilService.prototype.getDomElement = function (selector) {
        return new Promise(function (resolve, reject) {
            var element = document.querySelector(selector);
            var interval = setInterval(function () {
                if (!element) {
                    element = document.querySelector(selector);
                }
                if (element) {
                    clearInterval(interval);
                    resolve(element);
                }
            }, 50);
        });
    };
    UtilService.prototype.downloadFile = function (fileName, contentType, xhr) {
        var blob = new Blob([xhr.response], { type: contentType });
        var link = document.createElement('a');
        if (window.navigator.msSaveOrOpenBlob) {
            //IE11 & Edge
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        }
        else {
            //Other browsers
            link.href = window.URL.createObjectURL(blob);
        }
        link.download = fileName;
        link.style.cssText = 'display:none;';
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    };
    UtilService.prototype.getDatePickerConfig = function () {
        var config = new BsDatepickerConfig();
        config['locale'] = 'da';
        return config;
    };
    return UtilService;
}());
export { UtilService };
