import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-graph',
    templateUrl: './graph.component.html'
})
export class GraphComponent implements OnInit {

    @Input()
    graphList: any[] = [];

    graph: any = {
        "side_left": [],
        "side_right": [0, 1, 2, 3, 4, 5, 6],
        "graphs_elements": [4045, 777, 4845, 3215, 5444, 2111, 3445],
        "graphs_elements_x": ["Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag", "Søndag"],
        "graphs_elements_height": [],
        "graphs_elements_y": [],

    };

    showGraph1: boolean = false;
    maxHeight: number = 238;
    xInGraph: number = 311;

    constructor() {
    }

    ngOnInit() {
        this.graph.graphs_elements = this.graphList;
        this.onCalculateXposition();
    }

    onCalculateXposition() {
        //Find max height
        var maxHeightInArray = this.graph.graphs_elements[0];
        for (let i = 1; i < this.graph.graphs_elements.length; i++) {
            if (this.graph.graphs_elements[i] > maxHeightInArray) {
                maxHeightInArray = this.graph.graphs_elements[i];
            }
        }

        //Number to divide with
        var numberToDivideWith = maxHeightInArray / this.maxHeight;
        for (let i = 0; i < this.graph.graphs_elements.length; i++) {
            var height: number = Math.floor(this.graph.graphs_elements[i] / numberToDivideWith);
            var x = this.xInGraph - height;

            this.graph.graphs_elements_height.push(height);
            this.graph.graphs_elements_y.push(x);
        }

        //SIDE LEFT
        var sidestart = 0;
        this.graph.side_left.push(sidestart);
        var side = 0;
        for (let i = 0; i < 7; i++) {
            side = Math.floor(side + (maxHeightInArray / 6));
            this.graph.side_left.push(side);
        }
        this.graph.side_left.push(maxHeightInArray);
    }

}
