import {Component, Input, OnChanges} from '@angular/core';

@Component({
    selector: 'app-import-column-mapper',
    templateUrl: './import-column-mapper.component.html',
    styleUrls: ['./import-column-mapper.component.scss']
})
export class ImportColumnMapperComponent implements OnChanges {
    @Input() csv: any = [];
    @Input() validation_columns: any = [];

    constructor() {
    }

    ngOnChanges(changes) {
        this.mapKnownColumns();
    }

    isColumnSelected(csv_header, index) {
        return !!this.validation_columns.find(col => csv_header == col.csv_header && col.picked_by == index);
    }

    onColumnSelected(csv_header, type, position) {
        if (type == -1) {
            // unset the csv_header to remove the selection
            let col = this.validation_columns.find(col => col.csv_header == csv_header);
            col.picked_by = false;
            col.csv_header = undefined;
        } else {
            // set the csv_header to add the selection
            let col = this.validation_columns.find(col => col.type == type);
            col.csv_header = csv_header;
            col.picked_by = position;
        }
    }

    maxLengthString(str): string {
        if (str.length > 35) {
            return str.slice(0, 35) + "..";
        } else {
            return str;
        }
    }

    mapKnownColumns() {
        if (this.csv.length < 1) return;

        const csv_headers = this.csv[0];
        let column_idx = 0;
        for (let csv_header of csv_headers) {
            const column = this.validation_columns.find(col => col.alias === csv_header.toLowerCase());
            if (column && column.picked_by === false) {
                column.picked_by = column_idx;
                column.csv_header = csv_header;
            }
            column_idx++;
        }
    }
}
